import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Oliver_Icon_Color from '../../../assets/images/svg/Oliver-Icon-Color.svg';
import Oliver_Type from '../../../assets/images/svg/Oliver-Type.svg';
import Register_Icon from '../../../assets/images/svg/Register-Icon.svg';
import Customers_Icon from '../../../assets/images/svg/Customers-Icon.svg';
import Transactions_Icon from '../../../assets/images/svg/Transactions-Icon.svg';
import CashManagement_Icon from '../../../assets/images/svg/CashManagement-Icon.svg';
import Oliver_Icon_BaseBlue from '../../../assets/images/svg/Oliver-Icon-BaseBlue.svg';
import ToggleNavbar_Icon from '../../../assets/images/svg/ToggleNavbar-Icon.svg';
import Vector45 from '../../../assets/images/svg/Vector45.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import AppLauncher from "./AppLauncher";
import IframeWindow from "../../dashboard/IframeWindow";

import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { popupMessage } from "../commonAPIs/messageSlice";
import { CheckAppDisplayInView, UpdateRecentUsedApp } from '../commonFunctions/AppDisplayFunction';
import NoImageAvailable from '../../../assets/images/svg/NoImageAvailable.svg';
import MsgPopup from "../../common/commonComponents/MsgPopup";
import STATUSES from "../../../constants/apiStatus";
import { handleAppEvent } from '../../common/AppHandeler/commonAppHandler';
import IdleScreen from "./IdleScreen";

import { NavigateComponent } from "../../../settings";
import { Amelia } from "../../amelia/Amelia";
import { IsFreePlan } from "../../../settings/CommonJS";
import ResyncOrder from "../../checkout/ResyncOrder";
function LeftNavBar(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isShowLeftMenu, setisShowLeftMenu] = useState(false);
    const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
    const [isShowLinkLauncher, setisShowLinkLauncher] = useState(false);
    const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
    const [extApp, setExtApp] = useState('');
    const [isInit, setisInit] = useState(false);
    let _useCancelled = false;
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [warningImg, setWarningImg] = useState('');
    const [isBack, setIsBack] = useState(false);
    const [isFreePlan,setIsFreePlan]=useState(false);
    const [hasNoAccess,setHasNoAccess]=useState(false);

    var isCallOnce=false;
    useEffect(() => {
        if(isCallOnce==false){          
            if(IsFreePlan()==true){
            setIsFreePlan(true);
                if((location.pathname === "/transactions" || location.pathname === "/customers" || location.pathname === "/cashdrawer"))
                {  isCallOnce=true;
                    setHasNoAccess(true);   
                }        
}else{
                    if(location.pathname === "/cashdrawer"){
                        var isAllowCashManagement=true;
                        if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement !== true) {
                            isAllowCashManagement=false;
                        }
                        else if (selectedRegister && selectedRegister.EnableCashManagement === false) {
                            isAllowCashManagement=false;
                        }
                        if(isAllowCashDrawer==false){
                            navigate(-1)
                        }
                    }
                    

                }
        }
    },[])

    var isNoBack = false
    useEffect(() => {   
        if (isNoBack === false) {
            // Hey, a popstate event happened!
            window.addEventListener("popstate", e => {
                // Nope, go back to your page
                e.currentTarget.history.go(1)
               // console.log("popstate event--"+e)
                isNoBack = true;
            });
        }
    });
    //-------Start short key press handlling------------------
    const handleKeyPress = useCallback((event) => {
        console.log(`Key pressed: ${event.key}`);
        console.log(`Key code: ${event.keyCode}`);
        if (event.keyCode === 112) //f1
            NavigateComponent(navigate,"home")
        if (event.keyCode === 113) //f2
            navigate('/customers')
        if (event.keyCode === 114)  //f3
            navigate('/transactions')
        if (event.keyCode === 115) //f4
            navigate('/cashdrawer')
        return
    }, []);
    const handleF10 = () => {

        if (window.location.pathname == '/home') {
            props.toggleAdvancedSearch() && props.toggleAdvancedSearch()
        }

    }
    useEffect(() => {
        // attach the event listener
        window.onkeydown = evt => {
            if (window.location.pathname === '/checkout') {
                return;
            }
            switch (evt.keyCode) {
                //F1
                case 112:
                    NavigateComponent(navigate,"home");
                    break;
                case 113: //f2
                    navigate('/customers')
                    break;
                case 114:  //f3
                    navigate('/transactions')
                    break;
                case 115: //f4
                    navigate('/cashdrawer')
                    break;
                case 121: //f10
                    handleF10()
                    break;
                default:
                    return true;
            }
            //Returning false overrides default browser event
            return false;
        };
        // document.addEventListener('keydown', handleKeyPress);

        // // remove the event listener
        // return () => {
        //     document.removeEventListener('keydown', handleKeyPress);
        // };
    }, [navigate]);
    //-------End short key press handlling------------------
    useEffect(() => {
        if (location.pathname !== "/checkout" && location.pathname !== "/refund") {
            if (isInit === false && _useCancelled === false) {

                window.addEventListener('message', function (e) {
                    var data = e && e.data;
                    if (typeof data === 'string' && data !== "" && location.pathname !== "/checkout" && window.location.pathname !== "/checkout" && location.pathname !== "/refund" && window.location.pathname !== "/refund") {  //checkout page handle independentaly
                        try {
                            var _data = data && JSON.parse(data);
                            responseData(_data)
                        } catch (e) {
                            // console.log(e);
                        }

                    }
                })
                setisInit(true);
            }
            return () => {
                _useCancelled = true;
            }
        }
    }, [isInit]);

    const responseData = (data) => {
        var _route = window.location.pathname;
        var whereToview = "";
        
        if (_route == "/customers"){
            whereToview = "CustomerView"
        }
        else if (_route == "/checkout"){
            whereToview = "CheckoutView"}
        else
            {
                if(sessionStorage.getItem("view")=="Product View"){
                    whereToview = "ProductView";
                }else{
                    whereToview = "home";
                }
        }
        var response = handleAppEvent(data, whereToview, false, navigate);       
    }
    const toggleLeftMenu = () => {
        setisShowLeftMenu(!isShowLeftMenu)
    }
   
    const toggleAppLauncher = () => {
        setisShowAppLauncher(!isShowAppLauncher)
        setisShowLinkLauncher(false)
    }
    const toggleLinkLauncher = () => {
        setisShowLinkLauncher(!isShowLinkLauncher)
        setisShowAppLauncher(false)
    }
    const navigateTo = (page) => {
        setisShowLeftMenu(false)
        sessionStorage.removeItem('notificationRedirect');
        sessionStorage.removeItem('transactionredirect');
        sessionStorage.removeItem('customerredirect');
        navigate(page);
    }
    function ToggleiFrameWindow(_exApp = null, view = null) {
        
        if (_exApp !== null) { setExtApp(_exApp); }

        if(props.toggleiFrameWindow ){//&& props.isRefreshCheckList ==true
            props.toggleiFrameWindow(_exApp,view)
           // return ;
        }
        else if (view === "Shop View" ) { //&& (window.location.hostname.includes("localhost") || window.location.hostname.includes("dev1.sell.oliverpos.com"))
            props.toggleAmelia && props.toggleAmelia();

            if (props.isShowAmelia == true) {
                UpdateRecentUsedApp(_exApp, true, 0, view)
            }
        }
        else {
            if (isShowiFrameWindow === false) {
                UpdateRecentUsedApp(_exApp, true, 0, view)
            }
            setisShowiFrameWindow(!isShowiFrameWindow)
        }
    }
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }


    const [respopupMessage] = useSelector((state) => [state.popupMessage])
    useEffect(() => {
        if (respopupMessage && respopupMessage.status === STATUSES.IDLE && respopupMessage.is_success && respopupMessage.data) {
            toggleMsgPopup(true);
            setmsgBody(respopupMessage.data.msg);
            setmsgTitle(respopupMessage.data.title);
            setWarningImg(respopupMessage.data.img);
            if(respopupMessage.data.isBack)
            {
                setIsBack(respopupMessage.data.isBack)
            }
          
            dispatch(popupMessage(null));
        }
    }, [respopupMessage]);


    var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
    var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem("selectedRegister")) : '';
    var isAllowCashDrawer = false;
    if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement === true && selectedRegister && selectedRegister.EnableCashManagement === true) {
        isAllowCashDrawer = true;
    }
    if(isFreePlan==true){
        isAllowCashDrawer = true;
    }

    const notInCurrentPlan = () => {
        var msg = "";
        var title = "";
        if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement !== true) {
            title = LocalizedLanguage.notInCurrentPlan;
            msg = LocalizedLanguage.toUpdateCurrentPlan;
        }
        else if (selectedRegister && selectedRegister.EnableCashManagement === false) {
            title =  LocalizedLanguage.cashDisabled+"!";
            msg = LocalizedLanguage.cashDrawerPermission;
        }

        var data = { title: title, msg: msg, is_success: true }
        dispatch(popupMessage(data));
    }

    //Display 3 Most used app---------------------***********-----------------------
    var allAppList = JSON.parse(localStorage.getItem("GET_EXTENTION_FIELD")) ? JSON.parse(localStorage.getItem("GET_EXTENTION_FIELD")) : [];
    var mostUsedApp = localStorage.getItem("recent_apps") && JSON.parse(localStorage.getItem("recent_apps"));
    if (mostUsedApp && mostUsedApp.length > 0) {
        //const sortDesc = (_recentApp, used_count) => {
        mostUsedApp = mostUsedApp.sort((a, b) => {   //sort by most used app
            if (a["used_count"] > b['used_count']) { return -1; }
            if (b["used_count"] > a["used_count"]) { return 1; }
            return 0;
        })
        //  console.log("sortDesc", mostUsedApp)
        //}
    }
    var appsList = []
    var appDisplayCount = 0
    if (allAppList && mostUsedApp && mostUsedApp !== null && mostUsedApp.length > 0) {
        mostUsedApp.map(function (itemUsed, index) {
            // if (index < 3) {
            var app = allAppList && allAppList.find(item => item.Id === itemUsed.app_id && itemUsed.used_count !== 0 && CheckAppDisplayInView(item.viewManagement, props.view) === true)
            if (app) {
                var recentAppForView = itemUsed.view_name && itemUsed.view_name.find(a => a.view_name == props.view);
                if (!recentAppForView)
                    app = null;
            }

            if (app && appDisplayCount < 3)//only 3 item need to display
            {
                appsList.push(app);
                appDisplayCount++
            }
            // }
        });
        if (appDisplayCount < 3) {
            mostUsedApp = mostUsedApp.sort((a, b) => {   //sort by most used app
                if (a["view_count"] > b['view_count']) { return -1; }
                if (b["view_count"] > a["view_count"]) { return 1; }
                return 0;
            })
            mostUsedApp.map(function (itemUsed, index) {
                // if (index < 3) {
                var app = allAppList && allAppList.find(item => item.Id === itemUsed.app_id && itemUsed.view_count !== 0 && CheckAppDisplayInView(item.viewManagement, props.view) === true)
                if (app) {
                    var recentAppForView = itemUsed.view_name && itemUsed.view_name.find(a => a.view_name == props.view);
                    if (recentAppForView) {
                        var isfound = appsList.find(a => a.Id == app.Id)
                        if (app && appDisplayCount < 3 && typeof isfound == "undefined")//only 3 item need to display
                        {
                            appsList.push(app);
                            appDisplayCount++
                        }
                    }
                    //app = null;


                }
                // }
            });
        }      
    }


    const DisplayPluginPopup = useMemo(()=>{   
        return <Amelia toggleAmelia={ToggleiFrameWindow} exApp={extApp} view={sessionStorage.getItem("view") ? sessionStorage.getItem("view") : props.view}></Amelia> ;
    },[ToggleiFrameWindow]);

    const DisplayIFrame = useMemo(()=>{   
        return <IframeWindow exApp={extApp} isShow={isShowiFrameWindow} ToggleiFrameWindow={ToggleiFrameWindow} view={sessionStorage.getItem("view") ? sessionStorage.getItem("view") : props.view}></IframeWindow> ;
    },[extApp]);
    //----------------------------**************----------------------------------------
    //console.log("appsList", appsList)
    var displayAppCount = 0;
    var isVoid = ((location.pathname === "/checkout") === true && (typeof localStorage.getItem("oliver_order_payments") !== "undefined") && localStorage.getItem("oliver_order_payments") !== null) === true ? true : false;
    if (location.pathname !== "/home") {
        sessionStorage.removeItem("view")
        sessionStorage.removeItem("productId")
    }
    
    const handleErrorClick= () =>{     
        // set custom data when bug submitted Marker------------------------------
        var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
        if(client){
            window.Marker.setCustomData({
                client: client.user_full_name,
                email: client.user_email
            });
        }
            window.Marker.capture('advanced');
      }

    return (
        <React.Fragment> <IdleScreen></IdleScreen>
            <div className={isShowLeftMenu === true || (props.isShowMobLeftNav && props.isShowMobLeftNav === true) ? "navbar open" : "navbar"} style={{ zIndex: hasNoAccess==true ? 8: location.pathname === "/transactions" || location.pathname === "/customers" || location.pathname === "/cashdrawer" ? 5 : 4 }}>
                <div className="header-row" onClick={() => isVoid === false ? NavigateComponent(navigate,"home") : null}>
                    <img src={Oliver_Icon_Color} alt="" className="oliver-logo" />
                    <img src={Oliver_Type} alt="" className="oliver-text" />
                </div>
                <button id="registerButton" className={(location.pathname === "/home") ? "page-link selected" : isVoid === false ? location.pathname === "/checkout" ? "page-link selected" : "page-link" : "page-link disabled"} disabled={(location.pathname === "/checkout" || location.pathname === "/refund") ? true : isVoid === true ? true : false} onClick={() => isVoid === false ? navigateTo('/home') : null}>
                    <div className="img-container">
                        <img src={Register_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.home}</p>
                    <div className="f-key">F1</div>
                </button>
                <button id="customersButton" className={location.pathname === "/customers" ? "page-link selected" : location.pathname === "/checkout" ? "page-link disabled" : "page-link" ? location.pathname === "/refund" ? "page-link disabled" : "page-link" : ""} disabled={(location.pathname === "/checkout" || location.pathname === "/refund") ? true : false} onClick={() => navigateTo('/customers')}>
                    <div className="img-container">
                        <img src={Customers_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.customers}</p>
                    <div className="f-key">F2</div>
                </button>
                <button id="transactionsButton" className={location.pathname === "/transactions" ? "page-link selected" : location.pathname === "/checkout" ? "page-link disabled" : "page-link" ? location.pathname === "/refund" ? "page-link disabled" : "page-link" : ""} disabled={(location.pathname === "/checkout" || location.pathname === "/refund") ? true : false} onClick={() => navigateTo('/transactions')}>
                    <div className="img-container">
                        <img src={Transactions_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.transactions}</p>
                    <div className="f-key">F3</div>
                </button>
                {isAllowCashDrawer ?
                    <button id="cashManagementButton" className={location.pathname === "/cashdrawer" && isAllowCashDrawer === true ? "page-link selected" : location.pathname === "/checkout" ? "page-link disabled" : "page-link" ? location.pathname === "/refund" ? "page-link disabled" : "page-link" : ""} disabled={(isAllowCashDrawer === false || location.pathname === "/checkout" || location.pathname === "/refund") ? true : false}
                        onClick={() => isAllowCashDrawer === true ? navigateTo('/cashdrawer') : ""}>
                        <div className="img-container">
                            <img src={CashManagement_Icon} alt="" />
                        </div>
                        <p>{LocalizedLanguage.cashmanagement}</p>
                        <div className="f-key">F4</div>
                    </button>
                    :
                    <button id="cashManagementButton" className={location.pathname === "/cashdrawer" && isAllowCashDrawer === true ? "page-link selected" : "page-link" ? location.pathname === "/refund" || location.pathname === "/checkout" ? "page-link disabled" : "page-link" : ""} disabled={location.pathname === "/refund" || location.pathname === "/checkout" && isAllowCashDrawer === false ? true : false}
                        onClick={() => notInCurrentPlan()}>
                        <div className="img-container">
                            <img src={CashManagement_Icon} alt="" />
                        </div>
                        <p>{LocalizedLanguage.cashmanagement}</p>
                        <div className="f-key">F4</div>
                    </button>
                }
                 <div className="bug-card">
                        <div className="bug-card-group img-container">
                            <div className="bug-card-group-img " >
                            <img src={Vector45} onClick={()=>handleErrorClick()} ></img>
                            </div>
                            <p className="bug-card-heading">Found a bug?</p>
                        </div>
                        <div className="bug-card-group" >
                            <p className="bug-card-text">
                            You can easily capture and report bugs by clicking{" "}
                            <button className="bug-card-link" onClick={()=>handleErrorClick()}>
                                here
                            </button>{" "}
                            or pressing:{" "}
                            </p>
                        </div>
                        <div className="bug-card-footer">
                            <div className="bug-card-group">
                            <div className="bug-card-key">⌘</div>
                            <div className="bug-card-key">U</div>
                            <p className="bug-card-text">on Mac</p>
                            </div>
                            <div className="bug-card-group">
                            <div className="bug-card-key">ALT</div>
                            <div className="bug-card-key">U</div>
                            <p className="bug-card-text">on Windows</p>
                            </div>
                        </div>
        </div>
                {/* </button> */}
                <div className="divider"></div>
                <button id="appLauncherButton" className={isShowAppLauncher === true ? "launcher filter" : "launcher"} onClick={() => toggleAppLauncher()}>
                    <div className="img-container">
                        <img src={Oliver_Icon_BaseBlue} alt="" />
                    </div>
                    <p>{LocalizedLanguage.applauncher}</p>
                    {/* <div className="f-key">F6</div> */}
                </button>
                {/* display Apps for home page */}
                {
                    appsList && appsList !== null && appsList.length>0 && appsList.length > 0 && appsList.map((appItem, index) => {
                        var isDisplay = CheckAppDisplayInView(appItem.viewManagement, props.view)
                        {
                            return isDisplay === true &&
                                <button key={appItem.Id + "_" + index} id={appItem.Id + "_" + index} className="launcher app" onClick={() => ToggleiFrameWindow(appItem, props.view)}>
                                    <div className="img-container">                                      
                                        {appItem && appItem.logo !== null ? <img title={appItem.Name} src={appItem.logo} alt={appItem.Name} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = NoImageAvailable;
                                        }} /> : <img src={NoImageAvailable} alt="" />}
                                    </div>
                                    <p>{appItem.Name}</p>                                   
                                </button>

                        }
                    })}

                <button id="navToggle" className="toggle-nav" onClick={() => toggleLeftMenu()}>
                    <div className="img-container">
                        <img src={ToggleNavbar_Icon} alt="" />
                    </div>
                    <p>{LocalizedLanguage.minimizesidebar}</p>
                </button>
            </div>
            {isShowAppLauncher === true ? <AppLauncher view={sessionStorage.getItem("view") ? sessionStorage.getItem("view") : props.view} isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} ToggleiFrameWindow={ToggleiFrameWindow} isRefund={location.pathname === "/refund" || window.location.pathname === "/refund"}></AppLauncher> : null}
            {isShowiFrameWindow === true ? DisplayIFrame  : null}
            {props.isShowAmelia && props.isShowAmelia == true ? DisplayPluginPopup: null}
            <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody} img={warningImg} isBack={isBack}></MsgPopup>
            <ResyncOrder></ResyncOrder>
        </React.Fragment>)
}

export default LeftNavBar