import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import AngledBracket_Left_Blue from '../../assets/images/svg/AngledBracket-Left-Blue.svg'
//import Oliver_Icon_White from '../../assets/images/svg/Oliver-Icon-White.svg'
import AngledBracket_Right_Grey from '../../assets/images/svg/AngledBracket-Right-Grey.svg'
import WWW_Icon from '../../assets/images/svg/WWW-Icon.svg'
import CircledPlus_Icon_Blue from '../../assets/images/svg/CircledPlus-Icon-Blue.svg';
import Rounded_Square_Plus_Icon_NewGrey from '../../assets/images/svg/Rounded-Square-Plus-Icon-NewGrey.svg';

import { encode_UDid } from "../common/localSettings";
import { userLogin } from '../login/loginSlice';
import { NavigateComponent, RememberData } from "../../settings/CommonModuleJS";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import { useRef } from "react";
import { notificationStatus } from "../../settings/CommonWork";

const Site = () => {
    const registerHide = useRef();

    const dispatch = useDispatch()
    let useCancelled = false;
    useEffect(() => {
        if(notificationStatus()=='false')
        {
            navigate('/enablenotification?page=site');
            return;
        }
        if (localStorage.getItem("rememberme") && localStorage.getItem("rememberme") == "true") {
            if (localStorage.getItem("remember_data")) {
                RememberData("get")
                NavigateComponent(navigate,'pin');
                return;
            }
        }
        if (useCancelled == false) {
            var lang = localStorage.getItem('LANG') ? localStorage.getItem('LANG').toString() : 'en';
            var currentLanguage = LocalizedLanguage.getLanguage();
            //console.log("currentlanguage", currentLanguage, " local", lang)
            if (lang !== currentLanguage) {
                window.location.reload() // to set language need refresh page
            }
        }
        return () => {
            useCancelled = true;
        }
    }, []);

    const logout = () => {
        dispatch(userLogin('clear'))
        //localStorage.clear()
        const Android = window.Android;
        if ((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper") == true)) {
            Android.wrapperLogout();
        }
        else {
            navigate("/login");
        }
        //navigate('/')
    }

    const navigate = useNavigate();
    const handleSiteClick = (item) => {
        sessionStorage.removeItem("backUser")
        var udid = item.subscription_detail.udid;
        var user_full_name = item.user_full_name
        localStorage.setItem('user_full_name', user_full_name);

        encode_UDid(udid);
        navigate('/location')
    }
    var Sitelist = localStorage.getItem('sitelist') ? JSON.parse(localStorage.getItem('sitelist')) : null;
    if (Sitelist != null && typeof Sitelist != "undefined" ) {
        Sitelist["subscriptions"] = Sitelist.subscriptions.filter(m => m.subscription_detail.host_name !== null &&  m.subscription_detail.host_name !=='');
    }

    const siteData = useSelector(state => state)
    useEffect(() => {
        if (Sitelist && Sitelist.subscriptions.length == 1 && !sessionStorage.getItem("backUser")) {
            handleSiteClick(Sitelist.subscriptions[0])
        }
        // setTimeout(() => {
        //     if((Sitelist && Sitelist.length > 1) || sessionStorage.getItem("backUser") ){

        //             registerHide.current.classList.remove('hide_wrapper')
        //         }
        // }, 0);
    }, [])

    // {((Sitelist.length == 1) || sessionStorage.getItem("backUser"))? <LoadingModal></LoadingModal>:null}
    return (
        <div className="choose-wrapper " ref={registerHide}>
            {/* { (Sitelist.length == 1 || !sessionStorage.getItem("backUser")) ? <LoadingModal></LoadingModal>:null} */}
            <div className="choose-header">
                <button id="backButton" onClick={() => logout()}>
                    <img src={AngledBracket_Left_Blue} alt="" />
                    {LocalizedLanguage.logout}
                </button>
            </div>
            <div className="choose-body-default">
                <p>{LocalizedLanguage.choosesite}</p>
                <div className="divider"></div>
                <div className="button-container">
                    {/* <button className="option">
                    <div className="img-container background-violet">
                        <img src={Oliver_Icon_White} alt="" className="oliver-icon" />
                    </div>
                    <div className="col">
                        <p className="style1">Demo Store</p>
                    </div>
                    <img src={AngledBracket_Right_Grey} alt="" />
                    <div className="fake-button background-violet">Load Demo</div>
                </button> */}

                    {Sitelist !== null && Sitelist !== undefined && Sitelist.subscriptions &&
                        Sitelist.subscriptions.map((link, index) => {
                            return (
                                <button key={index} className="option" onClick={() => handleSiteClick(link)} title={link.subscription_detail.host_name}>
                                    <div className="img-container background-blue">
                                        <img src={WWW_Icon} alt="" className="www-icon" />
                                    </div>
                                    <div className="col">
                                        <p className="style1">{link.subscription_detail.host_name}</p>
                                        {/* <p className="style2">{link.subscription_detail.url}</p> */}
                                    </div>
                                    <img src={AngledBracket_Right_Grey} alt="" />
                                    <div className="fake-button background-blue">{LocalizedLanguage.select}  </div>
                                </button>
                            )
                        })
                    }

                    {/* <button className="option">
                <div className="img-container background-blue">
                    <img src={WWW_Icon} alt="" className="www-icon" />
                </div>
                <div className="col">
                    <p className="style1">Sushi Sun</p>
                    <p className="style2">sushisun.com</p>
                </div>
                <img src={AngledBracket_Right_Grey} alt="" />
                <div className="fake-button background-blue">Select</div>
            </button> */}
                    {/* <button className="option">
                <div className="img-container background-blue">
                    <img src={WWW_Icon} alt="" className="www-icon" />
                </div>
                <div className="col">
                    <p className="style1">Pizza Planet</p>
                    <p className="style2">pizzaplanet.com</p>
                </div>
                <img src={AngledBracket_Right_Grey} alt="" />
                <div className="fake-button background-blue">Select</div>
            </button> */}
                    <button id="addNew" style={{ "display": "none" }}>
                        <img src={Rounded_Square_Plus_Icon_NewGrey} alt="" />
                        {LocalizedLanguage.addsite}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Site