import React, { useState } from 'react';
import CameraIcon from '../../assets/images/svg/CameraIcon.svg'; 
import { sendDataToWrapper } from '../../settings/AndroidIOSConnect';

const CameraButton = ({inputRef}) => {
const [isClicked, setIsClicked] = useState(false);
const hanndleClick = (e) => {
    const data = {
      "version": "3.0",
        "data": {
            "cameraisOn": "true",
        }
    }

    sendDataToWrapper('open_camera', '', data)
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300); 
    if (inputRef) {
        inputRef.current.focus();
    }
}

 return (
      <div onClick={hanndleClick}  className={`camera-button ${isClicked ? 'scaled' : ''}`}>
        <img
          src={CameraIcon}
          alt=""
        />
      </div>
    );
  };
  
  export default CameraButton;
