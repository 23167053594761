import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import BarcodeReader from 'react-barcode-reader'
import AddTile from "./tiles/AddTile";
import AdvancedSearch from "./AdvancedSearch";
import CartDiscount from "./CartDiscount";
import Notifications from "./Notifications";
import OrderNote from "../common/commonComponents/OrderNote";
import { customergetDetail, customersave, customerupdate, getCountryList, getStateList, updateCustomerNote } from "../customer/CustomerSlice";
// import MsgPopup_ProductNotFound from "./MsgPopup_ProductNotFound";
// import MsgPopup_UpgradeToUnlock from "./MsgPopup_UpgradeToUnlock";

import LinkLauncher from "../common/commonComponents/LinkLauncher";
import AppLauncher from "../common/commonComponents/AppLauncher";
import IframeWindow from "./IframeWindow";
import LeftNavBar from "../common/commonComponents/LeftNavBar";
import HeadereBar from "./HeadereBar";
// import IframeWindow from "./IframeWindow";

import CartList from "./product/CartList";
import TileList from "./tiles/TileList";
import { initHomeFn } from "../common/commonFunctions/homeFn";
import { attribute } from "../common/commonAPIs/attributeSlice";
import { category } from "../common/commonAPIs/categorySlice";
import { group } from "../common/commonAPIs/groupSlice";
import { tile, addTile, deleteTile } from './tiles/tileSlice';
import Product from "./product/Product";
import { product } from "./product/productSlice";
import { userList } from "../common/commonAPIs/userSlice";
//import { discount } from "../common/commonAPIs/discountSlice";
import { getExtensions, getPaymentTypeName } from "../checkout/checkoutSlice";
//import { getRates, isMultipleTaxSupport, getTaxRateList } from "../common/commonAPIs/taxSlice";
import { useIndexedDB } from 'react-indexed-db';
import STATUSES from "../../constants/apiStatus";
import { getTaxAllProduct } from "../common/TaxSetting";
import MsgPopupOutOfStock from "./product/MsgPopupOutOfStock";
import TaxList from "./TaxList";
// import MsgPopup from "../common/commonComponents/MsgPopup";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import { useNavigate } from "react-router-dom";
import CommonModuleJS, { CheckProductBackordered, NavigateComponent } from "../../settings/CommonModuleJS";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { callProductXWindow, sendMessageToComposite, getCompositeAddedToCart, getCompositeSetProductxData } from "../../settings/CommonFunctionProductX";
import { getInventory } from "./slices/inventorySlice";
import { getDetails } from "../cashmanagement/CashmanagementSlice";
import { getCloudPrinters } from "../common/commonAPIs/cloudPrinterSlice"
// import ProductxWindow from "./product/ProductxWindow";
import CustomerCreate from "../customer/Customercreate";
import { cashRounding } from "../common/commonAPIs/cashRoundingSlice";
import { getShopLogo, getUserShopLogo, get_UDid } from "../common/localSettings";
import AdjustInventory from "./product/AdjustInventory";
import ProductTypeNotSupported from "./product/ProductTypeNotSupported";
// import LinkLauncherPage from "../common/commonComponents/LinkLauncherPage";
import KnowledgeBase from "./KnowledgeBase";
import { addSimpleProducttoCart, addtoCartProduct } from "./product/productLogic";
import { androidDisplayScreen, sendDataToWrapper } from "../../settings/AndroidIOSConnect";
//import moment from "moment";
import { v4 as uniqueKey } from 'uuid';
import { GTM_ClientDetail } from "../common/CommonfunctionGTM";
import { getPostMeta } from "../common/commonAPIs/postMetaSlice";
import { ValidateComponent } from "../common/validate";
import { convertImageToBase64, notificationStatus } from "../../settings/CommonWork";
import Config from '../../Config';
import { getStoreDetails } from "../common/AppHandeler/commonWrapperAppHandler";
import CustomFee from "./CustomFee";
const Home = () => {
    const { getByID, getAll } = useIndexedDB("products");
    const [isShowPopups, setisShowPopups] = useState(false);
    const [selProduct, setSelProduct] = useState(null);
    // const [isShowUserProfile, setisShowUserProfile] = useState(false);
    // const [isShowSwitchUser, setisShowSwitchUser] = useState(false);
    // const [isShowEndSession, setisShowEndSession] = useState(false);

    const [isShowAppLauncher, setisShowAppLauncher] = useState(false);
    const [isShowLinkLauncher, setisShowLinkLauncher] = useState(false);
    const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
    const [isShowProductxWindow, setisShowProductxWindow] = useState(false);

    const [isShowOrderNote, setisShowOrderNote] = useState(false);
    const [isShowCartDiscount, setisShowCartDiscount] = useState(false);
    const [isShowNotifications, setisShowNotifications] = useState(false);

    const [isShowAdvancedSearch, setisShowAdvancedSearch] = useState(false);
    const [isShowAddTitle, setisShowAddTitle] = useState(false);
    const [isShowOptionPage, setisShowOptionPage] = useState(false);
    const [listItem, setListItem] = useState([]);
    const [isOutOfStock, setisOutOfStock] = useState(false);
    const [isShowCreateCustomer, setisShowCreateCustomer] = useState(false);
    const [variationProduct, setVariationProduct] = useState(null);
    const [searchSringCreate, setsearchSringCreate] = useState('')
    const [isShowMobLeftNav, setisShowMobLeftNav] = useState(false);
    const [isSelectDiscountBtn, setisSelectDiscountBtn] = useState(false);
    const [isCustomFeeBtn, setIsCustomFeeBtn] = useState(false);
    
    const [isShowTaxList, setisShowTaxList] = useState(false);
    const [isShowMsg, setisShowMsg] = useState(false);
    const [tileOrder, setTileOrder] = useState(0);
    // const [productxItem, setProductxItem] = useState('');
    const [isAdjustInventory, setisAdjustInventory] = useState(false);
    // const [isShowLinkLauncherPage, setisShowLinkLauncherPage] = useState(false);
    // const [linkLauncherData, setLinkLauncherData] = useState(null);
    const [isProductEdit, setIsProductEdit] = useState(false);
    const [isShowKnowledgeBase, setIsShowKnowledgeBase] = useState(false);
    // const [warningImg, setWarningImg] = useState('');
    const [advanceSearchString, setAdvanceSearchString] = useState('')
    const [allProductList, setAllProductList] = useState(null)
    const [isShowProductTypeNotSupported, setIsShowProductTypeNotSupported] = useState(false);
    const [isDeleteTileOutClick, setIsDeleteTileOutClick] = useState('');
    const [customerAdd, setCustomerAdd] = useState(false);
    const [isShowAmelia, setIsShowAmelia] = useState(false);
    const [extApp, setExtApp] = useState('');
    let [isReload,setIsReload]=useState(true);
    const navigate = useNavigate()
    var Cash_Management_ID = localStorage.getItem('Cash_Management_ID')
    const dispatch = useDispatch();

setTimeout(() => {
    if(isReload ==true ){
         setIsReload(false);
    }
}, 500);
    ValidateComponent()

    const getProductFromIDB = () => {
        getAll().then((rows) => {
            if(rows.length===0){
                navigate('/productloader');
            }
            var allProdcuts = getTaxAllProduct(rows);
            setAllProductList(allProdcuts);

        });
    }
    useEffect(() => {
        setTimeout(() => {
             var _notificaions =notificationStatus()
            if(_notificaions==false|| localStorage.getItem("hasNotifyPermission")=='false')
            {       
            navigate('/enablenotification?page=home');
                return;        
            }
            },500);
          
            var request =window.indexedDB.open("POSDB");// check index DB Exist or not;                   
            request.onsuccess = function(event) {
                var db = event.target.result;   
                if( !db || !db.objectStoreNames || db.objectStoreNames.length <=0){ 
                    db.close();                      
                    navigate('/productloader');
                }else{
                    getAll().then((rows) => {
                        if(rows.length===0){
                            db.close();
                            navigate('/productloader');
                        }                     
                    });
                }
            } 
                
        },[]); 

       useEffect(() => {       
            //Intercom initialization  
                var check=localStorage.getItem("intercom"); 
                //(check ==undefined || check==null) &&    
            if (  window.location.hostname === Config.key.DEMO_USER_URL) {             
                console.log("ntercom initialization ")
                    window.Intercom && window.Intercom('startTour', Config.key.TOUR_HOME_KEY );          
                    localStorage.setItem("intercom","init")
            }       
        },[])

    var isCallable = true;
    useEffect(() => {
         if (isCallable === true) {         
            localStorage.removeItem("oliver_refund_order_payments");
            var user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
            if (user == "" || typeof user == "undefined" || user == null) {
                //navigate('/pin')
                NavigateComponent(navigate,'pin');
            }
            //Getting the company/user_shop logo image in a hidden img tag and convert it to base64 for saving in to the localstorage.
            //Config.key.OP_IMAGE_URL
            if (getShopLogo() != "" && !localStorage.getItem('companylogo_base64')) {
               // console.log("------companylogo-------->" + Config.key.OP_IMAGE_URL + "" + getShopLogo());
                convertImageToBase64(Config.key.OP_IMAGE_URL + "" + getShopLogo(),
                    function (base64image) {
                        //console.log("------companylogo_base64-------->" + base64image);
                    }, 'companylogo_base64');
            }
            if (getUserShopLogo() != "" && !localStorage.getItem('user_shoplogo_base64')) {
               //console.log("------user_shoplogo-------->" + Config.key.OP_IMAGE_URL + "" + getUserShopLogo());
                convertImageToBase64(Config.key.OP_IMAGE_URL + "" + getUserShopLogo(),
                    function (base64image) {
                        // console.log("------user_shoplogo_base64-------->" + base64image);
                    }, 'user_shoplogo_base64');
            }
            fetchData();
            isCallable = false;
            getProductFromIDB();

            //Trigger this listener when the view has changed
            sendDataToWrapper("current_view", "home", "");
            sendDataToWrapper("customer_display","","")
            //send store detail to client through wrapper command.
            getStoreDetails();
        }
        GTM_ClientDetail();

        //set currency Symbole----------------------------------------
        if (localStorage.getItem('user')) {
            var { CurrencySymbol } = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
            if (CurrencySymbol && CurrencySymbol != null) {
                var temporalDivElement = document && document.createElement("div");
                // Set the HTML content with the providen
                temporalDivElement.innerHTML = CurrencySymbol;
                // Retrieve the text property of the element (cross-browser support)
                localStorage.setItem("currecySymbole", (temporalDivElement.textContent || temporalDivElement.innerText || ""));
            }
        }
        //------------------------------------------------
    }, []);


    // const [resGetRates, respIsMultipleTaxSupport] = useSelector((state) => [state.getRates, state.isMultipleTaxSupport])
    // useEffect(() => {
    //     if ((resGetRates && resGetRates.status === STATUSES.IDLE && resGetRates.is_success) && (respIsMultipleTaxSupport && respIsMultipleTaxSupport.status === STATUSES.IDLE && respIsMultipleTaxSupport.is_success)) {
    //         getTax(respIsMultipleTaxSupport.data.content, resGetRates.data.content);
    //     }
    // }, [resGetRates, respIsMultipleTaxSupport]);

    const getFavourites = (doRefresh = false) => {
        var regId = localStorage.getItem('register');
        if (typeof regId != "undefined" && regId != null) {
            var _item = localStorage.getItem(get_UDid() + "_FavList");
            if ((typeof _item == "undefined" || _item == null) || doRefresh == true) {
                dispatch(tile({ "id": regId }));
                dispatch(addTile("clear"));
                dispatch(deleteTile("clear"));
            }
        }
    }
    const updateVariationProduct = (item) => {
        //setSelProduct(item);
        setVariationProduct(item);
    }

    const fetchData = async () => { //calling multiple api

        if ((!localStorage.getItem(get_UDid() + "_NumOfTiles") || typeof localStorage.getItem(get_UDid() + "_NumOfTiles") == "undefined") && window.location.hostname !== Config.key.DEMO_USER_URL) {
            var clientDetail = localStorage.getItem('clientDetail') ? JSON.parse(localStorage.getItem('clientDetail')) : null;
            if (clientDetail) {
                var register_id = localStorage.getItem("register") ? localStorage.getItem("register") : null;
                var _clientID = clientDetail && clientDetail.subscription_detail ? clientDetail.subscription_detail.client_guid : "";
                if (register_id !== null) {
                    localStorage.setItem("client_registerid", _clientID + "_" + register_id);
                    dispatch(getPostMeta(_clientID + "_" + register_id));
                }
            }
        }

        dispatch(customergetDetail('clear'));
        if (!localStorage.getItem("attribute"))
            dispatch(attribute());
        if (!localStorage.getItem("category"))
            dispatch(category());
        dispatch(product());
        if (!localStorage.getItem("user_List"))
            dispatch(userList());
        //dispatch(getRates());
       // dispatch(isMultipleTaxSupport());
       // dispatch(getTaxRateList();)
        // if (!localStorage.getItem("discountlst"))
        //     dispatch(discount());
        if (!localStorage.getItem("GET_EXTENTION_FIELD"))
            dispatch(getExtensions());
        if (!localStorage.getItem("PAYMENT_TYPE_NAME"))
            dispatch(getPaymentTypeName());
        if (!localStorage.getItem("CASH_ROUNDING"))
            dispatch(cashRounding(get_UDid()));
        if (Cash_Management_ID && Cash_Management_ID !== null && Cash_Management_ID !== "") {
            dispatch(getDetails(Cash_Management_ID));
        }

        getFavourites();
        if (!localStorage.getItem("countrylist"))
            dispatch(getCountryList())
        if (!localStorage.getItem("statelist"))
            dispatch(getStateList())


        dispatch(customersave('clear'));
        dispatch(customerupdate('clear'));
        dispatch(updateCustomerNote("clear"))
        var locationId = localStorage.getItem('Location');
        if (!localStorage.getItem('cloudPrinters')) {
            dispatch(getCloudPrinters(locationId));
        }
        var user_ = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        if (user_ && user_.group_sales && user_.group_sales !== null && user_.group_sales !== "" && user_.group_sales !== "undefined") {
            dispatch(group({ "locationId": locationId, "group_sales": user_.group_sales_by }));
        }
    }
    setTimeout(() => {
        initHomeFn();
    }, 1000);

    //Handling the scan 
    const handleScan = (data, ticketFields = null) => {
        if (data.length < 3)
            return;
        
        var scanBarcode = data
        if (allProductList && allProductList.length > 0) {
            var parentProduct = allProductList.filter(item => {
                return ((item.Type == "variable" || item.Type == "variation") && item.ParentId == 0
                    && (scanBarcode == item.Barcode || scanBarcode == item.Sku))
            })

            if (parentProduct && parentProduct.length == 1) {
                var variationProdect = allProductList.filter(filterItem => {
                    return (filterItem.ParentId === parentProduct[0].WPID)
                })

                parentProduct[0]['Variations'] = variationProdect

                openPopUp(parentProduct[0])

            }
            else {
                //Checking if barcode belong to the number of product--------------------
                var checkMultipleProductfound = false;
                var allProdctWithbarcode = allProductList.filter(item => {
                    return ((scanBarcode == item.Barcode || scanBarcode == item.Sku))
                })
                if (allProdctWithbarcode && allProdctWithbarcode.length > 1) {
                    checkMultipleProductfound = true;

                    setAdvanceSearchString(scanBarcode)
                    setisShowAdvancedSearch(true)
                }
                //--------------------------------------------------------------------------

                if (checkMultipleProductfound == false) {
                    // Check child variation Product-------------------------------------
                    var childVariationProduct = allProductList.find(item => {
                        return ((item.Type == "variable" || item.Type == "variation") && item.ParentId !== 0
                            && (scanBarcode == item.Barcode || scanBarcode == item.Sku))
                    })
                    if (childVariationProduct) {
                        var data = {
                            line_item_id: 0,
                            quantity: 1,
                            Title: childVariationProduct.Title,
                            Price: parseFloat(childVariationProduct.Price),
                            product_id: childVariationProduct.ParentId,
                            variation_id: childVariationProduct.WPID,
                            isTaxable: childVariationProduct.Taxable,
                            old_price: childVariationProduct.old_price,
                            TaxStatus: childVariationProduct.TaxStatus,
                            TaxClass: childVariationProduct.TaxClass,
                        }

                        var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
                        var qty = 0;
                        cartlist.map(items => {
                            if (childVariationProduct.WPID == items.variation_id) {
                                qty = items.quantity;
                            }
                        })
                        if ((childVariationProduct.StockStatus == null || childVariationProduct.StockStatus == 'instock') &&
                            (childVariationProduct.ManagingStock == false || (childVariationProduct.ManagingStock == true && qty < childVariationProduct.StockQuantity))) {
                            cartlist.push(data);

                            addtoCartProduct(cartlist, null, data);

                            //Android Call----------------------------
                            var totalPrice = 0.0;
                            cartlist && cartlist.map(item => {
                                totalPrice += item.Price;
                            })
                            androidDisplayScreen(data.Title, data.Price, totalPrice, "cart");
                            //-----------------------------------------
                            // if (!((scanBarcode === data.Barcode || scanBarcode === data.Sku) && data.ParentId !== 0)) { //if single child product found then add it to the cart do not display into search
                            // }
                        } else {
                            setisOutOfStock(true)
                        }

                    }
                    else {  //simple Product
                        // Check Other then variation simple Product-------------------------------------
                        var simpleProduct = allProductList.find(item => {
                            return ((item.Type !== "variable" && item.Type !== "variation")
                                && (scanBarcode == item.Barcode || scanBarcode == item.Sku))
                        })
                        var tick_data = simpleProduct && simpleProduct.IsTicket == true && simpleProduct.TicketInfo != '' ? JSON.parse(simpleProduct.TicketInfo) : '';
                        let isBackOrdereTemp = CheckProductBackordered(simpleProduct);
                        if (simpleProduct) {
                            if (simpleProduct && simpleProduct.IsTicket == false) {
                                var data = {
                                    line_item_id: 0,
                                    quantity: 1,
                                    Title: simpleProduct.Title,
                                    Price: parseFloat(simpleProduct.Price),
                                    product_id: simpleProduct.WPID,
                                    variation_id: 0,
                                    isTaxable: simpleProduct.Taxable,
                                    old_price: simpleProduct.old_price,
                                    TaxStatus: simpleProduct.TaxStatus,
                                    TaxClass: simpleProduct.TaxClass,
                                    ticket_status: simpleProduct.IsTicket,
                                    product_ticket: ticketFields
                                }
                            } else if (simpleProduct && simpleProduct.IsTicket == true && ticketFields != null) {
                                var TicketInfoForSeat = simpleProduct && simpleProduct.TicketInfo && JSON.parse(simpleProduct.TicketInfo);
                                var tcForSeating = TicketInfoForSeat ? TicketInfoForSeat : "";
                                this.setState({ ticket_Product_status: false })
                                var data = {
                                    line_item_id: 0,
                                    quantity: 1,
                                    Title: simpleProduct.Title,
                                    Price: parseFloat(simpleProduct.Price),
                                    product_id: simpleProduct.WPID,
                                    variation_id: 0,
                                    isTaxable: simpleProduct.Taxable,
                                    old_price: simpleProduct.old_price,
                                    TaxStatus: simpleProduct.TaxStatus,
                                    TaxClass: simpleProduct.TaxClass,
                                    tcForSeating: tcForSeating,
                                    tick_event_id: tick_data._event_name,
                                    ticket_status: simpleProduct.IsTicket,
                                    product_ticket: ticketFields
                                }
                            }
                            var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
                            var qty = 0;
                            cartlist.map(items => {
                                if (simpleProduct.WPID == items.product_id) {
                                    qty = items.quantity;
                                }
                            })
                            if (simpleProduct.ManagingStock == false || isBackOrdereTemp == true || ((simpleProduct.StockStatus == null || simpleProduct.StockStatus == 'instock') &&
                                ((simpleProduct.ManagingStock == true && qty < simpleProduct.StockQuantity)))) {
                                cartlist.push(data);
                                addtoCartProduct(cartlist, null, data);
                                //Android Call----------------------------)
                                var totalPrice = 0.0;
                                cartlist && cartlist.map(item => {
                                    totalPrice += item.Price;
                                })
                                androidDisplayScreen(data.Title, data.Price, totalPrice, "cart");
                                //-----------------------------------------

                            } else {
                                setisOutOfStock(true)
                            }
                        }
                        else {
                            if (scanBarcode) {
                                //Product Not found
                                var _data = { title: LocalizedLanguage.noMatchingProductFound, msg: LocalizedLanguage.barcodenotfound, is_success: true }
                                dispatch(popupMessage(_data));
                            }
                        }
                    }
                }

            }
            dispatch(product())
        }

        // });
    }

    var windowCloseEv = null;
    const openPopUp = async (item, index = null) => {
        setIsProductEdit(index === null ? false : true);
        let type = item.Type;
        var taglist = item.Tags ? item.Tags !== "" ? item.Tags.split(",") : null : null;
        if ((item.ProductMetaJson=="" || item.ProductMetaJson =="[]")  && taglist && (taglist !== null && taglist.includes('oliver_produt_x') === true) &&
            (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal()
                === true) && item !== null && item.ParamLink !== "" && item.ParamLink
            !== "False" && item.ParamLink !== null) {
            console.log("product x with tag--" + item.ParamLink)
            // setProductxItem(item);
            toggleProductTypeNotSupported();
        }
        else
            if ((type !== "simple" && type !== "variable" && type !== "variation") && item !== null && item.ParamLink !== "" && item.ParamLink !== "False" && item.ParamLink !== null && typeof item.ParamLink !== "undefined") {
                // console.log("product x---" + item.ParamLink)
                // setProductxItem(item);
                ToggleProductxWindow();
                windowCloseEv = callProductXWindow(item);
                window.addEventListener('message', function (e) {
                    var data = e && e.data;
                    if (typeof data === 'string' && data !== "") {
                        console.log('-- -- -- --' + data);
                    }
                })
            }
            else {
                if (index === null)
                    updateVariationProduct(null);
                var _itemId=(item && (item.product_id ? item.product_id : item.WPID ? item.WPID : item.Product_Id) )
                var _item = _itemId && await getByID(_itemId);
                if(! _item) return;
                var _product = getTaxAllProduct([_item])
                _product[0]["quantity"] = item.quantity;
                if (item.hasOwnProperty("selectedOptions")) {
                    _product[0]["selectedOptions"] = item.selectedOptions;

                }
                if (index != null) {
                    _product[0]["selectedIndex"] = index;
                }
                if (item) {
                    _product[0]['after_discount'] = item.after_discount ? item.after_discount : 0;
                    _product[0]['discount_amount'] = item.discount_amount ? item.discount_amount : 0;
                    _product[0]['product_after_discount'] = item.product_after_discount ? item.product_after_discount : 0;
                    _product[0]['product_discount_amount'] = item.product_discount_amount ? item.product_discount_amount : 0;
                    _product[0]['discount_type'] = item.discount_type ? item.discount_type : "";
                    _product[0]['new_product_discount_amount'] = item.new_product_discount_amount ? item.new_product_discount_amount : 0;
                    _product[0]['cart_after_discount'] = item.cart_after_discount ? item.cart_after_discount : 0;
                    _product[0]['cart_discount_amount'] = item.cart_discount_amount ? item.cart_discount_amount : 0;
                    _product[0]['excl_tax'] = item.excl_tax ? item.excl_tax : 0;
                    _product[0]['incl_tax'] = item.incl_tax ? item.incl_tax : 0;
                    _product[0]['isTaxable'] = item.isTaxable ? item.isTaxable : false;
                    if (typeof item.selectedOptions != "undefined" && item.selectedOptions == null) {
                        _product[0]['combination'] = item.selectedOptions && item.selectedOptions.join("~")
                    }
                    else {
                        if (typeof item.combination != "undefined" && item.combination !== null) {
                            _product[0]['combination'] = item.combination;
                        }
                    }
                    if (item.hasOwnProperty("modifiers_Id")) {
                        _product[0]['modifiers_Id'] = item.modifiers_Id;
                    }
                    if (item.hasOwnProperty("modifiers_key")) {
                        _product[0]['modifiers_key'] = item.modifiers_key;
                    }
//for addons
                    if (item.hasOwnProperty("addon_ids")) {
                        _product[0]['addon_ids'] = item.addon_ids;
                    }
                    if (item.hasOwnProperty("addon_key")) {
                        _product[0]['addon_key'] = item.addon_key;
                    }
                }
                // setSelProduct(_product[0]);
                // setisShowPopups(true);

                if (item.hasOwnProperty("SelVariationId")) //edit variation product
                {
                    dispatch(getInventory(item.SelVariationId));
                }
                else if (item.hasOwnProperty("WPID")) {
                    dispatch(getInventory(item.WPID)); // To fetch latest inventory
                }
                else if (item.hasOwnProperty("Product_Id")) {
                    dispatch(getInventory(item.Product_Id));
                }
                else if (item.hasOwnProperty("product_id")) // get inventory for simple product
                {
                    dispatch(getInventory(item.product_id));
                }
                setSelProduct(_product[0]);
                setisShowPopups(true);
            }
    }

    const closePopUp = () => {
        localStorage.removeItem("PRODUCT");
        localStorage.removeItem("SINGLE_PRODUCT");
        setisShowPopups(false);
        sessionStorage.removeItem("view");
        sessionStorage.removeItem("productId")
    }
   
    const toggleOrderNote = () => {
        setisShowOptionPage(false)
        setisShowOrderNote(!isShowOrderNote)
    }
    const toggleCartDiscount = () => {
        setisShowOptionPage(false)
        setisShowCartDiscount(!isShowCartDiscount)
    }
    const toggleEditCartDiscount = () => {
        setisShowOptionPage(false)
        setisSelectDiscountBtn(true);
        setisShowCartDiscount(!isShowCartDiscount)

    }
    const toggleNotifications = () => {
        setisShowNotifications(!isShowNotifications)
    }

    const toggleAdvancedSearch = () => {
        setisShowAdvancedSearch(!isShowAdvancedSearch)
    }

    // useEffect(() => {
    //     if (isShowAdvancedSearch === false) {
    //         //  dispatch(customergetDetail("clear"));
    //     }
    // }, [isShowAdvancedSearch, customerAdd])

    const toggleAddTitle = (Order = null) => {
        if (Order !== null) {
            setTileOrder(Order)
        }
        setisShowAddTitle(!isShowAddTitle)
    }

    const toggleAppLauncher = () => {
        setisShowAppLauncher(!isShowAppLauncher)
        setisShowLinkLauncher(false)
    }
    const toggleLinkLauncher = () => {
        setisShowLinkLauncher(!isShowLinkLauncher)
        setisShowAppLauncher(false)
    }

    const ToggleiFrameWindow = () => {
        setisShowiFrameWindow(!isShowiFrameWindow)
    }
    const ToggleProductxWindow = () => {
        setisShowProductxWindow(!isShowProductxWindow)
    }
    const toggleOptionPage = () => {
        setisShowOptionPage(!isShowOptionPage)
    }
    const toggleOutOfStock = (product) => {
        if (typeof product != "undefined" && product != null) {
            setSelProduct(product[0])
        }
        setisOutOfStock(!isOutOfStock)
    }
    const toggleCreateCustomer = (serachString) => {
        setisShowCreateCustomer(!isShowCreateCustomer)
        setsearchSringCreate(serachString)
    }
    const parentEmail = (data) => {
        setsearchSringCreate(data)
    }

    const toggleShowMobLeftNav = () => {
        setisShowMobLeftNav(!isShowMobLeftNav)
    }
    const toggleSelectDiscountBtn = () => {
        setisSelectDiscountBtn(!isSelectDiscountBtn)
    }
    const toggleCustomFeeBtn = () => {
        setIsCustomFeeBtn(!isCustomFeeBtn)
    }
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }
    const toggleTaxList = () => {
        setisShowTaxList(!isShowTaxList)
    }
    const toggleAdjustInventory = (istoggle) => {
        setisAdjustInventory(istoggle === null || istoggle === "undefined" ? !isAdjustInventory : istoggle)
    }
    const toggleKnowledgeBase = () => {
        setIsShowKnowledgeBase(!isShowKnowledgeBase)
    }
    const toggleProductTypeNotSupported = () => {
        setIsShowProductTypeNotSupported(!isShowProductTypeNotSupported)
    }
    const toggleAmelia = () => {
        setIsShowAmelia(!isShowAmelia)
    }
    const clearDeleteTileBtn = (e) => {
        if (document.getElementById('tileItemsList') && document.getElementById('tileItemsList').contains(e.target)) {
            // Clicked in box
            //console.log("inside")
        } else {
            // Clicked outside the box
            //console.log("ourside")
            setIsDeleteTileOutClick(uniqueKey());
        }
    }
    const getTax = (multiple_tax_support, get_tax_rates) => {
        if (multiple_tax_support && multiple_tax_support === true) {
            var taxList = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST')) : [];
            if ((typeof taxList !== 'undefined') && taxList !== null && taxList && taxList.length > 0) {
                var taxData = [];
                taxList && taxList.length > 0 && taxList.map(rate => {
                    taxData.push({
                        check_is: rate.check_is,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                })
                localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(taxData));
                if (!localStorage.getItem('DEFAULT_TAX_STATUS')) {
                    localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
                }
            } else {
                localStorage.setItem('DEFAULT_TAX_STATUS', 'true')
                var taxData = [];
                var inactiveTaxData = [];
                if (get_tax_rates && get_tax_rates.length > 0) {
                    get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                        taxData.push({
                            check_is: true,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                        inactiveTaxData.push({
                            check_is: false,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                    })
                    localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(inactiveTaxData))
                    if (!localStorage.getItem("SELECTED_TAX")) {
                        localStorage.setItem("SELECTED_TAX", JSON.stringify(inactiveTaxData));
                    }
                    //Update by Nagendra: Remove the tax which has same priority and lower rate, only for default tax..................................
                    taxData && taxData.length > 0 && taxData.map(rate => {
                        var duplicateArr = taxData.filter((ele, index) => ele.TaxClass === rate.TaxClass && ele.Priority === rate.Priority && ele.TaxClass === "");
                        if (duplicateArr && duplicateArr.length > 0) {
                            duplicateArr.map(dup => {
                                if (rate.TaxId < dup.TaxId) {
                                    taxData.splice(taxData.indexOf(dup), 1);
                                }
                            });

                            if (taxData && taxData.length > 0) {
                                var taxfilterData = taxData.filter((ele, index) => ele.TaxClass === "");
                                if (taxfilterData) {
                                    taxData = taxfilterData;
                                }
                            }

                            //..............................................................................
                        }
                    })
                    localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData));
                }
            }

        } else if (!multiple_tax_support || multiple_tax_support === false) {
            var taxList = localStorage.getItem('TAXT_RATE_LIST') ? JSON.parse(localStorage.getItem('TAXT_RATE_LIST')) : [];
            if (taxList && taxList.length === 0) {
                localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
                var taxData = [];
                if (get_tax_rates && get_tax_rates.length > 0) {
                    get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                        taxData.push({
                            check_is: true,
                            TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                            TaxName: rate.TaxName ? rate.TaxName : '',
                            TaxId: rate.TaxId ? rate.TaxId : '',
                            Country: rate.Country ? rate.Country : '',
                            State: rate.State ? rate.State : '',
                            TaxClass: rate.TaxClass ? rate.TaxClass : '',
                            Priority: rate.Priority ? rate.Priority : ''
                        })
                    })
                    var taxRateListIs = []
                    taxRateListIs.push(taxData[0]);
                    localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData))
                    if (typeof multiple_tax_support != "undefined") {
                        localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(taxRateListIs))
                    }
                }
            }
        }
        if (!localStorage.getItem('APPLY_DEFAULT_TAX') && localStorage.getItem('DEFAULT_TAX_STATUS') && localStorage.getItem('DEFAULT_TAX_STATUS') === 'true') {
            var taxData = [];
            var inactiveTaxData = [];
            if (get_tax_rates && get_tax_rates.length > 0) {
                get_tax_rates && get_tax_rates.length > 0 && get_tax_rates.map(rate => {
                    taxData.push({
                        check_is: true,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                    inactiveTaxData.push({
                        check_is: false,
                        TaxRate: rate.TaxRate ? rate.TaxRate : '0%',
                        TaxName: rate.TaxName ? rate.TaxName : '',
                        TaxId: rate.TaxId ? rate.TaxId : '',
                        Country: rate.Country ? rate.Country : '',
                        State: rate.State ? rate.State : '',
                        TaxClass: rate.TaxClass ? rate.TaxClass : '',
                        Priority: rate.Priority ? rate.Priority : ''
                    })
                })
                localStorage.setItem('TAXT_RATE_LIST', JSON.stringify(inactiveTaxData))
                if (!localStorage.getItem("SELECTED_TAX")) {
                    localStorage.setItem("SELECTED_TAX", JSON.stringify(inactiveTaxData));
                }
                //Update by Nagendra: Remove the tax which has same priority and lower rate, only for default tax..................................
                taxData && taxData.length > 0 && taxData.map(rate => {
                    var duplicateArr = taxData.filter((ele, index) => ele.TaxClass === rate.TaxClass && ele.Priority === rate.Priority && ele.TaxClass === "");
                    if (duplicateArr && duplicateArr.length > 0) {
                        duplicateArr.map(dup => {
                            if (rate.TaxId < dup.TaxId) {
                                taxData.splice(taxData.indexOf(dup), 1);
                            }
                        });

                        if (taxData && taxData.length > 0) {
                            var taxfilterData = taxData.filter((ele, index) => ele.TaxClass === "");
                            if (taxfilterData) {
                                taxData = taxfilterData;
                            }
                        }

                        //..............................................................................
                    }
                })
                localStorage.setItem('APPLY_DEFAULT_TAX', JSON.stringify(taxData));
            }
        }
    }

    // It is refreshing the tile list from server when a new tile is added
    const [resAddTile, resdeletTile] = useSelector((state) => [state.addTile, state.deletTile])
    useEffect(() => {
        if (resAddTile && resAddTile.status === STATUSES.IDLE && resAddTile.is_success) {
            dispatch(getPostMeta(localStorage.getItem("client_registerid")));
            getFavourites && getFavourites(true);
        }
        if (resdeletTile && resdeletTile.status === STATUSES.IDLE && resdeletTile.is_success) {
            dispatch(getPostMeta(localStorage.getItem("client_registerid")));
            getFavourites && getFavourites(true);
        }
    }, [resAddTile, resdeletTile]);

    // const [resProduct, respupdateTaxRateList] = useSelector((state) => [state.product, state.updateTaxRateList])
    // useEffect(() => {
    //     if (respupdateTaxRateList && respupdateTaxRateList.status === STATUSES.IDLE && respupdateTaxRateList.is_success) {
    //         getTax();
    //     }
    // }, [respupdateTaxRateList]);

    const [respopupMessage] = useSelector((state) => [state.popupMessage])
    useEffect(() => {
        if (respopupMessage && respopupMessage.status === STATUSES.IDLE && respopupMessage.is_success && respopupMessage.data) {
            toggleMsgPopup(true);
            dispatch(popupMessage(null));
        }
    }, [respopupMessage]);

    const [resProduct] = useSelector((state) => [state.product, state.updateTaxRateList])
    useEffect(() => {
        if (resProduct && resProduct.status === STATUSES.IDLE && resProduct.is_success) {
            setListItem(resProduct.data);
            setisShowPopups(false);
            // console.log("---resProduct--" + JSON.stringify(resProduct.data));
        }
    }, [resProduct], customerAdd);


    const [resCountryList] = useSelector((state) => [state.CountryList])
    useEffect(() => {
        if (resCountryList && resCountryList.status === STATUSES.IDLE && resCountryList.is_success && resCountryList.data) {

            const result = resCountryList.data.content.map(datum => Object.assign(...Object
                .keys(datum)
                .map(key => ({ [key.replace(/[.|&;$%@%"<>+]/g, '')]: datum[key] }))
            ));

            localStorage.setItem('countrylist', JSON.stringify(result))
        }
    }, [resCountryList]);

    const [resStateList] = useSelector((state) => [state.StateList])
    useEffect(() => {
        if (resStateList && resStateList.status === STATUSES.IDLE && resStateList.is_success && resStateList.data) {
            localStorage.setItem('statelist', JSON.stringify(resStateList.data.content))
        }
    }, [resStateList]);


    // Response from customer getDetails
    const [customerAllDetails] = useSelector((state) => [state.customergetDetail])
    useEffect(() => {
        if (customerAllDetails && customerAllDetails.custsiglestatus == STATUSES.IDLE && customerAllDetails.custsigleis_success && customerAllDetails.custsigledata && window.location.pathname == '/home') {
            CustomerDetail(customerAllDetails?.custsigledata?.content?.customerDetails);
            setCustomerAdd(true)

        } else if (customerAllDetails && customerAllDetails.is_success == false) {
            if (!sessionStorage.getItem("addcutomertosale")) {
                //localStorage.removeItem('AdCusDetail')
            }
        }
    }, [customerAllDetails]);

    const CustomerDetail = (cutomer_data) => {
        var data = cutomer_data;
        localStorage.setItem('AdCusDetail', JSON.stringify(data))

        var list = localStorage.getItem('CHECKLIST') !== null ? (typeof localStorage.getItem('CHECKLIST') !== 'undefined') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null : null;
        if (list !== null) {
            const CheckoutList = {
                ListItem: list.ListItem,
                customerDetail: data ? data : [],
                totalPrice: list.totalPrice,
                discountCalculated: list.discountCalculated,
                tax: list.tax,
                subTotal: list.subTotal,
                TaxId: list.TaxId,
                TaxRate: list.TaxRate,
                oliver_pos_receipt_id: list.oliver_pos_receipt_id,
                order_date: list.order_date,
                order_id: list.order_id,
                status: list.status,
                showTaxStaus: list.showTaxStaus,
                _wc_points_redeemed: list._wc_points_redeemed,
                _wc_amount_redeemed: list._wc_amount_redeemed,
                _wc_points_logged_redemption: list._wc_points_logged_redemption
            }
            localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList))
            // sessionStorage.removeItem("addcutomertosale")
        }
    }
    const LoadInAmelia = (_exApp, view) => {
        // console.log("----_exApp----" + JSON.stringify(_exApp))
        // console.log("-----view---" + view)
        setExtApp(_exApp);
    }
    useEffect(() => {
        if(window.location.pathname === '/home') {
            localStorage.removeItem('getorder')
        }
    },[])
    const DisplayPluginPopup = useMemo(()=>{   
        return <IframeWindow isShow={isShowiFrameWindow} ToggleiFrameWindow={ToggleiFrameWindow}></IframeWindow>;
    },[extApp]);

    var isTizenWrapper = localStorage.getItem("isTizenWrapper");
    return (
        <React.Fragment>
            {isShowPopups === true ?
                <Product isProductEdit={isProductEdit} variationProduct={variationProduct} updateVariationProduct={updateVariationProduct} openPopUp={openPopUp} closePopUp={closePopUp} selProduct={selProduct} isShowPopups={isShowPopups} toggleAppLauncher={toggleAppLauncher} /*refreshStockInventory={refreshStockInventory}*/ toggleProductTypeNotSupported={toggleProductTypeNotSupported}></Product> : null}
            <div onClick={(e) => clearDeleteTileBtn(e)} className={isShowPopups === true ? "homepage-wrapper hide" : isShowAmelia === false ? "homepage-wrapper" : "homepage-wrapper amelia"} /*style={{ display: isShowPopups===false ? "grid" : "none" }}*/>
                {/* left nav bar */}
                {/* top header */}
                {/* prodct list/item list */}
                {/* cart list */}

                <LeftNavBar toggleAmelia={toggleAmelia} isShowAmelia={isShowAmelia} LoadInAmelia={LoadInAmelia} isShowMobLeftNav={isShowMobLeftNav} toggleLinkLauncher={toggleLinkLauncher} toggleAppLauncher={toggleAppLauncher} ToggleiFrameWindow={ToggleiFrameWindow} toggleAdvancedSearch={toggleAdvancedSearch} view="Shop View"></LeftNavBar>
                <HeadereBar isShowAmelia={isShowAmelia} isShowIcon={isShowPopups} isShow={isShowOptionPage} isShowLinkLauncher={isShowLinkLauncher} isShowAppLauncher={isShowAppLauncher}
                    toggleAdvancedSearch={toggleAdvancedSearch} toggleShowMobLeftNav={toggleShowMobLeftNav}
                    toggleCartDiscount={toggleCartDiscount} toggleNotifications={toggleNotifications} toggleOrderNote={toggleOrderNote} toggleAppLauncher={toggleAppLauncher} toggleLinkLauncher={toggleLinkLauncher} ToggleiFrameWindow={ToggleiFrameWindow} toggleOptionPage={toggleOptionPage} toggleKnowledgeBase={toggleKnowledgeBase}></HeadereBar>
                {isShowAmelia === false && isShowAppLauncher === true ? <AppLauncher isShow={isShowAppLauncher} toggleAppLauncher={toggleAppLauncher} ToggleiFrameWindow={ToggleiFrameWindow}></AppLauncher> : null}
                {isShowAmelia === false && isShowLinkLauncher === true ? <LinkLauncher isShow={isShowLinkLauncher} toggleLinkLauncher={toggleLinkLauncher} ></LinkLauncher> : null}
                {isShowAmelia === false && isShowiFrameWindow === false ? DisplayPluginPopup : null}
                {isShowAmelia === false ? <TileList isOuterClick={isDeleteTileOutClick} openPopUp={openPopUp} toggleAddTitle={toggleAddTitle} clearDeleteTileBtn={clearDeleteTileBtn} toggleOutOfStock={toggleOutOfStock} toggleProductTypeNotSupported={toggleProductTypeNotSupported} closePopUp={closePopUp}></TileList> : null}
                {isShowAmelia === false ? <CartList updateVariationProduct={updateVariationProduct} openPopUp={openPopUp} /*selProduct={selProduct} variationProduct={variationProduct}*/ listItem={listItem} /*editPopUp={editPopUp}*/ toggleEditCartDiscount={toggleEditCartDiscount} toggleTaxList={toggleTaxList} toggleOrderNote={toggleOrderNote} toggleCartDiscount={toggleCartDiscount} toggleCustomFeeBtn={toggleCustomFeeBtn}></CartList> : null}
                {isShowAmelia === false && isShowKnowledgeBase === true ? <KnowledgeBase isShow={isShowKnowledgeBase} toggleKnowledgeBase={toggleKnowledgeBase}></KnowledgeBase> : null}
                {isShowAmelia === false && isShowNotifications === true ? <Notifications isShow={isShowNotifications} toggleNotifications={toggleNotifications}></Notifications> : null}
                <div id="navCover" className="nav-cover"></div>
            </div>


            {isShowTaxList === true ? <TaxList isShow={isShowTaxList} toggleTaxList={toggleTaxList}></TaxList> : null}
            <CartDiscount isShow={isShowCartDiscount} toggleSelectDiscountBtn={toggleSelectDiscountBtn} isSelectDiscountBtn={isSelectDiscountBtn} toggleCartDiscount={toggleCartDiscount}> </CartDiscount>
            <CustomFee toggleCustomFeeBtn={toggleCustomFeeBtn} isCustomFeeBtn={isCustomFeeBtn} > </CustomFee>
            {isShowAddTitle === true ? <AddTile isShow={isShowAddTitle} toggleAddTitle={toggleAddTitle} tileOrder={tileOrder}></AddTile> : null}
            <OrderNote isShow={isShowOrderNote} toggleOrderNote={toggleOrderNote} ></OrderNote>
            {/* <MsgPopup_ProductNotFound></MsgPopup_ProductNotFound>
            <MsgPopup_UpgradeToUnlock></MsgPopup_UpgradeToUnlock>  */}
            {isShowAdvancedSearch === true ? <AdvancedSearch barcodeSearchString={advanceSearchString} isShow={isShowAdvancedSearch} toggleCreateCustomer={toggleCreateCustomer} openPopUp={openPopUp} closePopUp={closePopUp} toggleAdvancedSearch={toggleAdvancedSearch} toggleOutOfStock={toggleOutOfStock} toggleProductTypeNotSupported={toggleProductTypeNotSupported}></AdvancedSearch> : null}

            <CustomerCreate searchSringCreate={searchSringCreate} childEmail={parentEmail} isShow={isShowCreateCustomer} toggleCreateCustomer={toggleCreateCustomer} toggleAdvancedSearch={toggleAdvancedSearch} />
            {/* <SwitchUser toggleSwitchUser={toggleSwitchUser} isShow={isShowSwitchUser}></SwitchUser>
            <EndSession toggleShowEndSession={toggleShowEndSession} isShow={isShowEndSession}></EndSession> */}
            {isAdjustInventory === true && <AdjustInventory isShow={isAdjustInventory} toggleAdjustInventory={toggleAdjustInventory}
                productStockQuantity={selProduct ? selProduct.StockQuantity : 0}
                product={selProduct}
                fatchUpdateInventory={null}
                isAdjustInventory={isAdjustInventory}
            ></AdjustInventory>
            }
            <ProductTypeNotSupported isShow={isShowProductTypeNotSupported} toggleProductTypeNotSupported={toggleProductTypeNotSupported}></ProductTypeNotSupported>
            <MsgPopupOutOfStock isShow={isOutOfStock} toggleOutOfStock={toggleOutOfStock} toggleAdjustInventory={toggleAdjustInventory}></MsgPopupOutOfStock>

            {isTizenWrapper && isTizenWrapper != null && typeof isTizenWrapper != "undefined" && isTizenWrapper == "true" ?
                null
                : <BarcodeReader
                    onError={handleScan}
                    onScan={handleScan}
                />
            }

        </React.Fragment>)
    // }

}
export default Home 