import React, { useState, useRef, useEffect } from "react";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { singleProductDiscount } from "./productLogic";
import { isAutoFocus } from "../../../settings/AndroidIOSConnect";
import { useDispatch, useSelector } from 'react-redux';
const ProductDiscount = (props) => {
    const refDiscountInput = useRef();
    const [discountAmount, setDiscountAmount] = useState("");
    const [txtValue, setTxtValue] = useState("")
    const [allDiscount, setAllDiscount] = useState([]);
    // const [discountType, setDiscountType] = useState('');
    const [respDiscountList] = useSelector((state) => [state.discountList]);
    // const dispatch = useDispatch();
    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            setDiscountAmount("");
            setTxtValue("");
            props.toggleProductDiscount();
        }
    }
    useEffect(() => {
        if (refDiscountInput) {
            if (isAutoFocus() == true)
                refDiscountInput && refDiscountInput.current && refDiscountInput.current.focus();
        }
    }, [props])

    useEffect(() => {
        if (localStorage.getItem("discountlst") && localStorage.getItem("discountlst") !== "undefined") {
            var discount_list = JSON.parse(localStorage.getItem("discountlst"));
            setAllDiscount(discount_list);
        }
    }, [respDiscountList])
    const closePopup = () => {
        setDiscountAmount("");
        setTxtValue("");
        props.toggleProductDiscount();

    }
    const discount_Amount = (a) => {
        if (a.key !== "Backspace" && a.key !== "backspace") {
            if ((/^\d+(\.\d{1,2})?$/.test(a.key.toString())) === false) { return; }
        }
        var str = "";
        if (a.key === "E" || a.key === "e") {
            return;
        }
        if (a.key === "Backspace" || a.key === "backspace") {
            str = txtValue.substring(0, txtValue.length - 1);
        }
        else {
            str = txtValue + a.key;
        }
        setTxtValue(str);
        setDiscountAmount((parseFloat(str) / 100).toFixed(2));
    }
    const handleListDiscount = (discount_Type, Amount) => {
        var dtype = discount_Type === "Percentage" ? "%" : "$";
        // setDiscountType(dtype);
        setDiscountAmount(Amount);
        handleDiscount(dtype, Amount);
    }
    const handleDiscount = (discount_Type, dAmount = null) => {
        if (discountAmount == ".") {
            return;
        }
        const { selecteditem } = props;
        var discount_amount = discountAmount ? discountAmount : 0;
        if (dAmount != null && typeof dAmount !== "undefined") {
            discount_amount = dAmount;
        }
        if (selecteditem) {
            var product = {
                type: 'product',
                discountType: discount_Type == "%" ? 'Percentage' : 'Number',
                discount_amount,
                Tax_rate: 0, //this.props.taxratelist.TaxRate,
                Id: selecteditem.id,
            }
            if (selecteditem.hasOwnProperty("modifiers_key")) {
                product["modifiers_key"] = selecteditem.modifiers_key;
            }
            if (selecteditem.hasOwnProperty("addon_key")) {
                product["addon_key"] = selecteditem.modifiers_key;
            }
            if (selecteditem.hasOwnProperty("addon_ids")) {
                product["addon_ids"] = selecteditem.modifiers_key;
            }
            setDiscountAmount("");
            setTxtValue("");

            localStorage.setItem("PRODUCT", JSON.stringify(product))
            localStorage.setItem("SINGLE_PRODUCT", JSON.stringify(selecteditem))
            var qty = selecteditem && selecteditem.hasOwnProperty("quantity") ? selecteditem.quantity : 1;
            singleProductDiscount(null, null, qty);
            props.toggleProductDiscount();
            props.setDiscountApplied( discount_Type == "%" ? 'Percentage' : 'Number', discount_amount)
        }
    }
    const clearDiscount = () => {
        handleDiscount("%")
    }
    var CurrencySymbol = localStorage.getItem('currecySymbole') ? localStorage.getItem('currecySymbole') : "$";
    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow product-discount current" : "subwindow product-discount"}>
                <div className="subwindow-header">
                    <p>{LocalizedLanguage.productdiscount}</p>
                    <button className="close-subwindow" onClick={() => closePopup()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    <label htmlFor="productDiscount">{LocalizedLanguage.discountamount}:</label>
                    <input ref={refDiscountInput} style={{ direction: "LTL" }} type="number" id="productDiscount" placeholder="0" value={discountAmount} onKeyDown={(e) => discount_Amount(e)} />
                    <p>{LocalizedLanguage.selectdiscounttobe}:</p>
                    <div className="button-row">
                        <button onClick={() => handleDiscount('$')}>{CurrencySymbol} {LocalizedLanguage.discount}</button>
                        <button onClick={() => handleDiscount('%')}>% {LocalizedLanguage.discount}</button>
                    </div>
                    <div className="auto-margin-bottom"></div>
                    {/* pre set discount list code goes here- uncomment this*/}
                    <div className="subwindow cart-discount hgt-wdt-unset">
                        <div className="subwindow-body">
                            <div id="cartDiscountDiv">
                                <div className="btm-boder"></div>
                                <div className="list">
                                    <p>{LocalizedLanguage.presetdiscounts}</p>
                                    {allDiscount && allDiscount.map(d => {
                                        //DISPLAYING DISCOUNT WITH PERCENTAGE
                                        // d.Type === "Percentage" ?
                                        return  <button key={d.Id} onClick={() => handleListDiscount(d.Type, d.Amount)}>
                                            <p>{d.Name} ({d.Type === "Percentage" ? d.Amount + "%" : CurrencySymbol + d.Amount})</p>
                                        </button> 
                                        //: ""
                                    })}
                                    <button onClick={() => clearDiscount()}>
                                        <p>{LocalizedLanguage.discountClr}</p>
                                    </button>
                                </div>
                            </div></div>
                    </div>
                </div>

            </div></div>)
}

export default ProductDiscount 