import TileText from "./TileText";
import CartSpeed from '../../../assets/images/svg/Cart_Speed.svg';
import LockIcon from '../../../assets/images/svg/LockIcon.svg';
import NoImageAvailable from '../../../assets/images/svg/NoImageAvailable.svg';
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
const TileProduct = (props) => {
   // {item,isTileDelete,filterProductByTile,viewProduct,isProdcutSupported,mrRight,tilesNo,tileWidth,delete_tile})
    return (<button className={props.mrRight + (props.isTileDelete == true ? "product custom-tile-size remove-state" : "product custom-tile-size")} key={"product_" + props.item.Id} id={"product_" + props.item.Id} onClick={() =>
        props.isTileDelete === false ?(props.item.Type === 'simple' ? props.viewProduct(props.item) : props.filterProductByTile("product", props.item, null)) : null
                                }  /* {...bind()} style={{backgroundColor:item.TileColor!=null && item.TileColor!=""?item.TileColor:"var(--oliver-blue)"}}*/>
                                    {
                                        props.item.Type === 'simple' && (
                                            <span className={props.isTileDelete === true && props.item.Type === 'simple' ? 'tile-cart-btn hide' : 'tile-cart-btn'}
                                                onClick={(e) => {
                                                    if (props.item.Type === 'simple' || props.isTileDelete === true) {
                                                        e.stopPropagation();
                                                        props.filterProductByTile("product", props.item, null);
                                                    } else {
                                                        // null
                                                    }
                                                }}
                                            >
                                                <img src={CartSpeed} alt='' />
                                            </span>
                                        )
                                    }

                                    { props.isProdcutSupported(props.item)==false ?
                                        <div className="not-supported">
                                            <img src={props.LockIcon} alt="" />
                                        </div> : null}
                                    <div className="body">
                                        {props.item.Image != null && props.item.Image !=="False"?
                                            <img src={props.item.Image} alt="" /> :
                                            <img src={NoImageAvailable} alt="" />
                                        }
                                    </div>
                                    <div className="footer">
                                    <TileText  key={props.tilesNo} tilesNo={props.tilesNo}    tileWidth={props.tileWidth} content={(props.item.Title).replace(" - ", "-")} />
                                    </div>
                                    <div className={props.isTileDelete == true ? "remove-cover" : "remove-cover hide"}><div className="remove-button" onClick={() => props.delete_tile(props.item.Id, "product_" + props.item.Id, props.item.Order)}><img src={X_Icon_DarkBlue} alt="" /></div></div>

                                </button>
    );
  
}
export default TileProduct; 

