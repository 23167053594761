import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LeftNavBar from "../../common/commonComponents/LeftNavBar";
import X_Icon_DarkBlue from '../../../assets/images/svg/X-Icon-DarkBlue.svg';
import Oliver_Icon_BaseBlue from '../../../assets/images/svg/Oliver-Icon-BaseBlue.svg';
import Coin_Blue from '../../../assets/images/svg/Coin-Blue.svg';
import Minus_Blue from '../../../assets/images/svg/Minus-Blue.svg';
import Plus_Blue from '../../../assets/images/svg/Plus-Blue.svg';
import CircledPlus_White from '../../../assets/images/svg/CircledPlus-White.svg';
import NoVariationDisplay from '../../../assets/images/svg/NoVariationDisplay.svg';
import NoImageAvailable from '../../../assets/images/svg/NoImageAvailable.svg';
import Product_OutOfStock from '../../../assets/images/svg/ProductOutOfStock.svg';
import Checkout_Minus from '../../../assets/images/svg/Checkout-Minus.svg';
import Checkout_Plus from '../../../assets/images/svg/Checkout-Plus.svg';

import Pencil from '../../../assets/images/svg/Pencil.svg';
import RefreshGrey from '../../../assets/images/svg/RefreshGrey.svg';
import RefreshImgBlue from '../../../assets/images/svg/RefreshImgBlue.svg'
// import Shoes from '../../../assets/images/Temp/Shoes.png';
// import CoffeeCup from '../../../assets/images/Temp/CoffeeCup.png';
// import SnapbackHat from '../../../assets/images/Temp/SnapbackHat.png';
// import Face_Mask from '../../../assets/images/Temp/Face Mask.png';
// import Checkmark from '../../../assets/images/svg/Checkmark.svg';
// import LockedIcon from '../../../assets/images/svg/LockedIcon.svg';
// import Hanged_Tshirt from '../../../assets/images/Temp/Hanged-Tshirt.png';
import { useIndexedDB } from 'react-indexed-db';
import FormateDateAndTime from '../../../settings/FormateDateAndTime';
import Config from '../../../Config'
// import { initProuctFn } from '../../common/commonFunctions/productFn';
import ProductNote from "./ProductNote";
import ProductDiscount from "./ProductDiscount";
import AdjustInventory from "./AdjustInventory";
import NoVariationSelected from "./NoVariationSelected";
import MsgPopupOutOfStock from "./MsgPopupOutOfStock";
import { addSimpleProducttoCart, updateProductNote, addtoCartProduct, checkProdcutStockStatus, singleProductDiscount } from './productLogic';
import { getTaxAllProduct, getSettingCase, cartPriceWithTax, getVariatioModalProduct } from "../../common/TaxSetting";

import { product } from "./productSlice";
import CommonModuleJS, { CheckProductBackordered } from "../../../settings/CommonModuleJS";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { popupMessage } from "../../common/commonAPIs/messageSlice";
import { getInventory } from "../slices/inventorySlice";
// import { NumericFormat } from 'react-number-format';
import { RoundAmount } from "../../common/TaxSetting";
import MsgPopup from "../../common/commonComponents/MsgPopup";
import { currecyFunction } from "../../common/commonFunctions/currencyFunction";
// import { useRef } from "react";
import { canManageInventory } from "../../../settings/CommonWork";
import CarbonError from "../../../assets/images/svg/CarbonError.svg"
import { v4 as uniqueKey } from 'uuid';
import { addModifierAsCustomFee } from "./productModifier";
import ProductAddons from "./ProductAddons"; 
//import { UpdateProductInventoryDB } from "../../loadProduct/loadProductSlice";
const Product = (props) => {
    const dispatch = useDispatch();
    const { getAll } = useIndexedDB("modifiers");
    const { getByID: getProductByID, getAll: getAllProducts,update } = useIndexedDB("products");
    // const [modifierList, setModifierList] = useState([])
    const [selectedModifiers, setSelectedModifiers] = useState([])
    const [productModifiers, setProductModifiers] = useState([])
    const [saveSelectedModifiers, setSaveSelectedModifiers] = useState([])
    const [recommProducts, setRecommProducts] = useState([])
    const [addOnsList, setAddOns] = useState([])
    const [isProductNote, setisProductNote] = useState(false);
    const [isProductDiscount, setisProductDiscount] = useState(false);
    const [isAdjustInventory, setisAdjustInventory] = useState(false);
    const [isNoVariationSelected, setisNoVariationSelected] = useState(false);
    const [isOutOfStock, setisOutOfStock] = useState(false);
    const [productQty, setProductQty] = useState(1);
    const [note, setNote] = useState("");
    const [selVariations, setSelVariations] = useState([]);
    const [selOptions, setSelOptions] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [discountType, setDiscountType] = useState(null);
    const [discountApply, setDiscountApply] = useState(null);   
    //const [selectedVariationProduct, setSelectedVariationProduct] = useState(null)

    const [variationStockQunatity, setVariationStockQunatity] = useState(0)
    //const [customFeeModifiers, setCustomFeeModifiers] = useState([]);
    const [discounts, setDiscounts] = useState(null);
    const [disableAttribute, setDisableAttribute] = useState([]);
    const [availableAttribute, setAvailableAttribute] = useState([]);

    //const [respAttribute] = useSelector((state) => [state.attribute]);
    const [stockStatusInOut, setStockStatusInOut] = useState('In Stock');
    const [isRefereshIconInventory, setisRefereshIconInventory] = useState(true);
    const [selectedVariations, setSelectedVariations] = useState([]);
    const [checkIsSelection, setCheckIsSelection] = useState(false);
    const [allCombinations, setAllCombinations] = useState([]);
    // common message popup state
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [warningImg, setWarningImg] = useState('');
    const [isBackOrdered, setBackOrdered] = useState(false);
    const [isBackOrderedNotification, setBackOrderedNotification] = useState(false);
    const [isClear, setIsClear] = useState(false);
    const [addonsRequired, setAddonsRequired] = useState(false);
    var allVariations = [];
    // useIndexedDB("modifiers").getAll().then((rows) => {
    //     setModifierList(rows);
    // });

    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }
    const [inventoryStatus] = useSelector((state) => [state.inventories])
    useEffect(() => {
        sessionStorage.setItem("view", "Product View")
        var _product = props.variationProduct != null ? props.variationProduct : props.selProduct;
        sessionStorage.setItem("productId", _product.WPID)        
    }, [])
     const fatchUpdateInventory = () => {
    }

 const setAddonsValue=(addonData,requiredAddons)=>{
    setAddOns("")
    setAddOns(addonData);
    setIsClear(false);
    setAddonsRequired(requiredAddons);
    console.log("setAddons",addonData);
 }
      
    // const isBackOrdered = _product && _product.BackOrderd === true && (variationStockQunatity < 1 || productQty > variationStockQunatity);

    useEffect(() => {
        const product = props.variationProduct ? props.variationProduct : props.selProduct;
        const { StockStatus, BackOrderd } = product;
        let checkQuantity = product.StockQuantity==0  || (productQty > product.StockQuantity);

       // console.log(productQty,variationStockQunatity)
        
        let isBackOrdereTemp = CheckProductBackordered(product);
        setBackOrdered(isBackOrdereTemp);
       
        if (isBackOrdereTemp && checkQuantity) {           
            setBackOrderedNotification(true);
        } else {
            setBackOrderedNotification(false);
        }
    
            
    },[productQty,props.selProduct,props.variationProduct,variationStockQunatity,isBackOrdered])
    //console.log("isBackourdered ",isBackOrdered,"isBackOrderedNotification",isBackOrderedNotification)


    useEffect(() => {
        setSelectedVariations(selVariations);
    }, [selVariations]);

    var currentWareHouseDetail = "";
    useEffect(() => {
        if (inventoryStatus && inventoryStatus.inventoryGet && inventoryStatus.inventoryGet.data && inventoryStatus.inventoryGet.data.content) {
            var warehouseDetail = inventoryStatus && inventoryStatus.inventoryGet && inventoryStatus.inventoryGet.data && inventoryStatus.inventoryGet.data.content
            var CurrentWarehouseId = localStorage.getItem("WarehouseId");
            if (warehouseDetail && warehouseDetail.length > 0) {
                currentWareHouseDetail = warehouseDetail.find(item => item.warehouseId == CurrentWarehouseId)
            }
            if (currentWareHouseDetail && currentWareHouseDetail.hasOwnProperty("Quantity")) {

                var _product = props.variationProduct != null ? props.variationProduct : props.selProduct;
                var _stockStatus = checkProdcutStockStatus(_product, currentWareHouseDetail.Quantity);
                // var _stockStatus = _product ? ((_product.ManagingStock == true && _product.StockStatus == "outofstock") ? LocalizedLanguage.outOfStock :
                //     (_product.StockStatus == null || _product.StockStatus == 'instock') && _product.ManagingStock == false ? LocalizedLanguage.unlimited : (typeof _product.StockQuantity != 'undefined') && currentWareHouseDetail.Quantity != '' ? currentWareHouseDetail.Quantity : 0
                // ) : 0;
                // if (typeof _stockStatus === "string") {
                //     if (currentWareHouseDetail.Quantity > 0 && _product.ManagingStock == true) {
                //         setStockStatusInOut("instock");
                //     }
                //     else { 
                setStockStatusInOut(_stockStatus);
                //     }
                // }
                // else {
                //     setStockStatusInOut(LocalizedLanguage.instock);
                // }

                if (props.isProductEdit && props.isProductEdit == true && _product.hasOwnProperty("quantity")) {
                    //setVariationStockQunatity(currentWareHouseDetail.Quantity - _product.quantity)
                    if (_stockStatus.toLocaleLowerCase() !== 'unlimited') {
                        setVariationStockQunatity(currentWareHouseDetail.Quantity)
                    }
                }
                else {
                    var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
                    var _alreadyAddedQuantity = 0;
                    if(currentWareHouseDetail.Price){
                        _product['Price']=currentWareHouseDetail.Price;
                        _product['old_price']=currentWareHouseDetail.Price;
                        
                       }
                       if(currentWareHouseDetail.Quantity){
                        _product['StockQuantity'] = currentWareHouseDetail.Quantity;
                       }      
                       if( props.selProduct) {
                        props.selProduct['Price']=currentWareHouseDetail.Price;
                        props.selProduct['old_price']=currentWareHouseDetail.Price;
                       }
                        if(  props.variationProduct){
                            props.variationProduct['Price']=currentWareHouseDetail.Price;
                            props.variationProduct['old_price']=currentWareHouseDetail.Price;
                        }  
                        update(_product);//update product into indexDB

                    if (cartlist && cartlist.length > 0 && _product.ManagingStock == true) {
                        cartlist.map(m => {
                            var _id = _product.product_id ? _product.product_id : _product.WPID;
                            if (m.product_id == _id) {
                                _alreadyAddedQuantity = m.quantity;
                            }
                        })
                    }
                    if (_stockStatus.toLocaleLowerCase() !== 'unlimited') {
                        setVariationStockQunatity(currentWareHouseDetail.Quantity - _alreadyAddedQuantity)
                    }

                }

                if (currentWareHouseDetail.Quantity == 0) {
                    if (_stockStatus.toLocaleLowerCase() == 'unlimited') {
                        if (props.isProductEdit == false) { // DONT SET QUANTITY 1 IN EDIT MODE
                            setProductQty(1);
                        }
                    } else
                        setProductQty(0);
                }
                else {
                    if (productQty == 0) { setProductQty(1); }
                }
                //changes made in case of unlimited quantity, ManagingStock=false
                if (_product.ManagingStock == false && _product.StockQuantity > 0 && _product.StockStatus !== "outofstock") {
                    // setStockStatusInOut(LocalizedLanguage.unlimited);
                    setVariationStockQunatity(1);
                    setProductQty(1);
                }

                setisRefereshIconInventory(false);
            }
        }
    }, [inventoryStatus])
    const toggleProductNote = () => {
        setisProductNote(!isProductNote)
    }
    const toggleProductDiscount = () => {
        if (CommonModuleJS.permissionsForDiscount() == false) {

            var data = { title: "", msg: LocalizedLanguage.discountPermissionerror, is_success: true }
            dispatch(popupMessage(data));
            //alert(LocalizedLanguage.discountPermissionerror);
        }
        else {
            setisProductDiscount(!isProductDiscount)
        }
        showDiscounts(true);
    }

    const toggleAdjustInventory = (istoggle) => {

        if (props.isProductEdit && props.isProductEdit == true) {
            toggleMsgPopup(true);
            setmsgBody(LocalizedLanguage.removeItemToAdjust);
            setmsgTitle(LocalizedLanguage.productcurrentlyuse);
            setWarningImg("alert");
        }
        else {
            setisAdjustInventory(istoggle == null || istoggle == "undefined" ? !isAdjustInventory : istoggle)
        }
    }

    const toggleNoVariationSelected = () => {
        setisNoVariationSelected(!isNoVariationSelected)
    }
    const toggleOutOfStock = () => {
        setisOutOfStock(!isOutOfStock)
    }
    const quantityUpdateOnChage = (e, qty, prodcut) => {
        const { value } = e.target;
        const re = new RegExp("^[1-9][0-9]*$");
        // const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        var _isBackourdered = CheckProductBackordered(prodcut)
        if (value == '' || re.test(value)) {

            if (e && e.target && e.target.value && (qty > parseInt(value) || _isBackourdered == true)) {
                setProductQty(parseInt(e.target.value));
            }
            else {
                if (qty > 0 && e.target.value === "") {
                    setProductQty(1)
                }
                else {
                    setProductQty(qty)
                }
            }
            //changes made in case of unlimited quantity, ManagingStock=false
            if (stockStatusInOut && stockStatusInOut.toLowerCase() === "unlimited" || _isBackourdered == true) {
                if (e && e.target && e.target.value) {
                    setProductQty(parseInt(e.target.value));
                }
            }
            if (discountApply !==null && discountType !==null){//   _product.hasOwnProperty("new_product_discount_amount") ) { //&& _product.new_product_discount_amount !== 0
                         var productDisc = {
                           type: 'product',
                           discountType:  discountType,
                           discount_amount:discountApply,
                           Tax_rate: 0, //this.props.taxratelist.TaxRate,
                           Id: _product.WPID,
                       }
                       prodcut['quantity']=parseInt(e.target.value);
                       localStorage.setItem("PRODUCT", JSON.stringify(productDisc))
                       localStorage.setItem("SINGLE_PRODUCT", JSON.stringify(prodcut))
                       singleProductDiscount(null, null, parseInt(e.target.value)); 
               }
        }

    }
   const setDiscountApplied=(discountType,discount)=>{
    setDiscountType(discountType);
    setDiscountApply(discount);
   }
    const quantityUpdate = (type) => {

        if (checkLength() === true) {
            var _product = props.variationProduct ? props.variationProduct : props.selProduct;
            const { selProduct } = props;
            if (selProduct) {
                if (type === "plus") {
                    var qty = parseInt(productQty);
                    //if (this.state.variationfound ? selProduct.WPID == this.state.variationfound.WPID : selProduct.WPID == this.props.getVariationProductData.WPID) {
                    var maxQty = (selProduct.ManagingStock == false && selProduct.StockStatus == "outofstock") ? "outofstock" :
                        (selProduct.StockStatus == null || selProduct.StockStatus == 'instock') && selProduct.ManagingStock == false ? "Unlimited" : (typeof selProduct.StockQuantity != 'undefined') && selProduct.StockQuantity != '' ? parseFloat(selProduct.StockQuantity) : 0;
                    // var maxQty = this.state.variationStockQunatity == 'Unlimited' ? 'Unlimited' : parseFloat(this.state.variationStockQunatity) + parseFloat(showSelectedProduct.quantity);
                    //-- maxQty update after inventory check

                    if (stockStatusInOut && stockStatusInOut.toLowerCase() === "unlimited") {
                        qty++;
                    }
                    else {
                        if (typeof _currentStock != "undefined" && _currentStock != null) {
                            if (props.hasOwnProperty("isProductEdit") && props.isProductEdit == false) {
                                if (variationStockQunatity <= 0 && isBackOrdered === true) { //for back order
                                    maxQty = variationStockQunatity
                                } else {
                                    maxQty = variationStockQunatity
                                }

                            }
                            else {
                                maxQty = _currentStock;
                            }
                        }
                        //---
                        if (props.hasOwnProperty("isProductEdit") && props.isProductEdit == false) {
                            if ((maxQty == 'Unlimited' || qty < variationStockQunatity) || isBackOrdered === true) {
                                qty++;
                            }
                        }
                        else {
                            if ((maxQty == 'Unlimited' || qty < maxQty) || isBackOrdered === true) {
                                qty++;
                            }
                        }
                    }
                    _product.quantity = qty;
                    setProductQty(qty)
                }
                else if (type === "minus") {
                    var qty = parseInt(productQty);
                    if (qty > 1) {
                        qty--;
                        setProductQty(qty);
                    };
                    _product.quantity = qty;
                }
            
                //var single_product = localStorage.getItem("SINGLE_PRODUCT") ? JSON.parse(localStorage.getItem("SINGLE_PRODUCT")) : null;
                 if (discountApply !==null && discountType !==null){//   _product.hasOwnProperty("new_product_discount_amount") ) { //&& _product.new_product_discount_amount !== 0
                 /// if( _product.new_product_discount_amount){ //calulate discount when quantity update
                        var productDisc = {
                            type: 'product',
                            discountType:  discountType,
                            discount_amount:discountApply,
                            Tax_rate: 0, //this.props.taxratelist.TaxRate,
                            Id: _product.WPID,
                        }
                       
                        localStorage.setItem("PRODUCT", JSON.stringify(productDisc))
                        localStorage.setItem("SINGLE_PRODUCT", JSON.stringify(_product))
                        singleProductDiscount(null, null, _product.quantity);       
                  //  }

                     // addSimpleProducttoCart(_product, null, false);                            
                  
                }
            }
        }
        else {
            toggleNoVariationSelected();
        }
    }

    // Modifers --start

    const isActive = (modifierSchedulings) => {
        var schedul = modifierSchedulings;
        var isActive = false;
        if (schedul != null) {
            if (!schedul.AllowScheduling)
                isActive = true;
            else {
                //var today = SystemTime != null ? SystemTime : DateTime.Today.ToLocalTime();
                var today = new Date();
                if (schedul.SelectDateRange) {
                    //if (today.Date >= schedul.FromDate.Date && today.Date <= schedul.ToDate.Date)
                    if (new Date(today.toDateString()) >= new Date(schedul.FromDate) && new Date(today.toDateString()) <= new Date(schedul.ToDate))
                        isActive = checkActiveTime(today, schedul);
                    else
                        isActive = false;
                }
                else
                    isActive = checkActiveTime(today, schedul);
            }
        }
        return isActive;

    }
    const checkActiveTime = (today, schedul) => {
        //var todayTime = today.TimeOfDay;
        var todayTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var _to = 0;
        var _from = 0;
        switch (today.getDay()) {
            case 1:
                if (schedul.MondayActive && schedul.MondayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.MondayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.MondayTo, todayTime);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.MondayActive && todayTime >= schedul.MondayFrom && todayTime <= schedul.MondayTo)
                //     return true;
                break;
            case 2:
                if (schedul.TuesdayActive && schedul.TuesdayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.TuesdayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.TuesdayTo, todayTime);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.TuesdayActive && todayTime >= schedul.TuesdayFrom && todayTime <= schedul.TuesdayTo)
                //     return true;
                break;
            case 3:
                if (schedul.WednesdayActive && schedul.WednesdayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.WednesdayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.WednesdayTo, todayTime);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.WednesdayActive && todayTime >= schedul.WednesdayFrom && todayTime <= schedul.WednesdayTo)
                //     return true;
                break;
            case 4:
                if (schedul.ThursdayActive && schedul.ThursdayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.ThursdayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.ThursdayTo, todayTime);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.ThursdayActive && todayTime >= schedul.ThursdayFrom && todayTime <= schedul.ThursdayTo)
                //     return true;
                break;
            case 5:
                if (schedul.FridayActive && schedul.FridayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.FridayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.FridayTo, todayTime);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.FridayActive && todayTime >= schedul.FridayFrom && todayTime <= schedul.FridayTo)
                //     return true;
                break;
            case 6:
                if (schedul.SaturdayActive && schedul.SaturdayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.SaturdayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.FridayTo, schedul.SaturdayTo);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.SaturdayActive && todayTime >= schedul.SaturdayFrom && todayTime <= schedul.SaturdayTo)
                //     return true;
                break;
            case 0:
                if (schedul.SundayActive && schedul.SundayActive == true) {
                    _to = FormateDateAndTime.timeCompare(todayTime, schedul.SundayFrom);
                    _from = FormateDateAndTime.timeCompare(schedul.FridayTo, schedul.SundayTo);
                    if ((_to == 1 || _to == 0) && (_from == 1 || _from == 0)) { return true; }
                    else { return false; }
                }
                // if (schedul.SundayActive && todayTime >= schedul.SundayFrom && todayTime <= schedul.SundayTo)
                //     return true;
                break;
            default:
                return false;
                break;
        }
        return false;
    }
    const getModifiers = async (_product = null, isClear = false) => {
        const rows = await getAll();
        // getAll().then((rows) => {
        var _modifierList = rows;
        var product = props.variationProduct ? props.variationProduct : props.selProduct;
        if (_product != null) {
            product = _product;
        }
        if (product == null || typeof product == "undefined")
            return;
        var d = _modifierList && _modifierList.filter(match => match.Visible == true && match.modifierAssingnees.find(m =>

            (((m.AssigneeId == product.ParentId && product.ParentId !== 0 || m.AssigneeId == product.WPID)) && m.AssigneeType == Config.key_AssigneeType.Product) ||
            (product.CategorieList && product.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Category) ||
            (product.ProductAttributes && product.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Attribute) ||
            (product.CategorieList && product.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubCategory) ||
            (product.ProductAttributes && product.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubAttribute)

            || (props.selProduct.CategorieList && props.selProduct.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Category) ||
            (props.selProduct.ProductAttributes && props.selProduct.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.Attribute) ||
            (props.selProduct.CategorieList && props.selProduct.CategorieList.find(x => x.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubCategory) ||
            (props.selProduct.ProductAttributes && props.selProduct.ProductAttributes.find(x => x.Name.toLowerCase() == m.AssigneeName.toLowerCase()) && m.AssigneeType == Config.key_AssigneeType.SubAttribute)
        )).map((match) => { return match })

        var all_modifiers = [];
        var modifiers = [];
        if (d && d.length > 0) {
            d.map(m => {
                let result = (m.modifierSchedulings && m.modifierSchedulings.length > 0) ? isActive(m.modifierSchedulings[0]) : true;
                if (result && result == true) {
                    all_modifiers.push(m);
                    var data = [];
                    m.modifierFields && m.modifierFields.map(mf => {
                        return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                            if (efm.Default === "yes") {
                                data.push({ id: efm.ModifierId, sub_title: efm.Name, qty: 1, amount: efm.Amount, add_sub: efm.AddnSubtract, amount_type: efm.Type, field_type: m.Type });
                            }
                        }))
                    })
                    modifiers.push({ id: m.Id, modifier_id: m.Title.replace(/ /g, "_"), title: m.Title.replace(/ /g, "_"), type: m.Type, is_active: data.length > 0 ? true : false, TaxOption: m.TaxOption, data: data })

                    //var modifirList = props.listItem.filter(b => b.hasOwnProperty('pid') && (b.pid === a.product_id) && (b.hasOwnProperty("check_modifer")) && b.hasOwnProperty("modifiers_key") && a.hasOwnProperty("modifiers_key") && a.modifiers_key == b.modifiers_key);
                    //Array.isArray(selected_tax) == true
                }
            });
        }
        if (selectedModifiers && selectedModifiers.length > 0 && isClear !== true) {
            setProductModifiers(all_modifiers);
        }
        else {
            setProductModifiers(all_modifiers);
            setSelectedModifiers(modifiers);
        }
        if (props.isProductEdit && props.isProductEdit == true) {
            if (product && product.hasOwnProperty("modifiers_Id")) {
                var _selModifiers = product.modifiers_Id
                try {
                    _selModifiers = JSON.parse(_selModifiers)
                    //return true;
                } catch (error) {
                    _selModifiers = _selModifiers.substring(1, _selModifiers.length - 1)
                    //return false;
                    console.log("---issue---")
                }
                var _selectedModifiers = _selModifiers;
                if (typeof _selModifiers == 'string') {
                    _selectedModifiers = JSON.parse(_selModifiers);
                }
                setSelectedModifiers(_selectedModifiers);
                getModifierFilled(_selectedModifiers);
            }
            if (product && product.hasOwnProperty("addon_ids")) {
                var _selAddons = product.addon_ids
                try {
                    _selAddons = JSON.parse(_selAddons)
                    //return true;
                } catch (error) {
                    _selAddons = _selAddons.substring(1, _selAddons.length - 1)
                    //return false;
                    console.log("---issue---")
                }
                var _selectedAddOns = _selAddons;
                if (typeof _selAddons == 'string') {
                    _selectedAddOns = JSON.parse(_selAddons);
                }
                setAddOns(_selectedAddOns);
               // getModifierFilled(_selectedAddOns);
            }

        }
        //});
    }
    const preSelectedModifiers = (id, name) => {
        var sSel = false;
        var _name = name;
        if (_name != null) {
            _name = _name.replace(/ /g, "_")
            var _mod = selectedModifiers && selectedModifiers.find(a => a.modifier_id == id);
            if (_mod && _mod.data && _mod.data.length > 0 && _mod.is_active === true) {
                var _found = _mod.data.find(a => a.sub_title == name || a.sub_title == _name);
                if (typeof _found != "undefined" && _found !== null) {
                    sSel = true;
                }
            }
            return sSel;
        }
    }
    const getModifierFilled = (_selModifiers = []) => {
        var product = props.variationProduct ? props.variationProduct : props.selProduct;
        if (_product != null) {
            product = _product;
        }
        const _datas = _selModifiers && _selModifiers.map(a => a.data);
        if (_datas && _datas.length > 0) {
            _datas.map(m => {
                if (m && Array.isArray(m)) {
                    m.map(fm => {
                        if (fm) {
                            const _data = fm;
                            const modi = _data.id;
                            if (document.getElementById(modi)) {
                                if (_data.field_type == 2 || _data.field_type == 3) {
                                }
                                else {
                                    document.getElementById(modi).value = _data.sub_title;
                                }
                            }
                            else if (document.getElementById(modi + "-quantityUpdater")) {
                                document.getElementById(modi + "-quantityUpdater").value = _data.qty;
                            }

                        }
                    });
                }
            })
        }
        // if (product.hasOwnProperty("modifiers_Id") && product.modifiers_Id.length > 0) {
        //     product.modifiers_Id.map(m => {
        //         if (m && Array.isArray(m)) {
        //             m.map(fm => {
        //                 if (fm && fm.length > 1) {
        //                     const _data = fm[1];
        //                     const modi = _data.id;
        //                     if (document.getElementById(modi)) {
        //                         if (_data.field_type == 2 || _data.field_type == 3) {
        //                             document.getElementById(modi).click();
        //                         }
        //                         else {
        //                             document.getElementById(modi).value = _data.sub_title;
        //                             onChangeValueEdit(document.getElementById(modi));
        //                         }
        //                     }
        //                     else if (document.getElementById(modi + "-quantityUpdater")) {
        //                         document.getElementById(modi + "-quantityUpdater").value = _data.qty;
        //                         onChangeValueEdit(document.getElementById(modi + "-quantityUpdater"));
        //                     }

        //                 }
        //             });
        //         }
        //     })
        // }
    }
    // editModifiersSelections()
    // {
    //     selectedModifiers && selectedModifiers.map(mod =>{
    //         switch (mod.type) {
    //             case Config.key_InputTypes.CheckBox:
    //                 return(
    //                     mod.data && mod.data.map(mf=>{
    //                         if(document.getElementById(mf.id)) { document.getElementById(mf.id).checked=true};
    //                     })
    //                 )
    //                 break;
    //             case Config.key_InputTypes.NumberField:
    //                 return(
    //                     mod.data && mod.data.map(mf=>{
    //                         if(document.getElementById(mf.id+"-quantityUpdater")) { document.getElementById(mf.id).value=mf.qty};
    //                         if(document.getElementById(mf.id+"-amount")) { document.getElementById(mf.id).value=mf.amount};
    //                     })
    //                 )
    //                 break;
    //             case Config.key_InputTypes.RadioButton:
    //                 return(
    //                     mod.data && mod.data.map(mf=>{
    //                         if(document.getElementById(mf.id)) { document.getElementById(mf.id).checked=true};
    //                     })
    //                 )
    //                 break;
    //             case Config.key_InputTypes.TextField:
    //                 return(
    //                     mod.data && mod.data.map(mf=>{
    //                         if(document.getElementById(mf.id+"-txt")) { document.getElementById(mf.id).value=mf.sub_title};
    //                         if(document.getElementById(mf.id+"-amount")) { document.getElementById(mf.id).value=mf.amount};
    //                     })
    //                 )
    //                 break;
    //             default:
    //                 break;
    //         }
    //     })
    // }
    const qunatityChange = (event) => {
        if (event.currentTarget.getAttribute("data-parent-id")) {
            var id = event.currentTarget.getAttribute("data-parent-id");
            var gpid = event.currentTarget.getAttribute("data-gpid");
            var btnType = event.currentTarget.getAttribute("data-btn-type")
            var inputField = document.getElementById(id + "-quantityUpdater")
            var pgpid = event.currentTarget.getAttribute("data-gparent-name")
            if (btnType == "plus") {
                if (inputField.getAttribute("data-max-number") && parseInt(inputField.value) < parseInt(inputField.getAttribute("data-max-number"))) {
                    inputField.value = parseInt(inputField.value) + 1
                }
            }
            else {
                if (parseInt(inputField.value) > 1) {
                    inputField.value = parseInt(inputField.value) - 1
                }
            }

            var amount = inputField.getAttribute("data-amount") && inputField.getAttribute("data-amount") * inputField.value;
            var amount_type = inputField.getAttribute("data-amount-type") && inputField.getAttribute("data-amount-type");
            var add_sub = inputField.getAttribute("data-add-sub") && inputField.getAttribute("data-add-sub");
            document.getElementById(id + "-amount").value = amount_type + " " + parseFloat(amount).toFixed(2);
            var data = { id: id, sub_title: id /*event.target.getAttribute("name")*/, qty: inputField.value, amount: amount, add_sub: add_sub, amount_type: amount_type, field_type: "1" };
            var update_data = selectedModifiers.map(md => {
                if (md.modifier_id === gpid || md.modifier_id == pgpid) {
                    md.is_active = true;

                    const index = md.data.findIndex(object => object.id === data.id);
                    if (index === -1) { md.data.push(data); }
                    else { md.data[index] = data; }
                    return md;
                }
                return md;
            });
            setSelectedModifiers(update_data);
        }
    }

    const onChangeValue = (event) => {
        // if(selectedModifiers.length==0)
        // {
        //     getModifiers();
        // }
        var add_sub = "";
        var amount_type = "";
        if (event.target.getAttribute("id").includes("quantityUpdater")) {
            if (event.target.value == "")
                event.target.value = 1;
            var amount = event.target.getAttribute("data-amount") && event.target.getAttribute("data-amount") * event.target.value;
            amount_type = event.target.getAttribute("data-amount-type") && event.target.getAttribute("data-amount-type");
            var newid = event.target.getAttribute("id").replace("quantityUpdater", "amount");
            document.getElementById(newid).value = amount_type + " " + parseFloat(amount).toFixed(2);
        }
        if (event.target.type) {
            amount_type = event.target.getAttribute("data-amount-type") && event.target.getAttribute("data-amount-type");
            var gpid = event.target.getAttribute("data-gpid") ? event.target.getAttribute("data-gpid") : "";
            var gpname_id = event.target.getAttribute("data-gparent-name") ? event.target.getAttribute("data-gparent-name") : "";

            add_sub = event.target.getAttribute("data-add-sub") && event.target.getAttribute("data-add-sub");
            var data = {};
            var action = "add_update";
            var _qty = 1;
            switch (event.target.type) {
                case "number":
                    data = { id: event.target.getAttribute("id").replace("-quantityUpdater", ""), sub_title: event.target.getAttribute("name"), qty: event.target.value, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "1" };
                    break;
                case "radio":
                    action = "update";
                    data = { id: event.target.getAttribute("id"), sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "2" };
                    break;
                case "checkbox":
                    if (event.target.checked == false) { action = "remove"; }
                    data = { id: event.target.getAttribute("id"), sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "3" };
                    break;
                case "text":
                    if (event.target.value == "")
                        return
                    data = { id: event.target.getAttribute("id"), sub_title: event.target.value, qty: _qty, amount: event.target.getAttribute("data-amount"), add_sub: add_sub, amount_type: amount_type, field_type: "0" };
                    break;
                default:
                    break;
            }
            if (action == "add_update") {
                var update_data = selectedModifiers.map(obj => {
                    if (obj.modifier_id === /*gpid*/ gpname_id) {
                        obj.is_active = true;
                        var index = obj.data.findIndex(object => object.id == data.id || (object.sub_title.replace(/ /g, "_") + object.id) == data.id);

                        if (index === -1) { obj.data.push(data); }
                        else { obj.data[index] = data; }
                        return obj;
                    }
                    return obj;
                });
                setSelectedModifiers(update_data)
                // this.setState({ SelectedModifiers: update_data });
            }
            else if (action == "remove") {
                var update_data = selectedModifiers.map(md => {
                    if (md.modifier_id === gpid) {
                        md.is_active = true;
                        md.data = md.data.filter(d => d.id !== data.id);
                    }
                    return md;
                });
                // this.setState({ SelectedModifiers: update_data });
                setSelectedModifiers(update_data)
            }
            else if (action == "update") {
                var update_data = selectedModifiers.map(md => {
                    if (md.modifier_id === gpid || md.id == gpid) {
                        md.is_active = true;
                        md.data = [data];
                    }
                    return md;
                });
                setSelectedModifiers(update_data)
            }
        }
    }
    // const submitChanges = () => {
    //     setSaveSelectedModifiers(selectedModifiers)
    //     setTimeout(() => {
    //         _addModifierAsCustomFee();
    //     }, 300);
    // }
    const _addModifierAsCustomFee = (_selectedModifiers, modifiers_key = "", modiferIds = "") => {
        var _product = props.variationProduct ? props.variationProduct : props.selProduct;
        if (_product) {
            if (typeof modiferIds !== "undefined" && modiferIds != null && modiferIds.length > 0) {
                _product["modifiers_Id"] = modiferIds;
                _product["modifiers_key"] = modifiers_key;
            }
        }
        //addModifierAsCustomFee(_selectedModifiers, _product);
        //return;
        var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
        if (cartlist && cartlist.length > 0) {
            //var updatedItem = cartlist.find(a => a.product_id == _product.WPID);
            var updatedItem = cartlist.find(a => a.product_id == _product.WPID && ((a.hasOwnProperty("modifiers_key") && a.modifiers_key == _product.modifiers_key) || !a.hasOwnProperty("modifiers_key")));
            if (typeof updatedItem != "undefined" && updatedItem != null) {
                _product = updatedItem;
            }
        }
        var _saveSelectedModifiers = _selectedModifiers;
        var _quantity = _product.quantity + (_product.hasOwnProperty('quantity_refunded') == true ? _product.quantity_refunded : 0);
        // var product_price = (_product.after_discount != 0 ? _product.after_discount : (_product.old_price * _quantity));
        var product_price = ( (_product.old_price * _quantity));
        var _data = [];
        _saveSelectedModifiers && _saveSelectedModifiers.map(m => {
            if (m.is_active == true) {
                m.data.map(n => {
                    var _summary = "";
                    var _sum = 0;
                    var _amount = 0;
                    _summary += n.sub_title != null ? n.sub_title : "";// (_summary == "" ? "" : ", ") + (n.sub_title != null ? n.sub_title : "");
                    if (n.add_sub && n.add_sub == "subtract") {

                        if (n.amount_type == "%") {
                            var a = (product_price * n.amount) / 100;
                            _sum += parseFloat(-a);
                            _amount += parseFloat(-a / _quantity);
                        }
                        else {
                            _sum += parseFloat(-n.amount * _quantity);
                            _amount += parseFloat(-n.amount);
                        }
                    }
                    else {

                        if (n.amount_type == "%") {
                            var a = (product_price * n.amount) / 100;
                            _sum += parseFloat(a);
                            _amount += parseFloat(a);
                        }
                        else {
                            _sum += parseFloat(n.amount * _quantity);
                            _amount += parseFloat(n.amount);
                        }
                    }
                    if (m.data.length > 0) {
                        var title = m.title.replace("_", " ") + (_summary != null & _summary != "" ? " (" + _summary + ")" : "")
                        // if(m.type!=0)
                        // {
                        //     title = n.id.toString().replace("_", " ") +title;
                        // }

                        _data.push({ Title: title, Price: _sum, old_price: _amount, isTaxable: m.TaxOption, TaxStatus: (m.TaxOption == true ? "taxable" : "none"), TaxClass: _product.hasOwnProperty("TaxClass") ? _product.TaxClass : '', discount_type: '', quantity: _quantity, check_modifer: true, pid: _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID, modifiers_key: modifiers_key });
                    }

                });
                //if (m.data.length > 0)
                // _data.push({ Title: m.title.replace("_", " ") + (_summary != null & _summary != "" ? " (" + _summary + ")" : ""), Price: _sum, old_price: _sum, isTaxable: m.TaxOption, TaxStatus: (m.TaxOption == true ? "taxable" : "none"), TaxClass: _product.hasOwnProperty("TaxClass") ? _product.TaxClass : '', discount_type: '', quantity: 1, check_modifer: true, pid: _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID });
            }
        })
        if (_data && _data.length > 0) {
            var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
            //var _pid = _product.product_id ? _product.product_id : _product.WPID;
            //var replaceModi = cartlist.filter(a => (a.pid != _pid) || (!a.hasOwnProperty('check_modifer') && a.Price == null));
            var replaceModiOnly = cartlist.filter(a => a.modifiers_key == _product.modifiers_key && (a.hasOwnProperty('check_modifer')) && a.check_modifer == true);
            var replaceModi = cartlist.filter((el) => !replaceModiOnly.includes(el));
            if (typeof replaceModi != "undefined" && replaceModi != null && replaceModi.length > 0) {
                cartlist = replaceModi == null ? [] : replaceModi;
            }
            else {
                cartlist = cartlist == null ? [] : cartlist;
            }
            //ADDING fee_id ATTRIBUTE IN THE MODIFIER(lineitem/custom fee)
            if (typeof replaceModiOnly != "undefined" && replaceModiOnly != null && replaceModiOnly.length > 0) {
                replaceModiOnly && replaceModiOnly.map((m, index) => {
                    var feefound = replaceModiOnly.find((a, _index) => a.Title == m.Title && index == _index);
                    if (typeof feefound != "undefined" && feefound != null && feefound.hasOwnProperty("fee_id")) {
                        var foundIndex = replaceModiOnly.indexOf(feefound);
                        if (_data[foundIndex]) {
                            _data[foundIndex]["fee_id"] = feefound.fee_id;
                        }
                    }
                })
            }
            //END

            cartlist = cartlist.concat(_data);
            addtoCartProduct(cartlist, null, _product);
        }
    }
    const addAddOnsAsCustomFee = (addon_key) => {
        console.log("addOnsList",addOnsList)
        if(addOnsList == null || addOnsList.length==0){
            return;
        }
        var _product = props.variationProduct ? props.variationProduct : props.selProduct;
        if (_product) {
            if (typeof addOnsList !== "undefined" && addOnsList != null && addOnsList.length > 0) {
                _product["addon_ids"] = addOnsList;
                _product["addon_key"] = addon_key;
            }
        }
        console.log("addOnsList",addOnsList)        
        var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
        //Remove the existing addond for current prodcut 
        //  cartlist =cartlist.filter(item=>(item.hasOwnProperty("addonId") && item.addonId==addon_key && item.pid !==_product.WPID));
        //  localStorage.setItem("CARD_PRODUCT_LIST",JSON.stringify(cartlist));
         if (cartlist && cartlist.length > 0) {
            var updatedItem = cartlist.find(a => a.product_id == _product.WPID && ((a.hasOwnProperty("addon_key") && a.addon_key == _product.addon_key) || !a.hasOwnProperty("addon_key")));
            if (typeof updatedItem != "undefined" && updatedItem != null) {
                _product = updatedItem;
            }          
        }
        var _saveAddons = addOnsList;
        var _quantity = _product.quantity + (_product.hasOwnProperty('quantity_refunded') == true ? _product.quantity_refunded : 0);
        // var product_price = (_product.after_discount != 0 ? _product.after_discount : (_product.old_price * _quantity));
        var product_price = ( (_product.old_price * _quantity));
        var _data = [];
        addOnsList && addOnsList.map(n => {
           // if (m.is_active == true) {
                //m.data.map(n => {
                    var _summary = "";
                    var _sum = 0;
                    var _amount = 0;
                    _summary += n.sub_title != null ? n.sub_title : "";// (_summary == "" ? "" : ", ") + (n.sub_title != null ? n.sub_title : "");
                  var addOnPrice=0;
                  if(n.amount_type=="input_multiplier")
                    addOnPrice=n.amount * n.qty;
                else
                    addOnPrice=n.amount;
                    if (n.add_sub && n.add_sub == "subtract") {

                        if (n.amount_type == "%") {
                            var a = (product_price * addOnPrice) / 100;
                            _sum += parseFloat(-a);
                            _amount += parseFloat(-a / _quantity);
                        }
                        else {
                            _sum += parseFloat(-addOnPrice * _quantity);
                            _amount += parseFloat(-addOnPrice);
                        }
                    }
                    else {

                        if (n.amount_type == "%" || n.amount_type == "percentage_based") {
                            var a = (product_price * addOnPrice) / 100;
                            _sum += parseFloat(a);
                            _amount += parseFloat(a);
                        }
                        else if (n.amount_type == "quantity_based"){
                            _sum += parseFloat(addOnPrice * _quantity);
                            _amount += parseFloat(addOnPrice);
                        }
                        else if (n.amount_type == "flat_fee"){                              
                            _sum += parseFloat(addOnPrice);
                            _amount += parseFloat(addOnPrice);
                        }
                    }
                   // if (m.data.length > 0) {
                        var title = n.title.replace("_", " ") + (_summary != null & _summary != "" ? " (" + _summary + ")" : "")
                        // if(m.type!=0)
                        // {
                        //     title = n.id.toString().replace("_", " ") +title;
                        // }

                        _data.push({ Title: title, Price: _sum, old_price: _amount, isTaxable: _product.isTaxable, TaxStatus: _product.TaxStatus, TaxClass: _product.hasOwnProperty("TaxClass") ? _product.TaxClass : '', discount_type: '', quantity: _quantity, check_addons: true, pid: _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID,addonId:n.id,name:n.title, value:_summary, addon_key:addon_key});
                    //}

                //});
                //if (m.data.length > 0)
                // _data.push({ Title: m.title.replace("_", " ") + (_summary != null & _summary != "" ? " (" + _summary + ")" : ""), Price: _sum, old_price: _sum, isTaxable: m.TaxOption, TaxStatus: (m.TaxOption == true ? "taxable" : "none"), TaxClass: _product.hasOwnProperty("TaxClass") ? _product.TaxClass : '', discount_type: '', quantity: 1, check_modifer: true, pid: _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID });
           // }
        })
        if (_data && _data.length > 0) {
            var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
            //var _pid = _product.product_id ? _product.product_id : _product.WPID;
            //var replaceModi = cartlist.filter(a => (a.pid != _pid) || (!a.hasOwnProperty('check_modifer') && a.Price == null));
            var replaceModiOnly = cartlist.filter(a => a.addon_key == _product.addon_key && (a.hasOwnProperty('check_addons')) && a.check_addons == true);
            var replaceModi = cartlist.filter((el) => !replaceModiOnly.includes(el));
            if (typeof replaceModi != "undefined" && replaceModi != null && replaceModi.length > 0) {
                cartlist = replaceModi == null ? [] : replaceModi;
            }
            else {
                cartlist = cartlist == null ? [] : cartlist;
            }
            //ADDING fee_id ATTRIBUTE IN THE MODIFIER(lineitem/custom fee)
            if (typeof replaceModiOnly != "undefined" && replaceModiOnly != null && replaceModiOnly.length > 0) {
                replaceModiOnly && replaceModiOnly.map((m, index) => {
                    var feefound = replaceModiOnly.find((a, _index) => a.Title == m.Title && index == _index);
                    if (typeof feefound != "undefined" && feefound != null && feefound.hasOwnProperty("fee_id")) {
                        var foundIndex = replaceModiOnly.indexOf(feefound);
                        if (_data[foundIndex]) {
                            _data[foundIndex]["fee_id"] = feefound.fee_id;
                        }
                    }
                })
            }
            //END

            cartlist = cartlist.concat(_data);
            addtoCartProduct(cartlist, null, _product);
        }
    }
    const getRecomProducts = () => {
        var ids = "";
        if (props && props.selProduct) {
            ids = props.selProduct.ReletedIds;
            if (ids != "" && typeof ids != "undefined") {
                var tempArr = ids.split(',');
                if (tempArr && tempArr.length > 4) {
                    tempArr = tempArr.slice(0, 4);
                    ids = tempArr;
                }
                else if (tempArr) {
                    ids = tempArr;
                }
            }
        }

        if (ids != "" && typeof ids != "undefined" && ids.length>0) {
            var recomProducts = [];
            ids.map(async (id) => {
                var aa = await getProductByID(id).then((row) => {
                    return row;
                });
                aa && recomProducts.push(aa);
                if (ids.length == recomProducts.length) {
                    setRecommProducts(recomProducts);
                }
            });
        }
    }

    var _selVariationsEdit = [];
    const setSelectedOption = (option, attribute, AttrIndex) => {
        _selVariationsEdit = selVariations;

        var _slug = null;
        let _OptionAll = attribute.OptionAll;// && JSON.parse(attribute.OptionAll);
        var isAllOption = false;
        if (Array.isArray(_OptionAll) == true || _OptionAll.length >= 1) {
            isAllOption = true;
        }
        else {
            _OptionAll = attribute.options ? attribute.options.split(',') : [];
        }
        var result = _OptionAll.filter(b => (b.hasOwnProperty("slug") && b.slug === option) || b === option);
        if (result && typeof result != "undefined" && result.length > 0) {
            _slug = result;
        }
        var _item = _selVariationsEdit.findIndex((element) => {
            return element.Name === attribute.Name;
        })
        if (_item == -1) {
            _selVariationsEdit.push({ "Name": attribute.Name, "Option": option, "Index": AttrIndex, "OptionTitle": option.replace(/\s/g, '-').toLowerCase(), "Slug": _slug ? _slug[0].slug : "" });
        }
        _selVariationsEdit = _selVariationsEdit.map(obj => {
            if (obj.Name === attribute.Name) {
                return { ...obj, Option: option, OptionTitle: option.replace(/\s/g, '-').toLowerCase(), "Slug": _slug && _slug[0].hasOwnProperty("slug") ? _slug[0].slug : (_slug != null ? _slug[0] : "") };
            }
            return obj;
        });

        if (checkLength() == false) {
            if (
                JSON.stringify(selVariations) != JSON.stringify(_selVariationsEdit)) {
                setSelVariations(_selVariationsEdit);
            }
        }
    }
    const availableVariations = () => {
        if (props && props.selProduct) {
            var _product = props.selProduct;
            var _attribute = [];
            var _attribute1 = [];
            var _allCombi = [];
            getAllProducts().then((rows) => {
                var data = rows.filter(a => a.ParentId === _product.WPID);
                var allProdcuts = getTaxAllProduct(data)
                if (allProdcuts && allProdcuts.length > 0) {
                    var filteredAttribute = allProdcuts.filter(item => {
                        if (item && item.combination !== null && item.combination !== undefined) {
                            if (_attribute1 && _attribute1.length > 0) {
                                var _result = _attribute1.find(b => b == item.combination);
                                if (typeof _result == "undefined" || _result == null) {
                                    _attribute1.push(item.combination);
                                }
                            }
                            else {
                                _attribute1.push(item.combination);
                            }
                        }
                        var allCombi = item && item.combination !== null && item.combination !== undefined && item.combination.split("~");
                        _allCombi.push(allCombi);
                        allCombi = allCombi.map(a => {
                            let _a = a.replace(/\//g, "-").toLowerCase();
                            if (_attribute && _attribute.length > 0) {
                                var _result = _attribute.find(b => b == _a);
                                if (typeof _result == "undefined" || _result == null) {
                                    _attribute.push(_a);
                                }
                            }
                            else {
                                _attribute.push(_a);
                            }
                        });

                    });
                    setAvailableAttribute(_attribute);
                    setAllCombinations(_allCombi);
                }
            });
        }
    }
    const doVariationSearch = () => {
        if (props && props.isShowPopups && props.isShowPopups == true) {
            if (props.selProduct && props.selProduct.hasOwnProperty("selectedOptions") && props.selProduct.selectedOptions.length > 0) {
                return;
            }
        }
        if (props && props.selProduct) {
            var _product = props.selProduct;
            var _attribute = [];
            var ProductAttribute = [];

            if (_product && _product.ProductAttributes !== null) {
                ProductAttribute = _product.ProductAttributes;
                _attribute = ProductAttribute && ProductAttribute.filter(item => item.Variation == true);
            }
            getAllProducts().then((rows) => {
                var data = rows.filter(a => a.ParentId === _product.WPID);
                var allProdcuts = getTaxAllProduct(data)
                if (allProdcuts && allProdcuts.length > 0) {
                    var filteredAttribute = allProdcuts.filter(item => {
                        var allCombi = item && item.combination !== null && item.combination !== undefined && item.combination.split("~");
                        allCombi = allCombi.map(a => { return a.replace(/\//g, "-").toLowerCase() });
                        return selVariations.every(ele => (allCombi.includes(ele.Slug.toLowerCase()) || allCombi.includes("**")) && _attribute.length === selVariations.length)
                    })
                    if (filteredAttribute && filteredAttribute.length == 1) {
                        props.updateVariationProduct && props.updateVariationProduct(filteredAttribute[0]);
                    }
                    else {
                        props.updateVariationProduct && props.updateVariationProduct(null);
                    }

                    allProdcuts.filter(item => {
                        var allCombi = item && item.combination !== null && item.combination !== undefined && item.combination.split("~");
                        var aa = selVariations.every(ele => (allCombi.includes(ele.Slug.toLowerCase()) || allCombi.includes("**")))
                        if (aa == true) {
                            allCombi = allCombi.map(a => {
                                var _att = a.replace(/\//g, "-").toLowerCase();
                                const index = _disableAttribute.findIndex(item => item === _att)
                                if (index === -1) {
                                    _disableAttribute.push(_att);
                                }
                                return _att;
                            });
                        }
                        var result = selVariations.every(ele => (allCombi.includes(ele.Slug.toLowerCase()) || allCombi.includes("**")) /*&& _attribute.length===selVariations.length*/)
                        return result;
                    })
                }
            });
        }
    }

    // const findVaraionts=(ProductSubAttribute)=>
    // {
    //     //const ProductSubAttribute = this.props;
    //     let _OptionAll=ProductSubAttribute.OptionAll;// && JSON.parse(ProductSubAttribute.OptionAll);
    //     var isAllOption=false;
    //     if( Array.isArray(_OptionAll)==true || _OptionAll.length >=1){
    //         isAllOption=true;
    //     }
    //     else{
    //         _OptionAll=ProductSubAttribute.options?ProductSubAttribute.options.split(','):[];
    //     }
    //     return (
    //         _OptionAll && _OptionAll !== undefined ? (
    //             _OptionAll.map((_allOpt, index) => {
    //                 var randomNum=Math.floor(Math.random() * 100); //used to differentiate the IDs
    //                 var option= isAllOption ==true && _allOpt.slug ?_allOpt.slug:_allOpt;
    //                 var displayOption=isAllOption ==true && _allOpt.name ?_allOpt.name:_allOpt;
    //                 var isExist = false;
    //                 var newOption = isAllOption ==true && _allOpt.slug ? _allOpt.slug:_allOpt;
    //                 var isVariationExist = false;
    //                 //Find options Code----------------------------------------------
    //                 var attribute_list = null;
    //                 attribute_list = localStorage.getItem("attributelist") && localStorage.getItem("attributelist") !=='undefined' && Array.isArray(JSON.parse(localStorage.getItem("attributelist"))) === true ? JSON.parse(localStorage.getItem("attributelist")) : null;
    //                 var sub_attribute;
    //                 //var option;
    //                 if(attribute_list !== null && attribute_list !== undefined && attribute_list.length > 0){
    //                     var found = attribute_list.find(function (element) {
    //                         return element.Code.toLowerCase() == ProductSubAttribute.parentAttribute.toLowerCase()
    //                     })
    //                     if (found) {
    //                         sub_attribute = found.SubAttributes.find(function (element) {
    //                             return element.Value.toLowerCase() == option.toLowerCase()
    //                         })
    //                     }
    //                 }

    //                 newOption = sub_attribute ? sub_attribute.Code : newOption;
    //                 var newOptionVal = sub_attribute ? sub_attribute.Value : newOption; // get sub_attribute value attr if Code not matched
    //                 var variationsOfrenderOption = [];
    //                 ProductSubAttribute.productVariations && ProductSubAttribute.productVariations.map(variation => {
    //                     if (variation && variation.combination) {
    //                         var arrComb = variation.combination.split('~');
    //                         if (arrComb && arrComb.length > 0) {
    //                             // compare all variation combination positions on arrComb
    //                             arrComb && arrComb.map((itm, i)=>{
    //                                 var combinationAtindex = arrComb[i];// put one by one combination position to check variation conbination
    //                                 if(combinationAtindex != null && newOption != null){
    //                                     // compare for newOptionVal 
    //                                     if ((combinationAtindex !== 'undefined' && newOption !=='undefined' && combinationAtindex.toLowerCase() === newOption.toLowerCase() || combinationAtindex.toLowerCase() === newOptionVal.toLowerCase()) || combinationAtindex == '**')  //variation exist for option to be displayed
    //                                     {
    //                                         if(combinationAtindex.toLowerCase() === newOptionVal.toLowerCase()){
    //                                             newOption = newOptionVal
    //                                         }
    //                                         if (variationsOfrenderOption.indexOf(variation) == -1) {
    //                                             variationsOfrenderOption.push(variation);
    //                                         }
    //                                         isVariationExist = true;
    //                                     }
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 })
    //                 //=================================================================
    //                 //=======Handle the filter===========================================
    //                 var checkVariationExistforFilter = true;
    //                 var arrmatch = [];
    //                 props.selProduct.selectedOptions && props.selProduct.selectedOptions !== undefined && props.selProduct.selectedOptions.map(selectedAtt => {
    //                     var exist = false;
    //                     props.selProduct.selectedOptions && variationsOfrenderOption && variationsOfrenderOption.map(variation => {
    //                         if (variation !==null && variation.combination !== null && variation.combination) {
    //                             var arrComb = variation.combination.split('~');
    //                             if (arrComb && arrComb.length > 0) {
    //                             // compare all variation combination positions on arrComb
    //                                 arrComb && arrComb.map((itm, i)=>{
    //                                     var combiOnFinterIndex = arrComb[i];// put one by one variation combination position 
    //                                     if (combiOnFinterIndex ? combiOnFinterIndex.toLowerCase() === selectedAtt.option.toLowerCase() || selectedAtt.option.includes(combiOnFinterIndex.toLowerCase()) || combiOnFinterIndex == '**' : null)  //variation exist for option to be displayed
    //                                     {
    //                                         exist = true
    //                                     }
    //                             })
    //                             }
    //                         }
    //                     })
    //                     if (exist == true || selectedAtt.index == ProductSubAttribute.itemIndex) {
    //                         arrmatch.push("match");
    //                     } else {
    //                         arrmatch.push("mismatch");
    //                     }
    //                 })
    //                 if (arrmatch.includes("mismatch")) {
    //                     checkVariationExistforFilter = false;
    //                 } else {
    //                     checkVariationExistforFilter = true;
    //                 }         

    //                 var isEnabled = isVariationExist == true && checkVariationExistforFilter == true ? true : false; // && isExist === true
    //                 var selectedValueSplit =[];// this.props.showSelectedProduct ? this.props.showSelectedProduct.split("~") : '';
    //                 var matchOption = selectedValueSplit && selectedValueSplit.find(function (item) {
    //                     return item == option.toLowerCase() || item == newOption.toLowerCase();
    //                 })
    //                 var ischecked
    //                 if (!matchOption) {
    //                     ischecked = this.props.showSelectedProduct &&
    //                         (this.props.showSelectedProduct.toLowerCase().includes(newOption.toLowerCase())) ? 'checked' : null
    //                 }
    //                 var checked = matchOption ? 'checked' : ischecked ? 'checked' : null
    //                 var attrIndex = props.selProduct.selectedIndex ? props.selProduct.selectedIndex : 0;
    //                 return (

    //                                 <div className="col-sm-4 col-xs-4" key={"subattr-" + index}>
    //                                     <div className="button_with_checkbox p-0">
    //                                         <input disabled={!isEnabled} type="radio" checked={checked} id={`variation-size-${option}-${_allOpt.term_id}-${randomNum}`} name={`variation-option-${ProductSubAttribute.parentAttribute}`} value={option} onClick={this.props.click.bind(this, newOption, ProductSubAttribute.parentAttribute, attrIndex)} />
    //                                         <label htmlFor={`variation-size-${option}-${_allOpt.term_id}-${randomNum}`} className="label_select_button" title={option}>{displayOption}</label>
    //                                     </div>
    //                                 </div>
    //                 )
    //             })
    //         ) : ""
    //     )
    // }
    const findOthers = (alloption, filterd) => {
        var _all = [];
        if (allCombinations && allCombinations.length > 0) {
            allCombinations.map(cmb => {
                if (cmb && cmb.length > 0) {
                    var _index1 = cmb.indexOf("**", 0);
                    if (_index1 != -1) {
                        var _data = _DistictAttribute[_index1];//.OptionAll[AttrIndex];
                        if (typeof _data != "undefined" && _data != null) {
                            _all.push(_data.OptionAll);
                            _data && _data.OptionAll && _data.OptionAll.length > 0 && _data.OptionAll.map(_m => {
                                if (_m.hasOwnProperty("slug")) {
                                    cmb.push(_m.slug);
                                }
                                else {
                                    cmb.push(_m);
                                }

                            })
                        }
                    }
                    // alloption && alloption.map(m=>
                    //     {
                    //         var found = m.OptionAll.find(a => cmb.includes(a.slug));
                    //         if (typeof found != "undefined" && found != null) {
                    //             var _f = cmb.find(a => (a === found.slug || a === found.slug.toLowerCase()) /*|| a === "**"*/);
                    //             if (typeof _f != "undefined" && _f != null) {
                    //                 _all.push(cmb);
                    //             }
                    //         }
                    //     })
                    //alloption[0].OptionAll.find(a => ['ajio','apple','blue'].includes(a.slug));

                    var found = alloption.find(a => cmb.includes(a.Slug));
                    if (typeof found != "undefined" && found != null) {
                        var _f = cmb.find(a => (a === found.Slug || a === found.Slug.toLowerCase()) /*|| a === "**"*/);
                        if (typeof _f != "undefined" && _f != null) {
                            _all.push(cmb);
                        }
                    }
                }
            })
           // console.log("--_all other-->" + JSON.stringify(_all))
        }
    }
    const showAvailabe = (selVari, option, AttrIndex, step) => {
        var _all = [];
        var _allNew = [];
        if (allCombinations && allCombinations.length > 0) {
            allCombinations.map(cmb => {
                if (cmb && cmb.length > 0) {
                    var _index1 = cmb.indexOf("**", 0);
                    if (_index1 != -1) {
                        var _data = _DistictAttribute[_index1];//.OptionAll[AttrIndex];
                        if (typeof _data != "undefined" && _data != null) {
                            _all.push(_data.OptionAll);
                            _data && _data.OptionAll && _data.OptionAll.length > 0 && _data.OptionAll.map(_m => {
                                if (_m.hasOwnProperty("slug")) {
                                    cmb.push(_m.slug);
                                }
                                else {
                                    cmb.push(_m);
                                }

                            })
                        }
                    }
                    var found = selVari.find(a => cmb.includes(a.Slug /*|| a === found.Slug.toLowerCase()*/) /*|| cmb.includes("**")*/);
                    if (typeof found != "undefined" && found != null) {
                        var _f = cmb.find(a => (a === found.Slug || a === found.Slug.toLowerCase()) /*|| a === "**"*/);
                        if (typeof _f != "undefined" && _f != null) {
                            _all.push(cmb);
                        }
                    }
                }
               // if(cmb.length!=selVari.length)
                {
                    var vdata = selVari.every(ele => (cmb.includes(ele.Slug.toLowerCase()) ))
                    if(vdata && vdata==true)
                    {
                        _allNew.push(cmb);
                    }
                }
                
            })
            _all = [...new Set(_all)];
            _allNew = [...new Set(_allNew)];
           
           // console.log("----vdata-----" + JSON.stringify(_allNew))
            
            // new end
           // console.log("--_all-->" + JSON.stringify(_all))
            var _temp = [];

            //--Varation Updates
            var _all_final = [];
            _all.map(v => {
                var count = 0;
                selVari.map(d => {
                    if (v.includes(d.Slug.toLowerCase())) {
                        count++;
                    }
                    if (selVari.length == count) {
                        _all_final.push(v);
                    }
                });
            });
            //--Varation Updates

            //------??
            // var _test=[];
            //  if (selVari) {
            //     var _sv = selVari.find(a => a.Position == step);
            //     var alloption = _sv.OptionAll;
            //     // var count_test=0;
            //     alloption.map(d => {
            //         if (availableAttribute.includes(d.slug ? d.slug : d)) {
            //             _test.push(d.slug ? d.slug : d)
            //         }
            //     });
            // }   
            //  console.log("-_test--->" + JSON.stringify(_test))

            //---??
            //--Varation Updates
           
            var _all_var_avail = [];

            allCombinations.map(v => {
                _all_final.map(e => {
                    var _count = 0;
                    e.map(f => {
                        if (v.includes(f.toLowerCase())) {
                            _count++;
                        }
                        //if (selVari.length == _count || (selVari.length - 1) == _count)
                        if (_count > 1) {
                            // _all_var_avail.push(v);
                            _all_var_avail = [...new Set([..._all_var_avail, ...v])];
                        }
                    });

                })

            });

           // console.log("-----found variation---" + _all_var_avail);

            //--try
            // var newVar = [];
            // //var tempAll = [...new Set(_all)];
            // //var dd = selVari && selVari.map(a => a.OptionAll.filter(d => d.slug));
            // //var _found = selVari[step].OptionAll.map(b => { return b.slug });

            // var _found = selVari.find(a => a.Position == step).OptionAll;
            // var _allCombinations = [...new Set(allCombinations)];
            // if (typeof _found !== "undefined" && _found != null && _found.length > 0) {
            //     _found.map(m => {
            //         _allCombinations.map(a => {
            //             var _index = a.indexOf(option);
            //             if (_index > -1) {
            //                 var _a = [...new Set(a)]
            //                 _a[_index] = m.slug;
            //                 console.log("--->" + _a);
            //                 _allCombinations.map(cmb => {
            //                     if (cmb && cmb.length > 0) {
            //                         var _index1 = cmb.indexOf("**", 0);
            //                         if (_index1 != -1) {
            //                             var _data = _DistictAttribute[_index1];//.OptionAll[AttrIndex];
            //                             if (typeof _data != "undefined" && _data != null) {
            //                                 _all.push(_data.OptionAll);
            //                                 _data && _data.OptionAll && _data.OptionAll.length > 0 && _data.OptionAll.map(_m => {
            //                                     if (_m.hasOwnProperty("slug")) {
            //                                         cmb.push(_m.slug);
            //                                     }
            //                                     else {
            //                                         cmb.push(_m);
            //                                     }

            //                                 })
            //                             }
            //                         }
            //                         if (JSON.stringify(cmb) == JSON.stringify(_a)) {
            //                             newVar.push(cmb);
            //                         }
            //                     }
            //                 })
            //             }
            //         })
            //     })
            //     console.log("-----newVar----" + JSON.stringify(newVar));
            //     //_all = [...new Set(newVar)];
            // }
            //end

            //--Varation Updates

            _all && _all.map(d => {
                d && d.map(e => {
                    var _f = _temp.find(a => a === e);
                    if (typeof _f != "undefined" && _f != null) { }
                    else {
                        if (e && e.hasOwnProperty("slug")) {
                            _temp.push(e.slug.toLowerCase());
                        }
                        else {
                            _temp.push(e.toLowerCase());
                        }
                    }
                })
            })


            // last try here
            // if (selVari /*&& selVari.length == 1*/) {
            //     var _sv = selVari.find(a => a.Position == step);
            //     if(_sv)
            //     {
            //         var alloption = _sv.OptionAll;
            //         var sv_test=[];
            //         alloption.map(d => {
            //             if (availableAttribute.includes(d.slug ? d.slug : d)) {
            //                 sv_test.push(d.slug ? d.slug : d)
            //             }
            //         });
            //         findOthers(selVari,sv_test);
            //     }
            // }
            //--last try end
            //console.log("-----_temp----" + JSON.stringify(_temp));
            // if (selVari.length > 1 /*&& checkLength()==false*/)
            {
                // _temp = _all_var_avail;
            }
            var array3 = allVariations.filter(function (obj) { return _temp.indexOf(obj.toLowerCase()) == -1; });
            //console.log("-----array3----" + JSON.stringify(array3));
            if (_product && _product.ProductAttributes !== null) {
                //var ProductAttribute = _product.ProductAttributes;
                //var _attribute = ProductAttribute && ProductAttribute.filter(item => item.Variation == true);
                if (/*(_attribute.length != 1 && _attribute.length != 0 ) ||*/ array3.length == 0 || selVari.length > 1)
                    setDisableAttribute(array3);
            }
        }
    }
    var _disableAttribute = [];
    var isAllOption = false;
    const optionClick = (option, attribute, AttrIndex, step) => {
        localStorage.removeItem("SINGLE_PRODUCT");
        showDiscounts();
        setIsEdit(false);
        var _slug = null;
        //  findVaraionts(attribute);
        let _OptionAll = attribute.OptionAll;// && JSON.parse(attribute.OptionAll);
        if (Array.isArray(_OptionAll) == true || _OptionAll.length >= 1) {
            isAllOption = true;
        }
        else {
            _OptionAll = attribute.options ? attribute.options.split(',') : [];
        }
        var result = isAllOption === true ? _OptionAll.filter(b => (b.hasOwnProperty("slug") ? b.slug : b) === option) : _OptionAll.filter(b => b === option);
        if (result && typeof result != "undefined" && result.length > 0) {
            _slug = isAllOption == true && result[0].hasOwnProperty("slug") ? result[0].slug : result[0];
        }
        //------
        _disableAttribute = []
        var _selVariations = selVariations;
        var _item = _selVariations.findIndex((element) => {
            return element.Name === attribute.Name;
        })
        if (_item == -1) {
            _selVariations.push({ "Name": attribute.Name, "Option": option, "Index": AttrIndex, "OptionTitle": option.replace(/\s/g, '-').toLowerCase(), "Slug": _slug ? _slug : "", "Position": attribute.Position });
        }
        _selVariations = _selVariations.map(obj => {
            if (obj.Name === attribute.Name) {
                return { ...obj, Option: option, OptionTitle: option.replace(/\s/g, '-').toLowerCase(), "OptionAll": attribute.OptionAll, "Slug": _slug ? _slug : "", "Position": attribute.Position };
            }
            return obj;
        });
        setProductQty(1);
        setSelVariations(_selVariations);
       
        if (props && props.selProduct) {
            var _product = props.selProduct;
            var _attribute = [];
            var ProductAttribute = [];

            if (_product && _product.ProductAttributes !== null) {
                ProductAttribute = _product.ProductAttributes;
                _attribute = ProductAttribute && ProductAttribute.filter(item => item.Variation == true);
            }
            showAvailabe(_selVariations, option, AttrIndex, step);
            getAllProducts().then((rows) => {
                var data = rows.filter(a => a.ParentId === _product.WPID);
                var allProdcuts = getTaxAllProduct(data)
                if (allProdcuts && allProdcuts.length > 0) {
                    var filteredAttribute = allProdcuts.filter(item => {
                        var allCombi = item && item.combination !== null && item.combination !== undefined && item.combination.split("~");
                        allCombi = allCombi.map(a => { return a.toLowerCase() }); //.replace(/\//g, "-")
                        var _index = allCombi.indexOf("**", 0);
                        if (_index != -1) {
                            var _data = _DistictAttribute[_index].OptionAll[AttrIndex];
                            if (typeof _data != "undefined" && _data != null) {
                                if (_data.hasOwnProperty("slug")) {
                                    allCombi.push(_data.slug.toLowerCase());
                                }
                                else { allCombi[_index] = _data.toLowerCase(); }
                            }
                            _DistictAttribute && _DistictAttribute[_index] && _DistictAttribute[_index].OptionAll && _DistictAttribute[_index].OptionAll.length > 0 && _DistictAttribute[_index].OptionAll.map(_m => {
                                if (_m.hasOwnProperty("slug")) {
                                    allCombi.push(_m.slug.toLowerCase());
                                }
                                else {
                                    allCombi.push(_m.toLowerCase());
                                }
                            })
                        }
                        var vdata = _selVariations.every(ele => (allCombi.includes(ele.Slug.toLowerCase()) /*|| (allCombi.includes("**") && allCombi[attribute.Position]=="**")*/) && _attribute.length === selVariations.length)
                        return vdata;
                    })
                    if (filteredAttribute && filteredAttribute.length == 1) {
                        props.updateVariationProduct && props.updateVariationProduct(filteredAttribute[0]);

                        // var _stokStatus= checkProdcutStockStatus(filteredAttribute[0].WPID,filteredAttribute[0].StockQuantity);
                        // if(_stokStatus.toLocaleLowerCase() !=='unlimited' && filteredAttribute[0].ManagingStock !==false){ //if unlimited then do not call 
                        setisRefereshIconInventory(true);
                        dispatch(getInventory(filteredAttribute[0].WPID)); //call to get product warehouse quantity
                        //}
                        //setSelectedModifiers([]);
                        //Unselecting the previous selected modifiers for the variation
                        // if (selectedModifiers && selectedModifiers.length > 0) {
                        //     var _tempSM = selectedModifiers;
                        //     selectedModifiers.map(m => {
                        //         var _tsm = m;
                        //         _tsm["data"] = [];
                        //         _tempSM.push(_tsm);
                        //     })
                        //     _tempSM = [...new Set([..._tempSM])];
                        //     setSelectedModifiers(_tempSM);
                        // }
                        //-------
                        getModifiers(filteredAttribute[0]);
                    }
                    else {
                        props.updateVariationProduct && props.updateVariationProduct(null);
                    }
                    allProdcuts.map(item => {
                        var allCombi = item && item.combination !== null && item.combination !== undefined && item.combination.split("~");
                        allCombi = allCombi.map(a => { return a.replace(/\//g, "-").toLowerCase() });
                        var aa = _selVariations.every(ele => (allCombi.includes(ele.Slug.toLowerCase()) /*|| allCombi.includes("**")*/))
                        if (aa == true) {
                            allCombi = allCombi.map(a => {
                                var _att = a.replace(/\//g, "-").toLowerCase();
                                const index = _disableAttribute.findIndex(item => item === _att)
                                if (index === -1) {
                                    _disableAttribute.push(_att);
                                }
                                return _att;
                            });
                        }
                        if (attribute && attribute.OptionAll) {
                            attribute.OptionAll.map((a, i) => {
                                var _att = a.hasOwnProperty('slug') ? a.slug : a;// a.replace(/\//g, "-").toLowerCase();
                                const index = _disableAttribute.findIndex(item => item === _att)
                                if (index === -1) {
                                    _disableAttribute.push(_att);
                                }
                            })
                        }
                    })
                }
            });
        }
    }


    const checkLength = () => {
        var result = true;
        var _product = props.selProduct;
        var _attribute = [];
        var ProductAttribute = [];
        if (_product && _product.ProductAttributes !== null && _product.Type !=='simple') {
            ProductAttribute = _product.ProductAttributes;
            _attribute = ProductAttribute && ProductAttribute.filter(item => item.Variation == true);
            if (_attribute && _attribute.length > 0 && selVariations) {
                if (_attribute.length === selVariations.length) { result = true; }
                else { result = false; }
            }
            else { result = true; }
        }
        return result;
    }
   ///-------xxxxx-------
    const addToCart = () => {
        if(addonsRequired && addonsRequired.length>0){
            console.log("addonsRequired",addonsRequired)
            return;
        }
        var isNew = true;
        var _product = props.variationProduct ? props.variationProduct : props.selProduct;

        // const isBackOrdered = _product && (_product.BackOrderd || variationStockQunatity < 1) ? true : false;

        if (checkLength() === true || isBackOrdered == true) {
            if (productQty > 0 || isBackOrdered == true) {
                if (_product) {
                    var modifiers_key = _product.hasOwnProperty("modifiers_key") ? _product.modifiers_key : uniqueKey();
                    var addon_key = _product.hasOwnProperty("addon_key") ? _product.addon_key : uniqueKey();
                    // var modiferIds = (selectedModifiers && selectedModifiers.length > 0) ? selectedModifiers.filter(a => a.is_active == true).map(b => b.modifier_id) : [];
                    var modiferData = (selectedModifiers && selectedModifiers.length > 0) ? selectedModifiers.filter(a => a.is_active == true) : [];
                    // var modiferIds = modiferData.map((values, index) => {
                    //     return values.data.map(({
                    //         ...d
                    //     }) => {
                    //         return [d.id, d]
                    //     });
                    // })
                    // var modiferIds = modiferData.map((values, index) => {
                    //     return values.data.map(({
                    //         ...d
                    //     }) => {
                    //         return [d.id, d]
                    //     });
                    // })
                    if (modiferData) {
                        // modiferData = modiferData.map(obj => { return { ...obj, modifiers_key: modifiers_key }; })
                    }
                    var modiferIds = JSON.stringify(modiferData);
                    var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : []
                    if (cartlist && cartlist.length > 0) {
                        //var updatedItem = cartlist.find(a => a.product_id == product.WPID);
                        var updatedItem = cartlist.find(a => a.product_id == _product.WPID
                            && ((a.hasOwnProperty("modifiers_Id") && JSON.stringify(a.modifiers_Id) == JSON.stringify(modiferIds)) || (!a.hasOwnProperty("modifiers_Id"))));
                        if (typeof updatedItem != "undefined" && updatedItem != null) {
                            if (updatedItem.hasOwnProperty("modifiers_Id")) {
                                _product["modifiers_Id"] = updatedItem.modifiers_Id;

                            }
                            _product["modifiers_key"] = updatedItem.modifiers_key;
                            modifiers_key = updatedItem.modifiers_key;
                        }
                        else if (typeof modiferIds !== "undefined" && modiferIds != null && modiferIds.length > 0) {
                            // _product["modifiers_Id"] = JSON.stringify(modiferIds);
                            _product["modifiers_Id"] = modiferIds;
                            _product["modifiers_key"] = modifiers_key;
                        }


                       
                        if (typeof addOnsList !== "undefined" && addOnsList != null && addOnsList.length > 0) {                           
                            // _product["modifiers_Id"] = JSON.stringify(modiferIds);
                            _product["addon_ids"] =  JSON.stringify(addOnsList);
                            _product["addon_key"] = addon_key;
                        }                       

                        //modifiers = modifiers.map(obj => { return { ...obj, modifiers_key: updatedItem.modifiers_key }; })

                        //modifiers=  modifiers && modifiers.map(b => b["modifiers_key"] = updatedItem.modifiers_key);
                    }
                    else if (typeof modiferIds !== "undefined" && modiferIds != null && modiferIds !="[]" && modiferIds.length > 0) {
                        _product["modifiers_Id"] = modiferIds;
                        _product["modifiers_key"] = modifiers_key;                       
                    }
                    else {
                        _product["modifiers_Id"] = [];
                        _product["modifiers_key"] = modifiers_key;
                    }
                    if (typeof addOnsList !== "undefined" && addOnsList != null && addOnsList.length > 0) {                           
                        // _product["modifiers_Id"] = JSON.stringify(modiferIds);
                        _product["addon_ids"] =  JSON.stringify(addOnsList);
                        _product["addon_key"] = addon_key;
                    }         
                    let item = _product;
                    var taglist = item.Tags ? item.Tags !== "" ? item.Tags.split(",") : null : null;
                    if ((item.ProductMetaJson=="" || item.ProductMetaJson =="[]")  && taglist && (taglist !== null && taglist.includes('oliver_produt_x') === true) &&
                        (CommonModuleJS.showProductxModal() !== null && CommonModuleJS.showProductxModal()
                            === true) && item !== null && item.ParamLink !== "" && item.ParamLink
                        !== "False" && item.ParamLink !== null) {
                        // var data = { title: "", msg: LocalizedLanguage.productxOutOfStock, is_success: true }
                        // dispatch(popupMessage(data));
                        props.toggleProductTypeNotSupported && props.toggleProductTypeNotSupported();
                        props.closePopUp()
                        return
                    }
                    if (_product.hasOwnProperty("quantity") && typeof _product.quantity == "undefined") { isNew = false };
                    _product.quantity = productQty;
                    //---- Replace exsiting product if different variation or proudct is selected
                    if (props.selProduct && props.selProduct.hasOwnProperty("selectedIndex")) {
                        _product['selectedIndex'] = props.selProduct.selectedIndex;
                    }
                    //----
                    var _variations = "";
                    var _combination = "";
                    selVariations && selVariations.map(m => {
                        _variations += "<p>" + m.Name + ": " + m.OptionTitle + "</p>";
                        _combination += (_combination != "" ? "~" : "") + m.Option;
                    })
                    if (_variations != '') {
                        _product["psummary"] = _variations;
                    }
                    if (_combination != '') {
                        _product["combination"] = _combination;
                    }

                    if (_currentStock) {
                        _product.StockQuantity = _currentStock;
                        _product.StockStatus = _currentStock == 0 ? "outofstock" : "instock";
                    }

                    var result = addSimpleProducttoCart(_product, null, isNew);
                    if (result === 'outofstock' && isBackOrdered == false) {
                        toggleOutOfStock();
                    }
                    else {
                        // if(props.isProductEdit && props.isProductEdit==true)
                        //     {
                        //         getModifierFilled();
                        //     }
                        _addModifierAsCustomFee(selectedModifiers, modifiers_key, modiferIds);
                        addAddOnsAsCustomFee(addon_key);
                    }
                    var pid = _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID;
                    if (note != "" && (result !== 'outofstock' || isBackOrdered == true)) {
                        var noteData = { "Title": note, "IsTicket": false, "pid": pid, "vid": _product.variation_id, modifiers_key: modifiers_key };
                        if (!updateProductNote(noteData, modifiers_key)) {
                            var result = addSimpleProducttoCart(noteData);
                        }
                    }

                    // if (selectedModifiers && selectedModifiers.length > 0 && props.isProductEdit == false) {
                    //     _addModifierAsCustomFee(selectedModifiers, modifiers_key);
                    //     var modifierArray = [];
                    //     var data = { pid: pid, data: selectedModifiers };
                    //     var selected_modifers = JSON.parse(localStorage.getItem("SELECTED_MODIFIERS"));
                    //     if (selected_modifers && selected_modifers.length > 0) {
                    //         modifierArray = selected_modifers;
                    //         const index = selected_modifers.findIndex(object => object.pid === pid);
                    //         if (index === -1) {
                    //             modifierArray.push(data);
                    //         }
                    //         else {
                    //             modifierArray[index] = data;
                    //         }
                    //     }
                    //     else {
                    //         modifierArray.push(data);
                    //     }

                    //     localStorage.setItem("SELECTED_MODIFIERS", JSON.stringify(modifierArray));
                    // }
                    // else if (props.isProductEdit == true && typeof localStorage.getItem("SELECTED_MODIFIERS") != "undefined") {
                    //     var selected_modifers = JSON.parse(localStorage.getItem("SELECTED_MODIFIERS"));
                    //     var foundModifiers = selected_modifers && selected_modifers.find(a => a.pid == pid);
                    //     if (typeof foundModifiers != "undefined" && foundModifiers != null) {
                    //         setSelectedModifiers(foundModifiers.data);
                    //         _addModifierAsCustomFee(foundModifiers.data, modifiers_key);
                    //     }
                    // }
                    else {
                        // var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
                        // var replaceModi = cartlist.filter(a => a.modifiers_key == _product.modifiers_key && (a.hasOwnProperty('check_modifer')) && a.check_modifer == true);
                        // var all_modifiers = [];
                        // var modifiers = [];
                        // if (replaceModi && replaceModi.length > 0) {
                        //     replaceModi.map(m => {
                        //         modifiers.push({ modifier_id: m.Title.replace(/ /g, "_"), title: Title, type: m.Type, is_active: true, TaxOption: m.isTaxable, data: data })
                        //     });
                        // }
                        //_addModifierAsCustomFee(foundModifiers.data, modifiers_key);
                    }
                    if (result !== 'outofstock' || isBackOrdered == true) {
                        setTimeout(() => {
                            sessionStorage.removeItem("view")
                            sessionStorage.removeItem("productId")
                            dispatch(product());
                        }, 100);
                    }
                }
            }
            else {
                toggleOutOfStock();
            }
        }
        else {
            setCheckIsSelection(true);
            toggleNoVariationSelected();
        }
    }
    const addNote = (note) => {
        setNote(note);
        toggleProductNote();
    }
    const isSelected = (name) => {
        if (checkIsSelection === true) {
            if (selectedVariations && selectedVariations.length > 0) {
                var item = selectedVariations.find(m => m.Name === name);
                return (typeof item != "undefined" && item != null) ? true : false;
            }
            else {
                return false;
            }
        }
        return true;
    }
    var _isApply = false;
    //Showing indivdual and overall discount 30sep2022
    const showDiscounts = (isApply = false) => {
        _isApply = isApply;
        var _product = props.variationProduct != null ? props.variationProduct : props.selProduct;
        var CurrencySymbol = localStorage.getItem('currecySymbole') ? localStorage.getItem('currecySymbole') : "$";
        if (localStorage.getItem("SINGLE_PRODUCT") && isApply == true/* && props.isProductEdit!=null && props.isProductEdit === false*/) {
            _product = JSON.parse(localStorage.getItem("SINGLE_PRODUCT"));
        }
        var disString = "";
        if (_product.product_discount_amount > 0) {
            if (_product.discount_type == "Number") {
                disString = CurrencySymbol + _product.new_product_discount_amount.toFixed(2) + " " + LocalizedLanguage.individual;
            }
            else {
                disString = _product.new_product_discount_amount + "% " + LocalizedLanguage.individual;
            }
        }
        let cart = localStorage.getItem("CART") ? JSON.parse(localStorage.getItem("CART")) : null;
        if (_product.cart_discount_amount > 0 && cart) {
            if (disString != "") {
                disString += " + "
            }
            if (cart && cart.discountType == "Number") {
                disString += CurrencySymbol + cart.discount_amount + " " + LocalizedLanguage.overall;
            }
            else {
                disString += cart.discount_amount + "% " + LocalizedLanguage.overall;
            }
        }
        if ((_product.product_discount_amount > 0 || _product.cart_discount_amount > 0) && disString != "") {
            disString = "(" + disString + ") "
            disString += " " + LocalizedLanguage.discountAdded;
        }
        else {
            disString += " " + LocalizedLanguage.addDiscount;
        }
        setDiscounts(disString != "" ? disString : LocalizedLanguage.addDiscount);
    }
    useEffect(() => {
        if (props.selProduct && props.selProduct.quantity) {
            setProductQty(props.selProduct.quantity);
        }
         setisRefereshIconInventory(true);
    }, [props.selProduct]);

    const clearSelection = () => {
        setCheckIsSelection(false);
        setSelVariations([]);
        clearSelectionModifier();
        setDisableAttribute([]);
        setProductQty(0);
        props.updateVariationProduct && props.updateVariationProduct(null);
        getModifiers(props.selProduct);
        refreshBtn(true)
        setAddOns([]);
        setIsClear(true);
    }
    const clearFields = (fieldid) => {
        var container, inputs, index;
        container = document.getElementById(fieldid);
        inputs = container.getElementsByTagName('input');
        for (index = 0; index < inputs.length; ++index) {
            if (inputs[index].type == "text") {
                inputs[index].value = '';
                if (inputs[index].getAttribute("data-min-number")) {
                    // inputs[index].value = inputs[index].getAttribute("data-min-number");
                    inputs[index].value = 0;
                }
                // else
                // {
                //     inputs[index].value = 0;
                // }
            }
        }
    }
    const clearSelectionModifier = () => {
        getModifiers(null, true);
        clearFields("mod-product");
        setAddOns("");      
    }
    useEffect(() => {
        if (props.isShowPopups == true) {
            setSelVariations([]);
            getModifiers();
            getRecomProducts();
            showDiscounts();
            availableVariations();
            // setTimeout(() => {
            //     getModifierFilled();
            // }, 500);
        }
    }, [props.isShowPopups, props.selProduct]);

    useEffect(() => {
        if (props.isShowPopups == true) {
            if (props.selProduct && props.selProduct.hasOwnProperty("selectedOptions") && props.selProduct.selectedOptions.length > 0) {
                setSelOptions(props.selProduct.selectedOptions)
                setIsEdit(true);
                doVariationSearch();
            }
        }
    }, [selOptions, props.isShowPopups]);
    //   end
    var _DistictAttribute = [];
    var _OptionAll = [];
    var ProductAttribute = props.selProduct && props.selProduct.ProductAttributes;
    var _attribute = [];
    if (ProductAttribute !== null && props.selProduct.Type !=='simple') {
        _attribute = ProductAttribute && ProductAttribute.filter(item => item.Variation == true);

        _attribute && _attribute.map((attribute, index) => {
            var item = { Name: attribute.Name, Option: attribute.Option, Slug: attribute.Slug, Variation: attribute.Variation, OptionAll: attribute.OptionAll ? JSON.parse(attribute.OptionAll) : [], Position: attribute.Position };
            _DistictAttribute.push(item);
        });
    }
    var _product = props.variationProduct != null ? props.variationProduct : props.selProduct;
    if (localStorage.getItem("SINGLE_PRODUCT") /* && _isApply===true&& props.isProductEdit!=null && props.isProductEdit === false*/) {
        _product = JSON.parse(localStorage.getItem("SINGLE_PRODUCT"));
    }
    var product_price = 0;
    var after_discount_total_price = 0;
    if (_product) {
         after_discount_total_price = _product && _product.product_discount_amount ?
            _product.product_discount_amount * (_product.discount_type != "Number" ? _product.quantity ? _product.quantity : productQty : 1) : 0;
        product_price = getSettingCase() == 2 || getSettingCase() == 4 || getSettingCase() == 7 ? _product && cartPriceWithTax(_product.old_price, getSettingCase(), _product.TaxClass) : getSettingCase() == 6 ? _product && _product.old_price : _product && _product.old_price;
    }
    var warehouseDetail = inventoryStatus && inventoryStatus.inventoryGet && inventoryStatus.inventoryGet.data && inventoryStatus.inventoryGet.data.content
    var CurrentWarehouseId = localStorage.getItem("WarehouseId");
    if (warehouseDetail && warehouseDetail.length > 0) {
        currentWareHouseDetail = warehouseDetail.find(item => item.warehouseId == CurrentWarehouseId)
    } else {
        currentWareHouseDetail = null;
    }
    var _currentStock = currentWareHouseDetail && currentWareHouseDetail !== "" ? currentWareHouseDetail.Quantity : variationStockQunatity;
    var _price = _product && RoundAmount(((product_price * productQty) - after_discount_total_price) /*+ (_product.incl_tax ? _product.incl_tax : 0)*/);
    // var tax_is = getVariatioModalProduct((props.variationProduct != null ? props.variationProduct : props.selProduct), productQty);
    // _price = _price + tax_is.incl_tax + tax_is.excl_tax;
    // if (typeof tax_is !== "undefined" && tax_is !== null && typeof tax_is.incl_tax !== "undefined" && tax_is.incl_tax !== null) {
    //     _price = _price + tax_is.incl_tax;
    // }
    // if (typeof tax_is !== "undefined" && tax_is !== null && typeof tax_is.excl_tax !== "undefined" && tax_is.excl_tax !== null) {
    //     _price = _price + tax_is.excl_tax;
    // }
   //console.log("selectedModifiers--", selectedModifiers);

   // Adding selected modifier price into total --------------
   var modifierPrice=0;
   selectedModifiers && selectedModifiers.length>0 && selectedModifiers.map(adItem=>{
    if(adItem.is_active==true && adItem.data){
        adItem.data && adItem.data.map(data=>{
            if(discountType && discountType !=="" && discountApply !==0){
                if(discountType =="%" || discountType =="Percentage"){
                    modifierPrice += parseFloat(data.amount)-(parseFloat(data.amount)*(discountApply/100));
                }else if(discountType =="$" || discountType =="Number"){
                    modifierPrice += parseFloat(data.amount);//-(discountApply);
                }
            }else{
                modifierPrice += parseFloat(data.amount);
            }      
        })
       
    }
   })     
   _price +=modifierPrice;
   //-------------------------------------------

   // Adding selected addons price into total --------------
   var addonPrice=0;
   addOnsList && addOnsList.length>0 && addOnsList.map(adItem=>{
    if(adItem.amount && adItem.amount !==""){
        if(discountType && discountType !=="" && discountApply !==0){
            if(discountType =="%" || discountType =="Percentage"){
                addonPrice += parseFloat(adItem.amount)-(parseFloat(adItem.amount)*(discountApply/100));
            }else if(discountType =="$" || discountType =="Number"){
                addonPrice += parseFloat(adItem.amount);//-(discountApply);
            }
        }else{
            if(adItem.amount_type=="input_multiplier"){
                addonPrice += parseFloat(adItem.amount * adItem.qty );
            }
            else if(adItem.amount_type=="quantity_based"){
                addonPrice += parseFloat(adItem.amount)* productQty;
            } else if(adItem.amount_type=="percentage_based"){
                addonPrice += (_price * parseFloat(adItem.amount))/100;
            }else{
                addonPrice += parseFloat(adItem.amount);
            }
        }      
    }
   })     
   _price +=addonPrice;
   //-------------------------------------------

 
    if (stockStatusInOut && stockStatusInOut.toLowerCase() === "unlimited" && _currentStock == 0) {
        _currentStock = 1;
    }
    if (props.isProductEdit == false) {
        _product.quantity = productQty;
    }
    var isDiscountBtn = ((_price === 0 && _product.product_discount_amount == 0) == true || checkLength() === false || variationStockQunatity == '0');
    isDiscountBtn = (checkLength() === true && stockStatusInOut.toString().toLocaleLowerCase() === 'unlimited') ? false : isDiscountBtn;
    const validateEnteredValue = (e) => {
        const { value } = e.target;
        const re = new RegExp('^\d+$')
        if (value == '' || re.test(value)) {
            return true
        }
        return false;
    }
    const removeSpecialChar = (item) => {
        return (item.replace(/\//g, "-"))
        //  return item.replace(/\s/g, '-').replace(/\//g, "-").replace("'", "").replace(".", "").replace("-", "").toLowerCase();
    }




    function refreshBtn(isClear = false) {
        var _product = props.variationProduct ? props.variationProduct : props.selProduct;
        var pid = _product.hasOwnProperty("product_id") ? _product.product_id : _product.WPID;      
        setisRefereshIconInventory(true);
        if (isClear == true) {
            pid = props.selProduct.hasOwnProperty("product_id") ? props.selProduct.product_id : props.selProduct.WPID;
        }
        dispatch(getInventory(pid));
    }

    var clsQuantity = variationStockQunatity === 0 ? "quantity quantity-disable" : variationStockQunatity < 0 && isBackOrderedNotification === true ? "quantity quantity-red" : "quantity";
 
    //console.log("stockStatusInOut",stockStatusInOut)
    //console.log("LocalizedLanguage.outOfStock",LocalizedLanguage.outOfStock)

    return (
        props.isShowPopups == false ? null :
            <React.Fragment>
                <div className={props.isProductEdit && props.isProductEdit == true ? "product-wrapper no-edit" : "product-wrapper"} >
                    <LeftNavBar view={"Product View"}></LeftNavBar>
                    <div className="header">
                        <div className="mobile-buttons">
                            <button id="mobileExitProductButton" onClick={() => props.closePopUp()}>
                                <img src={X_Icon_DarkBlue} alt="" />
                            </button>
                            <button id="mobileAppsButton" onClick={() => props.toggleAppLauncher()}>
                                <img src={Oliver_Icon_BaseBlue} alt="" />
                            </button>
                        </div>
                        <div className="main main-grid-view">
                            <p className="route">{"Clothing > T-Shirts"}</p>
                            <p className="prod-name">{_product && _product.Title}</p>
                            <button className="refreshBtn" id="" onClick={() => refreshBtn()}><img src={RefreshImgBlue} alt="" className="refreshBtn_icon"></img> &nbsp; {LocalizedLanguage.refresh}</button>
                            <button id="desktopExitProductButton" onClick={() => props.closePopUp()}>
                                <img src={X_Icon_DarkBlue} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="mod-product" id="mod-product">
                        {
                            variationStockQunatity == 0 && _product && _product.Type === "simple" && (_product.StockStatus == "outofstock") ?
                                <div className="variation_out_of_stock">
                                    <img src={CarbonError} alt="" />
                                    <p>{LocalizedLanguage.ProductIsOutOfStock}</p>
                                    {/* <button onClick={() => canManageInventory() == true ? toggleAdjustInventory() : null} disabled={canManageInventory() == false ? true : (stockStatusInOut.toString().toLocaleLowerCase() !== 'unlimited' ? false : true)} style={{ opacity: stockStatusInOut.toString().toLocaleLowerCase() !== 'unlimited' ? 1 : 0.5 }}>{LocalizedLanguage.adjustInven}</button> */}
                                </div> : ''
                        }

                        {_DistictAttribute && _DistictAttribute.length === 0 || productModifiers && productModifiers.length > 0 ?
                            <div className={variationStockQunatity != 0 ? "img-container no-var" : "img-container no-stock"}>
                                <img src={NoVariationDisplay} alt="" />
                                <img src={Product_OutOfStock} alt="" />
                                {variationStockQunatity == 0 ? <button id="noStockAdjustInv" onClick={() => toggleAdjustInventory()} disabled={!canManageInventory()}>{LocalizedLanguage.adjustInven}</button> : null}
                            </div> : ""
                        }
                        {_DistictAttribute && _DistictAttribute.length > 0 || productModifiers && productModifiers.length > 0 ?
                            <div className="row">
                                {_DistictAttribute && _DistictAttribute.length > 0 ? <p>{LocalizedLanguage.selectvariations}</p> : <p></p>}
                                <button id="clearModsButton" onClick={() => clearSelection()}>{LocalizedLanguage.clearselection}</button>
                            </div> : ""
                        }
                        {/* If item cannot be edited then product-wrapper element must be given no-edit class
                            Also Adjust inventory button has to be disabled */}
                        <div className="no-edit-item">
                            <div className="inner-container">
                                <p className="style1">
                                    {LocalizedLanguage.noeditline1} <br />
                                    {LocalizedLanguage.noeditline2}
                                </p>
                                <p className="style2">
                                    {LocalizedLanguage.noeditline3} <br />
                                    {LocalizedLanguage.noeditline4}
                                </p>
                            </div>
                        </div>
                        {
                            _DistictAttribute && _DistictAttribute.length > 0 ?
                                (_DistictAttribute.map((attribute, index) => {
                                    return (
                                        attribute && attribute.Variation == true &&
                                        <React.Fragment key={attribute.Slug}><p className={isSelected(attribute.Name) === false ? "error" : ""}>{attribute.Name}</p>
                                            <div className={isSelected(attribute.Name) === false ? "radio-group error" : "radio-group"}>
                                                {
                                                    attribute.OptionAll && attribute.OptionAll.map((opt, i) => {
                                                        var option = opt.hasOwnProperty("slug") ? opt.slug : opt;
                                                        var displayOption = opt.hasOwnProperty("name") ? opt.name : opt;
                                                        let _item = option;//opt.slug;
                                                        var _disabled = false
                                                        if (disableAttribute && disableAttribute.length > 0) {
                                                            _disabled = disableAttribute && disableAttribute.some(a => a.toLowerCase() === option.toLowerCase());
                                                        }
                                                        if (_disabled === false) {
                                                            if (availableAttribute && availableAttribute.length > 0) {
                                                                var _vari = availableAttribute.find(a => removeSpecialChar(a).toLowerCase() === removeSpecialChar(option).toLowerCase() || a === "**");
                                                                _disabled = typeof _vari != "undefined" && _vari != null ? false : true;
                                                            }

                                                        }
                                                        // console.log("_disabled",_disabled)
                                                        allVariations.push(_item);
                                                        if (isEdit === true) {
                                                            var selVal = selOptions ? selOptions.some(a => a.toLowerCase() === _item.toLowerCase()) : false;
                                                            //EDIT PRODUCT - WILL SET ALREADY SELECTED VARIATIONS 
                                                            if (selVal === true) {
                                                                setSelectedOption(_item, attribute, i)
                                                            }
                                                            return <label className={_disabled === true ? "btn-disable" : ""} disabled={_disabled} key={"l_" + option} onClick={() => _disabled === false ? optionClick(option, attribute, i, index) : null}><input type="radio" id={attribute.Name + "" + option} name={attribute.Name} checked={selVal} onChange={null} /><div className="custom-radio"><p>{displayOption}</p></div></label>
                                                        }
                                                        else {
                                                            var selVal = selVariations ? selVariations.some(a => a.Slug.toLowerCase() === _item.toLowerCase()) : false;
                                                            return <label className={_disabled === true ? "btn-disable" : ""} disabled={_disabled} key={"l_" + option} onClick={() => _disabled === false ? optionClick(option, attribute, i, index) : null}><input type="radio" id={attribute.Name + "" + option} name={attribute.Name} checked={selVal} onChange={null} /><div className="custom-radio"><p>{displayOption}</p></div></label>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                )
                                : <div className='noAttribute'></div>}
                        <React.Fragment >
                            {productModifiers && productModifiers.length > 0 &&
                                <div className="row">
                                    <p>{LocalizedLanguage.selectmodifiers}</p>
                                    {/* <button id="clearModsButton" onClick={() => clearSelection()}>{LocalizedLanguage.clearselection}</button> */}
                                </div>
                            }
                            {
                                productModifiers && productModifiers.map(mod => {
                                    var gpid = mod.hasOwnProperty("Id") ? mod.Id : (mod.Title).replace(/ /g, "_");
                                    var gpname = (mod.Title).replace(/ /g, "_");
                                    switch (mod.Type) {
                                        case Config.key_InputTypes.CheckBox:
                                            return (
                                                <React.Fragment>
                                                    <p>{mod.Title}</p>
                                                    <div className="radio-group" onChange={onChangeValue}>{
                                                        mod.modifierFields && mod.modifierFields.map(mf => {
                                                            return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                                                var id = (efm.Name != null && typeof efm.Name != "undefined") && (efm.Name).replace(/ /g, "_");
                                                                return (
                                                                    <label>
                                                                        <input type="checkbox" id={id} name={efm.Name} value={id} data-checked-value={efm.Default} data-gparent-name={gpname} data-gpid={gpid} data-amount={efm.Amount} data-add-sub={efm.AddnSubtract} data-amount-type={efm.Type} onChange={null} /*checked={efm.Default=="yes"?true:false}*/ checked={preSelectedModifiers(gpname, efm.Name)} />
                                                                        <div className="custom-radio">
                                                                            <p>{efm.Name}({currecyFunction(efm.Amount)})</p>
                                                                        </div>
                                                                    </label>)
                                                            }))
                                                        })
                                                    }</div></React.Fragment>
                                            )
                                            break;
                                        case Config.key_InputTypes.NumberField:
                                            return (
                                                //** HIDE THIS BECAUSE WE HAVE NOT DESIGN FOR IT 23-DEC-2022 */
                                                <React.Fragment>
                                                    <p className="labelTitle">{mod.Title}</p>
                                                    {
                                                        mod.modifierFields && mod.modifierFields.map(mf => {
                                                            return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                                                var id = ((efm.Name != null && typeof efm.Name != "undefined") ? efm.Name : String(efm.ModifierId)).replace(/ /g, "_");
                                                                return (<React.Fragment>
                                                                    <p>{efm.Name}({currecyFunction(efm.Amount)})</p>
                                                                    <div className="increment-input" onChange={onChangeValue}>
                                                                        <button onClick={qunatityChange} data-parent-id={id} data-btn-type="minus" data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract}>
                                                                            <img src={Checkout_Minus} alt="" />
                                                                            <input id={id + "-amount"} type="text" defaultValue={efm.Type + " " + efm.Amount} data-amount-type={efm.Type} readOnly className='hide' />
                                                                        </button>
                                                                        <input onKeyDown={(e) => validateEnteredValue(e)} id={id + "-quantityUpdater"} type="text" readOnly={true} name={id} data-max-number={efm.Maxnumber} data-min-number={efm.Minnumber} defaultValue={efm.Startingnumber} data-amount={efm.Amount} data-amount-type={efm.Type} data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract} />
                                                                        <button id="btn_dv_plus_popup" onClick={qunatityChange} data-parent-id={id} data-btn-type="plus" data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract}>
                                                                            <img src={Checkout_Plus} alt="" />
                                                                        </button>
                                                                    </div>
                                                                </React.Fragment>)
                                                            }))
                                                        })
                                                    }
                                                </React.Fragment>
                                                //**END */
                                            )
                                            break;
                                        case Config.key_InputTypes.RadioButton:
                                            return (
                                                <React.Fragment>
                                                    <p >{mod.Title}</p>
                                                    <div className="radio-group" onChange={onChangeValue}>{
                                                        mod.modifierFields && mod.modifierFields.map(mf => {
                                                            return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                                                var id = (efm.Name != null && typeof efm.Name != "undefined") && (efm.Name).replace(/ /g, "_");
                                                                return (
                                                                    <label htmlFor={id}>
                                                                        <input type="radio" id={id} name={mod.Title} value={efm.Name} data-checked-value={efm.Default} data-gparent-name={gpname} data-gpid={gpid} data-amount={efm.Amount} data-add-sub={efm.AddnSubtract} data-amount-type={efm.Type} onChange={null} /*checked={efm.Default=="yes"?true:false}*/ checked={preSelectedModifiers(gpname, efm.Name)} />
                                                                        <div className="custom-radio">
                                                                            <p>{efm.Name}({currecyFunction(efm.Amount)})</p>
                                                                        </div>
                                                                    </label>)
                                                            }))
                                                        })
                                                    }</div></React.Fragment>
                                            )
                                            break;
                                        case Config.key_InputTypes.TextField:
                                            return (//** HIDE THIS BECAUSE WE HAVE NOT DESIGN FOR IT 23-DEC-2022 */
                                                <React.Fragment>

                                                    <p className="labelTitle">{mod.Title}({currecyFunction(mod.Amount)})</p>
                                                    {
                                                        mod.modifierFields && mod.modifierFields.map(mf => {
                                                            return (mf.ExtendFormData && mf.ExtendFormData.map(efm => {
                                                                var id = (efm.Name).replace(/ /g, "_");
                                                                return (<React.Fragment>

                                                                    <p>{efm.Name}</p>
                                                                    <input id={id + gpid} type="text" name={id + "-txt"} defaultValue="" data-amount={efm.Amount} data-amount-type={efm.Type} data-gparent-name={gpname} data-gpid={gpid} data-add-sub={efm.AddnSubtract} onChange={onChangeValue} placeholder={LocalizedLanguage.enterYourtext} />
                                                                </React.Fragment>)
                                                            }))
                                                        })
                                                    }

                                                </React.Fragment>
                                            )
                                            break;
                                        default:
                                            break;
                                    }
                                })
                            }</React.Fragment>
                        <ProductAddons product={_product} setAddonsValue={setAddonsValue} isClear={isClear}></ProductAddons>
                    </div>
                    <div className="detailed-product">
                        <div className="row">
                            <div className="product-image-container">
                                {
                                    _product && _product.ProductImage != null && _product.ProductImage !="False" ?
                                        <img src={_product.ProductImage} alt="" id="productImage" className="height-fit" /> :
                                        <img src={NoImageAvailable} alt="" id="productImage" className="height-fit" />
                                }
                            </div>
                            <div className="col">
                                <p className="mobile-only">{LocalizedLanguage.stockdetails}</p>
                                <div className="group">
                                    <div className="text-row">
                                        <p className="mobile-only">{LocalizedLanguage.currently} {stockStatusInOut}:</p>
                                        {isRefereshIconInventory === true ?
                                            <p className="text-center"><img src={RefreshGrey} alt="" className="rotate"></img></p>
                                            :
                                            stockStatusInOut != LocalizedLanguage.outOfStock ? stockStatusInOut.toString().toLocaleLowerCase() !== 'unlimited' ? <p className={clsQuantity}>{variationStockQunatity}</p> : null
                                                : <p className={clsQuantity}>{variationStockQunatity < 0 ? variationStockQunatity : 0}</p>}
                                    </div>
                                    {isOutOfStock == false && <p className="desktop-only">
                                        {stockStatusInOut.toString().toLocaleLowerCase() === 'unlimited' && <p className="unlimited-text">ထ</p>}
                                        {stockStatusInOut.toString().toLocaleLowerCase() === 'unlimited' ? LocalizedLanguage.unlimited : variationStockQunatity == 0 ? LocalizedLanguage.outOfStock : (stockStatusInOut.toLocaleLowerCase() == 'instock' ? LocalizedLanguage.instock : stockStatusInOut)}
                                        {/* {variationStockQunatity == 0 ? LocalizedLanguage.outOfStock : stockStatusInOut} */}
                                    </p>}
                                    <button onClick={() => canManageInventory() == true ? toggleAdjustInventory() : null} disabled={canManageInventory() == false || _product.ManagingStock == false ? true : (stockStatusInOut.toString().toLocaleLowerCase() !== 'unlimited' ? false : true)} style={{ opacity: stockStatusInOut.toString().toLocaleLowerCase() !== 'unlimited' ? 1 : 0.5 }}>{LocalizedLanguage.adjustInven}</button>

                                </div>

                                <button id="addProductDiscountMobile" disabled={isDiscountBtn} className={isDiscountBtn ? "btn-disable" : ""} onClick={() => toggleProductDiscount()}>
                                    <img src={Coin_Blue} alt="" />
                                    {LocalizedLanguage.addDiscount}
                                </button>
                            </div>
                        </div>
                        <div className="col">
                            {_product && _product.ShortDescription && _product.ShortDescription != null && <><p className="title">{LocalizedLanguage.shortdescription}</p>
                                <p className="para" dangerouslySetInnerHTML={{ __html: _product && _product.ShortDescription }}>
                                </p></>}
                            {
                                _product && _product.ShortDescription == null && <><p className="title">{LocalizedLanguage.shortdescription}</p><p className="para" dangerouslySetInnerHTML={{ __html: props && props.selProduct && props.selProduct.ShortDescription }}>
                                </p></>
                            }
                            {_product && _product.ProductAttributes && _product.ProductAttributes.length > 0 &&
                                <p className="title">{LocalizedLanguage.additionalfields}</p>}
                            <p className="para">
                                {_product && _product.ProductAttributes && _product.ProductAttributes.map((item, index) => {
                                    if (item && item.Option) {
                                        let itemOption = item.Option && item.Option.replaceAll(",", ", ");
                                        return <div key={index}>{item.Name + " : " + itemOption}</div>
                                    }
                                })
                                }
                            </p>
                            {_product && _product.Sku && _product.Sku !== "" && <p className="title">SKU #</p>}
                            <p className="para">{_product && _product.Sku}</p>
                            {_product && _product.Barcode && _product.Barcode !== "" && <p className="title">Barcode ID #</p>}
                            <p className="para">{_product && _product.Barcode}</p>
                        </div>
                    </div>
                    <div className={props.isProductEdit && props.isProductEdit == true ? "recommended-upsells is-disabled" : "recommended-upsells"}>
                        <p>{LocalizedLanguage.recommendedupsells}</p>
                        <div className="button-row">
                            {recommProducts && recommProducts.length > 0 && recommProducts.map(a => {
                                return <button onClick={() => props.openPopUp(a)} key={a.WPID}>
                                    <div className="img-container">
                                        {a && a.ProductImage != null && a.ProductImage != 'False' ?
                                            <img src={a && a.ProductImage} alt="" className="height-fit" /> :
                                            <img src={NoImageAvailable} alt="" id="productImage" className="height-fit" />
                                        }
                                    </div>
                                    <div className="prod-name">
                                        <p>{a && a.Title}</p>
                                    </div>
                                </button>
                            })}
                        </div>
                    </div>
                    <div className={isBackOrdered || stockStatusInOut !== 'outofstock' || (props.hasOwnProperty("isProductEdit") && props.isProductEdit == true) ? "product-footer" : "product-footer is-disabled-footer"} >
                        <div className="row">
                            <button id="addProductNote" onClick={() => toggleProductNote()}>
                                <img src={Pencil} alt="" />
                                {LocalizedLanguage.addNote}
                            </button>
                            <button id="addProductDiscount" onClick={() => toggleProductDiscount()} disabled={isDiscountBtn} className={isDiscountBtn ? "btn-disable" : ""}>{discounts}</button>
                        </div>
                        <div className="row">
                            <div className="increment-input">
                                <button onClick={() => quantityUpdate('minus')} >
                                    <img src={Minus_Blue} alt="" />
                                </button>
                                <input id="quantityInputBox" type="number" placeholder="0" value={productQty} onChange={(e) => quantityUpdateOnChage(e, variationStockQunatity, _product)} />
                                <button onClick={() => quantityUpdate('plus')}>
                                    <img src={Plus_Blue} alt="" />
                                </button>
                            </div>

                            <button id="addProductToCart" className={isBackOrderedNotification ? 'back-order-btn' : ''} onClick={() => addToCart()}>
                                <img src={CircledPlus_White} alt="" />
                                <p>{isBackOrderedNotification ? `Sell on Backorder` : LocalizedLanguage.addToCart

                                } {currecyFunction(_price)}</p>
                            </button>

                        </div>
                    </div>

                    <div id="navCover" className="nav-cover"></div>
                </div>
                {isProductDiscount == true ? <ProductDiscount isShow={isProductDiscount} toggleProductDiscount={toggleProductDiscount} selecteditem={_product} setDiscountApplied={setDiscountApplied}></ProductDiscount> : null}
                {isAdjustInventory == true && <AdjustInventory isShow={isAdjustInventory} toggleAdjustInventory={toggleAdjustInventory}
                    productStockQuantity={(props.hasOwnProperty("isProductEdit") && props.isProductEdit == true) ? _currentStock : variationStockQunatity}
                    product={_product}
                    fatchUpdateInventory={fatchUpdateInventory}
                    isAdjustInventory={isAdjustInventory}
                ></AdjustInventory>
                }
                <NoVariationSelected isShow={isNoVariationSelected} toggleNoVariationSelected={toggleNoVariationSelected}></NoVariationSelected>
                <ProductNote isShow={isProductNote} toggleProductNote={toggleProductNote} addNote={addNote}></ProductNote>
                <MsgPopupOutOfStock isShow={isOutOfStock} toggleOutOfStock={toggleOutOfStock} toggleAdjustInventory={toggleAdjustInventory}></MsgPopupOutOfStock>
                <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody} img={warningImg}></MsgPopup>
            </React.Fragment>)
}
export default Product 