import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import FilterArrowDown from '../../assets/images/svg/FilterArrowDown.svg'
import FilterArrowUp from '../../assets/images/svg/FilterArrowUp.svg'
import OnlineSale from '../../assets/images/svg/OnlineSale.svg'
import InStoreSale from '../../assets/images/svg/InStoreSale.svg'
import moment from 'moment';
import Config from '../../Config'
import STATUSES from "../../constants/apiStatus";
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import { LoadingSmallModal } from "../common/commonComponents/LoadingSmallModal";
import { v4 as uniqueKey } from 'uuid';
import { useNavigate } from "react-router-dom";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import KeysDisplayOrderStatus  from "../../settings/KeysDisplayOrderStatus"
import { isMobileOnly } from "react-device-detect";
import { NavigateComponent } from "../../settings";
import SkeletonBox from "../common/SkeletonBox";
const ActivityList = (props) => {
    const myRef = useRef();
    const navigate = useNavigate()
    // var current_date = moment().format(Config.key.DATE_FORMAT);
    var current_date = new Date().toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });

    //var customer_to_OrderId = (typeof localStorage.getItem("CUSTOMER_TO_OrderId") !== 'undefined' && localStorage.getItem("CUSTOMER_TO_OrderId") !== null) ? localStorage.getItem("CUSTOMER_TO_OrderId") : '';
    // var id = `activity-order-${customer_to_OrderId}`
    // var elmnt = document.getElementById(`${id}`);
    var ordersDate = new Array();
    var orders = props.orders;
    //var custId = localStorage.getItem("CUSTOMER_TO_ACTVITY") !== 'undefined' && localStorage.getItem("CUSTOMER_TO_ACTVITY") !== null ? localStorage.getItem("CUSTOMER_TO_ACTVITY") : ""
    //var ids = `activity-order-${custId}`
    //var elmnts = document.getElementById(`${ids}`);

    if (!localStorage.getItem('user')) {
       // navigate('/pin')
       NavigateComponent(navigate,'pin');
    }
    if (typeof props.orders !== 'undefined') {
        for (const key in orders) {
            if (orders.hasOwnProperty(key)) {
                ordersDate.push(key)
            }
        }
        if (ordersDate.length > 0) {
            ordersDate.sort(function (a, b) {
                var keyA = new Date(a),
                    keyB = new Date(b);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            ordersDate.reverse();
        //     if (props.SelectedTypes == '' || props.SelectedTypes == 'dateAsc') {
        //         ordersDate.reverse();
        //     } else if (props.SelectedTypes == 'dateDesc') {

        //     }
         }
    }

    const sortOrders=(orders)=>{
        if (typeof orders !== 'undefined') {
            // for (const key in orders) {
            //     if (orders.hasOwnProperty(key)) {
            //         ordersDate.push(key)
            //     }
            // }
            if (orders.length > 0) {
                if (props.SelectedTypes == '' || props.SelectedTypes == 'dateAsc' || props.SelectedTypes == 'dateDesc'){
                    orders.sort(function (a, b) {
                        var keyA = new Date(a.order_id),
                            keyB = new Date(b.order_id);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                }
                if (props.SelectedTypes == '' || props.SelectedTypes == 'dateAsc') {
                    orders.reverse();
                } else if (props.SelectedTypes == 'dateDesc') {
    
                }
                if (props.SelectedTypes == 'amountAsc') {
                    orders.sort(function (a, b) {
                        var keyA = new Date(a.total),
                            keyB = new Date(b.total);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    orders.reverse();
                }
                    if (props.SelectedTypes == 'amountDesc') {
                        orders.sort(function (a, b) {
                            var keyA = new Date(a.total),
                                keyB = new Date(b.total);
                            // Compare the 2 dates
                            if (keyA < keyB) return -1;
                            if (keyA > keyB) return 1;
                            return 0;
                        });
                       
                    }
            }
        }
        return orders;
    }

    const onScroll = (e) => {
        const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
        // let page = this.state.page;
        if (bottom) {
            props.updateSomething()
        }
    };

    function Capitalize(val) {
        var str = val ? val.replace(/_/g, "\u00a0") : "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    var CurrencySymbol = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).CurrencySymbol:"";
    function currencyUpdate(html) {
        var temporalDivElement = document && document.createElement("div");
        temporalDivElement.innerHTML = html;
        return (temporalDivElement.textContent || temporalDivElement.innerText || "");
    }
    return (
        <div ref={myRef} onScroll={onScroll} className="body" onClick={()=>props.hundleTransectionClick()} >

            {props.isloader == true ? <SkeletonBox /> : null}

            {/* {activityAllDetails.status == STATUSES.LOADING ? <LoadingSmallModal></LoadingSmallModal> : null} */}
            {props.isloader == false && ((!ordersDate) || ordersDate.length == 0) ?
                <div className="no-results">
                    <p className="style1">{LocalizedLanguage.Noresultsfound}</p>
                    <p className="style2">{LocalizedLanguage.Sorryyousearchdidnot}<br /> {LocalizedLanguage.matchanyresults}</p>
                </div>
                :
                orders && ordersDate && ordersDate.map((getDate, index) => {
                    var sortedOrder=props.sortOrders( getDate && orders && orders[getDate] && orders[getDate])
                   
                    return (
                        <>
                            <div className="filter-name" key={uniqueKey()} >
                                <p key={"date" + index}> {current_date == getDate ? <>{LocalizedLanguage.today}</> : getDate}</p>
                            </div>
                            {
                                sortedOrder && sortedOrder.map((order, index) => {                                  
                                    var time = FormateDateAndTime.formatDateWithTime(order.date_time, order.time_zone);
                                    return (
                                        <>
                                        {
                                            isMobileOnly ===true? <button className="transaction-card no-transform" onClick={() => props.click(order, order.order_id,true)} key={uniqueKey()} >
                                            <div className="col" key={uniqueKey()} >
                                                <p className="style1">{LocalizedLanguage.Order}# {order.order_id}</p>
                                                <p className="style2">{order.CustFullName ? order.CustFullName:order.CustEmail}</p>
                                                <div className="row">
                                                    {
                                                        (order.OliverReciptId !== '') ? <img src={InStoreSale} alt="" /> : <img src={OnlineSale} alt="" />

                                                    }
                                                    <p>{KeysDisplayOrderStatus.key[order.order_status]}</p>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {
                                                    // (order.refunded_amount > 0) ?
                                                    //     <p className="style3">${parseFloat(order.total - order.refunded_amount).toFixed(2)}
                                                    //         &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                    //     </p>
                                                    //     :
                                                    //     <p className="style3">${parseFloat(order.total).toFixed(2)}</p>
                                                    
                                                        (order.refunded_amount > 0) ?
                                                        <p className="style3">{currencyUpdate(CurrencySymbol)}{parseFloat(order.total - order.refunded_amount).toFixed(2)}
                                                            &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                        </p>
                                                        :
                                                        <p className="style3">{currencyUpdate(CurrencySymbol)}{parseFloat(order.total).toFixed(2)}</p>
                                                }
                                                <p className="style4">{time}</p>
                                            </div>
                                            {props.updateActivityId == order.order_id ? <div className="selected-indicator"></div> : ''}
                                        </button>:<button className={props.updateActivityId == order.order_id ? "transaction-card no-transform selected" : "transaction-card no-transform"} onClick={() => props.click(order, order.order_id)} key={uniqueKey()} >
                                            <div className="col">
                                                <p className="style1">{LocalizedLanguage.Order}# {order.order_id}</p>
                                                <p className="style2">{order.CustFullName ? order.CustFullName:order.CustEmail}</p>
                                                <div className="row">
                                                    {
                                                        (order.OliverReciptId !== '') ? <img src={InStoreSale} alt="" /> : <img src={OnlineSale} alt="" />

                                                    }
                                                    <p>{KeysDisplayOrderStatus.key[order.order_status]}</p>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {
                                                    // (order.refunded_amount > 0) ?
                                                    //     <p className="style3">${parseFloat(order.total - order.refunded_amount).toFixed(2)}
                                                    //         &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                    //     </p>
                                                    //     :
                                                    //     <p className="style3">${parseFloat(order.total).toFixed(2)}</p>
                                                    
                                                        (order.refunded_amount > 0) ?
                                                        <p className="style3">{currencyUpdate(CurrencySymbol)}{parseFloat(order.total - order.refunded_amount).toFixed(2)}
                                                            &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                        </p>
                                                        :
                                                        <p className="style3">{currencyUpdate(CurrencySymbol)}{parseFloat(order.total).toFixed(2)}</p>
                                                }
                                                <p className="style4">{time}</p>
                                            </div>
                                            {props.updateActivityId == order.order_id ? <div className="selected-indicator"></div> : ''}
                                        </button>
                                        }
                                        {/* <button className={props.updateActivityId == order.order_id ? "transaction-card no-transform selected" : "transaction-card no-transform"} onClick={() => props.click(order, order.order_id)} key={uniqueKey()} >
                                            <div className="col">
                                                <p className="style1">{LocalizedLanguage.Order}# {order.order_id}</p>
                                                <p className="style2">{order.CustFullName ? order.CustFullName:order.CustEmail}</p>
                                                <div className="row">
                                                    {
                                                        (order.OliverReciptId !== '') ? <img src={InStoreSale} alt="" /> : <img src={OnlineSale} alt="" />

                                                    }
                                                    <p>{KeysDisplayOrderStatus.key[order.order_status]}</p>
                                                </div>
                                            </div>
                                            <div className="col">
                                                {
                                                    // (order.refunded_amount > 0) ?
                                                    //     <p className="style3">${parseFloat(order.total - order.refunded_amount).toFixed(2)}
                                                    //         &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                    //     </p>
                                                    //     :
                                                    //     <p className="style3">${parseFloat(order.total).toFixed(2)}</p>
                                                    
                                                        (order.refunded_amount > 0) ?
                                                        <p className="style3">{currecyFunction(parseFloat(order.total - order.refunded_amount).toFixed(2))}
                                                            &nbsp;<del>{parseFloat(order.total).toFixed(2)}</del>
                                                        </p>
                                                        :
                                                        <p className="style3">{currecyFunction(parseFloat(order.total).toFixed(2))}</p>
                                                }
                                                <p className="style4">{time}</p>
                                            </div>
                                            {props.updateActivityId == order.order_id ? <div className="selected-indicator"></div> : ''}
                                        </button> */}
                                        </>
                                    )
                                })
                            }

                        </>
                    )
                })
            }



        </div>
    )
}

export default ActivityList