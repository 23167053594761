import React, { useEffect, useState } from 'react'
import { TilesIcon } from '../../../assets/images/svg/TilesIcon'
import { ProductViewIcon } from '../../../assets/images/svg/ProductViewIcon'
import { CategoriesIcon } from '../../../assets/images/svg/CategoriesIcon'
import { DownArrowBorderIcon } from '../../../assets/images/svg/DownArrowBorderIcon'
import LocalizedLanguage from '../../../settings/LocalizedLanguage'
import Minus_Blue from '../../../assets/images/svg/Minus-Blue.svg';
import Plus_Blue from '../../../assets/images/svg/Plus-Blue.svg';

const CustomDropdown = ({ options, onChange, placeholder, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option.value);
    setIsOpen(false);
  };
  function capitalizedOption(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  return (
    <div className="custom-dropdown-container">
      <div
        className="custom-dropdown-header"
        onClick={handleToggleDropdown}
      >
       {value ? (
        <>
          <span>{capitalizedOption(value)}</span><DownArrowBorderIcon  fill={'var(--oliver-base-blue)'}/>
        </>
      ) : (
        <>
          {placeholder} <DownArrowBorderIcon  fill={'var(--oliver-base-blue)'}/>
        </>
      )}
      </div>
      {isOpen && (
        <div className="custom-dropdown-options">
          {options.map((option) => (
            <div
              key={option.value}
              className="custom-dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};



export default function TileNav({segementSelected, 
  handleSegmentSelected,
  isTileDelete,
  onEdit,
  onSave,
  categoryOptions,
  onSelectChange,
  showCategorySelection,
  catBreadcrumb,
  tilesNo,
  tilesCount,
  selectedOption
}) {
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
  const getClassName = (name) => segementSelected === name ? 'tileNav_menu__nav-button tileNav_menu__selected-button' : 'tileNav_menu__nav-button';

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div className='sticky-container'>
    <div className='tileNav'>
    <div className='tileNav_menu'>
    <button  className={getClassName('tile-view')}  onClick={(e) => handleSegmentSelected('tile-view')}>
        <TilesIcon width='20'/>
        <span>{LocalizedLanguage.custom} </span>
        </button>
    <button  className={getClassName('categories')}  onClick={(e) => handleSegmentSelected('categories')}>
       <CategoriesIcon width='18' />
       <span>{LocalizedLanguage.categories} </span>
       </button>
    <button className={getClassName('product-list')}  onClick={(e) => handleSegmentSelected('product-list')}> 
    <ProductViewIcon  width='20'/> 
    <span>{LocalizedLanguage.products} </span>
    </button>
    </div>
    {isTileDelete && segementSelected ==='tile-view' && !isPortrait && <div className="change-row-count">
                <button id="minusRow" className="dummy_minus" onClick={() => tilesCount("minus")}>
                    <img src={Minus_Blue} alt="" className="dummy_img" />
                </button>
                <p id="tileCount"> {tilesNo} tiles per row</p>
                <button id="plusRow" onClick={() => tilesCount("plus")} className="dummy_plus">
                    <img src={Plus_Blue} alt="" className="dummy_img" />
                </button>

            </div>
            }
      {segementSelected === 'categories' && (
        <CustomDropdown
          options={categoryOptions}
          onChange={onSelectChange}
          value={selectedOption}
          placeholder= {LocalizedLanguage.categories}
        />
      )}
      {segementSelected === 'tile-view' && catBreadcrumb && catBreadcrumb.length > 0 ? (
        <div className="category-header">
          {showCategorySelection()}
        </div>
      ) : (
        !isTileDelete && segementSelected === 'tile-view' && (
          <button className="tileNav__edit-button" onClick={onEdit}>
            {LocalizedLanguage.tileEdit}
          </button>
        )
      )}
      {isTileDelete && segementSelected === 'tile-view' && (
        <button id="exitEditing" className="tileNav__edit-button" onClick={onSave}>
          {LocalizedLanguage.SaveUpdate}
        </button>
      )}
</div>
 {isTileDelete && segementSelected ==='tile-view' && isPortrait && <div className="change-row-count">
 <button id="minusRow" className="dummy_minus" onClick={() => tilesCount("minus")}>
     <img src={Minus_Blue} alt="" className="dummy_img" />
 </button>
 <p id="tileCount"> {tilesNo} tiles per row</p>
 <button id="plusRow" onClick={() => tilesCount("plus")} className="dummy_plus">
     <img src={Plus_Blue} alt="" className="dummy_img" />
 </button>
</div>
}
</div>
  )
}