import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Stripe_Icon from '../../assets/images/svg/Stripe Icon.svg'
import CashButtonImage from '../../assets/images/svg/CashButtonImage.svg';
import CardButtonImage from '../../assets/images/svg/CardButtonImage.svg'
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import { get_customerName, get_UDid, get_userName } from '../common/localSettings';

import STATUSES from "../../constants/apiStatus";
import { useLocation, useNavigate } from 'react-router-dom';
import LeftNavBar from "../common/commonComponents/LeftNavBar";
import Header from "./Header";
import CartListBody from "../common/commonComponents/CartListBody";
import { RoundAmount, typeOfTax, getProductxChlidProductTax, getTotalTaxByName, getExtensionCheckoutList, setListActivityToCheckout, getCheckoutList } from "../common/TaxSetting";
import { popupMessage } from "../common/commonAPIs/messageSlice";
import { NumericFormat } from 'react-number-format';
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import NumberPad from "../common/commonComponents/NumberPad";
import { GetRoundCash } from "../../settings/CheckoutFunction";
import PartialPayment from "./PartialPayment";
import ActiveUser from '../../settings/ActiveUser';
import paymentsType from '../../settings/PaymentsType'
import { getAddonsField, getBookingField, NavigateComponent, productxArray } from "../../settings/CommonModuleJS";
import Config from '../../Config';
import moment from "moment";
import { isSafari, isMobileOnly, isTablet } from "react-device-detect";
import { product } from "../dashboard/product/productSlice";
import { addtoCartProduct } from "../dashboard/product/productLogic";
import { makeOnlinePayments, getMakePayment, save, paymentAmount, changeReturnAmount } from "../checkout/checkoutSlice";
import { LoadingModal } from "../common/commonComponents/LoadingModal";

import { handleAppEvent, postmessage } from "../common/AppHandeler/commonAppHandler";

import ParkSale from "./ParkSale";
import { CheckAppDisplayInView, CheckAppForPaymentView, UpdateRecentUsedApp } from "../common/commonFunctions/AppDisplayFunction";
import ManualPayment from "../common/commonComponents/paymentComponents/ManualPayment";
import UPIPayments from "../common/commonComponents/paymentComponents/UPIPayment";
import StripePayment from "../common/commonComponents/paymentComponents/StripePayment";
import GlobalPayment from "../common/commonComponents/paymentComponents/GlobalPayment";
import MsgPopup from "../common/commonComponents/MsgPopup";
import SplitByProduct from "../common/commonComponents/paymentComponents/SplitByProduct";
import IframeWindow from "../dashboard/IframeWindow";
import { checkStock } from "../checkout/checkoutSlice";
import { v4 as uniqueKey } from 'uuid';
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { ValidateComponent } from "../common/validate";
import { sendDataToWrapper } from "../../settings/AndroidIOSConnect";
import keyDisplayOrderStatus from "../../settings/KeysDisplayOrderStatus"
import PaymentNotSupportedPopup from "../common/commonComponents/PaymentNotSupportedPopup";
import { GetOpenRegister} from '../cashmanagement/CashmanagementSlice'
import { useIndexedDB } from "react-indexed-db";
import { useMemo } from "react";
const Checkout = (props) => {

    const [subTotal, setSubTotal] = useState(0.00);
    const [taxes, setTaxes] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [discountType, setDiscountType] = useState('');
    const [isShowNumberPad, setisShowNumberPad] = useState(false);
    const [isShowPartialPayment, setisShowPartialPayment] = useState(false);
    const [balance, setbalance] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [partialAmount, setPartialAmount] = useState(null);

    const [checkList, setCheckList] = useState(JSON.parse(localStorage.getItem("CHECKLIST")));
    const [changeAmount, setChangeAmount] = useState(0);
    const [cashPayment, setCashPayment] = useState(0);
    const [afterPaymentIs, setAfterPaymentIs] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cashRound, setCashRound] = useState(0);
    const [isPaymentStart, setIsPaymentStart] = useState(false);
    const [onlinePayCardData, setOnlinePayCardData] = useState({});
    const [global_Payments, setGlobal_Payments] = useState({});
    const [onlinePayments, setOnlinePayments] = useState({});
    const [myInput, setMyInput] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeCash, setActiveCash] = useState(false);
    const [payment_Type, setPayment_Type] = useState()
    const [count, setCount] = useState(0)
    const [paymentsArr, setPaymentsArr] = useState([])
    const [set_calculator_remaining_amount, set_set_calculator_remaining_amount] = useState(0);
    const [emvData, setEmvData] = useState([]);
    const [AllProductList, setAllProductList] = useState([]);
    const [orderType, setOrderType] = useState('');
    const [Notes, setNotes] = useState('');
    const [extensionMetaData, setExtensionMetaData] = useState({});
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [extensionOrderNote, setExtensionOrderNote] = useState([]);
    const [extensionUpdateCart, setExtensionUpdateCart] = useState(false);
    const [set_order_notes, set_set_order_notes] = useState([]);
    const [partialType, setPartialType] = useState('');
    const [isShowParkSale, setisShowParkSale] = useState(false);
    const [isLayAwayOrPark, setIsLayAwayOrPark] = useState('');
    const [storeCredit, setStoreCredit] = useState(0);
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [isShowiFrameWindow, setisShowiFrameWindow] = useState(false);
    const [extApp, setExtApp] = useState('');

    const [checkoutData, setCheckoutData] = useState(false);
    const [updateProductStatus, setUpdateProductStatus] = useState(false)
    const [checkseatStatus, setCheckseatStatus] = useState(false)
    const [orderStatus, setOrderStatus] = useState(localStorage.getItem("CHECKLIST") && (JSON.parse(localStorage.getItem("CHECKLIST")).hasOwnProperty("status") == true && JSON.parse(localStorage.getItem("CHECKLIST")).status != null) ? true : false)
    //const [extensionPayments, setExtensionPayments] = useState(localStorage.getItem('GET_EXTENTION_FIELD') ? JSON.parse(localStorage.getItem('GET_EXTENTION_FIELD')) : []);
    const [shippingAmount, setShippingAmount] = useState(0);
    const [shippingTax, setShippingTax] = useState(0);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    const [pointRewardData, setPointRewardData] = useState(null);
    const [startOrderProcessing, setStartOrderProcessing] = useState(null);

    const[payRedeem,setPayRedeem]=useState(false);
    const { getByID,getAll } = useIndexedDB("products");
    
    ValidateComponent()


    const [paymentTypeItem, setPaymentTypeItem] = useState({
        Code: "",
        ColorCode: "",
        EODReconcilliation: true,
        HasTerminal: false,
        Id: 0,
        Name: "",
        Support: "",
        TerminalCount: 0,
        TerminalSerialNo: []
    });
    const [isManualPayment, setisManualPayment] = useState(false);
    const [isUPIPayment, setisUPIPayment] = useState(false);
    const [isStripeTerminalPayment, setisStripeTerminalPayment] = useState(false);
    const [cancleTransaction, setcancleTransaction] = useState(false);
    const [isGlobalPayment, setisGlobalPayment] = useState(false);
    const [isShowSplitByProduct, setisShowSplitByProduct] = useState(false);
    const [isShowPaymentNotSupported,setIsShowPaymentNotSupported] = useState(false)
    const isDemoUser = window.location.hostname === Config.key.DEMO_USER_URL;
    const { status, dataone, error, is_success } = useSelector((state) => state.GetOpenRegister)
    
    const UID = get_UDid('UDID');
    const register_Id = localStorage.getItem('register');
    var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
    var selectedRegister = localStorage.getItem('selectedRegister') ? JSON.parse(localStorage.getItem("selectedRegister")) : '';      
  
    
    let useOnce = false;
    useEffect(() => {
        if (useOnce == false || AllProductList.length == 0) {
            getAll().then((rows) => {
                setAllProductList(rows)
            });
        }
        return () => {
            useOnce = true;
        }
    }, []);

    // change_amount: change_amount,
    // cash_payment: paying_amount,
    // after_payment_is: payment_is
    //var cashRound = 0;
    //**CHECK STOCK IF THE SALE STATUS IS PENDING/PARK/ OR LAYAWAY 23-DEC-2022-----START--
    const checkItemList = async () => {

        //Trigger this listener when the view has changed
        sendDataToWrapper("current_view", "checkout", "");

        var list_item = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : null;
        var demoUser = localStorage.getItem("demoUser") ? localStorage.getItem("demoUser") : false;
        if (demoUser == false) {
            const layaway_status = localStorage.getItem("CHECKLIST") && JSON.parse(localStorage.getItem("CHECKLIST"));
            var isLayAway = false;
            if (layaway_status && layaway_status.status != null) {
                if (layaway_status.status.toLowerCase() == "on-hold" || layaway_status.status.toLowerCase() == 'lay_away' || layaway_status.status.toLowerCase() == keyDisplayOrderStatus.key["on-hold"].toLowerCase()) {
                    isLayAway = true;
                }
            }
            var _item = [];
            if (list_item) {
                list_item.map(async item => {
                    var result = AllProductList?.length>0 && AllProductList.find(i=>i.WPID===item.product_id);
                   if(!result ||result==null){
                        result =await getByID(item.product_id)
                    }

                    if (item.hasOwnProperty("product_id") && result && !( result.BackOrderd==="yes" || result.BackOrderd==='notify' || result.StockStatus === 'onbackorder' )) {
                        if (isLayAway == true && item.line_item_id == 0 ) {
                            _item.push(item);
                        }
                        else if (isLayAway == false) {
                            _item.push(item);
                        }
                    }
                });
            }
            if (_item && _item.length > 0) {
                dispatch(checkStock(_item));
            }
            else {
                setLoading(false);
            }
        }
        else { setLoading(false); }
    }
    const checkStockResult = (checkout_list) => {
        var msg = '';
        var title = '';
        var cartProductList = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : null;
        var blank_quntity = []
        var new_data = [];
        checkout_list && checkout_list.map(checkfalse => {
            if (checkfalse.success === false || checkfalse.Quantity == 0) {
                new_data.push(checkfalse)
            }
        })

        if (cartProductList && new_data) {
            new_data.map(isExsit => {
                cartProductList.map(idExsit => {
                    if (idExsit.variation_id == 0 ? idExsit.product_id === isExsit.ProductId : idExsit.variation_id === isExsit.ProductId) {
                        blank_quntity.push(idExsit.Title)
                    }
                })
            })
        }
        if (blank_quntity.length > 0) {
            title = LocalizedLanguage.messageCartProductNotAvailable;

            msg += blank_quntity.map(name => {
                return (
                    blank_quntity.length == 1 ? name : msg == "" ? name : ", " + name
                )
            })

        }
        else {
            msg = LocalizedLanguage.messageCartNoProduct;
        }
        //show message popup here
        setLoading(false);
        var data = { title: title, msg: msg, is_success: true, isBack: true }
        dispatch(popupMessage(data));
        //NavigateComponent(navigate,"home");

    }
    const [resCheckStock] = useSelector((state) => [state.checkStock])
    useEffect(() => {
        if (resCheckStock && resCheckStock.status == STATUSES.IDLE && resCheckStock.is_success && orderStatus == true) {
            //console.log("---resCheckStock--" + JSON.stringify(resCheckStock.data));
            var checkout_list = resCheckStock.data.content;
            //var IsExist = false;
            //var IsExsitTicket = false;
            if (checkout_list && checkout_list.length > 0 && updateProductStatus == true) {
                var checkProductUpdate;
                //IsExist = false
                checkProductUpdate = checkout_list.find(item => (item.success == false || item.Quantity == 0));
                if (checkProductUpdate && checkProductUpdate.ProductId !== 0) {
                    //IsExist = false;
                    checkStockResult(checkout_list);
                }
                // else {
                //     IsExist = true;
                // }
                // var CHECKLIST = localStorage.getItem("CHECKLIST") ? JSON.parse(localStorage.getItem("CHECKLIST")) : null;
                // var cartproductlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : null;
                // if (cartproductlist) {
                //     cartproductlist && cartproductlist.map(ticketInfo => {
                //         if (ticketInfo.ticket_info && ticketInfo.ticket_info.length > 0 && ticketInfo.ticket_info !== "[]") {
                //             CHECKLIST && CHECKLIST.ListItem.map(findId => {
                //                 if (findId.ticket_info && findId.ticket_info.length > 0) {
                //                     findId.ticket_info.map(chart_Id => {
                //                         var chart_id = chart_Id && chart_Id.chart_id ? chart_Id.chart_id : null;
                //                         if (chart_id) {
                //                             IsExsitTicket = true;
                //                         }
                //                     })
                //                 }
                //             })
                //         }
                //     })
                // }

                // if (IsExist === true && IsExsitTicket === false && checkseatStatus == false && checkoutData == false) {
                //     // localStorage.removeItem("oliver_order_payments");
                //     // localStorage.removeItem("VOID_SALE");
                //     // dispatch(paymentAmount(null));
                //     // navigate('/checkout');
                //     // alert("go ahead");
                // }
                setUpdateProductStatus(true);
            }
        }
        else { setLoading(false) }
    }, [resCheckStock]);

    var isCheckStock = true;
    useEffect(() => {
        if (isCheckStock == true) {
            //if (orderStatus == true) {
            setLoading(true);
            setUpdateProductStatus(true);
            checkItemList();
            fetchData();
            //}
            isCheckStock = false;
        }       
    }, [orderStatus]);

    useEffect(() => {       
        //Chack cash drawer----------------------------
        if (status === STATUSES.IDLE && is_success) {
            if (dataone && dataone.content && dataone.content !== undefined) {
                if (dataone.content && dataone.content !== '' && dataone.content !== 0) {
                    localStorage.setItem("IsCashDrawerOpen", "true");
                    localStorage.setItem("Cash_Management_ID", dataone.content.Id);
                } else {
                    localStorage.setItem("IsCashDrawerOpen", "false");
                    localStorage.removeItem("Cash_Management_ID");
                    if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement == true) {
                        NavigateComponent(navigate, "pin");
                    }
                }    
            } else if (is_success ==true && dataone && dataone.content==null ) {
                localStorage.setItem("IsCashDrawerOpen", "false");
                localStorage.removeItem("Cash_Management_ID");
                if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement == true) {
                    NavigateComponent(navigate, "pin");
                }
            }
           
        }
        //--------------------------------------------
    }, [status]);
    const fetchData = () => {
         if (UID && register_Id) {
            // this.props.dispatch(favouriteListActions.getAll(UID, register_Id));

            if (client && client.subscription_permission && client.subscription_permission.AllowCashManagement == true && selectedRegister && selectedRegister.EnableCashManagement == true) {
                dispatch(GetOpenRegister(register_Id));
            }
            else {
                localStorage.setItem("IsCashDrawerOpen", "false");
            }
        }
    }

    //**CHECK STOCK IF THE SALE STATUS IS PENDING/PARK/ OR LAYAWAY 23-DEC-2022-----END--
    const [resSave] = useSelector((state) => [state.save])
    let navigateSaleComplete = localStorage.getItem('clientDetail') ? JSON.parse(localStorage.getItem('clientDetail')) : '';
    navigateSaleComplete = navigateSaleComplete && navigateSaleComplete.shop_settings && navigateSaleComplete.shop_settings.display_sale_refund_complete_screen;
    useEffect(() => {
        if (resSave && resSave.status == STATUSES.IDLE && resSave.is_success && loading === true) {
            setLoading(false);
            dispatch(paymentAmount(null));
            dispatch(changeReturnAmount(null));
            if (isLayAwayOrPark === "park_sale" || isLayAwayOrPark === "lay_away" || navigateSaleComplete == false) {
                gotoHome();
            }
            else {
                //localStorage.removeItem('paybyproduct');
                localStorage.removeItem("payType");
                NavigateComponent(navigate, "salecomplete");
            }
        }
        else if (resSave && resSave.status == STATUSES.ERROR && resSave.is_success === false && loading === true) {
            //console.log("error message---" + resSave.data);
            setLoading(false);
            var data = { title: "", msg: resSave.error, is_success: true }
            dispatch(popupMessage(data));
        }
       
    }, [resSave]);

    const [respopupMessage] = useSelector((state) => [state.popupMessage])
    useEffect(() => {
        if (respopupMessage && respopupMessage.status == STATUSES.IDLE && respopupMessage.is_success && respopupMessage.data) {
            toggleMsgPopup(true);
            setmsgBody(respopupMessage.data.msg);
            setmsgTitle(respopupMessage.data.title);
            dispatch(popupMessage(null));
        }
    }, [respopupMessage]);

    //var paidAmount = 0;
    // const setPaidAmount = (amt) => {
    //     if (amt == null) {
    //         paidAmount = 0;
    //         var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
    //         if (getPayments !== null) {
    //             getPayments.forEach(paid_payments => {
    //                 paidAmount += parseFloat(paid_payments.payment_amount);
    //             });
    //         }
    //         if (checkList && checkList.totalPrice && parseFloat(checkList.totalPrice) >= paidAmount) {
    //             paidAmount = (parseFloat(checkList.totalPrice) - parseFloat(paidAmount));
    //         }
    //     }
    //     paidAmount = amt;
    // }
    //setPaidAmount(null);
    useEffect(() => {
        // console.log("paymentsArr----" + JSON.stringify(paymentsArr));
        if (loading === false) { getPaymentDetails(); }
    }, []);
    useEffect(() => {
        var _checklist = JSON.parse(localStorage.getItem("CHECKLIST"));
        if (_checklist && _checklist.customerDetail && _checklist.customerDetail.store_credit) {
            setStoreCredit(_checklist.customerDetail.store_credit)
        }
        var AdCusDetail = localStorage.getItem('AdCusDetail');
        if ((typeof AdCusDetail !== 'undefined') && AdCusDetail != null) {
            var checkoutList = JSON.parse(AdCusDetail)
            setFirstName(checkoutList.FirstName)
            setLastName(checkoutList.LastName)
            setEmail(checkoutList.Email)
            setPhoneNumber(checkoutList.Contact)
        }

    }, [])

    const dispatch = useDispatch();
    const navigate = useNavigate();

    var cash_rounding = localStorage.getItem('CASH_ROUNDING') ? localStorage.getItem('CASH_ROUNDING') : 0;
    //ActiveUser.key.cash_rounding;
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }
    const toggleSplitByProduct = () => {
        dispatch(product());
        setisShowSplitByProduct(!isShowSplitByProduct)
    }
    const toggleManualPayment = () => {
        setisManualPayment(!isManualPayment)
    }
    const toggleStripeTerminalPayment = () => {
        setisStripeTerminalPayment(!isStripeTerminalPayment)
    }

    const toggleUPIPayment = () => {
        setisUPIPayment(!isUPIPayment)
    }
    const toggleGlobalPayment = () => {
        setisGlobalPayment(!isGlobalPayment)
    }
    const toggleParkSale = (type) => {
        setisShowParkSale(!isShowParkSale);
        setIsLayAwayOrPark(type);
    }
    const toggleNumberPad = () => {
        setisShowNumberPad(!isShowNumberPad)
    }
    const togglePaymentNotSupportedPopup = () => {
        setIsShowPaymentNotSupported(!isShowPaymentNotSupported)
    }
    const toggleShowPartialPayment = () => {
        setPartialType('');
        setisShowPartialPayment(!isShowPartialPayment)
    }

    const getDiscountAmount_Type = () => {
        var CurrencySymbol = localStorage.getItem('currecySymbole') ? localStorage.getItem('currecySymbole') : "$";
        if (localStorage.getItem("CART")) {
            let cart = JSON.parse(localStorage.getItem("CART"));
            let dtype = cart.discountType === "Percentage" ? '%' : CurrencySymbol;
            //let damount = cart.discount_amount;
            let damount = parseFloat(RoundAmount(cart.discount_amount)).toFixed(2);
            if (cart.discountType === "Percentage") {
                setDiscountType(damount + "" + dtype);
            } else {
                setDiscountType(dtype + "" + damount);
            }
        }
        else {
            setDiscountType('')
        }
    }
    const showPartial = (val) => {
        setPartialType(val);
        setisShowPartialPayment(true);
    }

    const setValues = (st, tx, dis, tt, shipAmt, shipTax) => {
        getDiscountAmount_Type();
        setSubTotal(RoundAmount(st));
        setTaxes(RoundAmount(tx));
        setDiscount(RoundAmount(dis));
        setTotal(RoundAmount(tt));
        setbalance(RoundAmount(tt))
        setPaidAmount(RoundAmount(tt));
        setShippingAmount(shipAmt);
        setShippingTax(shipTax);
    }

    const getRemainingPrice = () => {
        var paid_amount = 0;
        var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
        if (getPayments !== null) {
            getPayments.forEach(paid_payments => {
                paid_amount += parseFloat(paid_payments.payment_amount);
            });
        }
        if (checkList && checkList.totalPrice && parseFloat(checkList.totalPrice) >= paid_amount) {
            return (parseFloat(checkList.totalPrice) - parseFloat(paid_amount));
        }
    }

    const getRemainingPriceForCash = () => {
        var paid_amount = 0;
        var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
        if (getPayments !== null) {
            getPayments.forEach(paid_payments => {
                paid_amount += parseFloat(paid_payments.payment_amount);
            });
        }
        var totalPrice = checkList && checkList.totalPrice ? parseFloat(RoundAmount(checkList.totalPrice)) : 0;
        var cashRoundReturn = parseFloat(GetRoundCash(cash_rounding, totalPrice - paid_amount))
        if (cashRoundReturn < 1) {
            setCashRound(cashRoundReturn);
        }
        var new_total_price = (totalPrice - paid_amount) + parseFloat(cashRoundReturn)
        return new_total_price;
    }
    const pay_by_cash = (amount) => {

        toggleNumberPad();
        setPaidAmount(amount);
        setPartialAmount(null);
        dispatch(paymentAmount({ "type": "cash", "amount": amount }));
    }
    const pay_partial = (_amount, item, ptype = null) => {
        var amount = _amount;
        if (ptype != null && ptype !== "" && (ptype === 2 || ptype === 3 || ptype === 4)) {
            if (!localStorage.getItem("payType")) { localStorage.setItem("payType", "split-partial_" + ptype); }
            var getPayments = (typeof localStorage.getItem("oliver_order_payments") !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
            if (getPayments && getPayments.length > 0) {
                var count = getPayments.length;
                if (ptype == 2 && count == 0) {
                    localStorage.setItem("payType", "lastToComplete");
                }
                else if (ptype == 3 && count == 1) {
                    localStorage.setItem("payType", "lastToComplete");
                }
                else if (ptype == 4 && count == 2) {
                    localStorage.setItem("payType", "lastToComplete");
                }
            }
            else if (ptype == 2 && getPayments == null) {
                localStorage.setItem("payType", "lastToComplete");
            }
        }

        if (!localStorage.getItem("payType")) { localStorage.setItem("payType", "partial"); }
        toggleShowPartialPayment();
        setPartialAmount(amount);
        setPaymentTypeItem(item);
        dispatch(paymentAmount({ "type": item.Code, "amount": amount }));
    }
    const pay_by_product = (amount) => {

        toggleSplitByProduct();
        setPartialAmount(amount);
        setPaidAmount(amount);
        toggleShowPartialPayment();
        setPartialType('pay_by_product');
    }
    const pay_by_store_credit = () => {
        setPaymentTypeItem({
            Code: paymentsType.typeName.storeCredit, ColorCode: "#4b4b4b",
            EODReconcilliation: false,
            HasTerminal: false,
            Id: 0,
            Name: LocalizedLanguage.storeCreditTitle,
            Support: "",
            TerminalCount: 0,
            TerminalSerialNo: []
        });
        if (storeCredit < getRemainingPrice() && storeCredit > 0) {
            setPaidAmount(storeCredit);
            dispatch(paymentAmount({ "type": paymentsType.typeName.storeCredit, "amount": storeCredit }));
            setStoreCredit(0);
        }
        else if (storeCredit > 0) {
            setPaidAmount(getRemainingPrice());
            dispatch(paymentAmount({ "type": paymentsType.typeName.storeCredit, "amount": getRemainingPrice() }));
            setStoreCredit(storeCredit - getRemainingPrice())
        }
        else {
            //console.log("--no credit score--");
        }



    }
    // const checkStoreCreditBalance=()=> {
    //     var paid_amount_by_store_credit = 0;
    //     var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
    //     if (getPayments !== null) {
    //         getPayments.forEach(type => {
    //             if (type.payment_type == paymentsType.typeName.storeCredit) {
    //                 paid_amount_by_store_credit += parseFloat(type.payment_amount);
    //             }
    //         });
    //     }
    //     return paid_amount_by_store_credit;
    // }
    // const storeCreditPayment=(type, store_credit)=> {
    //     const _getRemainingPrice = getRemainingPrice() ? getRemainingPrice() : 0;
    //     var paying_amount = checkStoreCreditBalance();
    //     var store_credit_amount = RoundAmount(store_credit - paying_amount);
    //     setPayment_Type(paymentsType.typeName.storeCredit)
    //     //this.props.paymentType(paymentsType.typeName.storeCredit);
    //     var amount = 0;
    //     // var calc_output = $('#calc_output').val();
    //     // if (calc_output !== '' && typeof calc_output !== 'undefined') {
    //     //     amount = $('#calc_output').val();
    //     // } else {
    //     //     amount = $('#my-input').val();
    //     // }
    //     if (store_credit_amount >= amount) {
    //         //updateStoreCreditPayment(amount, type, true);
    //     } else if (store_credit_amount < amount) {
    //         setPaidAmount(RoundAmount(_getRemainingPrice));
    //         extraPayAmount(`${LocalizedLanguage.storeCreditMsg} $${store_credit_amount}`)
    //     } 
    //     // else if (type == 'lay_away') {
    //     //     getPayment(type);
    //     // }
    // }    
    const [respPaymentAmount] = useSelector((state) => [state.paymentAmount])
    useEffect(() => {
        if ((respPaymentAmount && respPaymentAmount.status == STATUSES.IDLE && respPaymentAmount.is_success)) {
           if (respPaymentAmount.data.type == "cash") {
                pay_amount("cash");
            }
            else {
                pay_amount_cash(paymentTypeItem)
            }
        }
    }, [respPaymentAmount]);

    const [respChangeAmount] = useSelector((state) => [state.changeReturnAmount])
    useEffect(() => {
        if ((respChangeAmount && respChangeAmount.status == STATUSES.IDLE && respChangeAmount.is_success)) {
            finalAdd();
        }
    }, [respChangeAmount]);
    
    //Handel Apps --------------------------------
    const [isInit, setisInit] = useState(false)
    const location = useLocation();
    let useCancelled = false;

    function AddEventListner(e) {
        var callCount = 1;//useed
        var data = e && e.data;

        if (typeof data == 'string' && data !== "" && location.pathname == "/checkout") {
            if (data.includes("{")) {
                var _data = JSON.parse(data);
                if (_data && _data.method && _data.command && (_data.command.toLowerCase() == 'transaction' || _data.command.toLowerCase() == 'payment') && _data.method.toLowerCase() == 'post') { // for transaction call it once
                    callCount == 1 && responseData(JSON.parse(data))
                    callCount += 1;
                }
                else {
                    responseData(JSON.parse(data));
                }

            }
        }
    }
    useEffect(() => {
        window.addEventListener('message', AddEventListner)
        setisInit(true);
        //}
        return () => {
            window.removeEventListener('message', AddEventListner)
        };
    }, []);
    const responseData = async (data) => {
        var _route = location.pathname;
        var whereToview = "";
        whereToview = "CheckoutView"
        // else
        //     whereToview = "home"
        var appResponse = handleAppEvent(data, whereToview)
        if (appResponse == 'app_do_transaction') {      // Transection App 2.0                     
            handleAppTransaction(data)
        }
        else if (appResponse == "do_app_orderPark") {
            setPayment('park_sale', 'byExtApp');
            setIsLayAwayOrPark('park_sale');
            setLoading(true);
        }
        else if (appResponse == 'app_do_payment') {
            handleAppPayment(data)
        }
        else if (appResponse == "app_cancle_transaction") {
            setcancleTransaction(true)
            console.log("--appResponcse-->" + appResponse)
        }
        else if (appResponse == 'app_handle_point_and_reward') {      // Transection App 2.0                     
            redeemPointsExtentionCart(data.data)
        }
        else if (appResponse == 'app_handle_cancel_point_and_reward') {      // Transection App 2.0                     
            cancelRedeemedPointsExtentionCart(data.data)
        }
        // else if (appResponse == 'app-coupon_duplicate') {
        //     var localCouponApplied = JSON.parse(localStorage.getItem("couponApplied"));
        //     var isCouponFound = null;
        //     if (extensionData && extensionData.data) {
        //         isCouponFound = localCouponApplied.find(coupon => { return coupon.coupon_code == extensionData.data.coupon_code })
        //     } //&& extensionData.data.length > 0 && extensionData.data.map(request => {

        //     if (isCouponFound) {
        //         this.setState({ common_Msg: "Coupon '" + isCouponFound.coupon_code + "' already exist!" });
        //     }
        //     setTimeout(function () {
        //         hideModal("common_ext_popup");
        //         showModal('common_msg_popup');
        //     }, 100)
        // }
        else if (appResponse == 'app-modificaiton-external' || appResponse == 'app-coupon_discount') {
            var _CARD_PRODUCT_LIST = localStorage.getItem('CARD_PRODUCT_LIST') ? JSON.parse(localStorage.getItem('CARD_PRODUCT_LIST')) : null;

            var updatedCartList = addtoCartProduct(_CARD_PRODUCT_LIST);
            dispatch(product());
            setTimeout(() => {
                var _price = 0;
                var _tax = 0;
                var _discount = 0;
                var _incltax = 0;
                var _excltax = 0;
                var checkList = JSON.parse(localStorage.getItem('CHECKLIST'));
                var _CARD_PRODUCT_LIST = localStorage.getItem('CARD_PRODUCT_LIST') ? JSON.parse(localStorage.getItem('CARD_PRODUCT_LIST')) : null;
                if (checkList != null && typeof checkList != "undefined") {
                    checkList.ListItem = _CARD_PRODUCT_LIST;
                    checkList.ListItem.map(items => {
                        if (items.Price) {
                            _price += parseFloat(items.Price);
                            _tax += parseFloat(items.excl_tax) + parseFloat(items.incl_tax);
                            _discount += parseFloat(items.discount_amount);
                            _incltax += parseFloat(items.incl_tax);
                            _excltax += parseFloat(items.excl_tax)
                        }
                    })
                   
                    const CheckoutList = {
                        ListItem: checkList.ListItem,
                        customerDetail: checkList.customerDetail,
                        totalPrice: (_price + _excltax) - _discount,
                        discountCalculated: _discount,
                        tax: _tax,
                        subTotal: _price - _discount,
                        TaxId: checkList.TaxId,
                        order_id: checkList.order_id !== 0 ? checkList.order_id : 0,
                        showTaxStaus: checkList.showTaxStaus,
                        _wc_points_redeemed: checkList._wc_points_redeemed,
                        _wc_amount_redeemed: checkList._wc_amount_redeemed,
                        _wc_points_logged_redemption: checkList._wc_points_logged_redemption
                    }
                    setTotal(RoundAmount( (_price + _excltax) - _discount));
                    setbalance( RoundAmount(  (_price + _excltax) - _discount))
                    setDiscount(_discount);
                    setTaxes(RoundAmount(_tax));
                  
                    localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList));
                    setCheckList(CheckoutList);
                }
               
                ToggleiFrameWindow(extApp, true);
            }, 600);

        }
        else if (appResponse == 'app-shipping-Address') {

            // setTimeout(() => {
                var chkList=JSON.parse(localStorage.getItem('CHECKLIST'))
                setCheckList(chkList);                
                ToggleiFrameWindow(extApp,true)
                  setTimeout(() => {
                    setCheckList(chkList);  
                    setValues(chkList.subTotal,chkList.tax,chkList.discountCalculated,chkList.totalPrice,parseFloat(chkList.total_shipping),chkList.shipping_tax)         
                     }, 400);
           // }, 400);

        }

    }
    function handleAppTransaction(RequesteData) {
        try {
            var data = RequesteData && RequesteData.data;
            //const { checkList } = this.state
            console.log("App Response: ",RequesteData)
            var _checkList = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
            if (data) {
                var type = data && data.processor ? data.processor : ''
                _checkList['transection_id'] = data && data.transaction_id ? data.transaction_id : ''
                var _amount = data && data.amount ? data.amount / 100 : 0
                var _emv = data && data.emv_data ? data.emv_data : ""
                var allEmvData = [];
                allEmvData = emvData ? emvData : [];
                if (_emv) {
                    var obj = {};
                    obj[type] = _emv;
                    allEmvData.push(obj)
                }

                setCheckList(_checkList);
                setEmvData(allEmvData)
                // set the current trnasaction status, Used for APP Command "TransactionStatus"
                localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": type, "status": "completed" }))
              
                setCheckList(_checkList);                    
                setTimeout(() => {                   
                     setPartialPayment(type, _amount,_checkList )
                        var clientJSON = {
                            command: RequesteData.command,
                            version: RequesteData.version,
                            method: RequesteData.method,
                            status: 200,
                        }
                        postmessage(clientJSON);
                }, 500);
            }
            else {                
                var clientJSON = {
                    command: RequesteData.command,
                    version: RequesteData.version,
                    method: RequesteData.method,
                    status: 406,
                    error :"payment failed"
                }               
                postmessage(clientJSON);
                console.error('App Error : Invalid Data');
            }
        } catch (error) {
            var clientJSON = {
                command: RequesteData.command,
                version: RequesteData.version,
                method: RequesteData.method,
                status: 406,
                error :"payment failed"
            }               
            postmessage(clientJSON);
            console.error('App Error : ', error);
        }
    }
    function handleAppPayment(RequesteData) {
        try {
            var data = RequesteData && RequesteData.data;
            // const { checkList } = this.state
            if (data && data.payment_type && data.payment_type.data) {
                var type = data && data.payment_type && data.payment_type.name ? data.payment_type.name : ''
                checkList['transection_id'] = data && data.payment_type && data.payment_type.data && data.payment_type.data.transaction_id ? data.payment_type.data.transaction_id : ''
                var _amount = data && data.payment_type && data.payment_type.data && data.payment_type.data.amt ? data.payment_type.data.amt : 0
                var _emv = data && data.payment_type && data.payment_type.data && data.payment_type.data.emv_data ? data.payment_type.data.emv_data : ""
                var allEmvData = [];
                allEmvData = emvData ? emvData : [];
                if (_emv) {
                    var obj = {};
                    obj[type] = _emv;
                    allEmvData.push(obj)
                }
                setCheckList(checkList);
                setEmvData(allEmvData)
                // set the current trnasaction status, Used for APP Command "TransactionStatus"
                localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": type, "status": "completed" }))

                setTimeout(() => {
                    setPartialPayment(type, _amount)
                    var clientJSON = {
                        command: RequesteData.command,
                        version: RequesteData.version,
                        method: RequesteData.method,
                        status: 200,
                    }
                    postmessage(clientJSON);
                }, 500);
            }
            else {
                console.error('App Error : Invalid Data');
            }
        } catch (error) {
            console.error('App Error : ', error);
        }
    }
    //-----------------------------------------------------
    const payRedeemAsDisocuntOrder=(isFullPayment,redeemdata=null)=>{       
        var list = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
        var data=pointRewardData ==null?redeemdata:pointRewardData; 
            var redeemAmount = data.points && data.points.amount && data.points.amount !== null ? parseFloat(data.points.amount) : 0;
            var redeemPoints = data.points && parseFloat(data.points.points);
            var discount_code = data.points && data.points.discount_code;

            //getting below field for reduce redeem tax amount from exclusive and inclusive----------------------------       
            var opr_tax_inclusive = data.points && data.points.opr_tax_inclusive;
            var redeem_amount_tax = data.points && data.points.amount_tax !== "" && opr_tax_inclusive && data.points.amount_tax ? parseFloat(data.points.amount_tax) : 0;
            //-------------------------------------------------------------------------------------------
            var redeemAmt = redeemAmount;// + ( parseFloat(redeem_amount_tax) * (opr_tax_inclusive == "inclusive" ? -1 : 1));
       
        var ListItem= list.ListItem;
        var discount_amount = redeemAmt ? redeemAmt : 0;
        var disocuntCart= localStorage.getItem("CART")? JSON.parse(localStorage.getItem("CART")):null;
        if(disocuntCart && disocuntCart !==null){
            if(disocuntCart.discountType=="Number"){
              discount_amount = parseFloat(discount_amount)+parseFloat(disocuntCart.discount_amount);
            }else{
                var percentCount= (redeemAmt *100)/list.subTotal;
                discount_amount = parseFloat(percentCount)+parseFloat(disocuntCart.discount_amount);
            }
            disocuntCart = {
                type: 'card',
                discountType:disocuntCart.discountType,
                discount_amount,
                Tax_rate: 0,
                real_type:disocuntCart.discountType
            }    
        }else{
            disocuntCart = {
                type: 'card',
                discountType:"Number",
                discount_amount,
                Tax_rate: 0,
                real_type:"Number"
            }    
        }
       
        localStorage.setItem("CART", JSON.stringify(disocuntCart))
        addtoCartProduct(ListItem);
        dispatch(product())


       // setTimeout(() => {
            var _subtotal = 0.0;
            var _total = 0.0;
            var _taxAmount = 0.0;
            var _totalDiscountedAmount = 0.0;
            var _customFee = 0.0;
            var _exclTax = 0;
            var _inclTax = 0;
            var _taxId = [];
            var _taxRate = [];
            var TaxIs = [];
            var _subtotalPrice = 0.00;
            var _subtotalDiscount = 0.00;
            var _cartDiscountAmount = 0.00;
            var _productDiscountAmount = 0.00;
            var _seprateDiscountAmount = 0.00;
            var taxratelist = [];
            if ((typeof localStorage.getItem('TAXT_RATE_LIST') !== 'undefined') && localStorage.getItem('TAXT_RATE_LIST') !== null) {
                taxratelist = localStorage.getItem('TAXT_RATE_LIST') && JSON.parse(localStorage.getItem('TAXT_RATE_LIST'));
            }
            if (taxratelist && taxratelist !== null && taxratelist !== "undefined") {
                taxratelist && taxratelist.length > 0 && taxratelist.map(tax => {
                    _taxId.push(tax.TaxId);
                    _taxRate.push(tax.TaxRate);
                    if (tax.check_is === true) {
                        TaxIs.push({ [tax.TaxId]: parseFloat(tax.TaxRate) })
                    }
                })
            
               // setTaxRate(_taxRate);
            }
           // _taxRate = taxRate;
    
           var card_productList= localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
           card_productList && card_productList.map((item, index) => {
                if (item.Price) {
                    _subtotalPrice += item.Price
                    _subtotalDiscount += parseFloat(item.discount_amount === null || isNaN(item.discount_amount) === true ? 0 : item.discount_amount)
                    //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
                    if (item.product_id || (item.hasOwnProperty("pid") && item.hasOwnProperty("modifiers_key") && item.hasOwnProperty("Price"))) {//donothing  
                        var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
                        _exclTax += item.excl_tax ? item.excl_tax : 0;
                        _inclTax += item.incl_tax ? item.incl_tax : 0;
                        _cartDiscountAmount += item.cart_discount_amount;
                        _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType === true ? 1 : item.quantity);
                    }
                    else {
                        _customFee += item.Price;
                        _exclTax += item.excl_tax ? item.excl_tax : 0;
                        _inclTax += item.incl_tax ? item.incl_tax : 0;
                    }
                }
            })    
    
            _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
            _subtotal = _subtotalPrice - _productDiscountAmount;
            _totalDiscountedAmount = _subtotalDiscount;
            if (_taxRate) {
                _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
            }
            _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);
             setTotal(RoundAmount(_total));
             setDiscount(_cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0);
             setTaxes(RoundAmount(_taxAmount));
            setbalance( RoundAmount( _total))//isFullPayment==true? 0:
           
          
            localStorage.setItem('extensionUpdateCart', true);
            setExtensionUpdateCart(true);
            var _totalRedeemPoint=list._wc_points_redeemed+ redeemPoints;
           var _totalRedeemAmount= list._wc_amount_redeemed+ redeemAmount;
            var _totalredeemTax=list._wc_tax_redeemed+ redeem_amount_tax;
            var redeemLog=list._wc_points_logged_redemption?list._wc_points_logged_redemption:[]; 
            redeemLog.push({
                "points": redeemPoints,
                "amount": redeemAmount,
                "discount_code": discount_code,
                "redeem_amount_tax": redeem_amount_tax,
                "opr_tax_inclusive": opr_tax_inclusive
            });
            const CheckoutList = {
                ListItem: card_productList,
                customerDetail: list.customerDetail,
                totalPrice: _total,
                discountCalculated: _totalDiscountedAmount,
                tax: _taxAmount ,
                subTotal: _subtotal,
                TaxId: list.TaxId,
                order_id: list.order_id !== 0 ? list.order_id : 0,
                showTaxStaus: list.showTaxStaus,
                _wc_points_redeemed: _totalRedeemPoint,
                _wc_amount_redeemed: _totalRedeemAmount,
                _wc_tax_redeemed: _totalredeemTax,
                _wc_points_logged_redemption:redeemLog
            }
          
           localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList));
           setCheckList(CheckoutList) ;   
           if(isFullPayment==true){
            setPayRedeem(true);
           }
            
        //}, 200);
       
    }

     //this used for redeeam amount only---------
    useEffect(()=>{ 
        if(payRedeem==true){
            setTimeout(() => {               
           var _payment= paymentTypeName.find(i=>i.Code =="card")             
               // console.log("checkList",checkList)
                setPaidAmount(0)                    
                pay_amount_cash(_payment);   
            localStorage.setItem("VOID_SALE", "void_sale");
                        
            setIsShowConfirmation(false);
           ToggleiFrameWindow(extApp,true);       
           setPayRedeem(false);       
        }, 500);
        }
       
    },[payRedeem])

   function redeemPointsExtentionCart(data) {
        try {          
            var redeemAmount = data.points && data.points.amount && data.points.amount !== null ? parseFloat(data.points.amount) : 0;
         
            //getting below field for reduce redeem tax amount from exclusive and inclusive----------------------------       
            var opr_tax_inclusive = data.points && data.points.opr_tax_inclusive;
            // var redeem_amount_tax = data.points && data.points.amount_tax !== "" && opr_tax_inclusive && opr_tax_inclusive == "exclusive" && data.points.amount_tax ? parseFloat(data.points.amount_tax) : 0;
            var redeem_amount_tax = data.points && data.points.amount_tax !== "" && opr_tax_inclusive && data.points.amount_tax ? parseFloat(data.points.amount_tax) : 0;
            //-------------------------------------------------------------------------------------------

            var list = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
            var redeemAmt = redeemAmount + ( parseFloat(redeem_amount_tax) * (opr_tax_inclusive == "inclusive" ? -1 : 1));
            var totalPrice = parseFloat(list.totalPrice) - parseFloat(redeemAmt);
            setPointRewardData(data);
            // check redeem points equal to order amount then set default store credit payment
           if (totalPrice <= 0) {    
            setPaidAmount(0);       
                setIsShowConfirmation(true)
                return;
           }
           else{
            payRedeemAsDisocuntOrder(false,data);
           }

           
        } catch (error) {
            console.error('App Error : ', error);
        }
    }

   function cancelRedeemedPointsExtentionCart(data) {
        try {
            var list = localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null;
            var discount_code = 0;
            if(list._wc_points_redeemed==0){
            return;
            }
            localStorage.setItem('extensionUpdateCart', true);

            localStorage.removeItem("CART")
            addtoCartProduct(list.ListItem);
            dispatch(product())
            setTimeout(() => {
              
                var _total = 0.0;
                var _taxAmount = 0.0;
                var _totalDiscountedAmount = 0.0;
                var _customFee = 0.0;
                var _exclTax = 0;
                var _inclTax = 0;             
                var _taxRate = [];              
                var _subtotalPrice = 0.00;
                var _subtotalDiscount = 0.00;
                var _cartDiscountAmount = 0.00;
                var _productDiscountAmount = 0.00;
                var _seprateDiscountAmount = 0.00;
            
               var card_productList= localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
               card_productList && card_productList.map((item, index) => {
                    if (item.Price) {
                        _subtotalPrice += item.Price
                        _subtotalDiscount += parseFloat(item.discount_amount === null || isNaN(item.discount_amount) === true ? 0 : item.discount_amount)
                        //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
                        if (item.product_id || (item.hasOwnProperty("pid") && item.hasOwnProperty("modifiers_key") && item.hasOwnProperty("Price"))) {//donothing  
                            var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
                            _exclTax += item.excl_tax ? item.excl_tax : 0;
                            _inclTax += item.incl_tax ? item.incl_tax : 0;
                            _cartDiscountAmount += item.cart_discount_amount;
                            // _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount:item.product_discount_amount; // quantity commment for addons
                            _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType === true ? 1 : item.quantity);
                        }
                        else {
                            _customFee += item.Price;
                            _exclTax += item.excl_tax ? item.excl_tax : 0;
                            _inclTax += item.incl_tax ? item.incl_tax : 0;
                        }
                    }
                })    
        
                _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
                _totalDiscountedAmount = _subtotalDiscount;
                if (_taxRate) {
                    _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
                }
                _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);               
                 setTotal(RoundAmount(_total));
                 setDiscount(_cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0);
                 setTaxes(RoundAmount(_taxAmount));
                setbalance( RoundAmount( _total));

                const CheckoutList = {
                    ListItem: card_productList,
                    customerDetail: list.customerDetail,
                    totalPrice: _total,
                    discountCalculated: parseFloat(list.discountCalculated) ,
                    tax: _taxAmount,// - redeemTax
                    subTotal: list.subTotal,
                    TaxId: list.TaxId,
                    order_id: list.order_id !== 0 ? list.order_id : 0,
                    showTaxStaus: list.showTaxStaus,
                    _wc_points_redeemed: 0,
                    _wc_amount_redeemed: 0,
                    _wc_points_logged_redemption: [{
                        "points": 0,
                        "amount": 0,
                        "discount_code": discount_code
                    }]
                }          
                localStorage.setItem('CHECKLIST', JSON.stringify(CheckoutList));        
                setCheckList( CheckoutList )
                ToggleiFrameWindow(extApp,true);    
            }, 200);
         
        } catch (error) {
            console.error('App Error : ', error);
        }

    }
   
    //  function use to check for payment types and perform action accordingly 
    const pay_amount = (paymentType, TerminalCount = 0, Support = '', paymentCode = '', paidConfirmAmount = 0) => {
        if (!localStorage.getItem("payType")) { localStorage.setItem("payType", "normal"); }
       
        var closingTab = '';
        if (paidConfirmAmount !== 0) {// set the achual payment done by payconiq
            setPaidAmount(paidConfirmAmount);
        }

        var payment_amount = partialAmount != null ? partialAmount : paidAmount ? paidAmount : 0;
        setPartialAmount(null);
        setPayment_Type(paymentType);

        
        const _getRemainingPrice = getRemainingPrice() ? getRemainingPrice() : 0;
        const _getRemainingPriceForCash = getRemainingPriceForCash();

        if (paymentType == paymentsType.typeName.cashPayment) {
            // var amount = paidAmount;// amount entered manually$('#calc_output_cash').val();
            var amount = partialAmount != null ? partialAmount : paidAmount ? paidAmount : 0;;
            payment_amount = amount
            if (payment_amount == 0) {
                setPaidAmount(parseFloat(RoundAmount(_getRemainingPrice)).toFixed(2));

                if (RoundAmount(parseFloat(_getRemainingPriceForCash)).toFixed(2) == 0) {
                    setOrderPartialPayments(0, paymentType);
                    // set the current trnasaction status, Used for APP Command "TransactionStatus"
                    localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": "cash", "status": "completed" }))
                } else {
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)
                }
            }
        } else {
            if (parseFloat(payment_amount) == 0) {
                setPaidAmount(parseFloat(RoundAmount(_getRemainingPrice)).toFixed(2));
                if (paymentType !== true && RoundAmount(parseFloat(_getRemainingPrice)) == 0) {
                    setOrderPartialPayments(0, paymentType);

                    // set the current trnasaction status, Used for APP Command "TransactionStatus"
                    localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": paymentType, "status": "completed" }))
                } else if (paymentType !== true) {
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)
                }
            }
        }
        setPayment_Type(paymentType);
        if (parseFloat(payment_amount) > 0) {
            if (paymentType !== true && paymentType !== paymentsType.typeName.cashPayment && TerminalCount == 0 && paymentType !== paymentsType.typeName.storeCredit && paymentType !== 'manual_global_payment' && Support !== paymentsType.typeName.Support && paymentType !== 'stripe_terminal' && Support !== paymentsType.typeName.UPISupport && paymentType != paymentsType.typeName.manualPayment) {
                // if (closingTab == false) {
                payment_amount = partialAmount != null ? partialAmount : parseFloat(RoundAmount(_getRemainingPrice));
                setPartialPayment(paymentType, payment_amount)
                }
            if (TerminalCount > 0 && paymentType !== 'manual_global_payment') {

                //var global_amount = (isMobileOnly == true) ? payment_amount : myInput;
                var global_amount = payment_amount;
                if (ActiveUser.key.isSelfcheckout == true) {
                    global_amount = !global_amount ? payment_amount : global_amount;
                }
                payment_amount = parseFloat(RoundAmount(_getRemainingPrice));
                if (parseFloat(global_amount) !== 0 && parseFloat(payment_amount) >= parseFloat(global_amount)) {
                  setIsPaymentStart(true);
                    // set the current trnasaction status, Used for APP Command "TransactionStatus"
                    localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": paymentType, "status": "processing" }))
                    globalPayments(paymentType, global_amount)
                } else if (parseFloat(global_amount) == 0) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)

                } else if (parseFloat(payment_amount) < parseFloat(global_amount)) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                }
            }

            if (paymentType == 'manual_global_payment') {
                var global_amount = payment_amount;
                global_amount = ActiveUser.key.isSelfcheckout == true ? payment_amount : global_amount
                var paymentMode = paymentType == "manual_global_payment" ? paymentCode : paymentType;
                payment_amount = parseFloat(RoundAmount(_getRemainingPrice));
                if (parseFloat(global_amount) !== 0 && parseFloat(payment_amount) >= parseFloat(global_amount)) {
                    setPartialPayment(paymentMode, global_amount)
                } else if (parseFloat(global_amount) == 0) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)

                } else if (parseFloat(payment_amount) < parseFloat(global_amount)) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                }
            }

            if (paymentType == paymentsType.typeName.storeCredit) {
                setPartialPayment(paymentType, payment_amount)
            }

            if (paymentType == paymentsType.typeName.cashPayment) {
                if (_getRemainingPriceForCash > 0) {
                    setPartialPayment(paymentType, payment_amount)
                } else if (_getRemainingPriceForCash == 0 && payment_amount > 0) {
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                } else {
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)
                }
            }

            // payamount for online manula card payment
            if (paymentType == paymentsType.typeName.manualPayment || Support == "Online") {

                //let online_amount = myInput;
                let online_amount = _getRemainingPrice;
                if (ActiveUser.key.isSelfcheckout == true) {
                    online_amount = !online_amount ? payment_amount : online_amount;
                }
                payment_amount = parseFloat(RoundAmount(_getRemainingPrice));
                if (parseFloat(online_amount) !== 0 && parseFloat(payment_amount) >= parseFloat(online_amount)) {
                    // this.setState({
                    //     isPaymentStart: true
                    // })
                    setIsPaymentStart(true);

                    var _tempOnlinePayCardData = onlinePayCardData;
                    setOnlinePayCardData(_tempOnlinePayCardData);

                    onlineCardPayments(paymentType, online_amount)
                } else if (parseFloat(online_amount) == 0) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)

                } else if (parseFloat(payment_amount) < parseFloat(online_amount)) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                }
            }

            // payamount forStripe payment

            if (paymentType == 'stripe_terminal') {
                var stripePayAmount = myInput;
                stripePayAmount = ActiveUser.key.isSelfcheckout == true ? payment_amount : stripePayAmount
                var paymentMode = paymentType;
                payment_amount = parseFloat(RoundAmount(_getRemainingPrice));
                if (parseFloat(stripePayAmount) !== 0 && parseFloat(payment_amount) >= parseFloat(stripePayAmount)) {
                    setPartialPayment(paymentMode, stripePayAmount)
                } else if (parseFloat(stripePayAmount) == 0) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)

                } else if (parseFloat(payment_amount) < parseFloat(stripePayAmount)) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                }
            }
            if (Support == paymentsType.typeName.UPISupport) {
                var payconiqAmount = payment_amount;// myInput;
                payconiqAmount = ActiveUser.key.isSelfcheckout == true ? payment_amount : payconiqAmount
                var paymentMode = paymentType;
                payment_amount = parseFloat(RoundAmount(_getRemainingPrice));
                if (parseFloat(payconiqAmount) !== 0 && parseFloat(payment_amount) >= parseFloat(payconiqAmount)) {
                    setPartialPayment(paymentMode, payconiqAmount)
                } else if (parseFloat(payconiqAmount) == 0) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.zeroPaymentMsg)

                } else if (parseFloat(payment_amount) < parseFloat(payconiqAmount)) {
                    setPaidAmount(parseFloat(payment_amount).toFixed(2));
                    extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                }
            }

        }
    }
    const activeForCash = (st) => {
        setActiveCash(st);

    }
    const setPartialPayment = (paymentType, paymentAmount, _checklist=null) => {
        var checkList= checkList?checkList: JSON.parse(localStorage.getItem("CHECKLIST"));;
        if(_checklist && _checklist !==null){
            checkList=_checklist;
            setCheckList(_checklist);
        }
        activeForCash(false);
        var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
        var paid_amount = 0;
        setPaidAmount(0);
        if (getPayments !== null) {
            getPayments.forEach(paid_payments => {
                paid_amount += parseFloat(paid_payments.payment_amount);
            });
        }
        var remianAmount = parseFloat(checkList.totalPrice) - parseFloat(paid_amount + parseFloat(paymentAmount));

        if (typeof paymentType !== 'undefined') {
            if (parseFloat(paymentAmount) <= 0) {
                setPaidAmount(parseFloat(RoundAmount(remianAmount)).toFixed(2));
                extraPayAmount(LocalizedLanguage.zeroPaymentMsg)
            } else if (parseFloat(RoundAmount(checkList.totalPrice)) >= parseFloat(parseFloat(paid_amount + parseFloat(paymentAmount))) && paymentType !== paymentsType.typeName.cashPayment) {
               
                setPaidAmount(parseFloat(RoundAmount(remianAmount)).toFixed(2));
                setLoading(true);
                setOrderPartialPayments(parseFloat(paymentAmount), paymentType,_checklist);
                            } else if (paymentType == paymentsType.typeName.cashPayment) {
                setPaidAmount(parseFloat(RoundAmount(remianAmount)).toFixed(2));
                setLoading(true);
                setOrderPartialPayments(parseFloat(paymentAmount), paymentType);
                            } else if (parseFloat(checkList.totalPrice) == parseFloat(RoundAmount(parseFloat(paid_amount) + parseFloat(paymentAmount)))) {
                setPaidAmount(parseFloat(RoundAmount(remianAmount)).toFixed(2));
                setLoading(true);
                setOrderPartialPayments(parseFloat(paymentAmount), paymentType);
                            } else if (parseFloat(checkList.totalPrice) < parseFloat(parseFloat(paid_amount) + parseFloat(paymentAmount))) {
                setPaidAmount(parseFloat(RoundAmount(checkList.totalPrice - paid_amount)).toFixed(2));
                extraPayAmount(LocalizedLanguage.amountNotExceedMsg)
                            } else if (RoundAmount(parseFloat(checkList.totalPrice)) == parseFloat(RoundAmount(parseFloat(paid_amount) + parseFloat(paymentAmount)))) {
                setPaidAmount(0);
                setLoading(true);
                setOrderPartialPayments(parseFloat(paymentAmount), paymentType);
                            }
                   } else {
            setLoading(false);
            extraPayAmount(LocalizedLanguage.validModeMsg)
                  }
    }
    const globalPayments = (paycode, amount) => {
        var UID = get_UDid('UDID');
        setPaidAmount(amount);
        setGlobal_Payments(paycode);
        dispatch(getMakePayment(UID, localStorage.getItem('register'), paycode, amount, "sale"))

    }
    const [respmakeOnlinePayments] = useSelector((state) => [state.makeOnlinePayments])
    useEffect(() => {
        if (respmakeOnlinePayments && respmakeOnlinePayments.status == STATUSES.IDLE && respmakeOnlinePayments.is_success && respmakeOnlinePayments.data && loading == true) {
            
            if (respmakeOnlinePayments.data.content.RefranseCode && respmakeOnlinePayments.data.content.IsSuccess == true)
            {
                // set the current trnasaction status, Used for APP Command "TransactionStatus"
                localStorage.setItem("CurrentTransactionStatus", JSON.stringify({ "paymentType": onlinePayments, "status": "completed" }))
                //Set the actual amount paid from card
                var _paidAmount = paidAmount;
                if (respmakeOnlinePayments.data.content.Amount && respmakeOnlinePayments.data.content.Amount !== "") {
                    _paidAmount = parseFloat(respmakeOnlinePayments.data.content.Amount) / 100
                    setPaidAmount(_paidAmount)
                }
                setPartialPayment(onlinePayments, _paidAmount)
                toggleManualPayment();
            }
            setTimeout(() => {
                dispatch(makeOnlinePayments("clear"));
            }, 200);

            if (getRemainingPriceForCash() > 0) {
                setLoading(false);
            }

        }
        else if (respmakeOnlinePayments && (respmakeOnlinePayments.status == STATUSES.IDLE || respmakeOnlinePayments.status == STATUSES.ERROR) && respmakeOnlinePayments.is_success == false && loading == true && respmakeOnlinePayments.message != "") {
            setLoading(false);
            var data = { title: "", msg: respmakeOnlinePayments.error, is_success: true }
            dispatch(popupMessage(data));
        }
    }, [respmakeOnlinePayments])

    // handle online payment payamount
    const onlineCardPayments = (paycode, amount) => {
        setPaidAmount(amount);
        setOnlinePayments(paycode);
        setLoading(true);
        dispatch(makeOnlinePayments(onlinePayCardData))
    }
    const extraPayAmount = (msg) => {

        var data = { title: "", msg: msg, is_success: true }
        dispatch(popupMessage(data));
        setLoading(false);
    }
    const paymentType = (type) => {
        // var totalPrice = this.state.checkList && this.state.checkList.totalPrice;
        // var cash_round = parseFloat(this.getRemainingPriceForCash())
        // if (type == 'cash') {
        //     this.state.cash_round = cash_round;
        //     this.setState({
        //         cash_round: cash_round
        //     })
        // } else {
        //     this.state.cash_round = 0;
        //     this.setState({
        //         cash_round: 0,
        //         total_price: 0
        //     })
        // }

    }
    const finalAdd = () => {
        // if (isMobileOnly == true) {
        //     $("#popup_cash_rounding").removeClass("show")
        //     hideModal('popup_cash_rounding');
        // }
        //const { checkList, total_price, cash_round } = this.state;
        var after_payment_is = 0;
        var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
        if (getPayments !== null) {
            getPayments.forEach(paid_payments => {
                after_payment_is += parseFloat(paid_payments.payment_amount);
            });
        }
        var totalPrice = checkList && checkList.totalPrice;
        var order_id = (typeof checkList.order_id !== "undefined") ? checkList.order_id : 0;
        var cash_round_is = parseFloat(getRemainingPriceForCash())
        if (cash_round_is == (totalPrice - after_payment_is) || totalPrice == 0) {
            cash_round_is = 0;
        }
        //this.setState({ cash_round: cash_round_is })
        setCashRound(cash_round_is);
        //cashRound = cash_round_is;
        if (localStorage.getItem("oliver_order_payments")) {
            var payments = JSON.parse(localStorage.getItem("oliver_order_payments"));
            payments.push({
                "Id": 0,
                "payment_type": 'cash',
                "payment_amount": RoundAmount((totalPrice + cash_round_is) - after_payment_is),
                "order_id": order_id,
                "description": ''
            });
            localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
        } else {

            var payments = new Array();
            payments.push({
                "Id": 0,
                "payment_type": 'cash',
                "payment_amount": RoundAmount(totalPrice + cashRound),
                "order_id": order_id,
                "description": ''
            });
            localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
            localStorage.setItem("VOID_SALE", "void_sale");
            //this.props.dispatch(checkoutActions.changeStatusSaleToVoid("void_sale"));
        }
        setLoading(false);
        getPaymentDetails();
        isOrderPaymentComplete(order_id, cash_round_is);

    }
    // its set the order payments
    const setOrderPartialPayments = (paying_amount, payment_type,_checklist=null) => {
        var checkList=checkList?checkList:JSON.parse(localStorage.getItem('CHECKLIST'));
        if(_checklist && _checklist !==null){
            checkList=_checklist;
            setCheckList(_checklist);
        }
        if (localStorage.getItem("paybyproduct_unpaid")) {

            var _pbpun = JSON.parse(localStorage.getItem("paybyproduct_unpaid"))
            _pbpun.forEach(function (v) { v.hasOwnProperty("unpaid_qty") && delete v.unpaid_qty });

            localStorage.setItem("paybyproduct", JSON.stringify(_pbpun));
            localStorage.removeItem("paybyproduct_unpaid");
            // if (!localStorage.getItem("payType")) { 
            localStorage.setItem("payType", "byproduct");
            // }
        }

        //var checkList = JSON.parse(localStorage.getItem('CHECKLIST'));
        //var _totalPrice = checkList && checkList.totalPrice
        var change_amount = 0;
        var payment_is = 0;
        var order_id = checkList && checkList.order_id !== 0 ? checkList && checkList.order_id : 0;
        var transection_id = checkList && checkList.transection_id !== 0 ? checkList && checkList.transection_id : 0;
        if (checkList && checkList.transection_id) { //fix the issue same transation id should not used in other payment type 
            var temp_checkList = checkList;
            temp_checkList.transection_id = 0;
            setCheckList(checkList);
            //checkList.transection_id = 0;
        }
        var actual_amount = totalPrice == 0 ? checkList && checkList.totalPrice : totalPrice;
        var _getRemainingPriceForCash = parseFloat(getRemainingPriceForCash())
        if (_getRemainingPriceForCash == actual_amount || paying_amount == 0) {
            _getRemainingPriceForCash = 0;
        }
        var check_required_field = false;
        var paymentTypeName = localStorage.getItem("PAYMENT_TYPE_NAME") && JSON.parse(localStorage.getItem("PAYMENT_TYPE_NAME"))
        var isGlobalPay = false;
        paymentTypeName.map((pay_name, index) => {
            if (pay_name.Code !== "cash" && payment_type !== 'stripe_terminal' && pay_name.Code == payment_type && (pay_name.HasTerminal == true && pay_name.TerminalCount > 0)) {
                isGlobalPay = true;
            }
        });
        var isOnline = false;
        paymentTypeName.map((pay_name, index) => {
            if (pay_name.Support == 'Online' && pay_name.Code == payment_type) {
                isOnline = true;
            }
        });

        var isUPIPayment = false;
        paymentTypeName.map((pay_name, index) => {
            if (pay_name.Support == paymentsType.typeName.UPISupport && pay_name.Code == payment_type) {
                isUPIPayment = true;
            }
        });
        if (typeof (Storage) !== "undefined") {
            var payments = localStorage.getItem("oliver_order_payments") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
            // cash payment 
            if (payment_type == 'cash') {
                if (localStorage.getItem("oliver_order_payments")) {
                    payments = JSON.parse(localStorage.getItem("oliver_order_payments"));
                    payments.map((items) => {
                        return payment_is += parseFloat(items.payment_amount);
                    })
                    var ret = payment_is;
                    var total_pay = ret + parseFloat(paying_amount);
                    //setCashRound(_getRemainingPriceForCash);
                    //cashRound = cash_round;
                    paying_amount = paying_amount ? String(paying_amount).replace(',', '') : 0;
                    var paidAmount = cashRound == 0 ? actual_amount : _getRemainingPriceForCash;
                    //if (total_pay > parseFloat(RoundAmount(payment_is+cash_round))) {
                    if (total_pay > parseFloat(RoundAmount(payment_is + parseFloat(paidAmount)))) {
                        //if (total_pay > parseFloat(RoundAmount(payment_is+parseFloat(paying_amount)))) {
                        change_amount = parseFloat(total_pay) - (paidAmount + payment_is);
                        // this.setState({
                        //     change_amount: change_amount,
                        //     cash_payment: paying_amount,
                        //     after_payment_is: payment_is
                        // })
                        setChangeAmount(change_amount);
                        setCashPayment(paying_amount);
                        setAfterPaymentIs(payment_is);

                        dispatch(changeReturnAmount({ "cashPayment": paying_amount, "change": parseFloat(paying_amount) - parseFloat(actual_amount) }));
                        //make payment while enter amount is greater than total price
                        //finalAdd();
                        //---------- 


                        // this.freezScreen();
                        // if (isMobileOnly == true) {
                        //     $('#popup_cash_rounding').addClass('show')
                        // }
                        // //$('#popup_cash_rounding').modal('show')
                        // setTimeout(function () {
                        //     showModal('popup_cash_rounding');
                        // }, 100)

                        //show message if payment amount is greater than total amount

                    } else {
                        if (paying_amount && paying_amount !== null)
                            payments.push({
                                "Id": 0,
                                "payment_type": payment_type,
                                "payment_amount": String(paying_amount).replace(',', ''),
                                "order_id": order_id,
                                "description": '',
                                "transection_id": transection_id
                            });
                        localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                        //this.gtmEvent(payments);
                        getPaymentDetails();
                        isOrderPaymentComplete(order_id, _getRemainingPriceForCash);
                    }

                }
                else {
                    payments = new Array();
                   // setCashRound(cash_round);
                    //cashRound = cash_round;
                    paying_amount = paying_amount ? String(paying_amount).replace(',', '') : 0.00;
                    var paidAmount = _getRemainingPriceForCash == 0 ? actual_amount : _getRemainingPriceForCash;
                    if (parseFloat(paying_amount) > parseFloat(RoundAmount(paidAmount))) {
                        change_amount = paying_amount - (paidAmount);
                        // this.setState({
                        //     change_amount: change_amount,
                        //     cash_payment: paying_amount,
                        //     after_payment_is: 0
                        // })

                        setChangeAmount(change_amount);
                        setCashPayment(paying_amount);
                        setAfterPaymentIs(0);
                        dispatch(changeReturnAmount({ "cashPayment": paying_amount, "change": parseFloat(paying_amount) - parseFloat(actual_amount) }));
                        //dispatch(changeReturnAmount({ "cashPayment": paying_amount, "change": change_amount }));
                        //make payment while enter amount is greater than total price
                        //finalAdd();


                        //show message if payment amount is greater than total amount

                        // this.freezScreen();
                        // if (isMobileOnly == true) {
                        //     $('#popup_cash_rounding').addClass('show')
                        // }
                        // // $('#popup_cash_rounding').modal('show')

                        // setTimeout(function () {
                        //     showModal('popup_cash_rounding');
                        // }, 100)
                    }
                    else {
                        if (paying_amount) {
                            payments.push({
                                "Id": 0,
                                "payment_type": payment_type,
                                "payment_amount": String(paying_amount).replace(',', ''),
                                "order_id": order_id,
                                "description": "",
                                "transection_id": transection_id
                            });
                        }
                        localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                        //this.gtmEvent(payments);
                        getPaymentDetails();
                        isOrderPaymentComplete(order_id, _getRemainingPriceForCash);
                    }

                }
            }
            //  global  payments
            else if (isGlobalPay == true) {
                var g_payment = global_payment ? global_payment : localStorage.getItem('GLOBAL_PAYMENT_RESPONSE') && localStorage.getItem('GLOBAL_PAYMENT_RESPONSE') !== 'undefined' && JSON.parse(localStorage.getItem('GLOBAL_PAYMENT_RESPONSE'));
                if (g_payment && g_payment !== null && g_payment.is_success === true) {
                    var global_payments = g_payment.content;
                    var data = `TerminalId-${global_payments.TerminalId} , Authrization-${global_payments.Authrization},RefranseCode-${global_payments.RefranseCode}`;

                    if (paying_amount && data)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": data,
                            "transection_id": global_payments.RefranseCode ? global_payments.RefranseCode : transection_id
                        });
                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    //this.gtmEvent(payments);
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                } else {
                    if (paying_amount)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": "",
                            "transection_id": transection_id
                        });
                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    //(payments);
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                }
            }
            // online payments
            else if (isOnline == true) {
                let g_payment = localStorage.getItem('ONLINE_PAYMENT_RESPONSE') && localStorage.getItem('ONLINE_PAYMENT_RESPONSE') !== 'undefined' ? JSON.parse(localStorage.getItem('ONLINE_PAYMENT_RESPONSE')) : null
                if (g_payment !== null && g_payment.is_success === true) {
                    let olnine_payments = g_payment.content;
                    let data = `RefTransID-${olnine_payments.RefranseCode},Authrization-${olnine_payments.Authrization} ,RefranseCode-${olnine_payments.RefranseCode}`;
                    if (paying_amount && data)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": data,
                            "transection_id": olnine_payments.RefranseCode ? olnine_payments.RefranseCode : transection_id
                        });

                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                } else {
                    if (paying_amount)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": "",
                            "transection_id": transection_id
                        });
                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                }
            }
            // payconiq payments
            else if (isUPIPayment) {
                let payconiq_pay = localStorage.getItem('PAYCONIQ_PAYMENT_RESPONSE') && localStorage.getItem('PAYCONIQ_PAYMENT_RESPONSE') !== 'undefined' ? JSON.parse(localStorage.getItem('PAYCONIQ_PAYMENT_RESPONSE')) : null
                if (payconiq_pay !== null && payconiq_pay.is_success === true) {
                    let payconiqPaymentData = payconiq_pay.content;
                    let data = `RefTransID-${payconiqPaymentData.RefrenceID},Authrization-${payconiqPaymentData.RefrenceID} ,RefranseCode-${payconiqPaymentData.RefrenceID}`;
                    if (paying_amount && data)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": data,
                            "transection_id": payconiqPaymentData.RefrenceID ? payconiqPaymentData.RefrenceID : transection_id
                        });

                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                } else {
                    if (paying_amount)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": "",
                            "transection_id": transection_id
                        });
                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                }
            }
            // Stripe terminal
            else if (payment_type == 'stripe_terminal') {
                let s_payment = localStorage.getItem('STRIPE_PAYMENT_RESPONSE') && localStorage.getItem('STRIPE_PAYMENT_RESPONSE') !== 'undefined' ? JSON.parse(localStorage.getItem('STRIPE_PAYMENT_RESPONSE')) : null
                if (s_payment && s_payment.is_success) {
                    let stripe_payments = s_payment.content;
                    let data = `RefTransID-${stripe_payments.RefranseCode},Authrization-${stripe_payments.RefranseCode} ,RefranseCode-${stripe_payments.RefranseCode}`;
                    if (paying_amount && data)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": data,
                            "transection_id": stripe_payments.RefranseCode ? stripe_payments.RefranseCode : transection_id
                        });

                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                   getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                } else {
                    if (paying_amount)
                        payments.push({
                            "Id": 0,
                            "payment_type": payment_type,
                            "payment_amount": String(paying_amount).replace(',', ''),
                            "order_id": order_id,
                            "description": "",
                            "transection_id": transection_id
                        });
                    localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                    getPaymentDetails();
                    isOrderPaymentComplete(order_id, 0);
                }
            }
            // other payments
            else {
                if (paying_amount) {
                    var _emvData = emvData
                    var emvPaymentdata = ""
                    if (_emvData && _emvData.length > 0) {
                        _emvData.map(item => {
                            emvPaymentdata = item[payment_type]
                        });
                    }
                    payments.push({
                        "Id": 0,
                        "payment_type": payment_type,
                        "payment_amount": String(paying_amount).replace(',', ''),
                        "order_id": order_id,
                        "description": "",
                        "transection_id": transection_id,
                        "emv_data": emvPaymentdata
                    });
                }


                localStorage.setItem("oliver_order_payments", JSON.stringify(payments));
                localStorage.setItem("payType", JSON.stringify(payment_type));
                getPaymentDetails();
                isOrderPaymentComplete(order_id, 0,_checklist);
            }
            // ***  ***//      
            localStorage.setItem("VOID_SALE", "void_sale");
        } else {
            //alert("Your browser not support local storage");
        }
    }

    const getPaymentDetails = () => {
        var paid_amount = 0.0;
        var payments = new Array();
        if (checkList && checkList !== null && checkList.totalPrice !== null) {
            setTotalPrice(totalPrice);
        }
        if (localStorage.getItem("oliver_order_payments") && checkList && checkList.totalPrice !== null && checkList.totalPrice) {
            var getPayments = (typeof JSON.parse(localStorage.getItem("oliver_order_payments")) !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : new Array();
            getPayments.forEach(paid_payments => {
                paid_amount += parseFloat(paid_payments.payment_amount);
                payments.push({
                    "payment_type": paid_payments.payment_type,
                    "payment_amount": paid_payments.payment_amount,
                });
            });

            setTotalPrice(checkList.totalPrice - paid_amount);
            setCount(payments.length);
            setPaymentsArr(payments);
            setPaidAmount(paid_amount);
            
            if (checkList.totalPrice >= paid_amount) {
                setCalculatorRemainingprice(paidAmount);
            } else {
                setCalculatorRemainingprice(0);
            }
        }
    }

    const setCalculatorRemainingprice = (amount) => {
        set_set_calculator_remaining_amount(amount);
        }
    // its check the order paymets complete or not
    const isOrderPaymentComplete = (order_id, cash_round1,_checkList=null) => {
        var checkList=checkList?checkList:JSON.parse(localStorage.getItem('CHECKLIST'));
        if(_checkList && _checkList !==null){
            checkList=_checkList;
            setCheckList(_checkList);
        }
         var amountToBePaid = checkList && (typeof checkList !== 'undefined') ? parseFloat(checkList.totalPrice) : 0;
        var paidPaments = getOrderPayments(order_id);
        var paidAmount = 0;
        var cash_round = cashRound;
        paidPaments && paidPaments.forEach(paid_payments => {
            paidAmount += parseFloat(paid_payments.payment_amount);
        });
        if (cash_round > 0) {
            if (parseFloat(RoundAmount(amountToBePaid + cash_round)) == parseFloat(RoundAmount(paidAmount))) {
                createOrder("completed");
            } else if (parseFloat(amountToBePaid) <= parseFloat(paidAmount)) {
                createOrder("completed");
            } else if (parseFloat(RoundAmount(amountToBePaid)) <= parseFloat(RoundAmount(paidAmount))) {
                createOrder("completed");
            }
            else { setLoading(false); }

        } else {
            if (parseFloat(RoundAmount(amountToBePaid + cash_round)) <= parseFloat(RoundAmount(paidAmount))) {
                createOrder("completed");
            }
            else { setLoading(false); }
        }

    }
    const createOrder = (status) => {
        setLoading(true);
        setPayment(status);
    }
    const setPayment = (get_order_status, updatedBy = "") => {
        var location_id = localStorage.getItem('Location');
        const activityToCheckout = localStorage.getItem("BACK_CHECKOUT");
        var checkList = JSON.parse(localStorage.getItem('CHECKLIST'));
        setCheckList(JSON.parse(localStorage.getItem("CHECKLIST")));
        
        //Create new receipt Id-----------
        const TwoDigitRId = register_Id.toString().padStart(2, '0');
        const TwoDigitLId = location_id.toString().padStart(2, '0');
        const TwelveDigitDateUtc = Math.floor(Date.now() / 1000).toString();
        const ReciptId = `${TwoDigitLId}${TwoDigitRId}${TwelveDigitDateUtc}`;
       

        var customer = checkList && checkList.customerDetail && checkList.customerDetail;
        var place_order;
        var order_payments = [];
        var newList = [];
        var order_custom_fee = [];
        var order_custom_fee_meta = [];
        var order_notes = [];
        var totalPrice = checkList && checkList.totalPrice;
        var order_id = checkList && (typeof checkList.order_id !== "undefined") && checkList.order_id !== null && checkList.order_id !== 0 ? checkList.order_id : 0;
        var oliver_pos_receipt_id = checkList && (typeof checkList.oliver_pos_receipt_id !== "undefined") && checkList.oliver_pos_receipt_id !== 0 && checkList.oliver_pos_receipt_id !=="" ? checkList.oliver_pos_receipt_id : ReciptId;
        localStorage.setItem('tempOrder_Id', JSON.stringify(oliver_pos_receipt_id));
        var status = get_order_status;
        var storeCredit = customer ? customer.store_credit : 0;
        var paidAmount = 0;
        var managerData = JSON.parse(localStorage.getItem('user'));
        var manager_name = '';
        var ListItem = null;
        var discountIs = 0;
        var update_local_payment = [];
        var order_meta = [];
        var oliver_order_payments = getOrderPayments(order_id);
        var redeemAmount = checkList && checkList._wc_amount_redeemed ? parseFloat(checkList._wc_amount_redeemed) : 0;
        var productXArray = localStorage.getItem("PRODUCTX_DATA") ? JSON.parse(localStorage.getItem("PRODUCTX_DATA")) : "";
        var checkoutProductList = [];
        var productxAddons = {}
        // add extention field in meta data
        const productX = productXArray && productXArray.length > 0 ? [...new Map(productXArray.map(item =>
            [item['strProductX'], item])).values()] : "";
        //for uniqness we used strProductX instead of product_id

        productX && productX.map((prodX) => {
            //productxAddons[prodX.product_id] = prodX.addons
            var _detail = prodX.details ? prodX.details : [];

            //for addOns 
            if (prodX.addons && prodX.addons.length > 0) {
                _detail = [..._detail, ...getAddonsField(prodX)];
                prodX["details"] = _detail;
            }
            //for Measurement 
            if (prodX.addons && prodX.pricing_item_meta_data) {
                _detail = [..._detail, ...getAddonsField(prodX)];
                prodX["details"] = _detail;
            }
            //for booking
            if (prodX.booking) {
                _detail = [..._detail, ...getBookingField(prodX)];
                prodX["details"] = _detail;
            }
        })

        // add Tip to order notes
        var tipAmount = 0;
        var tipPaymentRefrence = ''
        var tipNoteData = localStorage.getItem('PAYMENT_RESPONSE') ? JSON.parse(localStorage.getItem('PAYMENT_RESPONSE')) : null
        if (tipNoteData && tipNoteData.is_success == true && tipNoteData.content && tipNoteData.content.Tip) {
            tipAmount = tipNoteData.content.Tip && tipNoteData.content.Tip > 0 ? (tipNoteData.content.Tip / 100) : 0
            tipPaymentRefrence = tipNoteData.content.RefranseCode;
            // order_notes.push({
            //     note_id: '',
            //     note: `Tip : ${tipAmount}`,
            //     is_customer_note: 1,
            // })
            var tipPercent = 0;
            if (checkList.totalPrice && checkList.totalPrice != 0 && tipAmount != 0) {
                tipPercent = (tipAmount / checkList.totalPrice) * 100;
            }
            var customfeeData = {
                fee_id: '',
                Title: `Tip (${parseFloat(tipPercent).toFixed(2)}%)`,
                amount: tipAmount,
                Price: tipAmount
            }
            checkList.ListItem.push(customfeeData)

        }
        if (typeof oliver_order_payments !== "undefined") {
            var _paymentNotes = [];
            oliver_order_payments.map(items => {
                var amountForPayType = 0.0;
                if (tipPaymentRefrence && tipPaymentRefrence !== '' && tipPaymentRefrence == items.transection_id) {
                    //Add tip amount into payment amount..
                    paidAmount += parseFloat(items.payment_amount) + parseFloat(tipAmount);
                    amountForPayType = parseFloat(items.payment_amount) + parseFloat(tipAmount);
                } else {
                    paidAmount += parseFloat(items.payment_amount);
                    amountForPayType = parseFloat(items.payment_amount);
                }

                var _paymentDetail = {
                    "Id": (typeof items.Id !== "undefined") ? items.Id : 0,
                    "amount": amountForPayType,
                    "payment_type": "order",
                    "type": items.payment_type,
                    "description": (typeof items.Id !== "undefined") ? items.description : '',
                    "transection_id": items.transection_id && items.transection_id !== 0 ? (items.transection_id).toString() : "",
                    "emv_data": items.emv_data ? items.emv_data : ""
                }
                if (items.payment_date) {
                    _paymentDetail["payment_date"] = items.payment_date;
                }
                order_payments.push(_paymentDetail);

                //  add order notes for payments type and transaction id 
                var transactionStr = items.transection_id && items.transection_id !== 0 ? `(${(items.transection_id).toString()})` : ""
                _paymentNotes.push(`${items.payment_type}${transactionStr}`)

            })
            if (_paymentNotes.length > 0) { }
            order_notes.push({
                note_id: '',
                note: `Payment done with: ${_paymentNotes.join(", ")}`,
                is_customer_note: 0,
                is_extension_note: true
            })
        }

        // add extension's notes for order to order_notes field
        //Need to display into receipt so make the is_customer_note=ture
        if (extensionOrderNote && extensionOrderNote.length) {

            extensionOrderNote.map((itm, ind) => {
                order_notes.push({
                    note_id: '',
                    note: itm,
                    is_customer_note: true,
                    is_extension_note: false
                })
            });

        }





        // update payments for place order
        setCashRound(getRemainingPriceForCash());
        //cashRound = getRemainingPriceForCash();
        if (cashRound > 0) {
            var length_is = order_payments.length - 1;
            var update_rounding_amount = parseFloat(totalPrice + cashRound) - parseFloat(paidAmount);
            if (order_payments && typeof order_payments[length_is] != "undefined" && order_payments[length_is].hasOwnProperty("amount")) {
                var new_amount = parseFloat(order_payments[length_is].amount);
                if (order_payments[length_is].type == "cash" && cashRound <= 1) {
                    order_payments[length_is].amount = parseFloat(parseFloat(new_amount) + parseFloat(RoundAmount(update_rounding_amount)))
                    setCashRound(0);
                    //cashRound = 0;
                }
            }
        }

        // update loacal staorage for show payment in rounded amount
        if (order_payments) {
            order_payments.map(items => {
                var _paymentDetail = {
                    "Id": items.Id,
                    "payment_type": items.type,
                    "payment_amount": items.amount,
                    "order_id": order_id,
                    "description": items.description,
                    "emv_data": items.emv_data
                };
                if (items.payment_date) {
                    _paymentDetail["payment_date"] = items.payment_date;
                }
                update_local_payment.push(_paymentDetail)
            })
        }
        ListItem = checkList && checkList.ListItem;
        localStorage.setItem('oliver_order_payments', JSON.stringify(update_local_payment))
        //discountIs = checkList && checkList.discountCalculated ? getDiscountAmount(ListItem) : 0;    note: generate wrong calc when product and cart discount applied
        discountIs = checkList && checkList.discountCalculated ? checkList.discountCalculated : 0; // getDiscountAmount(ListItem)
        if (extensionUpdateCart == true) {
            getExtensionCheckoutList(ListItem);
            if (productX) { getProductxChlidProductTax(productX, AllProductList) }
        } else if (activityToCheckout == "true") {
            setListActivityToCheckout(ListItem)
        } else {
            getCheckoutList(ListItem);
            if (productX) { getProductxChlidProductTax(productX, AllProductList) }
        }
        var SingleTax = checkList && checkList.TaxId && checkList.TaxId.length > 0 ? checkList.TaxId[0] : '';
        var _tax_id = SingleTax ? Object.keys(SingleTax)[0] : 0;
        var _subtotal_tax = 0;
        var _total_tax = 0;

        //remove addons from list and add addons price into the main prodcit price
        if (ListItem !== null && ListItem.length > 0) {
            var addons=[];
            ListItem.map((items, i) => {
                if(items.hasOwnProperty("product_id")){
                    let addonsMeta=[];
                    var product_addons= ListItem.filter(lm=>( ! lm.hasOwnProperty("product_id") && lm.hasOwnProperty("addonId") && lm.pid==items.product_id ))
                    if (product_addons && product_addons.length>0) {
                       let arr=[...addons,...product_addons];
                        addons = [...new Set(arr)] ;                      
                        product_addons.map(ad=>{
                            addonsMeta.push({name:ad.name , price:ad.Price, value:ad.value})
                            items.Price += ad.Price;                         
                            items.after_discount+=ad.after_discount;
                            items.cart_after_discount +=ad.cart_after_discount;
                            items.cart_discount_amount +=ad.cart_discount_amount;                          
                            items.excl_tax +=ad.excl_tax;
                            items.incl_tax+=ad.incl_tax;                       
                            items.old_price +=ad.old_price;
                            items.product_after_discount +=ad.product_after_discount;
                            items.product_discount_amount +=ad.product_discount_amount;                           
                            items.subtotal+=ad.subtotal;
                            items.total+=ad.total;                            
                           // items.subtotal_tax +=ad.subtotal_tax;
                            items.subtotal_tax.map(tp=>{
                                for (var k in tp) {                                   
                                    ad.subtotal_tax.map(tad=>{
                                        for (var i in tad) {
                                            if(i==k){
                                                tp[k]=tp[k]+tad[i];
                                            }                                         
                                        }
                                    })                                   
                                }                               
                            })
                        })
                    }
                    items["addons_meta_data"] =JSON.stringify(addonsMeta);
                }               
            })
            if(addons.length>0){
                 ListItem = ListItem.filter(im => !im.hasOwnProperty("addonId"));
                           
            }
            // console.log("addons",addons)
            // console.log("ListItem",ListItem)
        }
        if (ListItem !== null && ListItem.length > 0) {
            var ListItemLenght = ListItem.length
            ListItem.map((items, i) => {
                if (items.product_id != null) {
                    var _details = [];

                    // fix issue for other tax class other then standerd -------  
                    var _subtotaltax = 0.00;

                    if (items.subtotal_tax) { //&& items.subtotal_tax[0] && items.subtotal_tax[0][_tax_id] ==undefined
                        items.subtotal_tax.map(itm => {
                            for (var k in itm) {
                                _subtotaltax += itm[k];
                            }
                        })
                    }
                    //-----------------------------------------------------------------
                    _subtotal_tax = _subtotaltax
                    _total_tax = _subtotaltax
                    // _subtotal_tax = _tax_id !== 0 ? items.subtotal_tax ? items.subtotal_tax[0] && items.subtotal_tax[0][_tax_id]==undefined ? _subtotaltax : items.subtotal_tax[0] && items.subtotal_tax[0][_tax_id]  : items.subtotaltax ? items.subtotaltax : 0 : 0;
                    // _total_tax = _tax_id !== 0 ? items.total_tax ? items.total_tax[0] && items.total_tax[0][_tax_id]==undefined ? _subtotaltax :items.total_tax[0] && items.total_tax[0][_tax_id] : items.totaltax ? items.totaltax : 0 : 0;

                    var _addOns = productX && productX.filter(prodX => (prodX.product_id == items.product_id && prodX.strProductX == items.strProductX)) //strProductX added in condition if the same product having the diffrent variation 

                    if (items.Sku && items.Sku != '') {
                        _details.push({ "Sku": items.Sku });
                    }
                    if (_addOns && _addOns.length > 0 && _addOns[0].addons) {
                        _details = [..._details, ..._addOns[0].addons];
                    }

                    var _addExtMetaData = ''
                    if (extensionMetaData && extensionMetaData.line_item_id && extensionMetaData.line_item_id == items.product_id) {
                        _addExtMetaData = extensionMetaData.metaData
                    }
                    else if (extensionMetaData && ListItemLenght == i + 1) {
                        _addExtMetaData = extensionMetaData.metaData
                    }
                    var productCost = AllProductList && AllProductList.length > 0 && AllProductList.find(element => element.WPID == ((typeof items.variation_id !== "undefined" && items.variation_id !== 0) ? items.variation_id : (typeof items.product_id !== "undefined") ? items.product_id : items.WPID))
                  
                    var temp_subtotal =(typeOfTax() == "incl")? 
                                            (items.old_price * items.quantity) - items.incl_tax
                                        : items.subtotal;

                    var _item = {
                        Price:items.Price,
                        line_item_id: items.line_item_id,
                        name: items.Title,
                        Sku: items.Sku,
                        product_id: (typeof items.product_id !== "undefined") ? items.product_id : items.WPID,
                        variation_id: _addOns && _addOns[0] && _addOns[0].variation_id && _addOns[0].variation_id !== 0 ? _addOns[0].variation_id : items.variation_id,
                        quantity: items.quantity,
                        // subtotal: items.subtotal ? items.subtotal : items.subtotalPrice,
                        subtotal: temp_subtotal,
                        subtotal_tax: _subtotal_tax,
                        subtotal_taxes: items.subtotal_tax ? items.subtotal_tax : items.subtotaltax,
                        total: items.total || items.total == 0 ? items.total : items.totalPrice,
                        total_tax: _total_tax,
                        total_taxes: items.total_tax ? items.total_tax : items.totaltax,
                        isTaxable: items.isTaxable,
                        ticket_info: items.ticket_info,
                        is_ticket: items.ticket_status ? items.ticket_status : '',
                        discount_amount: items.discount_amount ? items.discount_amount : 0,
                        // Type: items && items.Type ? items.Type : '', // added Type to check product simple and variation for productX
                        Type: _addOns && _addOns[0] && _addOns[0].Type ? _addOns[0].Type : items && items.Type ? items.Type : '',

                        //  addons_meta_data:items.addons_meta_data  ,
                        //_addOns && _addOns.length > 0 && _addOns[0].addons ? JSON.stringify(_addOns[0].addons) : '',

                        addons_meta_data: items.addons_meta_data ? items.addons_meta_data : productX !== '' ? JSON.stringify(_details) : "",

                        meta_data: items.addons_meta_data ? items.addons_meta_data : _addExtMetaData && _addExtMetaData !== '' ? [_addExtMetaData] : '',
                        details: _details,
                        cost_per_item: productCost && productCost.Cost ? productCost.Cost : 0,
                        psummary: items && items.psummary ? items.psummary : '',
                        //cart_item_discount_amount: items.cart_discount_amount ? items.cart_discount_amount : 0,
                        //individual_discount_amount: items.product_discount_amount ? items.product_discount_amount : 0,
                    };

                    if (items.hasOwnProperty("modifiers_Id")) {
                        _item["modifiers_Id"] = items.modifiers_Id;
                    }
                    if (items.hasOwnProperty("modifiers_key")) {
                        _item["modifiers_key"] = items.modifiers_key;
                    }
                    if (items.hasOwnProperty("addon_ids")) {
                        _item["addon_ids"] = items.addon_ids;
                    }
                    if (items.hasOwnProperty("addon_key")) {
                        _item["addon_key"] = items.addon_key;
                    }
                    newList.push(_item);

                }
                if (items.product_id == null) {
                    // fix issue for other tax class other then standerd -------  
                    var _subtotaltax = 0.00;
                    if (items.subtotal_tax) { //&& items.subtotal_tax[0] && items.subtotal_tax[0][_tax_id] ==undefined
                        items.subtotal_tax.map(itm => {
                            for (var k in itm) {
                                _subtotaltax += itm[k];
                            }
                        })
                    }
                    //-----------------------------------------------------------------
                    _subtotal_tax = _subtotaltax
                    _total_tax = _subtotaltax

                    var _cfee_total = items.total || items.total == 0 ? items.total : items.totalPrice;
                    var _cfee_sub_total = items.subtotal ? items.subtotal : items.subtotalPrice;
                    if ((typeof items.Price !== 'undefined') && items.Price !== null) {
                        if (_cfee_total == 0 && items.subtotal_tax.length == 0) {
                            _cfee_total = items.Price;
                        }
                        if (!_cfee_sub_total || typeof _cfee_sub_total == "undefined") {
                            _cfee_sub_total = items.Price;
                        }
                        var _item = {
                            fee_id: items.fee_id ? items.fee_id : '',
                            amount: items.Price,
                            note: items.Title,
                            Price: items.Price,
                            TaxClass: items.TaxClass,
                            TaxStatus: items.TaxStatus,
                            Title: items.Title,
                            Sku: items.Sku,
                            after_discount: items.after_discount ? items.after_discount : 0,
                            cart_after_discount: items.cart_after_discount,
                            cart_discount_amount: items.cart_discount_amount,
                            discount_amount: items.discount_amount,
                            discount_type: items.discount_type,
                            excl_tax: items.excl_tax,
                            incl_tax: items.incl_tax,
                            isTaxable: items.isTaxable,
                            new_product_discount_amount: items.new_product_discount_amount,
                            old_price: items.old_price,
                            product_after_discount: items.product_after_discount,
                            product_discount_amount: items.product_discount_amount,
                            quantity: items.quantity,
                            subtotal: _cfee_sub_total,
                            subtotal_tax: _subtotal_tax,
                            subtotal_taxes: items.subtotal_tax ? items.subtotal_tax : items.subtotaltax,
                            total: _cfee_total,
                            total_tax: _total_tax,
                            total_taxes: items.total_tax ? items.total_tax : items.totaltax,
                            Type: _addOns && _addOns[0] && _addOns[0].Type ? _addOns[0].Type : items && items.Type ? items.Type : '',
                        };
                        if (items.hasOwnProperty("modifiers_Id")) {
                            _item["modifiers_Id"] = items.modifiers_Id;
                        }
                        if (items.hasOwnProperty("modifiers_key")) {
                            _item["modifiers_key"] = items.modifiers_key;
                        }
                        order_custom_fee.push(_item);
                        var last_custom_fee_inArray = order_custom_fee[order_custom_fee.length - 1];
                        if (items.hasOwnProperty("pid")) {
                            last_custom_fee_inArray["pid"] = items.pid;
                        }
                        order_custom_fee_meta.push(last_custom_fee_inArray);
                    } else {
                        var _nitem = {
                            note_id: items.id ? items.id : '',
                            note: items.Title,
                            is_customer_note: items.extention_custom_id ? 0 : 1,
                            pid: items.hasOwnProperty("pid") ? items.pid : 0
                        };
                        if (items.hasOwnProperty("modifiers_key")) {
                            _nitem["modifiers_key"] = items.modifiers_key;
                        }
                        order_notes.push(_nitem);
                    }
                }
            })
        }
        if (set_order_notes.length !== 0) {
            var note = set_order_notes[0]
            order_notes.push({
                note_id: '',
                note: note.note,
                is_customer_note: note.is_customer_note,
                is_extension_note: note.is_extension_note
            })
        }
        var Index = [];
        newList.map(calc => {
            // if (calc.total_taxes && calc.total_taxes.length > 0) {  
            //Checking each product for isTaxable. 01/08/2022
            if (calc.total_taxes && calc.total_taxes.length > 0 && calc.hasOwnProperty('isTaxable') && calc.isTaxable == true) {
                calc.total_taxes.map(ids => {
                    Index.push(ids)
                })
            }
        })
        order_custom_fee.map(calc => {
            if (calc.total_taxes && calc.total_taxes.length > 0) {
                calc.total_taxes.map(ids => {
                    Index.push(ids)
                })
            }
        })
        var distinctTaxIdsObject = new Object();
        Index.map(function (element, index) {
            var getObjectKey = Object.keys(element)[0];
            var getObjectValues = Object.values(element)[0];
            if (distinctTaxIdsObject.hasOwnProperty(getObjectKey)) {
                var existingValue = parseFloat(distinctTaxIdsObject[getObjectKey]);
                distinctTaxIdsObject[getObjectKey] = existingValue + parseFloat(getObjectValues);
            } else {
                distinctTaxIdsObject[getObjectKey] = getObjectValues;
            }
        });
        var distinctTaxIdsArray = new Array();
        for (var key in distinctTaxIdsObject) {
            var obj = new Object();
            obj[key] = distinctTaxIdsObject[key];
            distinctTaxIdsArray.push(obj)
        }
        if (managerData && managerData.display_name !== " " && managerData.display_name !== 'undefined') {
            manager_name = managerData.display_name;
        } else {
            manager_name = managerData !== null ? managerData.user_email : ''
        }

        //Updating the productX data from LineItem Data to calculate tax, total and subtotal;S
        if (productX && newList && newList.length > 0) {



            productX.map(_prodx => {
                var findProdxItems = newList.filter(_Item => (parseInt(_Item.product_id) == parseInt(_prodx.product_id)));

                var productXItemPrice = _prodx.cardSubToal
                var productXItemTax = _prodx.cardtax;

                if (findProdxItems && findProdxItems.length > 0) {
                    _prodx['SKu'] = findProdxItems[0].Sku;
                    _prodx['line_tax_data'] = [{ subtotal: findProdxItems[0].subtotal_taxes }, { total: findProdxItems[0].total_taxes }];
                    _prodx['line_subtotal'] = productXItemPrice;
                    _prodx['line_subtotal_tax'] = parseFloat(productXItemTax);
                    _prodx['line_total'] = findProdxItems[0].total;
                    _prodx['line_tax'] = findProdxItems[0].total_tax;
                    _prodx['line_subtotal_taxes'] = findProdxItems[0].subtotal_taxes;
                    _prodx['line_total_taxes'] = findProdxItems[0].total_taxes;
                    _prodx['quantity'] = findProdxItems[0].quantity;
                    _prodx['psummary'] = findProdxItems[0].psummary;
                    //In the case of inclusive tax minus the tax from the subtotal 28-07-20022
                    if (typeOfTax() == "incl")
                        _prodx['line_subtotal'] = _prodx.cardSubToal - _prodx.cardtax;

                }
            })
            // removing simpleproductX and variationProductX type from productX to show data into wooCommerce 
            newList.map((removeProductxItems, i) => {
                productX.map((prod, index) => {
                    if ((removeProductxItems.Type == 'simple' || removeProductxItems.Type == 'variable') && removeProductxItems.product_id == prod.product_id) {
                        //This field is added to dispaly the simple and variation productX measurement data 
                        removeProductxItems["pricing_item_meta_data"] = prod.pricing_item_meta_data ? prod.pricing_item_meta_data : "";
                        //for Measurement 
                        if (prod && prod.pricing_item_meta_data) {
                            removeProductxItems["details"] = getAddonsField(prod);
                        }
                        productX.splice(index, 1);
                    }
                })
            })

            localStorage.setItem("PRODUCTX_DATA", JSON.stringify(productX))
        }
        checkoutProductList = newList;
        //Removing the productX data from the LineItem because we are passing ProductX data in saperate variavle i.e. productx_line_items
        if (checkoutProductList && checkoutProductList.length > 0 && productX && productX.length > 0) {
            productX.map(prod => {
                checkoutProductList.map((removeProductxItems, index) => {
                    prod["psummary"] = removeProductxItems.psummary;
                    if ((removeProductxItems.Type !== 'simple' && removeProductxItems.Type !== 'variable')
                        && (prod.Type !== 'simple' && prod.Type !== 'variable') && removeProductxItems.product_id == prod.product_id) {
                        checkoutProductList.splice(index, 1);
                    }
                })
            })
        }
        //Geting the all product of the order and their discount applied on them to send into meta-data, this will used when reopen the park sale
        var productDiscout = []
        var checklist = localStorage.getItem('CHECKLIST') && JSON.parse(localStorage.getItem('CHECKLIST'));
        const cartProducts = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
        const CartDiscountAmount = localStorage.getItem("CART") ? JSON.parse(localStorage.getItem("CART")) : '';

        if (cartProducts && cartProducts.length > 0) {
            // sent total_subTotal_fileds to use in activity view  incase of park and lay-away  
            var subTotal_Total_sent_to_part = {
                'total_subTotal_fileds': {
                    subTotal: checklist && checklist.subTotal ? checklist.subTotal : 0,
                    totalPrice: checklist && checklist.totalPrice ? checklist.totalPrice : 0
                }
            }
            productDiscout && productDiscout.push(subTotal_Total_sent_to_part)

            // var discount_amount = 0
            cartProducts.map((item, index) => {
                if (item.product_id) {
                    //----------AddOns remove-------------------------------------------------
                    if(item.hasOwnProperty("product_id")){
                        let addonsMeta=[];
                        var product_addons= cartProducts.filter(lm=>( ! lm.hasOwnProperty("product_id") && lm.hasOwnProperty("addonId") && lm.pid==item.product_id ))
                        if (product_addons && product_addons.length>0) {
                           let arr=[...addons,...product_addons];
                            addons = [...new Set(arr)] ;
                          
                            product_addons.map(ad=>{
                                addonsMeta.push({name:ad.name , price:ad.Price, value:ad.value})
                                item.Price += ad.Price;                         
                                item.after_discount+=ad.after_discount;
                                item.cart_after_discount +=ad.cart_after_discount;
                                item.cart_discount_amount +=ad.cart_discount_amount;                          
                                item.excl_tax +=ad.excl_tax;
                                item.incl_tax+=ad.incl_tax;                       
                                item.old_price +=ad.old_price;
                                item.product_after_discount +=ad.product_after_discount;
                                item.product_discount_amount +=ad.product_discount_amount;                           
                                item.subtotal+=ad.subtotal;
                                item.total+=ad.total;
                            //    item.subtotal_tax.map(tp=>{
                            //         for (var k in tp) {                                   
                            //             ad.subtotal_tax.map(tad=>{
                            //                 for (var i in tad) {
                            //                     if(i==k){
                            //                         tp[k]=tp[k]+tad[i];
                            //                     }                                         
                            //                 }
                            //             })                                   
                            //         }                               
                            //     })
                            })
                        }                       
                    } 
                    //-------------------------------------------------------------
                    var _product = AllProductList && AllProductList.length > 0 && AllProductList.find(element => element.WPID == ((typeof item.variation_id !== "undefined" && item.variation_id !== 0) ? item.variation_id : (typeof item.product_id !== "undefined") ? item.product_id : item.WPID))
                    // if (item.product_id && item.variation_id) {
                    // discount_total += item.cart_discount_amount 
                                      var _item = {
                        // order_notes: (typeof order_notes !== "undefined") && order_notes.length > 0 ? order_notes : [],
                        product_id: item.product_id,
                        variation_id: item.variation_id ? item.variation_id : 0,
                        product_discount_amount: item.product_discount_amount,
                        cart_discount_amount: item.cart_discount_amount,
                        discount_type: item.discount_type,
                        after_discount: item.after_discount,
                        discount_amount: item.discount_amount,
                        cart_after_discount: item.cart_after_discount,
                        product_after_discount: item.product_after_discount,
                        ticket_status: item.ticket_status,
                        tick_event_id: item.tick_event_id,
                        ticket_info: item.ticket_info,
                        product_ticket: item.product_ticket,
                        new_product_discount_amount: item.new_product_discount_amount,
                        TaxStatus: item.TaxStatus,
                        tcForSeating: item.tcForSeating,
                        TaxClass: item.TaxClass,
                        old_price: item.old_price,
                        Price: item.Price,
                        Title: item.Title,
                        Sku: item.Sku,
                        quantity: item.quantity,
                        discountCart: CartDiscountAmount,
                        isTaxable: item.isTaxable,
                        //Sort description length and html tag issue
                        shortDescription: _product && _product.ShortDescription ? _product.ShortDescription : "",
                        // subTotal: checklist && checklist.subTotal ? checklist.subTotal : 0,
                         // totalPrice: checklist && checklist.totalPrice ? checklist.totalPrice : 0
                        psummary: item.psummary,
                        Type: item.Type ? item.Type : '',
                    };
                    if (item.hasOwnProperty("modifiers_Id")) {
                        _item["modifiers_Id"] = item.modifiers_Id;
                    }
                    if (item.hasOwnProperty("modifiers_key")) {
                        _item["modifiers_key"] = item.modifiers_key;
                    }
                    if (item.hasOwnProperty("addon_ids")) {
                        _item["addon_ids"] = item.addon_ids;
                    }
                    if (item.hasOwnProperty("addon_key")) {
                        _item["addon_key"] = item.addon_key;
                    }
                    productDiscout.push(_item);
                }
            })
        }


        // get system ip, system id and device type 
        var deviceType = ''
        var deviceIP = ''
        var deviceName = ''
        if (isMobileOnly == true) {
            deviceType = 'Mobile'
        } else if (isTablet == true) {
            deviceType = 'Tablet'
        } else {
            deviceType = 'Desktop'
        }
        // $.getJSON("https://jsonip.com/?callback=?").then((data) => {
        //     deviceIP = data.ip
        //     place_order.device_ip = data.ip
        // });
        if (localStorage.getItem('IPAddress')) {
            deviceIP = localStorage.getItem('IPAddress')
        }
        var _cashPayment = 0;
        var _changeAmount = 0;
        if ((respChangeAmount && respChangeAmount.status == STATUSES.IDLE && respChangeAmount.is_success && respChangeAmount.data)) {
            //console.log("--respChangeAmount--" + JSON.stringify(respChangeAmount));
            _cashPayment = respChangeAmount.data.cashPayment;
            _changeAmount = respChangeAmount.data.change;
        }
        // push order_custom_fee in discount meta data

        productDiscout && productDiscout.push({ order_custom_fee: order_custom_fee_meta })
        productDiscout && productDiscout.push({ order_notes: (typeof order_notes !== "undefined") && order_notes.length > 0 ? order_notes : [] })
        productDiscout && productDiscout.push({ taxType: typeOfTax() })
        var selectedGroupSale = localStorage.getItem('selectedGroupSale') ? JSON.parse(localStorage.getItem('selectedGroupSale')) : null;
        var couponDetail = localStorage.getItem('couponDetail') ? JSON.parse(localStorage.getItem('couponDetail')) : null;
        localStorage.removeItem('couponDetail');
        localStorage.removeItem("couponApplied");
        var shippingAddress = localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : null;
        localStorage.removeItem("shippingAddress");
        var _cash_round = cashRound > 1 ? 0 : cashRound;

        order_meta.push({
            "manager_id": localStorage.getItem('demoUser') && localStorage.getItem('demoUser') == "true" ? localStorage.getItem('VisiterUserID') : localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')).user_id : "",
            "manager_name": localStorage.getItem('demoUser') && localStorage.getItem('demoUser') == "true" ? localStorage.getItem('VisiterUserEmail') : manager_name,
            "location_id": location_id,
            "warehouse_id": localStorage.getItem('WarehouseId') ? localStorage.getItem('WarehouseId') :0, 
            "register_id": localStorage.getItem('register'),
            "_order_oliverpos_group_name": selectedGroupSale && selectedGroupSale.GroupName,
            "_order_oliverpos_group_slug": selectedGroupSale && selectedGroupSale.Slug,
            "_order_oliverpos_group_qrcode": selectedGroupSale && selectedGroupSale.QRCode,
            "_order_oliverpos_group_label": selectedGroupSale && selectedGroupSale.Label,
            "cash_rounding": _cash_round,
            "refund_cash_rounding": 0,
            "_order_oliverpos_extension_data": orderType,
            "_wc_points_logged_redemption": checkList && checkList._wc_points_logged_redemption ? checkList._wc_points_logged_redemption : "",
            // "_wc_amount_redeemed":  checkList && checkList._wc_amount_redeemed ? checkList._wc_amount_redeemed:"",
            "_wc_points_redeemed": checkList && checkList._wc_points_redeemed ? checkList._wc_points_redeemed : "",
            "_order_oliverpos_product_discount_amount": productDiscout,
            // "_order_oliverpos_order_custom_fee" : order_custom_fee
            "_order_oliverpos_park_sale_notes": (typeof order_notes !== "undefined") && order_notes.length > 0 ? order_notes : [],
            "_order_oliverpos_cash_change": { "cashPayment": _cashPayment, "change": _changeAmount },
            //"_order_oliverpos_addons" : productxAddons && productxAddons!== {} ? JSON.stringify(productxAddons) : {}      
            "_order_oliverpos_coupon": couponDetail,
            "_order_oliverpos_shipping": shippingAddress,
            "_order_oliverpos_source": "3.0"
        })

        var loginUser = JSON.parse(localStorage.getItem("user"));
        var CustomerDetail = JSON.parse(localStorage.getItem('AdCusDetail'));
        var ShippingAddress = CustomerDetail && CustomerDetail.customerAddress ? CustomerDetail.customerAddress.find(Items => Items.TypeName == "shipping") : '';
        var BillingAddress = CustomerDetail && CustomerDetail.customerAddress ? CustomerDetail.customerAddress.find(Items => Items.TypeName == "billing") : '';

        //If shipping address of the customer is blank then billind address will be shown as shipping address START
        if (ShippingAddress && ShippingAddress.City == "" && ShippingAddress.State == "" && ShippingAddress.Country == "" && ShippingAddress.PostCode == "") {
            if (BillingAddress && BillingAddress.City != "" && BillingAddress.Country != "") {
                ShippingAddress = BillingAddress;
            }
        }
        //END
        var country_code = loginUser ? loginUser.shop_country : "";
        if (country_code && country_code.includes(":")) {
            country_code = country_code.split(":")[0];
        }
        var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
        place_order = {
            order_id: order_id,
            oliver_pos_receipt_id: oliver_pos_receipt_id,
            status: status,
            customer_note: Notes ? Notes : 'Add Note',
            customer_id: CustomerDetail && CustomerDetail.WPId ? CustomerDetail.WPId : 0,
            order_tax: checkList && checkList.tax ? checkList.tax : 0,
            order_total: (status == 'park_sale' || status == 'lay_away') ? checkList && checkList.totalPrice + tipAmount : checkList && parseFloat(RoundAmount(checkList.totalPrice + cashRound + tipAmount)),
            order_discount: parseFloat(discountIs) ,//+ redeemAmount
            tax_id: _tax_id,
            tax_ids: distinctTaxIdsArray,
            //line_items: newList,
            line_items: checkoutProductList,
            productx_line_items: productX, //Sending the ProductX data.
            customer_email: CustomerDetail && CustomerDetail.Email ? CustomerDetail.Email : '',
            billing_address: [{
                first_name: FirstName ? FirstName : '',
                last_name: LastName ? LastName : '',
                company: "",
                email: Email ? Email : '',
                phone: PhoneNumber ? PhoneNumber : '',
                address_1: BillingAddress ? BillingAddress.Address1 : "", // Street_Address ? Street_Address : '',
                address_2: BillingAddress ? BillingAddress.Address2 : "", // Street_Address2 ? Street_Address2 : '',
                city: BillingAddress ? BillingAddress.City : "", //  city ? city : '',
                state: BillingAddress ? BillingAddress.State : "", //  "",
                postcode: BillingAddress ? BillingAddress.PostCode : "", //  Pincode ? Pincode : '',
                // country: loginUser ? loginUser.shop_country_full_Name : ""
                country: BillingAddress ? BillingAddress.Country : "",
            }],
            shipping_address: [{
                first_name: CustomerDetail && CustomerDetail.FirstName ? CustomerDetail.FirstName : "", // FirstName ? FirstName : '',
                last_name: CustomerDetail && CustomerDetail.LastName ? CustomerDetail.LastName : "", //  LastName ? LastName : '',
                company: "",
                email: Email ? Email : '',
                phone: PhoneNumber ? PhoneNumber : '',
                address_1: ShippingAddress ? ShippingAddress.Address1 : "", // CustomerDetail. Street_Address ? Street_Address : '',
                address_2: ShippingAddress ? ShippingAddress.Address2 : "", //  Street_Address2 ? Street_Address2 : '',
                city: ShippingAddress ? ShippingAddress.City : "", // city ? city : '',
                state: ShippingAddress ? ShippingAddress.State : "", // ,
                postcode: ShippingAddress ? ShippingAddress.PostCode : "",  // Pincode ? Pincode : '',
                country: ShippingAddress ? ShippingAddress.Country : "", // 
            }],
            order_custom_fee: order_custom_fee,
            order_notes: (typeof order_notes !== "undefined") && order_notes.length > 0 ? order_notes : [],
            order_payments: order_payments,
            order_meta: order_meta,
            Udid: get_UDid(),
            device_type: deviceType,
            // device_ip: deviceIP,
            device_id: deviceName,
            _currentTime: moment().format(isSafari ? Config.key.DATETIME_FORMAT_SAFARI : 'YYYY-MM-DD, h:mm:ss a'),  //date used for cloud print
            total_shipping: shippingAmount,
            shipping_tax: shippingTax,

            _shopName: loginUser?.shop_name,
            _currency:  loginUser?.currency,
            _country:  loginUser?.shop_country, 
            _countryFullName:  loginUser?.shop_country_full_Name,
            _managerEmail:  loginUser?.user_email,
            _userGUID: client && client !==""? client.user_id :"" //shop owner Id
        }
        localStorage.setItem('placedOrderList', JSON.stringify(newList));
        // add cash rounding amount in localstorage for android and ios section
        // get tax name for android and ios section
        var TotalTaxByName = getTotalTaxByName('completecheckout', productX);
        if (localStorage.getItem('CHECKLIST')) {
            //var checklist = JSON.parse(localStorage.getItem('CHECKLIST'));
            if (productX && productX.length > 0) {
                checklist.ListItem.map(item => {
                    var sub_tilte = productxArray(item.product_id, AllProductList, 'setPayment');
                    if (sub_tilte && sub_tilte !== "") {
                        item['productx_subtitle'] = sub_tilte;
                    }
                })
            }
            checklist['cash_rounding'] = _cash_round;
            checklist['servedby'] = manager_name;
            checklist['multiple_tax'] = TotalTaxByName;
            //added into checklist for android print receipt
            checklist['_order_oliverpos_cash_change'] = { "cashPayment": _cashPayment, "change": _changeAmount };

            localStorage.setItem('CHECKLIST', JSON.stringify(checklist))
        }
        //------------------------------------

        setTimeout(() => {
            // order_custom_fee_meta
            // if (place_order["order_meta"] && place_order["order_meta"].length > 0) {
            //     var temp_meta_data = place_order["order_meta"][0];
            //     temp_meta_data["temp_meta_data"] = JSON.stringify(_newMeta);
            //     requestData["order_meta"] = temp_meta_data;
            // }

            localStorage.setItem("GTM_ORDER", JSON.stringify(place_order));
            console.log("order request: ",place_order)
            setStartOrderProcessing(new Date().getTime());
            if (status.includes("park_sale") || status.includes("lay_away")) {
                setTimeout(() => {
                   dispatch(save(place_order, 2, updatedBy));
                }, 500);
            } else { 
               dispatch(save(place_order, 1));
             
            }
            //Android Call----------------------------
            // androidDisplayScreen("Total", place_order.order_total, 0, "finalcheckout");
            //androidDisplayScreen("Total", place_order.order_total, 0, "cart");
            //-----------------------------------------

        }, 1000);
    }
    const handleChange = (e) => {
        const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
        if (e.target.value === '' || re.test(e.target.value)) {
            const { value } = e.target;
            setPaidAmount(value);
            //this.props.normapNUM(value);
            this.setState({
                paidAmount: '',
                paidAmountStatus: true,
                temporary_vaule: value
            })
        }
    }
    //its used for get the order payments
    const getOrderPayments = (order_id) => {
        if (localStorage.getItem("oliver_order_payments")) {
            var paid_amount = 0;
            var payments = new Array();

            JSON.parse(localStorage.getItem("oliver_order_payments")).forEach(paid_payments => {
                paid_amount += parseFloat(paid_payments.payment_amount);
                var paymentObj = {
                    "Id": paid_payments.Id !== 0 ? paid_payments.Id : 0,
                    "payment_type": paid_payments.payment_type,
                    "payment_amount": paid_payments.payment_amount,
                    "order_id": order_id,
                    "transection_id": paid_payments.transection_id ? paid_payments.transection_id : 0,
                    "emv_data": paid_payments.emv_data,
                    "description": paid_payments.emv_data && paid_payments.emv_data != "" ? JSON.stringify(paid_payments.emv_data) : paid_payments.description,

                }
                if (paid_payments.payment_date && paid_payments.payment_date != "") {
                    paymentObj['payment_date'] = paid_payments.payment_date;
                }
                payments.push(paymentObj);
            });
            return payments;
        } else {
            //alert("Your browser not support local storage");
        }
    }
    const gotoHome = () => {
        localStorage.removeItem('CARD_PRODUCT_LIST');
        localStorage.removeItem('GTM_ORDER');

        localStorage.removeItem('ORDER_ID');
        localStorage.removeItem('CHECKLIST');
        localStorage.removeItem('oliver_order_payments');
        localStorage.removeItem('AdCusDetail');
        localStorage.removeItem('CARD_PRODUCT_LIST');
        localStorage.removeItem("CART");
        localStorage.removeItem("SINGLE_PRODUCT");
        localStorage.removeItem("PRODUCT");
        localStorage.removeItem('PRODUCTX_DATA');
        localStorage.removeItem('PAYCONIQ_PAYMENT_RESPONSE');
        localStorage.removeItem('ONLINE_PAYMENT_RESPONSE');
        localStorage.removeItem('STRIPE_PAYMENT_RESPONSE');
        localStorage.removeItem('GLOBAL_PAYMENT_RESPONSE');
        localStorage.removeItem('PAYMENT_RESPONSE');
        localStorage.removeItem('PENDING_PAYMENTS');
        localStorage.setItem('DEFAULT_TAX_STATUS', 'true');
        localStorage.removeItem('PrintCHECKLIST');

        // dispatch(addtoCartProduct(null));

        localStorage.removeItem('paybyproduct');
        localStorage.removeItem("paybyproduct_unpaid");
        localStorage.removeItem("payType");
        localStorage.removeItem("CUSTOMER_TO_ACTVITY");
        NavigateComponent(navigate, "home")
    }
    const pay_amount_cash = (item) => {
        if (isDemoUser && item.Code !== paymentsType.typeName.cashPayment) {
            return togglePaymentNotSupportedPopup();
        }

        setPaymentTypeItem(item);
        if (item.Code == paymentsType.typeName.cashPayment) {
            toggleNumberPad();
        }
        else if (item.Support == paymentsType.typeName.UPISupport) {
            toggleUPIPayment();
        }
        else if (item.Support == paymentsType.typeName.Support) {
            toggleManualPayment();
        }
        else if (item.HasTerminal == true && item.Support == "Terminal" && item.Code != paymentsType.typeName.stripePayment) {
            toggleGlobalPayment();
        }
        else if (item.Code == paymentsType.typeName.stripePayment) {
            toggleStripeTerminalPayment();
        }
        else if (item.Code == paymentsType.typeName.storeCredit) {
            setPartialPayment(item.Code, paidAmount)
        }
        else {
            setPartialAmount(item.Code, partialAmount);
            pay_amount(item.Code);
        }
    }

    const placeParkLayAwayOrder = (status) => {
        // setCheckList(JSON.parse(localStorage.getItem("CHECKLIST"))) ;
        createOrder(status);
    }
    const closingTab = () => { }
    const onlinePayCardDetails = (cardData) => {
        setOnlinePayCardData(cardData);
    }
    useEffect(() => {
        if (onlinePayCardData.hasOwnProperty("paycode")) {
            pay_amount(onlinePayCardData.paycode, 0, 'Online');
        }
    }, [onlinePayCardData]);
    const activeDisplay = (st) => {

        // this.closingTab()
        // this.setState({ activeDisplay: st })
    }
    function ToggleiFrameWindow(_exApp = null,appClose) {
        if (_exApp != null && _exApp != "") { setExtApp(_exApp); setisInit(false); }
        if (isShowiFrameWindow === false) {
            UpdateRecentUsedApp(_exApp, true, 0, "Checkout")
        }
        if(appClose==true)
        {
             setisShowiFrameWindow(false)
        }
        else
        {setisShowiFrameWindow(!isShowiFrameWindow)
           
        }

    }
    var paymentTypeName = localStorage.getItem("PAYMENT_TYPE_NAME") ? JSON.parse(localStorage.getItem("PAYMENT_TYPE_NAME")) : [];
    //Arranging array to put cash and card type in the first and the second 
    if (paymentTypeName && paymentTypeName.length > 0) {
        var card = paymentTypeName.find(a => a.Name.toLowerCase() === "card");
        var cash = paymentTypeName.find(a => a.Name.toLowerCase() === "cash");
        paymentTypeName = paymentTypeName.filter(a => a.Name.toLowerCase() != "cash" && a.Name.toLowerCase() != "card");
        if (card && typeof card != "undefined")
            paymentTypeName.unshift(card);
        if (cash && typeof cash != "undefined")
            paymentTypeName.unshift(cash);
    }
    var true_dimaond_field = localStorage.getItem('GET_EXTENTION_FIELD') ? JSON.parse(localStorage.getItem('GET_EXTENTION_FIELD')) : [];
    var extensionPayments = [];
    if (true_dimaond_field && true_dimaond_field.length > 0) {
        true_dimaond_field.map((Item, index) => {
            if ((Item.removed_from_origin == false && Item.ShowAtCheckout === true) &&
                Item.viewManagement && Item.viewManagement.length != [] && CheckAppForPaymentView(Item.viewManagement, "Payment Types") === true) {
                extensionPayments.push(Item);
            }
        })
    }
    const isEnablePaymentType = (type) => {
        var pay_type = localStorage.getItem("payType") ? localStorage.getItem("payType") : null;
        if (pay_type == null || pay_type === type) { return false }
        else { return true }

    }
   var _activeDisplay = true;
    var global_payment = null;


    function Capitalize(val) {
        var str = val ? val.replace(/_/g, "\u00a0") : "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    var client = localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : '';
    var isAllowQuickPay = true;
    if (client && client.subscription_permission && client.subscription_permission.HideQuickSplitPayments === true ) {
        isAllowQuickPay = false;
    }

const handleResendReqest=()=>{
    var place_order=localStorage.getItem("GTM_ORDER")? JSON.parse(localStorage.getItem("GTM_ORDER")):"";
    console.log("order request: ",place_order)
    if(place_order && place_order !==""){
        setStartOrderProcessing(new Date().getTime());
        if (status.includes("park_sale") || status.includes("lay_away")) {
            setTimeout(() => {
               dispatch(save(place_order, 2));
            }, 500);
        } else { 
            dispatch(save(place_order, 1));
         
        }
    }
   
}

const DisplayPluginPopup = useMemo(()=>{   
        return <IframeWindow exApp={extApp} isShow={isShowiFrameWindow} ToggleiFrameWindow={ToggleiFrameWindow} ></IframeWindow>;
    },[extApp]);
    return (<React.Fragment>
        {loading === true ? <LoadingModal startOrderProcessing= {startOrderProcessing}  gotoHome={gotoHome} handleResendReqest={handleResendReqest} loading={loading}></LoadingModal> : null}
        <div className="checkout-wrapper">
            <LeftNavBar view="Checkout" toggleiFrameWindow={ToggleiFrameWindow}  ></LeftNavBar>
            <Header toggleParkSale={toggleParkSale}></Header>
            <div className="cart">
                <CartListBody setValues={setValues}></CartListBody>
                <div className="footer">
                    <div className="totals">
                        <div className="row">
                            <p>{LocalizedLanguage.subTotal}</p>
                            <p><b>{currecyFunction(subTotal)}</b></p>
                        </div>
                        {discountType != "" ?
                            <div className="row">
                                <p>{LocalizedLanguage.cartdiscount} ({discountType})</p>
                               <p><b>-{currecyFunction(discount)}</b></p>
                            </div> : null}

                        {taxes !== 0 ?
                            <div className="row">
                                <p>{LocalizedLanguage.taxes} {typeOfTax() == 'incl' ? "(Incl)" : LocalizedLanguage.exclTax}</p>
                               <p><b>{currecyFunction(taxes)}</b></p>
                            </div> : null}
                        {shippingAmount && shippingAmount > 0 ? <div className="row">
                            <p>{LocalizedLanguage.shippingAmount}</p>
                            <p>{currecyFunction(shippingAmount + (typeOfTax() == 'incl' ? shippingTax : 0))}</p>
                        </div> : null}
                        <div className="row">
                            <p><b>{LocalizedLanguage.total}</b></p>
                            <p><b>{currecyFunction(total)}</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout-body">
                <button id="balanceButton" className={isEnablePaymentType("partial") ? "balance-container payment-type-disable" : "balance-container"} onClick={() => toggleShowPartialPayment()} disabled={isEnablePaymentType("partial")}>
                    <div className="row">
                        <p className="style1">{LocalizedLanguage.balanceDue}</p>
                        <p className="style2" >{currecyFunction((parseFloat(balance) - (paymentsArr && paymentsArr.length > 0 ? (paymentsArr.reduce((a, v) => a = parseFloat(a) + parseFloat(v.payment_amount), 0)) : 0)).toFixed(2))}</p>
                        <input id="my-input" hidden="true" value={(parseFloat(balance) - (paymentsArr && paymentsArr.length > 0 ? (paymentsArr.reduce((a, v) => a = parseFloat(a) + parseFloat(v.payment_amount), 0)) : 0)).toFixed(2)}></input>

                    </div>
                    {paymentsArr && paymentsArr.map(p => {
                        return <div className="row" key={uniqueKey()}>
                            <p className="style3">{Capitalize(p.payment_type)}</p>
                            <p className="style3">{currecyFunction((parseFloat(p.payment_amount)).toFixed(2))}</p>
                          </div>
                    })}
                      {paymentsArr && paymentsArr.length > 0 && <div className="row">
                        <p className="style4">{LocalizedLanguage.totalpaid}</p>
                        {/* <p className="style4">${parseFloat((paymentsArr.reduce((a, v) => a = parseFloat(a) + parseFloat(v.payment_amount), 0))).toFixed(2)}</p> */}
                        <p className="style4">{currecyFunction((paymentsArr.reduce((a, v) => a = parseFloat(a) + parseFloat(v.payment_amount), 0)))}</p>
                    </div>}

                </button>
                { isAllowQuickPay==true && <p id="bottomText" onClick={() => isEnablePaymentType("partial") == false ? toggleShowPartialPayment() : null} disabled={isEnablePaymentType("partial")} className={isEnablePaymentType("partial") ? "payment-type-disable-txt" : ""}>{LocalizedLanguage.clicktomakeapartialpayment}</p>}
                { isAllowQuickPay==true && <p className="style2">{LocalizedLanguage.quicksplit}</p>}
                { isAllowQuickPay==true && <div className="button-row">
                    <button onClick={() => showPartial(2)} disabled={isEnablePaymentType("split-partial_2")}>1/2</button>
                    <button onClick={() => showPartial(3)} disabled={isEnablePaymentType("split-partial_3")}>1/3</button>
                    <button onClick={() => showPartial(4)} disabled={isEnablePaymentType("split-partial_4")}>1/4</button>
                </div>
                }
                { isAllowQuickPay==true && <div className="button-row">
                    <button onClick={() => toggleSplitByProduct()} disabled={isEnablePaymentType("byproduct")}>{LocalizedLanguage.byproduct}</button>
                    <button disabled>{LocalizedLanguage.bygroup} ({LocalizedLanguage.comingsoon})</button>
                </div>
                }
                <p className="style2">{LocalizedLanguage.customerpaymenttypes}</p>
                {get_customerName() == null ? <p className="style3">{LocalizedLanguage.pleaseaddapaymenttypesavailable}</p> : null}
                <div className="button-row">
                    <button disabled={get_customerName() == null ? true : false} onClick={() => toggleParkSale('lay_away')}>{LocalizedLanguage.layAway}</button>
                    <button disabled={storeCredit == 0 || get_customerName() == null ? true : false} onClick={() => pay_by_store_credit()}>{LocalizedLanguage.stroeCredit} {storeCredit > 0 ? <> (${parseFloat(storeCredit).toFixed(2)})</> : null}</button>
                </div>

                <div className="payment-types">
                    <p>{LocalizedLanguage.paymenttypes}</p>
                    <div className="button-container">
                        {
                            paymentTypeName && paymentTypeName.length > 0 && paymentTypeName.map(payment => {
                                return payment.image || payment.Code === "stripe_terminal" ?
                                    <button onClick={() => pay_amount_cash(payment)} key={uniqueKey()}>
                                        <img src={Stripe_Icon} alt=""></img></button>
                                    :
                                    payment.Code === "cash" ? <button onClick={() => pay_amount_cash(payment)} key={uniqueKey()}>
                                        <img src={CashButtonImage} alt=""></img></button>
                                        :
                                        payment.Code === "card" ? <button className={isDemoUser && 'disabled'} onClick={() => pay_amount_cash(payment)} key={uniqueKey()}>
                                            <img src={CardButtonImage} alt=""></img></button>
                                            :
                                            <button style={{ backgroundColor: payment.ColorCode, borderColor: payment.ColorCode }} className={isDemoUser && 'disabled'} onClick={() => pay_amount_cash(payment)} key={uniqueKey()}>
                                                {payment.Name}
                                            </button>
                            })
                        }
                        {
                            extensionPayments && extensionPayments.length > 0 && extensionPayments.map(exPayment => {
                                return <button style={{ backgroundColor: "var(--oliver-blue)" }} className={isDemoUser && 'disabled'} onClick={() => isDemoUser ? togglePaymentNotSupportedPopup():ToggleiFrameWindow(exPayment)} key={exPayment.Id} title={exPayment.Name}>
                                    {exPayment.logo != null ? <img src={exPayment.logo} alt=""></img> : exPayment.Name}</button>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
        {isStripeTerminalPayment === true ? <StripePayment
            isShow={isStripeTerminalPayment}
            toggleStripeTerminalPayment={toggleStripeTerminalPayment}
            color={"#f75f40"}
            Name={"Stripe Terminal"}
            code={"stripe_terminal"}
            pay_amount={(text) => pay_amount(text)}
            activeDisplay={(text) => activeDisplay(text)}
            styles={activeDisplay == false || activeDisplay == `stripe_terminal_true` ? '' : 'none'}
            paymentDetails={paymentTypeItem}
            terminalPopup={(msg) => extraPayAmount(msg)}
            paidAmount={paidAmount}
            cancleTransaction={cancleTransaction}
            partialAmount={partialAmount}
        /> : null}
        {isManualPayment === true ? <ManualPayment isShow={isManualPayment} toggleManualPayment={toggleManualPayment}
            color={paymentTypeItem.ColorCode}
            Name={paymentTypeItem.Name}
            code={paymentTypeItem.Code}
            pay_amount={(text) => pay_amount(text, paymentTypeItem.TerminalCount, paymentTypeItem.Support)}
            msg={props.global_payment ? props.global_payment.message : LocalizedLanguage.waitForTerminal}
            activeDisplay={(text) => activeDisplay(text)}
            styles={_activeDisplay == false || _activeDisplay == `${paymentTypeItem.Code}_true` ? '' : 'none'}
            closingTab={(text) => closingTab(text)}
            onlinePayCardDetails={(cardData) => onlinePayCardDetails(cardData)}
            partialAmount={partialAmount}
        /> : null}
        {isUPIPayment === true ? <UPIPayments
            isShow={isUPIPayment}
            toggleUPIPayment={toggleUPIPayment}
            color={paymentTypeItem.ColorCode}
            Name={paymentTypeItem.Name}
            code={paymentTypeItem.Code}
            pay_amount={(text) => pay_amount(text, paymentTypeItem.TerminalCount, paymentTypeItem.Support)}
            activeDisplay={(text) => activeDisplay(text)}
            styles={_activeDisplay == false || _activeDisplay == `${paymentTypeItem.Code}_true` ? '' : 'none'}
            cancleTransaction={cancleTransaction}
            partialAmount={partialAmount}
        /> : null}
        {isGlobalPayment === true ? <GlobalPayment
            isShow={isGlobalPayment}
            toggleGlobalPayment={toggleGlobalPayment}
            color={paymentTypeItem.ColorCode}
            Name={paymentTypeItem.Name}
            code={paymentTypeItem.Code}
            pay_amount={(text) => pay_amount(text, paymentTypeItem.TerminalCount, '', paymentTypeItem.Code)}
            msg={global_payment ? global_payment.message : ''}
            activeDisplay={(text) => activeDisplay(text)}
            styles={_activeDisplay == false || _activeDisplay == `${paymentTypeItem.Code}_true` ? '' : 'none'}
            closingTab={(text) => closingTab(text)}
            paymentDetails={paymentTypeItem}
            terminalPopup={(msg) => extraPayAmount(msg)}
            cancleTransaction={cancleTransaction}
            partialAmount={partialAmount}
        /> : null}
        {isShowNumberPad ? <NumberPad isShow={isShowNumberPad} toggleNumberPad={toggleNumberPad} pay_by_cash={pay_by_cash} amount={(parseFloat(balance) - (paymentsArr && paymentsArr.length > 0 ? (paymentsArr.reduce((a, v) => a = parseFloat(a) + parseFloat(v.payment_amount), 0)) : 0)).toFixed(2)} getRemainingPriceForCash={getRemainingPriceForCash} ></NumberPad> : null}
        {isShowPartialPayment ? <PartialPayment extensionPayments={extensionPayments} ToggleiFrameWindow={ToggleiFrameWindow} isShow={isShowPartialPayment} toggleShowPartialPayment={toggleShowPartialPayment} amount={paidAmount} pay_partial={pay_partial} getRemainingPrice={getRemainingPrice} partialType={partialType}></PartialPayment> : null}
        {isShowParkSale ? <ParkSale toggleParkSale={toggleParkSale} isShow={isShowParkSale} placeParkLayAwayOrder={placeParkLayAwayOrder} isLayAwayOrPark={isLayAwayOrPark}></ParkSale> : null}
        <PaymentNotSupportedPopup isShowPaymentNotSupported={isShowPaymentNotSupported} togglePaymentNotSupportedPopup={togglePaymentNotSupportedPopup}></PaymentNotSupportedPopup>
        {isShowSplitByProduct ? <SplitByProduct listItem={checkList.ListItem} isShow={isShowSplitByProduct} toggleSplitByProduct={toggleSplitByProduct} pay_by_product={pay_by_product}></SplitByProduct> : null}
        {isShowiFrameWindow == true ? DisplayPluginPopup: null}


        <div className={isShowConfirmation === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"}  style={{ zIndex: 9 }}>
        <div className={isShowConfirmation === true ? "subwindow delete-customer-confirm current" : "subwindow delete-customer-confirm"}>
        <div className="subwindow-header">
                        <p>{LocalizedLanguage.confirmation}</p>
                        <button className="close-subwindow" onClick={()=>setIsShowConfirmation(false)} >
                            <img src={X_Icon_DarkBlue} alt="" />
                        </button>
                    </div>
                    <div className="subwindow-body">
                        <div className="auto-margin-top" />
                        <p>{LocalizedLanguage.confirmationRedeemPay}</p>
                        <button id="blueButton" onClick={()=>payRedeemAsDisocuntOrder(true)} >{LocalizedLanguage.yes}</button>
                        <button id="cancelDeleteCustomer" onClick={()=>setIsShowConfirmation(false)}>{LocalizedLanguage.no}</button>
                        <div className="auto-margin-bottom"/>
                    </div>
        </div>
    </div>
    </React.Fragment>)
}
export default Checkout