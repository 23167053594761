import React, { useState, useEffect } from "react";
import EmptyCart from '../../../assets/images/svg/EmptyCart.svg';
import { RoundAmount } from "../TaxSetting";
import STATUSES from "../../../constants/apiStatus";
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { get_customerName } from "../localSettings";
import { Markup } from "interweave";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { currecyFunction } from "../commonFunctions/currencyFunction";
import { v4 as uniqueKey } from 'uuid';
const CartListBody = (props) => {

    const [openSaleList, setopenSaleList] = useState(localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null)
    const [taxRate, setTaxRate] = useState(0.00);
    const [listItem, setListItem] = useState([]);
    useEffect(() => {
        calculateCart();
        if (openSaleList) {
            setListItem(openSaleList.ListItem);
        }
    }, [listItem, openSaleList]);

    const [resProduct] = useSelector((state) => [state.product])
    useEffect(() => {
        if (resProduct && resProduct.status == STATUSES.IDLE && resProduct.is_success) {
            setopenSaleList(localStorage.getItem('CHECKLIST') ? JSON.parse(localStorage.getItem('CHECKLIST')) : null);
            calculateCart();
        }
    }, [resProduct]);

    const calculateCart = () => {
        var _subtotal = 0.0;
        var _total = 0.0;
        var _taxAmount = 0.0;
        var _totalDiscountedAmount = 0.0;
        var _customFee = 0.0;
        var _exclTax = 0;
        var _inclTax = 0;
        var _taxId = [];
        var _taxRate = [];
        var TaxIs = [];
        var _subtotalPrice = 0.00;
        var _subtotalDiscount = 0.00;
        var _cartDiscountAmount = 0.00;
        var _productDiscountAmount = 0.00;
        var _seprateDiscountAmount = 0.00;
        var taxratelist = [];
        if ((typeof localStorage.getItem('TAXT_RATE_LIST') !== 'undefined') && localStorage.getItem('TAXT_RATE_LIST') !== null) {
            taxratelist = localStorage.getItem('TAXT_RATE_LIST') && JSON.parse(localStorage.getItem('TAXT_RATE_LIST'));
        }
        if (taxratelist && taxratelist !== null && taxratelist !== "undefined") {
            taxratelist && taxratelist.length > 0 && taxratelist.map(tax => {
                _taxId.push(tax.TaxId);
                _taxRate.push(tax.TaxRate);
                if (tax.check_is == true) {
                    TaxIs.push({ [tax.TaxId]: parseFloat(tax.TaxRate) })
                }
            })
            setTaxRate(_taxRate);
        }
        _taxRate = taxRate;
        listItem && listItem.map((item, index) => {
            if (item.Price) {
                _subtotalPrice += item.Price
                _subtotalDiscount += parseFloat(item.discount_amount == null || isNaN(item.discount_amount) == true ? 0 : item.discount_amount)
                //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
                if (item.product_id || (item.hasOwnProperty("pid") && (item.hasOwnProperty("modifiers_key") || item.hasOwnProperty("addon_key")) && item.hasOwnProperty("Price"))) {//donothing  
                    var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
                    _exclTax += item.excl_tax ? item.excl_tax : 0;
                    _inclTax += item.incl_tax ? item.incl_tax : 0;
                    _cartDiscountAmount += item.cart_discount_amount;
                    // _productDiscountAmount += item.discount_type == "Number" ? item.product_discount_amount:item.product_discount_amount; // quantity commment for addons
                    _productDiscountAmount += item.discount_type == "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType == true ? 1 : item.quantity);
                }
                else {
                    _customFee += item.Price;
                    _exclTax += item.excl_tax ? item.excl_tax : 0;
                    _inclTax += item.incl_tax ? item.incl_tax : 0;
                }
            }
        })
        _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
        _subtotal = _subtotalPrice - _productDiscountAmount;
        _totalDiscountedAmount = _subtotalDiscount;
        if (_taxRate) {
            _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
        }
        _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);
        var _dis = _cartDiscountAmount > 0 ? RoundAmount(_cartDiscountAmount) : 0;

        var shippingTax = openSaleList && openSaleList.shipping_tax ? openSaleList.shipping_tax : 0;
        _taxAmount += shippingTax;

        var shippingAmount = openSaleList && openSaleList.total_shipping ? openSaleList.total_shipping : 0;
        _total += shippingAmount + shippingTax;
        // if (listItem && listItem.length>0) {
        //     var _isWebShop = listItem.filter(a => { return a.isWebShop && a.isWebShop == true });
        //     if (_isWebShop != null && typeof _isWebShop != "undefined" && _isWebShop.length > 0) {
        //         _total = _total + _taxAmount;
        //     }
        // }
        props.setValues && props.setValues(_subtotal, RoundAmount(_taxAmount), _dis, _total, shippingAmount, shippingTax);
    }
    return (
        <div className="body">
            <img src={EmptyCart} alt="" />
            {get_customerName() != null ? <div className="cart-item">
                <div className="main-row aligned">
                    <div className="tag customer">{LocalizedLanguage.customer}</div>
                    <div className="content-style trim-long-email">{get_customerName().Name}</div>
                </div>
            </div> : null}
            {listItem && listItem.length > 0 && listItem.map(a => {
                var notes = listItem.find(b => b.hasOwnProperty('pid') && a.hasOwnProperty('product_id') && (b.pid === a.product_id /*&& b.vid === a.variation_id*/) && (!b.hasOwnProperty("check_modifer") && (b.hasOwnProperty("modifiers_key") && a.hasOwnProperty("modifiers_key") && a.modifiers_key == b.modifiers_key)));
                var varDetail = a.ProductSummery && a.ProductSummery.toString();
                var modifirList = listItem.filter(b => b.hasOwnProperty('pid') && (b.pid === a.product_id) && (b.hasOwnProperty("check_modifer")) && b.hasOwnProperty("modifiers_key") && a.hasOwnProperty("modifiers_key") && a.modifiers_key == b.modifiers_key);
                var addOnList = listItem.filter(b => b.hasOwnProperty('pid') && (b.pid === a.product_id) && (b.hasOwnProperty("check_addons")) &&  a.product_id == b.pid && b.hasOwnProperty("addon_key") && a.hasOwnProperty("addon_key") && a.addon_key == b.addon_key);
                var item_type = "";
                if ((!a.hasOwnProperty('Price') || a.Price == null) && !a.hasOwnProperty('product_id')) {
                    item_type = "no_note";
                }
                else if (a.hasOwnProperty('product_id') && a.product_id !== 0) { item_type = "product"; }
                else if ((a.hasOwnProperty('Price') && !a.hasOwnProperty('pid')) || (a.hasOwnProperty('Price') && a.hasOwnProperty('pid') && a.pid == 0)) { item_type = "custom_fee"; }
                if ((!a.hasOwnProperty('Price') || a.Price == null) && !a.hasOwnProperty('product_id') && (!a.hasOwnProperty('pid')|| (a.hasOwnProperty('pid') && a.pid==0))) { item_type = "note"; }
                switch (item_type) {
                    case "product":
                        return <div className="cart-item" key={uniqueKey()}>
                            <div className="main-row" >
                                <p className="quantity">{a.quantity && a.quantity}</p>
                                <p className="content-style">{a.Title && a.Title}</p>
                                <p className={a.product_discount_amount != 0 ? "strike-through price" : "price"}>
                                    {/* $<NumericFormat className={a.product_discount_amount != 0 ? "strike-through" : ""} value={a.Price} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> */}
                                    {currecyFunction(a.Price)}
                                </p>
                                &nbsp;
                                {a.product_discount_amount != 0 &&
                                    <p className="price" >
                                        {/* $<NumericFormat value={a.discount_type == "Number" ? a.Price - (a.product_discount_amount) : a.Price - (a.product_discount_amount * a.quantity)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} /> */}
                                        {currecyFunction(a.discount_type == "Number" ? a.Price - (a.product_discount_amount) : a.Price - (a.product_discount_amount * a.quantity))}
                                    </p>
                                }
                            </div>
                            {
                                //((typeof a.psummary != "undefined" && a.psummary != "") || (typeof notes != "undefined" && notes != "")) &&
                                <div className="secondary-col" >
                                    {typeof a.psummary != "undefined" && a.psummary != "" && <Markup content={(a.psummary)} noWrap={true} />}
                                    {typeof notes != "undefined" && notes != "" && <Markup content={("<b>**" + LocalizedLanguage.note + ": </b>" + notes.Title)} tagName="p" />}
                                    {modifirList && modifirList !== null && modifirList.map(item => {
                                        return <div className="row">
                                            <p>{item.Title ? item.Title.replace(/_/g, " ") : ""}</p>
                                            {/* <p>{currecyFunction(item.Price ? item.Price : 0)}</p> */}
                                            <p className={item.product_discount_amount !== 0 ? "strike-through" : ""} >
                                                {(currecyFunction(item.Price))}
                                            </p>
                                            {item.product_discount_amount !== 0 && <p >
                                                {(currecyFunction(item.discount_type === "Number" ? item.Price - (item.product_discount_amount) : item.Price - (item.product_discount_amount * item.quantity)))}
                                            </p>}
                                        </div>
                                    })}
{
                                            addOnList && addOnList !== null && addOnList.map(item => {
                                                return  <div className="row">
                                                <p>{item.Title ? item.Title.replace(/_/g, " ") : ""}</p>
                                                {/* <p>{currecyFunction(item.Price ? item.Price : 0)}</p> */}
                                                <p className={item.product_discount_amount !== 0 ? "strike-through" : ""} >
                                                    {item.Price !==0 ? (currecyFunction(item.Price)):""}
                                                </p>
                                                {item.product_discount_amount !== 0 ?<p >
                                                    {(currecyFunction(item.discount_type === "Number" ? item.Price - (item.product_discount_amount) : item.Price - (item.product_discount_amount * item.quantity)))}
                                                </p> :""}
                                            </div>

                                            })
                                            }
                                </div>}
                            {varDetail ? <div className="secondary-col" ><p >{varDetail} </p>
                            </div> : null}
                        </div>
                    case "note":
                        return <div className="cart-item" key={a.Title}>
                            <div className="main-row aligned">
                                <div className="tag cart-note">{LocalizedLanguage.note}</div>
                                <p className="content-style line-capped">
                                    {a.Title && a.Title}
                                </p>
                            </div>
                        </div>
                    case "custom_fee":
                        return <div className="cart-item" key={uniqueKey()}>
                            <div className="main-row aligned">
                                <div className="tag custom-fee">{LocalizedLanguage.customFee}</div>
                                <div className="content-style">{a.Title && a.Title}</div>
                                <div className="price">{currecyFunction(a.Price) && currecyFunction(a.Price)}</div>
                            </div>
                        </div>
                    case "group":
                        return <div className="cart-item" key={a.Title}>
                            <div className="main-row aligned">
                                <div className="tag group">Group</div>
                                <p className="content-style">{a.Title && a.Title}</p>
                            </div>
                        </div>
                    default:
                        return null;
                }
            })}
        </div>)
}
export default CartListBody