import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import IconDarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import Amelia_logo from '../../assets/images/svg/amelia_logo.svg';
import Refresh_icon from '../../assets/images/svg/refresh_icon.svg';
import Down_angled_bracket from '../../assets/images/svg/down-angled-bracket.svg';
import AppIconPlaceholder from '../../assets/images/svg/AppIconPlaceholder.svg';
import {getMenu} from "../dashboard/tiles/ameliaSlice";
import STATUSES from "../../constants/apiStatus";
import Config from '../../Config';
export const Amelia = (props) => {
    const dispatch = useDispatch();
    const [ameliaMenu, setAmelyaMenu] = useState(null);
   
    const navigate = useNavigate()
  
    const [appUrl,setAppUrl]=useState(props.exApp ? props.exApp.PageUrl :'')
    var callAPI=true;
    useEffect(() => {

            if(props.exApp.Name=="Ported Plugin" || props.exApp.Name=="Amelia"){              
                if(callAPI==true){                    
                    callAPI=false;
                    dispatch(getMenu())
                }
               
            }else{
                dispatch(getMenu("null"))
            }
    }, []);


    const [resGetMenu] = useSelector((state) => [state.getMenu])
    useEffect(() => {
        if ((resGetMenu && resGetMenu.status === STATUSES.IDLE && resGetMenu.is_success)) {
           // console.log("Menu",resGetMenu.data.Content);
            setAmelyaMenu(JSON.parse(resGetMenu.data.Content))
        }
    }, [resGetMenu]);


const onOptionChangeHandler = (event) => {
    console.log("User Selected Value - ", event.target.value)
    setAppUrl(event.target.value);
}
    const refreshPage=()=>
    {
        document.getElementById('amelia-Iframe') && document.getElementById('amelia-Iframe').contentDocument && document.getElementById('amelia-Iframe').contentDocument.location.reload(true);
    }
    var menuOptions= ameliaMenu && ameliaMenu!==""?  ameliaMenu.amelia_menus :""
   console.log("menuOptions",menuOptions)
    var urlParameter=(props.exApp.Name=="Ported Plugin" || props.exApp.Name=="Amelia") ? "register_token="+ btoa(localStorage.getItem("AUTH_KEY")).toString():"";
    //console.log("props.exApp.Name",urlParameter)
    // 
    var iframeUrl= appUrl + (urlParameter !=="" ?(appUrl.includes("?")?"&":"?")+ urlParameter :"");//"?register_token="+ btoa(localStorage.getItem("AUTH_KEY")).toString();
   // console.log("iframeUrl",iframeUrl)
    var verNo = new Date().getTime();
    //commenting due to refresh the page when click out the select 
    // if (iframeUrl && typeof iframeUrl !== "undefined" && iframeUrl !== "") {
    //     if (iframeUrl.includes("?")) {
    //         iframeUrl = iframeUrl + "&v=" + verNo;
    //     }
    //     else
    //     {
    //         iframeUrl = iframeUrl + "?v=" + verNo;
    //     }
    // }
    const DisplayPluginPopup = useMemo(()=>{ 
        return <iframe id="amelia-Iframe" src={iframeUrl} frameborder="0" ></iframe>
    },[iframeUrl]);
    return (
        <React.Fragment>
            <div className="amelia-content-container">
                <div className="amelia-content">
                    <div className="amelia-content-header">
                        <div className="amelia-logo">
                            <img src={ props?.exApp?.logo !=' ' ? props?.exApp?.logo : AppIconPlaceholder} alt="" />
                            <p>{props && props.exApp && props.exApp.Name}</p>
                        </div>
                       
                        <div className="amelia-header-end">
                         
                         { (props.exApp.Name=="Ported Plugin" || props.exApp.Name=="Amelia" ) &&
                          menuOptions && menuOptions !=="" && menuOptions.length>0 &&   <p>Menu:</p> }
                         {(props.exApp.Name=="Ported Plugin" || props.exApp.Name=="Amelia" ) &&
                          menuOptions && menuOptions !=="" && menuOptions.length>0 && 
                               <div className="menu-wrapper" id="menuListWrapper">
                                
                                    <div className="option-container" id="menuListOptionsContainer">
                                        <select onChange={onOptionChangeHandler}>                                      
                                   {menuOptions && menuOptions.map(item=>{
                                   
                                        return   <option value={item.page_url} > {item.menu_name}</option>
                                    })} 
                                      </select>
                                    </div>
                                </div>
                        }            
                              
                            <button onClick={()=>refreshPage()}>
                                <img src={Refresh_icon} alt="" />
                            </button>
                            <button onClick={() => {setAmelyaMenu(null); props.toggleAmelia && props.toggleAmelia(props.exApp && props.exApp,props.view && props.view)}}>
                                <img src={IconDarkBlue} alt="" />
                            </button>
                        </div>
                    {/* } */}
                    </div>
                    <div className="amelia-iframe-container">
                        {DisplayPluginPopup}
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}