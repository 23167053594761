import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { PrintPage } from "../common/PrintPage";
import moment from 'moment';
import STATUSES from "../../constants/apiStatus";
import { FormateDateAndTime } from '../../settings/FormateDateAndTime';
import { getTotalTaxByName, TaxSetting, getTaxAllProduct } from "../common/TaxSetting";
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import CommonModuleJS, { getInclusiveTaxType } from "../../settings/CommonModuleJS";
import Config from '../../Config'
import { useIndexedDB } from 'react-indexed-db';
import ViewReceipt from "../common/commonComponents/ViewReceipt";
import MsgPopup from "../common/commonComponents/MsgPopup";
import { getCustomFeeWC_Register, getModifierFromCustomFee, getOrderListOnActivity, getPSummary, getPTaxable, getProductNotes, getWCCustomFeeDetails } from "../../settings/CommonWork";
import CloudPrinterListPopup from "../common/commonComponents/CloudPrinterListPopup";
import { popupMessage } from "../common/commonAPIs/messageSlice";

export const ActivityFooter = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { getAll } = useIndexedDB("products");
    const [activityOrderDetails, setActivityOrderDetails] = useState([])
    const [productList, setProductList] = useState([])
    const [isShowViewReceipt, setisShowViewReceipt] = useState(false);
    const [isShowCloudPrinterList, setShowCloudPrinterList] = useState(false)
    const [isShowMsg, setisShowMsg] = useState(false);
    const [msgTitle, setmsgTitle] = useState('');
    const [msgBody, setmsgBody] = useState('');
    const [cloudPrinters, setCloudPrinters] = useState(localStorage.getItem('cloudPrinters') ? JSON.parse(localStorage.getItem('cloudPrinters')) : []);

    const toggleViewReceipt = () => {
        setisShowViewReceipt(!isShowViewReceipt)
    }
    const toggleMsgPopup = () => {
        setisShowMsg(!isShowMsg)
    }
    const toggleCloudPrinter = () => {

        setShowCloudPrinterList(!isShowCloudPrinterList)
    }
    let useCancelled = false;
    useEffect(() => {
        if (useCancelled == false || productList.length == 0) {
            getAll().then((rows) => {
                var allProdcuts = getTaxAllProduct(rows);
                setProductList(allProdcuts)
            });
        }
        return () => {
            useCancelled = true;
        }
    }, []);



    var JsBarcode = require('jsbarcode');
    var print_bar_code;
    function textToBase64Barcode(text) {
        if (text == "") { return "" };
        var canvas = document.createElement("canvas");
        JsBarcode(canvas, text, {
            format: "CODE128", displayValue: false, width: 1,
            height: 30,
        });
        print_bar_code = canvas.toDataURL("image/png");
        return print_bar_code;
    }
    // const getPSummary = (single_Order_list, _item) => {
    //     var psummary = "";
    //     var getorderlist = single_Order_list && single_Order_list.content && single_Order_list.content.meta_datas && single_Order_list.content.meta_datas !== null ? single_Order_list.content.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
    //     if (getorderlist !== null) {
    //         getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    //         getorderlist && getorderlist.map((item, index) => {
    //             if ((item.hasOwnProperty("product_id") && _item.product_id == item.product_id) || (item.hasOwnProperty("variation_id") && _item.product_id == item.variation_id)) {
    //                 psummary = item.psummary && item.psummary != "" ? item.psummary : "";
    //             }
    //         })
    //     }
    //     return psummary;
    // }
    // const getPTaxable = (single_Order_list, _item) => {
    //     var isTaxable = true;
    //     var getorderlist = [];
    //     if (single_Order_list && single_Order_list.hasOwnProperty("content")) {
    //         getorderlist = single_Order_list && single_Order_list.content && single_Order_list.content.meta_datas && single_Order_list.content.meta_datas !== null ? single_Order_list.content.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
    //     }
    //     else {
    //         getorderlist = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas !== null ? single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
    //     }

    //     if (getorderlist !== null) {
    //         getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    //         getorderlist && getorderlist.map((item, index) => {
    //             if ((item.hasOwnProperty("product_id") && _item.product_id == item.product_id) || (item.hasOwnProperty("variation_id") && _item.product_id == item.variation_id)) {
    //                 if (typeof item.isTaxable !== 'undefined')
    //                     isTaxable = item.isTaxable && item.isTaxable;
    //             }
    //         })
    //     }
    //     return isTaxable;
    // }
    // Getting Response from activitygetDetail Api
    const [activitygetdetails] = useSelector((state) => [state.activityGetDetail])
    useEffect(() => {
        if (activitygetdetails && activitygetdetails.status == STATUSES.IDLE && activitygetdetails.is_success && activitygetdetails.data) {
            var single_Order_list = { ...activitygetdetails.data };
            var refund_tax = 0.0;
            if (single_Order_list && single_Order_list.content && single_Order_list.content.line_items && single_Order_list.content.line_items.length > 0) {
                var items = single_Order_list.content.line_items.map(item => {
                    var _item = { ...item };
                    _item["isTaxable"] = getPTaxable(single_Order_list, item);
                    if (_item.isTaxable == true && _item.quantity_refunded && Math.abs(_item.quantity_refunded) > 0) {
                        refund_tax += (parseFloat(_item.total_tax / _item.quantity) * Math.abs(_item.quantity_refunded));
                    }
                    return _item;
                });
                single_Order_list = { ...single_Order_list.content, tax_refunded: refund_tax, line_items: items };
                // single_Order_list = {...single_Order_list.content, line_items: items};
                //single_Order_list.content.tax_refunded = refund_tax;
                //single_Order_list.content.line_items = items;
                //this.setState({ Details: single_Order_list, isloading_activity: false });
            }
            setActivityOrderDetails(single_Order_list);
        }
    }, [activitygetdetails]);

    var orderList = ''
    var DateTime = activityOrderDetails
    var gmtDateTime = "";
    if (DateTime && DateTime.OrderDateTime && DateTime.time_zone) {
        gmtDateTime = FormateDateAndTime.formatDateAndTime(DateTime.OrderDateTime, DateTime.time_zone)
    }
    var Totalamount = activityOrderDetails ? activityOrderDetails.total_amount : 0
    var cash_rounding_amount = activityOrderDetails ? activityOrderDetails.cash_rounding_amount : 0
    var refunded_amount = activityOrderDetails ? activityOrderDetails.refunded_amount : 0
    var isTotalRefund = false;
    if ((Totalamount - refunded_amount).toFixed(2) == '0.00') {
        isTotalRefund = true
    }
    var type = 'activity';
    var productxList = "";
    var AllProductList = "";
    var order_reciept = localStorage.getItem('orderreciept') ? localStorage.getItem('orderreciept') === "undefined" ? null : JSON.parse(localStorage.getItem('orderreciept')) : "";
    var TotalTaxByName = (order_reciept && order_reciept.ShowCombinedTax == false) ? activityOrderDetails && activityOrderDetails.order_taxes && getTotalTaxByName(activityOrderDetails.order_taxes, activityOrderDetails.order_include_tax, activityOrderDetails.total_amount - activityOrderDetails.total_tax) : "";
    var redeemPointsToPrint = activityOrderDetails ? activityOrderDetails.meta_datas && activityOrderDetails.meta_datas[1] ? activityOrderDetails.meta_datas[1].ItemValue : 0 : 0
    var appreposnse = ''
    // print by local printer in case no cloud printer available or if any available show cloud popup
    const selectPrinting = () => {
        if (cloudPrinters && cloudPrinters.length > 0) {
            setShowCloudPrinterList(true);
        }
        else {
            setisShowViewReceipt(true);
        }
    }


    function PrintClick(isGift = false) {
        // toggleViewReceipt();
        setisShowViewReceipt(false);

        //-----START----HERE YOU CAN FIND THE UPDATED LIST WITH CUSTOM FEE BELOW THE RESPECTIVE PRODUCT
        //var updateItemList = getModifierFromCustomFee(activityOrderDetails.line_items, activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee)
        // var _activityOrderDetails = activityOrderDetails;
        // _activityOrderDetails["updateItemList"] = updateItemList;
        // console.log("--activityOrderDetails--" + JSON.stringify(_activityOrderDetails));
        // console.log("--updateItemList--" + JSON.stringify(updateItemList));
        //-----END----
        // var printersList = cloudPrinters
        // if (printersList && printersList.length > 0) { }
        // else {
        var _activityOrderDetails = activityOrderDetails;
        _activityOrderDetails["isWebShopOrder"] = props.isWebShopOrder ? props.isWebShopOrder : false;
        PrintPage.PrintElem(activityOrderDetails, props.getPdfdateTime, isTotalRefund, cash_rounding_amount, textToBase64Barcode(activityOrderDetails.OliverReciptId == "" ? activityOrderDetails.order_id : activityOrderDetails.OliverReciptId), orderList, type, productxList, AllProductList, TotalTaxByName, redeemPointsToPrint
            , appreposnse, true, null, isGift);
        //}


        //For Tickera ------------------------------------            
        if (activityOrderDetails && activityOrderDetails.TicketDetails && activityOrderDetails.TicketDetails != "" && activityOrderDetails.TicketDetails.length > 0) {
            // Ticket_print();
        }

        //--------------------------------------------
    }


    const VoidPOP = (type) => {
        //   if (type == 'voidsale') {
        //     toggleMsgPopup(true);
        //     setmsgTitle("Message")
        //     setmsgBody("Selected order has been Cancelled.")
        //   }
    }


    const RefundPOP = () => {
        alert('refunded')
    }


    const onClick1 = () => {
        var refund_log = false;
        var { refunded_amount, meta_datas } = activityOrderDetails && activityOrderDetails;
        console.log(refunded_amount, "refund amount", typeof (meta_datas), "meta data")
        meta_datas.map((item) => {
            if (item && item.ItemName == "_order_oliverpos_refund_log") {
                refund_log = true;
                return true
            }
        })
        if (refund_log == false && refunded_amount > 0) {
            var data = { title: "", msg: LocalizedLanguage.refundfromanotherchannel, is_success: true }
            dispatch(popupMessage(data));
            return
        }
        onClick2("statuscompleted", activityOrderDetails ? activityOrderDetails && activityOrderDetails.order_id : 0)
    }



    const onClick2 = (type, id, product_List = []) => {
        if (type == 'statuscompleted' && id) {
            if (CommonModuleJS.permissionsForRefund() == false) {
                toggleMsgPopup(true);
                setmsgTitle(LocalizedLanguage.messageTitle)
                setmsgBody(LocalizedLanguage.refundPermissionerror)
            } else {
                getOrderListOnActivity(activityOrderDetails);
                // var single_Order_list = activityOrderDetails && activityOrderDetails;
                // single_Order_list && single_Order_list.line_items && single_Order_list.line_items.length > 0 &&
                //     single_Order_list.line_items.map(item => {
                //         if (!item.ProductSummery || item.ProductSummery.length == 0) {
                //             var psummary = getPSummary(single_Order_list, item)
                //             item["ProductSummery"] = psummary;
                //         }
                //         item["isTaxable"] = getPTaxable(single_Order_list, item);
                //     });
                // var _order_custom_fee = [];
                // single_Order_list && single_Order_list.order_custom_fee && single_Order_list.order_custom_fee.length > 0 &&
                //     single_Order_list.order_custom_fee.map(item => {
                //         item = getCustomFeeDetails(item);
                //         _order_custom_fee.push(item);
                //         //single_Order_list.line_items.push(item);
                //     })
                // if (_order_custom_fee.length > 0) {
                //     single_Order_list.order_custom_fee = _order_custom_fee;
                // }
                // //----Same product with different modifiers
                // var lineitems = activityOrderDetails && activityOrderDetails.line_items ? activityOrderDetails.line_items : [];
                // if (lineitems.length > 0 && typeof activityOrderDetails.meta_datas != "undefined") {
                //     lineitems = getModifierFromCustomFee(lineitems, activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee);
                // }
                // single_Order_list.line_items = lineitems;
                // //----end

                // //------custom fee
                // if (_order_custom_fee.length > 0) {
                //     var products = single_Order_list.line_items;
                //     var sortedProductArray = []
                //     if (products.length > 0) {
                //         products.map(p => {
                //             sortedProductArray.push(p); //Push parent product
                //             //var child = _order_custom_fee.filter(l => (l.hasOwnProperty("pid") == true && l.pid == p.product_id));
                //             var child = _order_custom_fee.filter(l => l.hasOwnProperty("pid") === true && l.pid === p.product_id
                //                 && ((l.hasOwnProperty("modifiers_Id") && p.hasOwnProperty("modifiers_Id") ? JSON.stringify(l.modifiers_Id) == JSON.stringify(p.modifiers_Id) : true)
                //                     && ((l.hasOwnProperty("modifiers_key") && p.hasOwnProperty("modifiers_key") ? l.modifiers_key == p.modifiers_key : true)))
                //             );
                //             child && child.map(c => {
                //                 sortedProductArray.push(c); // push children of that product
                //             })
                //         })
                //     }
                //     else {
                //         sortedProductArray = ListItem;
                //     }
                //     single_Order_list.line_items = sortedProductArray;
                // }
                // //-------custom fee




                setTimeout(function () {
                    //localStorage.setItem("getorder", JSON.stringify(single_Order_list))
                    localStorage.removeItem("oliver_refund_order_payments");
                    navigate('/refund');
                }, 100)
            }
        }
        if (type == 'statuspending' && id) {
            localStorage.removeItem("oliver_order_payments"); //remove existing payments   
            // sessionStorage.getItem("OrderDetail") for mobile view.............
            var single_Order_list = sessionStorage.getItem("OrderDetail") && sessionStorage.getItem("OrderDetail") !== undefined ? JSON.parse(sessionStorage.getItem("OrderDetail")) : activityOrderDetails
            var addcust;

            var typeOfTax = TaxSetting.typeOfTax()
            if (single_Order_list.meta_datas) {
                if (JSON.stringify(single_Order_list.meta_datas).includes("_order_oliverpos_product_discount_amount") == true) {
                    typeOfTax = getInclusiveTaxType(single_Order_list.meta_datas);
                    if (typeOfTax == "" || typeOfTax == "Tax" || typeOfTax == "tax")
                        typeOfTax = "Tax";
                }
            }
            var setOrderPaymentsToLocalStorage = new Array();
            if (typeof single_Order_list.order_payments !== 'undefined') {
                single_Order_list.order_payments.map(pay => {
                    var _payDetail = {
                        "Id": pay.Id,
                        "payment_type": pay.type,
                        "payment_amount": pay.amount,
                        "order_id": single_Order_list.order_id,
                        "type": pay.type,
                        "transection_id": pay.transection_id
                    }
                    if (pay.payment_date && pay.payment_date != "") {
                        _payDetail["payment_date"] = pay.payment_date;
                    }
                    setOrderPaymentsToLocalStorage.push(_payDetail);

                })
            }

            var single_Order_list = activityOrderDetails && activityOrderDetails;
            single_Order_list && single_Order_list.line_items && single_Order_list.line_items.length > 0 &&
                single_Order_list.line_items.map(item => {
                    if (!item.ProductSummery || item.ProductSummery.length == 0) {
                        var psummary = getPSummary(single_Order_list, item)
                        item["ProductSummery"] = psummary;

                    }
                    item["isTaxable"] = getPTaxable(single_Order_list, item);
                });
            localStorage.setItem("oliver_order_payments", JSON.stringify(setOrderPaymentsToLocalStorage))
            localStorage.setItem("VOID_SALE", "void_sale");
            var deafult_tax = localStorage.getItem('APPLY_DEFAULT_TAX') && localStorage.getItem('APPLY_DEFAULT_TAX') !== undefined ? JSON.parse(localStorage.getItem("APPLY_DEFAULT_TAX")) : null;
            var ListItem = new Array();
            var taxIds = null;
            if (single_Order_list.line_items !== null && single_Order_list.line_items[0] && single_Order_list.line_items[0].Taxes !== null && single_Order_list.line_items[0].Taxes !== 'undefined' && single_Order_list.line_items.length > 0) {
                taxIds = single_Order_list.line_items && single_Order_list.line_items[0].Taxes;
            }
            var taxArray = taxIds && taxIds !== undefined ? JSON.parse(taxIds).total : null;
            var Taxes = taxArray ? Object.entries(taxArray).map(item => ({ [item[0]]: item[1] })) : deafult_tax;
            // console.log("taxIds", taxIds)
            // console.log("Taxes", Taxes)
            single_Order_list.line_items.map((item, index) => {
                //productList from mobile view
                var _productList = productList && productList.length > 0 ? productList : productList;
                //var _productList = allProdcuts && allProdcuts.length > 0 ? allProdcuts : productList;

                if (!_productList || _productList == 'undefined') {

                }
                var productData = _productList.find(prdID => prdID.WPID == item.product_id && (item.bundled_parent_key == '' || item.bundled_parent_key == null));
                var SingleOrderMetaData = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount')
                SingleOrderMetaData = SingleOrderMetaData ? SingleOrderMetaData.ItemValue : []
                var productDiscountData = SingleOrderMetaData && SingleOrderMetaData !== undefined ? SingleOrderMetaData.length > 0 && JSON.parse(SingleOrderMetaData) : []

                // GETTING POSITION OF THE PRODCUT WITH SAME PRODUCT ID BUT DIFFERNT MODIFIERS
                var item_indexs = [];
                var _data = productDiscountData.filter(a => a.hasOwnProperty("product_id"));
                if (_data && _data != null && _data.length > 0) {
                    item_indexs = _data.map(a => productDiscountData.indexOf(a));
                }
                //---------

                var orderMetaData = productDiscountData && productDiscountData != [] && productDiscountData.find((metaData, _index) => (metaData.variation_id ? metaData.variation_id == item.product_id : metaData.product_id == item.product_id)
                    && (item_indexs && item_indexs.length > 0 ? _index == item_indexs[index] : true)
                );
                if (orderMetaData && orderMetaData.discountCart) {
                    var cart = {
                        type: 'card',
                        discountType: (orderMetaData.discountCart.discountType == '%' || orderMetaData.discountCart.discountType == "Percentage") ? "Percentage" : "Number",
                        discount_amount: orderMetaData.discountCart.discount_amount,
                        Tax_rate: orderMetaData.discountCart.Tax_rate
                    }
                    localStorage.setItem("CART", JSON.stringify(cart))
                }
                // var _isTaxable = false;
                // if (typeof productData != "undefined" && productData != null) {
                //     _isTaxable = productData.isTaxable;
                // }
                // else if (typeof orderMetaData != "undefined" && orderMetaData != null) {
                //     _isTaxable = orderMetaData.isTaxable;
                // }
                var _isTaxable = getPTaxable(single_Order_list, item);
                var _isWebShop = (single_Order_list.OliverReciptId == "" && typeOfTax && typeOfTax == "(Incl)") ? true : false;
                if (productData || orderMetaData) {
                    var _item = {
                        Price: orderMetaData && orderMetaData.Price ? orderMetaData.Price : (_isWebShop == true ? (item.subtotal + item.subtotal_tax) : item.subtotal),
                        isWebShop: _isWebShop,
                        // Price: item.subtotal,
                        // Title: item.name,
                        Title: orderMetaData ? orderMetaData.Title : item.name,
                        Sku: orderMetaData ? orderMetaData.Sku : productData && productData.Sku,
                        // product_id: 
                        product_id: orderMetaData ? orderMetaData.product_id : (productData && productData.Type == "variation") ? productData.ParentId : item.product_id,
                        // quantity: item.quantity,
                        quantity: orderMetaData ? orderMetaData.quantity : item.quantity,
                        after_discount: orderMetaData ? orderMetaData.after_discount : (item.total == item.subtotal) ? 0 : item.total,
                        discount_amount: orderMetaData ? orderMetaData.discount_amount : (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // variation_id: (productData.Type == "variation") ? item.product_id : 0,
                        variation_id: orderMetaData ? orderMetaData.variation_id : (productData && productData.Type == "variation") ? item.product_id : 0,
                        cart_after_discount: orderMetaData ? orderMetaData.cart_after_discount : (item.total == item.subtotal) ? 0 : item.total,
                        cart_discount_amount: orderMetaData ? orderMetaData.cart_discount_amount : 0,
                        product_after_discount: orderMetaData ? orderMetaData.product_after_discount : 0,
                        product_discount_amount: orderMetaData ? orderMetaData && orderMetaData.product_discount_amount ? orderMetaData.product_discount_amount : 0 : 0,
                        old_price: orderMetaData ? orderMetaData.old_price : productData ? productData.Price : 0,
                        discount_type: orderMetaData ? orderMetaData.discount_type : null,
                        new_product_discount_amount: orderMetaData ? orderMetaData.new_product_discount_amount : 0,
                        line_item_id: item.line_item_id,
                        subtotalPrice: item.subtotal,
                        subtotaltax: item.subtotal_tax,
                        totalPrice: item.total,
                        totaltax: item.total_tax,
                        // after_discount: (item.total == item.subtotal) ? 0 : item.total,
                        // discount_amount: (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // old_price: productData.Price,
                        incl_tax: (typeOfTax == 'incl' || typeOfTax == '(Incl)') ? item.subtotal_tax : 0,
                        excl_tax: (typeOfTax == 'Tax' || typeOfTax == '') ? item.subtotal_tax : 0,
                        Taxes: item.Taxes,
                        // product_discount_amount: (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // TaxClass: productData.TaxClass,
                        // TaxStatus: productData.TaxStatus,
                        isTaxable: _isTaxable,
                        // ticket_status: productData.IsTicket,
                        ticket_status: orderMetaData ? orderMetaData.ticket_status : null,
                        tick_event_id: orderMetaData ? orderMetaData.tick_event_id : null,
                        ticket_info: orderMetaData ? orderMetaData.ticket_info : null,
                        product_ticket: orderMetaData ? orderMetaData.product_ticket : null,
                        TaxStatus: orderMetaData ? orderMetaData.TaxStatus : productData && productData.TaxStatus,
                        tcForSeating: orderMetaData ? orderMetaData.tcForSeating : null,
                        TaxClass: orderMetaData ? orderMetaData.TaxClass : productData && productData.TaxClass,
                        //addons: item.meta && item.meta ? JSON.parse(item.meta) : '', //COMMENTED BECAUSE IT IS ADDING EXTRA LINEITEM IN THE LISITEM AS PRODUCT X 
                        strProductX: '',
                        ProductSummery: item.ProductSummery,
                    };
                    if (orderMetaData && orderMetaData.hasOwnProperty("modifiers_Id")) {
                        _item["modifiers_Id"] = orderMetaData.modifiers_Id;
                    }
                    if (orderMetaData && orderMetaData.hasOwnProperty("modifiers_key")) {
                        _item["modifiers_key"] = orderMetaData.modifiers_key;
                    }
                    if (orderMetaData && orderMetaData.hasOwnProperty("Type")) {
                        _item["Type"] = orderMetaData.Type;
                    }
                    ListItem.push(_item);
                }
                else {
                    ListItem.push({
                        Price: (_isWebShop == true ? (item.subtotal + item.subtotal_tax) : item.subtotal),
                        isWebShop: _isWebShop,
                        Title: item.name,
                        Sku: item.Sku,
                        product_id: item.product_id,
                        quantity: item.quantity,
                        after_discount: (item.total == item.subtotal) ? 0 : item.total,
                        discount_amount: (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // variation_id: (productData.Type == "variation") ? item.product_id : 0,
                        variation_id: item.variation_id,
                        cart_after_discount: (item.total == item.subtotal) ? 0 : item.total,
                        cart_discount_amount: item.cart_item_discount_amount,
                        product_after_discount: 0,
                        product_discount_amount: 0,
                        old_price: 0,
                        discount_type: null,
                        new_product_discount_amount: 0,
                        line_item_id: item.line_item_id,
                        subtotalPrice: item.subtotal,
                        subtotaltax: item.subtotal_tax,
                        totalPrice: item.total,
                        totaltax: item.total_tax,
                        // after_discount: (item.total == item.subtotal) ? 0 : item.total,
                        // discount_amount: (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // old_price: productData.Price,
                        incl_tax: (typeOfTax == 'incl' || typeOfTax == '(Incl)') ? item.subtotal_tax : 0,
                        excl_tax: (typeOfTax == 'Tax' || typeOfTax == '') ? item.subtotal_tax : 0,
                        Taxes: item.Taxes,
                        // product_discount_amount: (item.total == item.subtotal) ? 0 : item.subtotal - item.total,
                        // TaxClass: productData.TaxClass,
                        // TaxStatus: productData.TaxStatus,
                        isTaxable: _isTaxable,
                        // ticket_status: productData.IsTicket,
                        ticket_status: null,
                        tick_event_id: null,
                        ticket_info: null,
                        product_ticket: null,
                        TaxStatus: '',
                        tcForSeating: null,
                        TaxClass: '',
                        addons: item.meta && item.meta ? JSON.parse(item.meta) : '',
                        strProductX: '',
                        ProductSummery: item.ProductSummery,
                    })
                }
            })
            ////-----
            var isIncl = typeOfTax !== "" ? typeOfTax.toLocaleLowerCase().includes("incl") : false;
            var custFeeItemList = getCustomFeeWC_Register(single_Order_list, isIncl);
            ListItem = [...new Set([...ListItem, ...custFeeItemList])];
            //-------

            //----Same product with different modifiers
            // if (ListItem.length > 0 && typeof activityOrderDetails.meta_datas != "undefined") {
            //     ListItem = getModifierFromCustomFee(ListItem, activityOrderDetails.meta_datas, activityOrderDetails.order_custom_fee);
            // }
            //----end

            // add custom fee to the CARD_PRODUCT_LIST
            var orderMeta = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount');
            orderMeta = orderMeta ? orderMeta.ItemValue : [];
            var parsedFeeData = orderMeta && orderMeta !== undefined ? orderMeta.length > 0 && JSON.parse(orderMeta) : [];
            var orderFeeData = parsedFeeData && parsedFeeData.length>0 && parsedFeeData.find(item => item.order_custom_fee);

            orderFeeData && orderFeeData.order_custom_fee.map(item => {
                single_Order_list.order_custom_fee.map(listItem => {
                    if (item.amount === listItem.amount && (item.Title == "" || (item.Title === listItem.note))) {
                        item['fee_id'] = listItem.fee_id;
                    }
                });
            })

            if (orderFeeData && orderFeeData.order_custom_fee.length > 0 && orderFeeData.order_custom_fee) {
                orderFeeData && orderFeeData.order_custom_fee.map(item => {
                    // var isCustomeFeeExist= ListItem.some(i => item.amount === i.Price && item.note === i.Title);
                    // if(isCustomeFeeExist==false) //checking the custome already exist in  case of park sale
                    // {
                    var _item = {
                        Title: item.note,
                        Price: item.amount !== 0 ? item.amount : null,
                        TaxClass: item.TaxClass,
                        TaxStatus: item.TaxStatus,
                        after_discount: item.after_discount,
                        cart_after_discount: item.cart_after_discount,
                        cart_discount_amount: item.cart_discount_amount,
                        discount_amount: item.discount_amount,
                        discount_type: item.discount_type,
                        excl_tax: item.excl_tax,
                        incl_tax: item.incl_tax,
                        isTaxable: item.isTaxable,
                        new_product_discount_amount: item.new_product_discount_amount,
                        old_price: item.old_price,
                        product_after_discount: item.product_after_discount,
                        product_discount_amount: item.product_discount_amount,
                        quantity: item.quantity,
                        fee_id: item.fee_id,
                        check_modifer: item.hasOwnProperty("pid") ? true : false,
                        pid: item.pid

                    };

                    if (item.hasOwnProperty("modifiers_key")) {
                        _item["modifiers_key"] = item.modifiers_key;
                    }
                    ListItem.push(_item);
                    // }
                })
            }

            //18july2023
            var _notes_item = getProductNotes(single_Order_list.meta_datas, null, true);

            // add notes in cart list
            if ((typeof single_Order_list.order_notes !== 'undefined') && single_Order_list.order_notes.length !== 0) {
                single_Order_list.order_notes.map((item, index) => {
                    var _item = {
                        Title: item.note,
                        id: item.note_id
                    };

                    if (_notes_item && _notes_item.length > 0) {
                        const current_note = _notes_item[index];
                        if(current_note && current_note!="" && current_note!=null)
                        {
                            if (current_note.hasOwnProperty("modifiers_key")) {
                                _item["modifiers_key"] = current_note.modifiers_key;
                            }
                            if (current_note.hasOwnProperty("pid")) {
                                _item["pid"] = current_note.pid;
                            }
                        }
                    }       
                    if (item.hasOwnProperty("modifiers_key")) {
                        _item["modifiers_key"] = item.modifiers_key;
                    }
                    ListItem.push(_item);
                })
            }


            if ((typeof single_Order_list.order_payments !== 'undefined') && single_Order_list.order_payments.length == 0 && single_Order_list && single_Order_list.order_id == 0) {
                //this.props.single_Order_list && this.props.single_Order_list.order_id == 0) {
                localStorage.setItem("CARD_PRODUCT_LIST", JSON.stringify(ListItem))
                localStorage.removeItem("VOID_SALE")
            } else {
                if ((typeof single_Order_list !== 'undefined') && single_Order_list.order_status != "park_sale" && single_Order_list.order_status != "pending" && single_Order_list.order_status !== 'on-hold' && single_Order_list.order_status !== 'lay_away') {
                    // if (single_Order_list.order_status != "park_sale" && single_Order_list.order_status != "pending") {
                    localStorage.setItem("VOID_SALE", "void_sale")
                    localStorage.removeItem("CARD_PRODUCT_LIST")
                    // remove void sale for park_sale
                } else {
                    localStorage.setItem("CARD_PRODUCT_LIST", JSON.stringify(ListItem))
                    if (localStorage.getItem("oliver_order_payments") == null || (typeof single_Order_list.order_payments !== 'undefined') && single_Order_list.order_payments.length == 0) {
                        localStorage.removeItem("VOID_SALE")
                    }
                }
            }
            //  console.log("single_Order_list",single_Order_list)
            var orderCustomerInfo = (typeof single_Order_list.orderCustomerInfo !== 'undefined') && single_Order_list.orderCustomerInfo !== null ? single_Order_list.orderCustomerInfo : null;
            if (orderCustomerInfo !== null) {
                addcust = {

                    AccountBalance: 0,
                    City: orderCustomerInfo.customer_city ? orderCustomerInfo.customer_city : '',
                    Email: orderCustomerInfo.customer_email ? orderCustomerInfo.customer_email : '',
                    FirstName: orderCustomerInfo.customer_first_name ? orderCustomerInfo.customer_first_name : '',
                    //ASPId: orderCustomerInfo.customer_id ? orderCustomerInfo.customer_id : single_Order_list.customer_id,
                    LastName: orderCustomerInfo.customer_last_name ? orderCustomerInfo.customer_last_name : '',
                    Notes: orderCustomerInfo.customer_note ? orderCustomerInfo.customer_note : '',
                    Phone: orderCustomerInfo.customer_phone ? orderCustomerInfo.customer_phone : '',
                    Pin: 0,
                    Pincode: orderCustomerInfo.customer_post_code ? orderCustomerInfo.customer_post_code : '',
                    StoreCredit: orderCustomerInfo.store_credit ? orderCustomerInfo.store_credit : '',
                    StreetAddress: orderCustomerInfo.customer_address ? orderCustomerInfo.customer_address : '',
                    UID: 0,
                    WPId: orderCustomerInfo.customer_id ? orderCustomerInfo.customer_id : single_Order_list.customer_id,
                    customerAddress: orderCustomerInfo.customerAddress
                }
                localStorage.setItem('AdCusDetail', JSON.stringify(addcust));
                sessionStorage.setItem("CUSTOMER_ID", orderCustomerInfo.customer_id ? orderCustomerInfo.customer_id : single_Order_list.customer_id)
            }
            // single_Order_list.line_items.map(item => {

            // var discountOrderMeta = single_Order_list && single_Order_list.meta_datas[2] ? single_Order_list.meta_datas[2].ItemValue : []
            var SingleOrderMetaData = single_Order_list && single_Order_list.meta_datas && single_Order_list.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount')
            SingleOrderMetaData = SingleOrderMetaData && SingleOrderMetaData !== undefined ? SingleOrderMetaData.ItemValue : []
            var productDiscountData = SingleOrderMetaData.length > 0 && JSON.parse(SingleOrderMetaData)
            // var orderMetaData = productDiscountData && productDiscountData != [] && productDiscountData.find(metaData => metaData.product_id);

            // total_subTotal_fileds sent from checkout in meta when we order as a park or lay-away
            var orderMetaData = productDiscountData && productDiscountData != [] && productDiscountData.find(itm => itm.total_subTotal_fileds);
            // });
            orderMetaData = orderMetaData && orderMetaData.total_subTotal_fileds && orderMetaData.total_subTotal_fileds.totalPrice && orderMetaData.total_subTotal_fileds.subTotal ? orderMetaData.total_subTotal_fileds : null
            var CheckoutList = {
                ListItem: ListItem,
                customerDetail: orderCustomerInfo ? addcust : null,
                totalPrice: orderMetaData ? orderMetaData.totalPrice : single_Order_list.total_amount,
                // totalPrice: single_Order_list.total_amount,
                discountCalculated: single_Order_list.discount,
                tax: single_Order_list.total_tax,
                subTotal: orderMetaData ? parseFloat(orderMetaData.subTotal) : parseFloat(single_Order_list.total_amount) - parseFloat(single_Order_list.total_tax),
                // subTotal: parseFloat(single_Order_list.total_amount) - parseFloat(single_Order_list.total_tax),
                // TaxId: deafult_tax && deafult_tax[0] ? deafult_tax[0].TaxId : 0,
                TaxId: Taxes ? Taxes : 0,
                status: single_Order_list.order_status,
                order_id: single_Order_list && single_Order_list.order_id,
                oliver_pos_receipt_id: single_Order_list && single_Order_list.OliverReciptId,
                order_date: moment(single_Order_list.OrderDateTime).format(Config.key.DATETIME_FORMAT),
                showTaxStaus: typeOfTax == 'Tax' ? typeOfTax : 'Incl. Tax',
                total_shipping: single_Order_list && single_Order_list.total_shipping ? single_Order_list.total_shipping : 0,
                shipping_tax: single_Order_list && single_Order_list.shipping_tax ? single_Order_list.shipping_tax : 0,
                isWebShopOrder: props.isWebShopOrder ? props.isWebShopOrder : false
            }
            localStorage.removeItem('PENDING_PAYMENTS');
            localStorage.setItem("CHECKLIST", JSON.stringify(CheckoutList))
            var addonsItem = []
            ListItem && ListItem.map((list) => {
                if (list && list.addons && list.addons !== '' && list.addons.length > 0) {
                    list['Type'] = list.variation_id && list.variation_id !== 0 ? 'variable' : 'simple'
                    list['line_subtotal'] = list.Price
                    list['line_subtotal_tax'] = list.subtotaltax
                    list['line_tax'] = list.totaltax
                    list['strProductX'] = ''
                    addonsItem.push(list)
                }
            })
            localStorage.setItem("PRODUCTX_DATA", JSON.stringify(addonsItem))
            localStorage.setItem("BACK_CHECKOUT", true)
            // window.location = '/checkout';
            navigate('/checkout')

        }
    }

    // const getCustomFeeDetails = (_itemPara) => {
    //     var _item = { ..._itemPara };
    //     var getorderlist = activityOrderDetails && activityOrderDetails.meta_datas && activityOrderDetails.meta_datas !== null ? activityOrderDetails.meta_datas.find(data => data.ItemName == '_order_oliverpos_product_discount_amount') : null;
    //     if (getorderlist !== null) {
    //         getorderlist = getorderlist && getorderlist.ItemValue && JSON.parse(getorderlist.ItemValue);
    //         getorderlist && getorderlist.map((item, index) => {
    //             item && item.order_custom_fee && item.order_custom_fee.map((fee, index) => {
    //                 if (item.order_custom_fee != null && _item.note == fee.note) {
    //                     var _total_tax = {};
    //                     fee.total_taxes && fee.total_taxes.map((item, index) => {
    //                         Object.assign(_total_tax, item)
    //                     });
    //                     // var _Tax= JSON.stringify({"total":(fee.total_taxes && Array.isArray(fee.total_taxes) && fee.total_taxes.length>0) ?fee.total_taxes[0]:{},"subtotal":fee.subtotal_taxes && Array.isArray(fee.subtotal_taxes) &&fee.subtotal_taxes.length>0?fee.subtotal_taxes[0]:{}});
    //                     var _Tax = JSON.stringify({ "total": _total_tax, "subtotal": _total_tax });
    //                     _item['price'] = fee.incl_tax != 0 ? _item.amount - fee.total_tax : _item.amount;
    //                     _item['line_item_id'] = _item.fee_id;
    //                     _item['quantity'] = 1;
    //                     _item['total'] = fee.incl_tax != 0 ? _item.amount - fee.total_tax : _item.amount;
    //                     _item['subtotal'] = fee.incl_tax != 0 ? _item.amount - fee.subtotal_tax : item.amount;
    //                     // _item['subtotal_tax'] = 0;
    //                     // _item['total_tax'] = _item.total_tax;
    //                     _item['name'] = _item.note;
    //                     _item['is_ticket'] = false;
    //                     _item['product_id'] = 0;
    //                     _item['parent_id'] = 0;
    //                     _item['Taxes'] = _Tax;

    //                     _item['variation_id'] = 0;
    //                     _item['amount_refunded'] = _item.amount_refunded;
    //                     _item['quantity_refunded'] = _item.amount_refunded > 0 ? -1 : 0;
    //                     // var _subtotal=_item.subtotal;
    //                     _item['subtotal_tax'] = fee.subtotal_tax ? fee.subtotal_tax : 0;
    //                     _item['subtotal_taxes'] = fee.subtotal_taxes ? fee.subtotal_taxes : [];
    //                     _item['total_tax'] = fee.total_tax ? fee.total_tax : 0;
    //                     _item['total_taxes'] = fee.total_taxes ? fee.total_taxes : [];
    //                     _item['pid'] = fee.pid ? fee.pid : 0;

    //                     if (fee.hasOwnProperty("modifiers_Id")) {
    //                         _item["modifiers_Id"] = fee.modifiers_Id;
    //                     }
    //                     if (fee.hasOwnProperty("modifiers_key")) {
    //                         _item["modifiers_key"] = fee.modifiers_key;
    //                     }
    //                     if (fee.hasOwnProperty("cart_discount_amount") && fee.cart_discount_amount != 0) {
    //                         _item["cart_discount_amount"] += fee.cart_discount_amount;
    //                     }
    //                     if (fee.hasOwnProperty("product_discount_amount") && fee.product_discount_amount != 0) {
    //                         _item["product_discount_amount"] += fee.product_discount_amount* (fee.discount_type === "Number" ? 1 : fee.quantity);
    //                     }

    //                 }
    //             });
    //         })
    //     }
    //     return _item
    // }

    return (
        <React.Fragment>
            <div className="footer">
                <button id="refundButton" disabled={activityOrderDetails && activityOrderDetails !== null && (activityOrderDetails.order_status == "refunded" || activityOrderDetails.order_status == "pending" || activityOrderDetails.order_status == "lay_away" || activityOrderDetails.order_status == "on-hold" || activityOrderDetails.order_status == "void_sale"
                    || activityOrderDetails.order_status == "park_sale" || activityOrderDetails.order_status == "init sale" || activityOrderDetails.order_status == "processing"
                    || activityOrderDetails.order_status == "" || activityOrderDetails.order_status == "cancelled") ? true : false} style={{
                        opacity: activityOrderDetails && activityOrderDetails !== null && (activityOrderDetails.order_status == "refunded" || activityOrderDetails.order_status == "pending" || activityOrderDetails.order_status == "lay_away" || activityOrderDetails.order_status == "on-hold" || activityOrderDetails.order_status == "void_sale"
                            || activityOrderDetails.order_status == "park_sale" || activityOrderDetails.order_status == "init sale" || activityOrderDetails.order_status == "processing"
                            || activityOrderDetails.order_status == "" || activityOrderDetails.order_status == "cancelled") ? 0.5 : 1
                    }} onClick={() =>
                        activityOrderDetails.order_status == 'completed' ? onClick1()
                            : (activityOrderDetails.order_status == "pending" || activityOrderDetails.order_status == "lay_away" || activityOrderDetails.order_status == "on-hold" || activityOrderDetails.order_status == "park_sale" || activityOrderDetails.order_status == "init sale" || activityOrderDetails.order_status == "processing") ? onClick2("statuspending", activityOrderDetails ? activityOrderDetails && activityOrderDetails.order_id : '')
                                : activityOrderDetails.order_status == "refunded" ? RefundPOP
                                    : (activityOrderDetails.order_status == "void_sale" || activityOrderDetails && activityOrderDetails.order_status == "cancelled" || activityOrderDetails.order_status == "cancelled_sale") ? VoidPOP('voidsale')
                                        : null
                    }  > {activityOrderDetails.order_status == 'completed' ? LocalizedLanguage.refundSale
                        : (activityOrderDetails.order_status == "pending" || activityOrderDetails.order_status == "lay_away" || activityOrderDetails.order_status == "on-hold"
                            || activityOrderDetails.order_status == "park_sale" || activityOrderDetails.order_status == "init sale" || activityOrderDetails.order_status == "processing"
                            || activityOrderDetails.order_status == "") ? LocalizedLanguage.refundedSale
                            : activityOrderDetails.order_status == "refunded" ? LocalizedLanguage.refundedSale
                                : (activityOrderDetails.order_status == "void_sale" || activityOrderDetails.order_status == "cancelled" || activityOrderDetails.order_status == "cancelled_sale") ? LocalizedLanguage.cancel
                                    : null
                    }</button>

                <button id="receiptButton" onClick={() => selectPrinting()}>{LocalizedLanguage.Receipt}</button>
                <button id="receiptButton" onClick={() => PrintClick(true)}>{LocalizedLanguage.printGiftReceipt}</button>
                <button disabled={activityOrderDetails && activityOrderDetails !== null && (activityOrderDetails.order_status == 'completed' || activityOrderDetails && activityOrderDetails.order_status == 'refunded' || (activityOrderDetails.order_status == "void_sale" || activityOrderDetails.order_status == "cancelled" || activityOrderDetails.order_status == "cancelled_sale")) ? true : false} style={{ opacity: activityOrderDetails && activityOrderDetails.order_status == 'completed' || activityOrderDetails && activityOrderDetails.order_status == 'refunded' || (activityOrderDetails.order_status == "void_sale" || activityOrderDetails.order_status == "cancelled" || activityOrderDetails.order_status == "cancelled_sale") ? 0.5 : 1 }} onClick={() =>
                    activityOrderDetails && activityOrderDetails !== null && (activityOrderDetails.order_status == "pending" ||
                        activityOrderDetails.order_status == "lay_away" ||
                        activityOrderDetails.order_status == "on-hold" ||
                        activityOrderDetails.order_status == "park_sale" ||
                        activityOrderDetails.order_status == "init sale" ||
                        activityOrderDetails.order_status == "processing")
                        ? onClick2("statuspending", activityOrderDetails ? activityOrderDetails && activityOrderDetails.order_id : '') : null} id="openSaleButton">{LocalizedLanguage.openSale}</button>
            </div >
            {isShowCloudPrinterList ? <CloudPrinterListPopup type="activity" order_id={activityOrderDetails ? activityOrderDetails && activityOrderDetails.order_id : ''} isShow={isShowCloudPrinterList} toggleCloudPrinter={toggleCloudPrinter} localPrinterPrint={PrintClick}></CloudPrinterListPopup> : null}
            {isShowViewReceipt ? <ViewReceipt isShow={isShowViewReceipt} toggleViewReceipt={toggleViewReceipt} PrintClick={PrintClick}></ViewReceipt> : null}
            <MsgPopup isShow={isShowMsg} toggleMsgPopup={toggleMsgPopup} msgTitle={msgTitle} msgBody={msgBody}></MsgPopup>
        </React.Fragment >
    )
}
