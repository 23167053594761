import { get_UDid } from "../../common/localSettings";
import moment from 'moment';
import { getTaxCartProduct, getInclusiveTax, getExclusiveTax, typeOfTax } from "../../common/TaxSetting";
import LocalizedLanguage from "../../../settings/LocalizedLanguage";
import { orderToCancelSale } from "../../checkout/checkoutSlice";
import { store } from "../../../app/store";
import { popupMessage } from "../../common/commonAPIs/messageSlice";
import { sendDataToWrapper, wrapperReceiptData } from "../../../settings/AndroidIOSConnect";
import { getModifierTotalAmount } from "../../../settings/CommonWork";
function percentWiseDiscount(price, discount) {
    var discountAmount = (price * discount) / 100.00;
    return discountAmount;
}

function NumberWiseDiscount(price, discount) {
    var discountAmount = (discount * 100.00) / price;
    return discountAmount;
}
function stockUpdateQuantity(cardData, data, product) {
    var qty = 0
    cardData.map(item => {
        if (data.product_id === item.product_id) {
            qty += item.quantity;
        }
    })
    if (product) {
        var quantity = (product.StockStatus == null || product.StockStatus == 'instock') && product.ManagingStock == false ? "Unlimited" : (typeof product.StockQuantity != 'undefined') && product.StockQuantity != '' ? product.StockQuantity - qty : '0';
        localStorage.setItem("CART_QTY", quantity);
    }
}
export const updateProductNote = (note, modifiers_key) => {
    var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
    var isExist = false;
    if (cartlist && cartlist.length > 0) {
        cartlist.map(findId => {
            if (findId.hasOwnProperty("pid") && findId.pid === note.pid && !findId.hasOwnProperty("check_modifer") && (findId.hasOwnProperty("modifiers_key") ? findId.modifiers_key == modifiers_key : true)) {
                findId.Title = note.Title;
                isExist = true;
            }
        });
        localStorage.setItem("CARD_PRODUCT_LIST", JSON.stringify(cartlist));
    }
    return isExist;
}
export const addSimpleProducttoCart = (product, ticketFields = null, isUpdate = null) => {
    const isBackOrdered = product && (product.BackOrderd === 'yes' || product.BackOrderd === 'notify' || product.StockStatus === 'onbackorder');// && product.StockQuantity < 1;
    //const { dispatch, checkout_list, cartproductlist } = this.props;
    var cartproductlist = [];
    var cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
    var single_product = localStorage.getItem("SINGLE_PRODUCT") ? JSON.parse(localStorage.getItem("SINGLE_PRODUCT")) : null;
    if (cartlist.length > 0 && !single_product) {
        cartlist.map(findId => {
            // if (findId.product_id === product.WPID
            //     && (((findId.hasOwnProperty("modifiers_Id") && product.hasOwnProperty("modifiers_Id") ? JSON.stringify(findId.modifiers_Id) == JSON.stringify(product.modifiers_Id) : true)
            //         && (
            //             (findId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? findId.modifiers_key == product.modifiers_key : true)))
            //         || (!findId.hasOwnProperty("modifiers_Id") && !product.hasOwnProperty("modifiers_Id"))
            //     )
            // )
            if (findId.product_id === product.WPID
                && (findId.hasOwnProperty("modifiers_Id") && product.hasOwnProperty("modifiers_Id") ? JSON.stringify(findId.modifiers_Id) == JSON.stringify(product.modifiers_Id) : true)
                && (findId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? findId.modifiers_key == product.modifiers_key : true)
                && (findId ? findId.product_discount_amount : 0) > 0
            ) {
                product['after_discount'] = findId ? findId.after_discount : 0;
                product['discount_amount'] = findId ? findId.discount_amount : 0;
                product['product_after_discount'] = findId ? findId.product_after_discount : 0;
                product['product_discount_amount'] = findId ? findId.product_discount_amount : 0;
                product['discount_type'] = findId ? findId.discount_type : "";
                product['new_product_discount_amount'] = findId ? findId.new_product_discount_amount : 0;
            }
        });
    }

    if (cartlist.length > 0 && single_product) {
        cartlist.map(prdId => {
            // if (prdId.product_id === product.WPID
            //     && (((prdId.hasOwnProperty("modifiers_Id") && product.hasOwnProperty("modifiers_Id") ? JSON.stringify(prdId.modifiers_Id) == JSON.stringify(product.modifiers_Id) : true)
            //         && (
            //             (prdId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? prdId.modifiers_key == product.modifiers_key : true)))
            //         || (!prdId.hasOwnProperty("modifiers_Id") && !product.hasOwnProperty("modifiers_Id"))
            //     )
            // )
            if (prdId.product_id === product.WPID
                && (prdId.hasOwnProperty("modifiers_Id") && product.hasOwnProperty("modifiers_Id") ? JSON.stringify(prdId.modifiers_Id) == JSON.stringify(product.modifiers_Id) : true)
                && (prdId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? prdId.modifiers_key == product.modifiers_key : true)
            ) {
                product['after_discount'] = single_product.after_discount;
                product['product_discount_amount'] = single_product.product_discount_amount;
                product['product_after_discount'] = single_product.product_after_discount;
                product['new_product_discount_amount'] = single_product.new_product_discount_amount;
                product['discount_amount'] = single_product.discount_amount;
                product['discount_type'] = single_product.discount_type;
                product['cart_after_discount'] = single_product.cart_after_discount;
                product['cart_discount_amount'] = single_product.cart_discount_amount;
            }
        })
    }
    else if (single_product) {
        product['after_discount'] = single_product.after_discount;
        product['product_discount_amount'] = single_product.product_discount_amount;
        product['product_after_discount'] = single_product.product_after_discount;
        product['new_product_discount_amount'] = single_product.new_product_discount_amount;
        product['discount_amount'] = single_product.discount_amount;
        product['discount_type'] = single_product.discount_type;
        product['cart_after_discount'] = single_product.cart_after_discount;
        product['cart_discount_amount'] = single_product.cart_discount_amount;
    }
    if (cartlist.length > 0 && single_product) {
        var _tempPro = cartlist.find(prdId => prdId.product_id === product.WPID
            && (prdId.hasOwnProperty("modifiers_Id") && product.hasOwnProperty("modifiers_Id") ? JSON.stringify(prdId.modifiers_Id) == JSON.stringify(product.modifiers_Id) : true)
            && ((prdId.hasOwnProperty("modifiers_key") && product.hasOwnProperty("modifiers_key") ? prdId.modifiers_key == product.modifiers_key : true))
        );

        if (typeof _tempPro == "undefined" || _tempPro == null || !_tempPro) {
            product['after_discount'] = single_product.after_discount;
            product['product_discount_amount'] = single_product.product_discount_amount;
            product['product_after_discount'] = single_product.product_after_discount;
            product['new_product_discount_amount'] = single_product.new_product_discount_amount;
            product['discount_amount'] = single_product.discount_amount;
            product['discount_type'] = single_product.discount_type;
            product['cart_after_discount'] = single_product.cart_after_discount;
            product['cart_discount_amount'] = single_product.cart_discount_amount;
        }
    }


    var setQunatity = typeof product.quantity != "undefined" ? ((isBackOrdered == true && product.quantity <= 0) ? 1 : product.quantity) : 1;
    // var setQunatity = 1;

    var tick_data = product && product.IsTicket == true && product.TicketInfo != '' ? JSON.parse(product.TicketInfo) : '';
    //var availability_to_date = tick_data && tick_data !== 'null' ? moment(tick_data._ticket_availability_to_date).format('YYYY-MM-DD') : ''
    //var today_date = moment().format('YYYY-MM-DD')

    if (product && product.IsTicket == true && ticketFields == null) {
        //var tick_type = 'simpleadd';
        //this.getTicketFields(product, tick_type)
    }
    if (product !== null && product !== undefined && product.IsTicket == false) {
        var data = {
            line_item_id: 0,
            cart_after_discount: product.cart_after_discount ? product.cart_after_discount : 0,
            cart_discount_amount: product.cart_discount_amount ? product.cart_discount_amount : 0,
            after_discount: product.after_discount ? product.after_discount : 0,
            discount_amount: product.discount_amount ? product.discount_amount : 0,
            product_after_discount: product.product_after_discount ? product.product_after_discount : 0,
            product_discount_amount: product.product_discount_amount ? product.product_discount_amount : 0,
            quantity: setQunatity,
            Title: product.Title,
            Sku: product.Sku,
            Price: setQunatity * parseFloat(product.Price),
            product_id: product.WPID,
            variation_id: 0,
            isTaxable: product.Taxable,
            old_price: product.old_price,
            incl_tax: product.incl_tax,
            excl_tax: product.excl_tax,
            ticket_status: product.IsTicket,
            discount_type: product.discount_type ? product.discount_type : "",
            new_product_discount_amount: product ? product.new_product_discount_amount : 0,
            TaxStatus: product ? product.TaxStatus : "",
            TaxClass: product ? product.TaxClass : '',
            Type: product ? product.Type : '',
            psummary: product ? product.psummary : '',
            combination: product ? product.combination : '',
            categories: product.CategorieList ? product.CategorieList : [],
            BackOrderd: product.BackOrderd //product.BackOrderd === 'yes' ? true : false
        }
        if (product.hasOwnProperty("modifiers_Id")) {
            data["modifiers_Id"] = product.modifiers_Id;
        }
        if (product.hasOwnProperty("modifiers_key")) {
            data["modifiers_key"] = product.modifiers_key;
        }
        if (product.hasOwnProperty("addon_ids")) {
            data["addon_ids"] = product.addon_ids;
        }
        if (product.hasOwnProperty("addon_key")) {
            data["addon_key"] = product.addon_key;
        }
        if (product.hasOwnProperty("pid")) {
            data["pid"] = product.pid;
        }
        var qty = 0;
        cartlist.map(item => {
            if (product.WPID == item.product_id
                && ((product.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") ? JSON.stringify(product.modifiers_Id) == JSON.stringify(item.modifiers_Id) : true)
                    && ((product.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? product.modifiers_key == item.modifiers_key : true)))
            ) {
                if (product.quantity > 1) {

                    qty = product.quantity;
                    if (isUpdate != null && isUpdate === false) {
                        qty += item.quantity;
                    }
                }
                else {
                    if ((item.hasOwnProperty("quantity") && item.quantity) == product.StockQuantity) {
                        if (product.StockQuantity == 2)
                            qty = item.quantity;// + 1;
                        else
                            qty = item.quantity + 1;
                    }
                    else {
                        qty = item.quantity;

                    }

                }
            }
        })
        //  add simple product with below condotions
        var product_is_exist = (product.ManagingStock == false && product.StockStatus == "outofstock") ? "outofstock" :
            (product.StockStatus == null || product.StockStatus == 'instock') && product.ManagingStock == false ? "Unlimited" : (typeof product.StockQuantity != 'undefined')
                && (product.StockQuantity != '') ? (isUpdate == null ? qty < product.StockQuantity : qty <= product.StockQuantity) : '0'
        if (product_is_exist == '0' || product_is_exist == false) {
            if (typeof product.Price != "undefined" && isBackOrdered == false) { return 'outofstock'; }
            else { product_is_exist = true; }
        }
        //changes made in case of unlimited quantity, ManagingStock=false
        if (product.ManagingStock === false) {
            product_is_exist = "Unlimited";
        }
        if (isBackOrdered == true || (product_is_exist !== 'outofstock' && product_is_exist !== '0' && product_is_exist == true || product_is_exist == 'Unlimited')) {
            //Added this code to replace the product at the existing position in the case of edit
            if (product.hasOwnProperty("selectedIndex")) {
                cartlist[product.selectedIndex] = data;
            }
            else {
                cartlist.push(data);
            }

            //cartlist.push(data);
            //Android Call----------------------------
            var totalPrice = 0.0;
            cartlist && cartlist.map(item => {
                totalPrice += item.Price;
            })
            //androidDisplayScreen(data.Title, data.Price, totalPrice, "cart");
            //-----------------------------------------
            stockUpdateQuantity(cartlist, data, product)
            if ((!localStorage.getItem("APPLY_DEFAULT_TAX")) || localStorage.getItem("APPLY_DEFAULT_TAX") == null) {
                // setTimeout(() => {
                addtoCartProduct(cartlist, null, product);
                // }, 400);
            } else {
                addtoCartProduct(cartlist, null, product);
            }
        } else {
            return 'outofstock';
        }
    }
    else if (product !== null && product !== undefined && product.IsTicket == true && ticketFields != null) {
        var TicketInfoForSeat = product && product.TicketInfo && JSON.parse(product.TicketInfo);
        var tcForSeating = TicketInfoForSeat ? TicketInfoForSeat : "";
        this.setState({ ticket_Product_status: false })
        var data = {
            line_item_id: 0,
            cart_after_discount: product.cart_after_discount ? product.cart_after_discount : 0,
            cart_discount_amount: product.cart_discount_amount ? product.cart_discount_amount : 0,
            after_discount: product.after_discount ? product.after_discount : 0,
            discount_amount: product.discount_amount ? product.discount_amount : 0,
            product_after_discount: product.product_after_discount ? product.product_after_discount : 0,
            product_discount_amount: product.product_discount_amount ? product.product_discount_amount : 0,
            quantity: setQunatity,
            Title: product.Title,
            Sku: product.Sku,
            Price: setQunatity * parseFloat(product.Price),
            product_id: product.WPID,
            variation_id: 0,
            isTaxable: product.Taxable,
            tick_event_id: tick_data._event_name,
            ticket_status: product.IsTicket,
            product_ticket: ticketFields,
            old_price: product.old_price,
            incl_tax: product.incl_tax,
            excl_tax: product.excl_tax,
            discount_type: product.discount_type ? product.discount_type : "",
            new_product_discount_amount: product ? product.new_product_discount_amount : 0,
            TaxStatus: product ? product.TaxStatus : "",
            tcForSeating: tcForSeating,
            TaxClass: product ? product.TaxClass : '',
            combination: product ? product.combination : '',
            categories: product.CategorieList ? product.CategorieList : [],
            BackOrderd: product.BackOrderd //product.BackOrderd === 'yes' ? true : false
        }
        if (product.hasOwnProperty("modifiers_Id")) {
            data["modifiers_Id"] = product.modifiers_Id;
        }
        if (product.hasOwnProperty("modifiers_key")) {
            data["modifiers_key"] = product.modifiers_key;
        }
        if (product.hasOwnProperty("addon_ids")) {
            data["addon_ids"] = product.addon_ids;
        }
        if (product.hasOwnProperty("addon_key")) {
            data["addon_key"] = product.addon_key;
        }
        var qty = 0;
        cartproductlist.map(item => {
            if (product.WPID == item.product_id) {
                qty = item.quantity;
            }
        })
        //  add simple product with below condotions
        var product_is_exist = (product.ManagingStock == false && product.StockStatus == "outofstock") ? "outofstock" :
            (product.StockStatus == null || product.StockStatus == 'instock') && product.ManagingStock == false ? "Unlimited" : (typeof product.StockQuantity != 'undefined') && product.StockQuantity != '' ? qty < product.StockQuantity : '0'
        if (product_is_exist == '0') {
            return 'outofstock';
        }
        if (product_is_exist !== 'outofstock' && product_is_exist !== '0' && product_is_exist == true || product_is_exist == 'Unlimited') {
            //Added this code to replace the product at the existing position in the case of edit
            if (product.hasOwnProperty("selectedIndex")) {
                cartlist[product.selectedIndex] = data;
            }
            else {
                cartlist.push(data);
            }
            // cartlist.push(data);
            //Android Call----------------------------
            var totalPrice = 0.0;
            cartlist && cartlist.map(item => {
                totalPrice += item.Price;
            })
            //androidDisplayScreen(data.Title, data.Price, totalPrice, "cart");
            //-----------------------------------------
            stockUpdateQuantity(cartlist, data, product)
            addtoCartProduct(cartlist, null, product);  // this.state.cartproductlist
        } else {
            return 'outofstock';
        }
    }
}
export const deleteProduct = (item) => {
    var product_cartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];//
    var productx = localStorage.getItem("PRODUCTX_DATA") ? JSON.parse(localStorage.getItem("PRODUCTX_DATA")) : [];//
    var tikeraSelectedSeats = localStorage.getItem('TIKERA_SELECTED_SEATS') ? JSON.parse(localStorage.getItem('TIKERA_SELECTED_SEATS')) : [];
    if (tikeraSelectedSeats.length > 0) {
        tikeraSelectedSeats.map((items, index) => {
            if (parseInt(items.chart_id) == parseInt(item.product_id)) {
                tikeraSelectedSeats.splice(index, 1);
            }
        })
        localStorage.setItem('TIKERA_SELECTED_SEATS', JSON.stringify(tikeraSelectedSeats))
    }
    var i = 0;
    var index;
    for (i = 0; i < product_cartlist.length; i++) {
        if ((typeof item.product_id !== 'undefined') && item.product_id !== null) {
            if (item.variation_id !== 0) {
                if (product_cartlist[i].variation_id == item.variation_id && (product_cartlist[i].hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? product_cartlist[i].modifiers_key !== item.modifiers_key : true))
                    index = i;
            }
            else {
                if (product_cartlist[i].product_id == item.product_id && product_cartlist[i].strProductX == item.strProductX && (product_cartlist[i].hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? product_cartlist[i].modifiers_key == item.modifiers_key : true))
                    index = i;
            }

        } else {
            if (product_cartlist[i].Title == item.Title) {
                index = i;
            }
        }
    }
    var _deletingProduct = product_cartlist[index];
    // productDataForApp(_deletingProduct, "remove", product);// send data to app (WRAPPER)
    product_cartlist.splice(index, 1);
    //var replaceModi = product.filter(a => (a.hasOwnProperty("pid") == true && a.pid !== item.product_id) || a.hasOwnProperty("pid") == false);
    var replaceModi = product_cartlist.filter(a => (
        a.hasOwnProperty("pid") == false || (a.hasOwnProperty("pid") == true && a.pid !== item.product_id)
        || (a.hasOwnProperty("pid") == true && a.pid == item.product_id && a.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key")
            ? a.modifiers_key !== item.modifiers_key : true))
    );
    //var replaceModi = product.filter(a =>(!a.hasOwnProperty("pid") || (a.hasOwnProperty("pid") && a.pid !== item.product_id)));


    product_cartlist = replaceModi == null ? [] : replaceModi;
    //delete productx
    var j = 0;
    var xindex;
    for (j = 0; j < productx.length; j++) {
        if ((typeof item.product_id !== 'undefined') && item.product_id !== null) {
            // we hvae added item.strProductX == undefined condistion for park sale edit case becs we dont have strProductX in cardProductList localstorage 
            if (productx[j].product_id == item.product_id && (productx[j].strProductX == item.strProductX || (item.strProductX == undefined && productx[j].strProductX == ""))) {
                xindex = j;
            }
        }
    }
    xindex !== undefined && productx.splice(xindex, 1);

    if (product_cartlist.length == 0) {
        var checklist = localStorage.getItem('CHECKLIST') && JSON.parse(localStorage.getItem('CHECKLIST'))
        if (checklist && (checklist.status == "pending" || checklist.status == "park_sale" || checklist.status == "lay_away" || checklist.status == "on-hold")) {
            //var udid = get_UDid('UDID');
            //this.setState({ isLoading: true })
            localStorage.removeItem('PENDING_PAYMENTS');
            // this.props.dispatch(checkoutActions.orderToCancelledSale(checklist.order_id, udid));
        }
        localStorage.removeItem('CHECKLIST');
        localStorage.removeItem("CART");
        localStorage.removeItem("PRODUCT");
        localStorage.removeItem("SINGLE_PRODUCT");
        localStorage.removeItem("CARD_PRODUCT_LIST");
        localStorage.removeItem('TIKERA_SELECTED_SEATS');
        localStorage.removeItem("PRODUCTX_DATA");
        localStorage.removeItem("SELECTED_MODIFIERS");
        addtoCartProduct(null);

        // const { dispatch } = this.props;
        // if(dispatch){
        // dispatch(cartProductActions.addtoCartProduct(null));
        // dispatch(cartProductActions.singleProductDiscount())
        // dispatch(cartProductActions.showSelectedProduct(null));
        // dispatch(cartProductActions.addInventoryQuantity(null,null));
        // }
    } else {
        addtoCartProduct(product_cartlist, true);
        // const { dispatch } = this.props;
        // localStorage.setItem("PRODUCTX_DATA", JSON.stringify(productx));
        // if(dispatch){
        // dispatch(cartProductActions.addtoCartProduct(product));
        // dispatch(cartProductActions.showSelectedProduct(null));
        // dispatch(cartProductActions.addInventoryQuantity(null));
        // }
    }
    productDataForApp(_deletingProduct, "remove", product_cartlist);// send data to app (WRAPPER)
    //this.props.simpleProductData();

    //Android Call----------------------------
    //androidDisplayScreen(item.Title, 0, 0, "deleteproduct");
    //-----------------------------------------
}

export const removeCheckOutList = () => {
    //const { dispatch } = this.props;

    var checklist = localStorage.getItem('CHECKLIST') && JSON.parse(localStorage.getItem('CHECKLIST'))

    if (checklist && (checklist.status == "pending" || checklist.status == "park_sale" || checklist.status == "lay_away" || checklist.status == "on-hold")) {
        var data = {
            order_id: checklist.order_id,
            udid: get_UDid(),
            WarehouseId: localStorage.getItem("WarehouseId") ? parseInt(localStorage.getItem("WarehouseId")) : 0
        }
        store.dispatch(orderToCancelSale(data))
        localStorage.removeItem('PENDING_PAYMENTS');
    }
    localStorage.removeItem('SELECTED_MODIFIERS');
    localStorage.removeItem('CHECKLIST');
    localStorage.removeItem('oliver_order_payments');
    localStorage.removeItem('AdCusDetail');
    localStorage.removeItem('TIKERA_SELECTED_SEATS');
    localStorage.removeItem("CART");
    localStorage.removeItem('CARD_PRODUCT_LIST');
    localStorage.removeItem("PRODUCT");
    localStorage.removeItem("SINGLE_PRODUCT");
    localStorage.removeItem("PRODUCTX_DATA");
    localStorage.removeItem("couponDetail");
    localStorage.removeItem("couponApplied");
    localStorage.removeItem("shippingAddress");

    //this.props.ticketDetail(status, item)
    addtoCartProduct(null);
}
//Added third parameter(pro) to the method, pro parameter represents the product being added or modified 
export const addtoCartProduct = (cartproductlist, calledAfterDelete = false, pro = null) => {
    //var oldCartlist = cartproductlist;
    var oldCartlist = localStorage.getItem("CARD_PRODUCT_LIST") ? JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST")) : [];
    var _product = pro;

    var isProduct = "";
    
    var TAX_CASE = typeOfTax();   
    //-----update quantity and price for multiple product ----------------------------------------
    var newCartList = []
    cartproductlist && cartproductlist.map((item, index) => {
        var isProdAddonsType = "";// CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products
        var _discount_amount = 0.00;
        if (typeof item.product_id == 'undefined') {
            newCartList.push(item);
        }

        //APPLYING DISCOUNT TO MODIFIERS 27/07/2023

        var parentItem = cartproductlist && cartproductlist.find(a => a.product_id == item.pid && a.modifiers_key == item.modifiers_key && item.check_modifer == true);
        if (typeof parentItem != "undefined" && parentItem != null) {
            item["discount_type"] = parentItem.discount_type;
            item["discountType"] = parentItem.discount_type;
            item["product_discount_amount"] = parentItem.new_product_discount_amount;
            item["new_product_discount_amount"] = parentItem.new_product_discount_amount;
            //item["discount_amount"] = parentItem.discount_amount;

            var _item = singleProductModifierDiscount(item, parentItem, parentItem.quantity);
            if (_item != null) {
               item["discount_amount"] = _item["product_discount_amount"];
              

            }
            //console.log(_item);
        }
        //END
        //Addons discount-----------------
        var parentItemAddon = cartproductlist && cartproductlist.find(a => a.product_id == item.pid && a.addon_key == item.addon_key && item.check_addons == true);
        if (typeof parentItemAddon != "undefined" && parentItemAddon != null) {
            item["discount_type"] = parentItemAddon.discount_type;
            item["discountType"] = parentItemAddon.discount_type;
            item["product_discount_amount"] = parentItemAddon.new_product_discount_amount;
            item["new_product_discount_amount"] = parentItemAddon.new_product_discount_amount;
            //item["discount_amount"] = parentItem.discount_amount;

            var _item = singleProductModifierDiscount(item, parentItemAddon, parentItemAddon.quantity);
            if (_item != null) {               
                item["discount_amount"] = _item["product_discount_amount"];                
            }
           
        }

        const countTypes = cartproductlist
            .filter(prd => prd.product_id === item.product_id && prd.variation_id == item.variation_id && prd.Title == item.Title
                && (prd.strProductX == undefined || prd.strProductX == item.strProductX)
                && ((prd.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") ? JSON.stringify(prd.modifiers_Id) == JSON.stringify(item.modifiers_Id) : true)
                    && ((prd.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? prd.modifiers_key == item.modifiers_key : true)))
            );
        var _price = 0.0;
        var _qty = 0;
        var _afterDiscount = 0;
        var new_incl_tax = 0;
        countTypes.map(nitem => {
            _price = _price + nitem.Price;
            _qty = _qty + nitem.quantity;
            new_incl_tax = nitem.isTaxable == true ? getInclusiveTax((nitem.old_price * _qty) - (nitem.discount_type == "Number" ? nitem.product_discount_amount : nitem.product_discount_amount * _qty), nitem.TaxClass) : 0;

        })

        if ((item.discount_type == 'Percentage' || !item.discount_type) && item.discount_amount && item.discount_amount !== 0) {
            _discount_amount = item.product_discount_amount * _qty;
            if (isProdAddonsType && isProdAddonsType == true) {
                _discount_amount = item.product_discount_amount; // don not multiply by quantity for addons
            }
        } else if (item.discount_type == 'Number' && item.product_discount_amount !== 0) {
            _discount_amount = item.product_discount_amount;
        }



        var _newITem = {
            Price: _price,
            Sku: item.Sku,
            Title: item.Title,
            isTaxable: item.isTaxable,
            line_item_id: item.line_item_id,
            product_id: item.product_id,
            quantity: _qty,
            after_discount: item.after_discount && _discount_amount > 0 ? item.after_discount : 0, //_afterDiscount, 
            discount_amount: _discount_amount,
            variation_id: item.variation_id,
            cart_after_discount: item.cart_after_discount && _discount_amount > 0 ? item.cart_after_discount : 0,
            cart_discount_amount: item.cart_discount_amount && _discount_amount > 0 ? item.cart_discount_amount : 0,
            product_after_discount: item.product_after_discount ? item.product_after_discount : 0,
            product_discount_amount: item.product_discount_amount ? item.product_discount_amount : 0,
            old_price: item.old_price ? item.old_price : 0,
            incl_tax: item.incl_tax ? item.incl_tax : 0,
            excl_tax: item.excl_tax ? item.excl_tax : 0,
            ticket_status: item.ticket_status,/////ticket
            tick_event_id: item.tick_event_id,
            ticket_info: item.ticket_info ? item.ticket_info : [], /////ticket
            product_ticket: item.product_ticket ? item.product_ticket : '',
            discount_type: item.discount_type ? item.discount_type : "",
            new_product_discount_amount: item ? item.new_product_discount_amount : 0,
            TaxStatus: item ? item.TaxStatus : '',// updateby shakuntala jatav, date:03-06-2019 , description: tax is applicable for per items is yes or not.
            //   ticket_cart_display_status:item.ticket_cart_display_status?item.ticket_cart_display_status:''
            tcForSeating: item.tcForSeating ? item.tcForSeating : "",
            TaxClass: item ? item.TaxClass : '',
            Type: item && item.Type ? item.Type : '', // added product Type in checklist
            strProductX: item.strProductX ? item.strProductX : undefined,
            addons_meta_data: item.addons_meta_data ? item.addons_meta_data : "",
            psummary: item.psummary ? item.psummary : '',
            combination: item.combination ? item.combination : '',
            categories: item.categories ? item.categories : [],
            BackOrderd: item.BackOrderd,// item.BackOrderd === "yes" ? true : false
        }
        if (item.hasOwnProperty("pid")) {
            _newITem["pid"] = item.pid;
        }
        if (item.hasOwnProperty("modifiers_Id")) {
            _newITem["modifiers_Id"] = item.modifiers_Id;
        }
        if (item.hasOwnProperty("modifiers_key")) {
            _newITem["modifiers_key"] = item.modifiers_key;
        }
        if (item.hasOwnProperty("addon_ids")) {
            _newITem["addon_ids"] = item.addon_ids;
        }
        if (item.hasOwnProperty("addon_key")) {
            _newITem["addon_key"] = item.addon_key;
        }
        // set Price for productX from productX localstorage.
        var prodXData = localStorage.getItem("PRODUCTX_DATA") ? JSON.parse(localStorage.getItem("PRODUCTX_DATA")) : 0
        // cartproductlist && cartproductlist.map(pro => {
        var incr = 1
        var productXSingleData = prodXData && prodXData.map(prodX => {
            if (prodX.product_id == _newITem.product_id && incr && prodX.strProductX == _newITem.strProductX) {
                incr = 0
                var productXItemPrice = prodX && prodX.line_subtotal ? prodX.line_subtotal : 0
                var productXItemTax = prodX && prodX.line_tax ? prodX.line_tax : 0
                // _newITem.Price = productXItemPrice;
                //commented this line because : -we have already updated the old_price 28-07-20022
                //_newITem.old_price = TAX_CASE == 'incl' ? (productXItemPrice + productXItemTax) / prodX.quantity : productXItemPrice / prodX.quantity

                var cartData = localStorage.getItem('CARD_PRODUCT_LIST') && JSON.parse(localStorage.getItem('CARD_PRODUCT_LIST'))
                cartData && cartData.map(itm => {
                    if (itm.product_id == _newITem.product_id && countTypes.length > 1) {
                        _newITem.Price = TAX_CASE == 'incl' ? itm.Price + productXItemPrice + productXItemTax : itm.Price + productXItemPrice;
                    }
                })
            }
        })
        // })
        // end
        _discount_amount = 0;
        var _newItemExist = newCartList && newCartList.filter(prd => prd.product_id === item.product_id && prd.variation_id == item.variation_id && prd.Title == item.Title
            && (prd.strProductX == undefined || item.strProductX == undefined || prd.strProductX == item.strProductX)
            && ((prd.hasOwnProperty("modifiers_Id") && item.hasOwnProperty("modifiers_Id") ? JSON.stringify(prd.modifiers_Id) == JSON.stringify(item.modifiers_Id) : true)
                && ((prd.hasOwnProperty("modifiers_key") && item.hasOwnProperty("modifiers_key") ? prd.modifiers_key == item.modifiers_key : true)))
        );
        if ((_newItemExist) && _newItemExist.length == 0) {
            newCartList.push(_newITem);
        } else {
            _newItemExist = _newITem
            newCartList.map(newItem => {
                if (newItem.product_id === _newITem.product_id && newItem.variation_id == _newITem.variation_id && newItem.Title == _newITem.Title && (newItem.strProductX == _newITem.strProductX) && (newItem.hasOwnProperty("modifiers_key") && _newITem.hasOwnProperty("modifiers_key") ? newItem.modifiers_key == _newITem.modifiers_key : true)) {
                    newItem.discount_amount = _newITem.discount_amount;
                    newItem.cart_after_discount = _newITem.cart_after_discount;
                    newItem.cart_discount_amount = _newITem.cart_discount_amount;
                    newItem.product_after_discount = _newITem.product_after_discount;
                    newItem.product_discount_amount = _newITem.product_discount_amount;
                    newItem.old_price = _newITem.old_price ? _newITem.old_price : 0;
                    newItem.incl_tax = _newITem.incl_tax ? _newITem.incl_tax : 0;
                    newItem.excl_tax = _newITem.excl_tax ? _newITem.excl_tax : 0;
                    newItem.discount_type = _newITem.discount_type;
                    newItem.after_discount = _newITem.after_discount
                    newItem.new_product_discount_amount = _newITem.new_product_discount_amount ? _newITem.new_product_discount_amount : 0;
                }
            })
        }
    })
    cartproductlist = getTaxCartProduct(newCartList);

    var totalPrice = 0;
    var customefee = 0; //should not include into discount
    var totalCartProductDiscount = 0;
    cartproductlist && cartproductlist.map((item, index) => {
        var product_after_discount = 0;
        var isProdAddonsType = "";// CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products
        //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
        if (item.product_id || (item.hasOwnProperty("pid") && item.hasOwnProperty("modifiers_key") && item.hasOwnProperty("Price"))) {//donothing
            totalPrice += item.Price;
            //$ Overall discount amount issue while increasing the quantity - START
            if (item.discount_type == "Percentage") {
                totalCartProductDiscount += item.product_discount_amount * item.quantity;
                //product_after_discount += parseFloat(item.product_discount_amount * item.quantity);
                product_after_discount = parseFloat(item.product_discount_amount * item.quantity);
            }
            else {
                totalCartProductDiscount += item.product_discount_amount;
                //product_after_discount += parseFloat(item.product_discount_amount);
                product_after_discount = parseFloat(item.product_discount_amount);
            }
            //END

            // totalCartProductDiscount +=item.product_discount_amount * item.quantity;
            // product_after_discount += parseFloat(item.product_discount_amount * item.quantity); 
            if (isProdAddonsType && isProdAddonsType == true) {
                //product_after_discount = 0
                //product_after_discount += parseFloat(item.product_discount_amount); // quantity comment for addons
                product_after_discount = parseFloat(item.product_discount_amount); // quantity comment for addons
            }
        } else {
            customefee += item.Price;
        }
        if (item.product_discount_amount !== 0 && item.TaxStatus !== 'none') { // indivisual product discount
            if (TAX_CASE == "incl") {
                var incl_tax = 0;
                if (item.discount_type == "Percentage") {
                    //incl_tax = getInclusiveTax(item.Price - (product_after_discount > 0 ? (product_after_discount) : item.discount_amount), item.TaxClass)
                    //Now calculating the tax on Price with inclusive tax... 
                    //incl_tax = getInclusiveTax((product_after_discount > 0 ? item.Price - product_after_discount : item.Price), item.TaxClass)
                    incl_tax = item.isTaxable == true ? getInclusiveTax((product_after_discount > 0 ? item.Price - product_after_discount : item.Price), item.TaxClass) : 0
                } else {
                    //incl_tax = getInclusiveTax(item.Price - (item.discount_amount ? item.discount_amount : 0), item.TaxClass)
                    incl_tax = item.isTaxable == true ? getInclusiveTax(item.Price - (item.discount_amount ? item.discount_amount : 0), item.TaxClass) : 0
                }
                item["incl_tax"] = incl_tax
            } else {
                var excl_tax = 0;
                if (item.discount_type == "Percentage") {
                    excl_tax = getExclusiveTax(item.product_after_discount, item.TaxClass)

                    //excl_tax = excl_tax * item.quantity;

                    //excl_tax = getExclusiveTax(item.after_discount * item.quantity, item.TaxClass)// 
                    if (isProdAddonsType && isProdAddonsType == true) 
                    {
                        excl_tax = getExclusiveTax(item.product_after_discount, item.TaxClass)// quantity comment for addons
                    }
                } else {
                    excl_tax = getExclusiveTax(item.Price - (item.discount_amount ? item.discount_amount : 0), item.TaxClass)
                }
                item["excl_tax"] = excl_tax;
            }
        }
    })
    var cart = localStorage.getItem("CART") ? JSON.parse(localStorage.getItem("CART")) : null

    var incl_tax = 0;
    var excl_tax = 0;
    if (cart !== null) {   //Calculating the cart Discount
        cartproductlist && cartproductlist.map(item => {
            var product_after_discount = 0;
            var isProdAddonsType = "";

            //  multiply by quantity in percentage discount case only...
            var product_discount_amount = item.discount_type == "Percentage" ? item.product_discount_amount * item.quantity : item.product_discount_amount; // test 
            var discount_amount = item.discount_amount ? item.discount_amount * item.quantity : 0;
            var price = item.Price;
            var discount = parseFloat(cart.discount_amount)
            var Tax = parseFloat(cart.Tax_rate);
            var discount_amount_is = 0;
            var afterDiscount = 0;
            var new_price = 0.00;
            var cart_after_discount = 0;
            var cart_discount_amount = 0;
            if (item.product_id || (item.hasOwnProperty("pid") && (item.hasOwnProperty("modifiers_key") || item.hasOwnProperty("addon_key")) && item.hasOwnProperty("Price"))) {
                price = parseFloat(price - parseFloat(product_discount_amount));
                product_after_discount += parseFloat(item.product_discount_amount * item.quantity);
                if (isProdAddonsType && isProdAddonsType == true) {
                    product_after_discount = 0
                    product_after_discount += parseFloat(item.product_discount_amount); // quantity comment for addons
                }
                if (price == 0) {
                    cart_after_discount = 0.00;
                    cart_discount_amount = 0.00;
                    new_price = 0.00;
                } else {
                    if (cart.discountType == 'Percentage') {
                        if (discount > 100) {
                            localStorage.removeItem("CART")
                            //console.log(LocalizedLanguage.discountMoreThenMsg)
                            var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                            store.dispatch(popupMessage(data));
                        } else {
                            var incl_tax = 0
                            discount_amount_is = percentWiseDiscount(price, discount);
                            if (item.TaxStatus !== 'none') {
                                if (TAX_CASE == 'incl') {
                                    //incl_tax = getInclusiveTax(price, item.TaxClass)
                                    incl_tax = item.isTaxable == true ? getInclusiveTax(price, item.TaxClass) : 0
                                    //item["incl_tax"] = incl_tax

                                    //discount_amount_is = percentWiseDiscount(price - incl_tax, discount);
                                    discount_amount_is = percentWiseDiscount(price, discount);

                                }
                            }
                            //afterDiscount = price - incl_tax - discount_amount_is;
                            afterDiscount = price - discount_amount_is;
                            //  if ((item.product_after_discount !== 0 || (item.product_after_discount == 0 && item.product_discount_amount > 0)) && item.cart_after_discount === 0 && item.cart_discount_amount === 0) {
                            //     afterDiscount = price - incl_tax - discount_amount_is;
                            //   }
                            cart_after_discount = afterDiscount;
                            cart_discount_amount = discount_amount_is;
                            new_price = price - parseFloat(cart_discount_amount);
                            //var total_tax = afterDiscount + (afterDiscount * Tax / 100.00);
                            if (new_price == price) {
                                new_price = 0.00;
                            }
                        }
                    } else {
                        var new_discount = NumberWiseDiscount((totalPrice - totalCartProductDiscount), discount);
                        if (new_discount > 100) {
                            localStorage.removeItem("CART")
                            //console.log(LocalizedLanguage.discountMoreThenMsg)
                            var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                            store.dispatch(popupMessage(data));
                        } else {
                            discount_amount_is = percentWiseDiscount(price, new_discount);
                            afterDiscount = price - discount_amount_is;
                            cart_after_discount = afterDiscount;
                            cart_discount_amount = parseFloat(discount_amount_is);
                            new_price = price - parseFloat(cart_discount_amount);
                            if (new_price == price) {
                                new_price = 0.00;
                            }
                        }
                    }
                }
                item["Sku"] = item.Sku;
                item["cart_after_discount"] = parseFloat(cart_after_discount);
                item["cart_discount_amount"] = parseFloat(cart_discount_amount);
                // item["after_discount"] = new_price
                item["discount_amount"] = parseFloat(cart_discount_amount) + parseFloat(product_discount_amount);
                if (item.discount_amount && item.discount_amount !== 0 && item.TaxStatus !== 'none') {
                    if (TAX_CASE == 'incl') {
                        //incl_tax = getInclusiveTax((item.discount_amount > 0 ? cart_after_discount : item.Price), item.TaxClass)
                        incl_tax = item.isTaxable == true ? getInclusiveTax((item.discount_amount > 0 ? cart_after_discount : item.Price), item.TaxClass) : 0;

                        // incl_tax = getInclusiveTax(item.Price - (product_discount_amount > 0 ? product_discount_amount : item.discount_amount), item.TaxClass)
                        //incl_tax = getInclusiveTax(item.Price- item.discount_amount, item.TaxClass)
                        item["incl_tax"] = incl_tax
                    } else {
                        //excl_tax = getExclusiveTax(item.Price - item.discount_amount, item.TaxClass);
                        excl_tax = item.isTaxable == true ? getExclusiveTax(item.Price - item.discount_amount, item.TaxClass) : 0;
                        item["excl_tax"] = excl_tax
                    }
                } else {
                    if (item.TaxStatus !== 'none') {
                        if (TAX_CASE == 'incl') {
                            //incl_tax = getInclusiveTax(item.Price, item.TaxClass)
                            incl_tax = item.isTaxable == true ? getInclusiveTax(item.Price, item.TaxClass) : 0
                            item["incl_tax"] = incl_tax
                        } else {
                            //excl_tax = getExclusiveTax(item.Price, item.TaxClass);
                            excl_tax = item.isTaxable == true ? getExclusiveTax(item.Price, item.TaxClass) : 0;
                            item["excl_tax"] = excl_tax
                        }
                    }
                }
                // updated after discount 
                item["after_discount"] =
                    item ?
                        ((item.old_price) * item.quantity) - (item.cart_discount_amount + product_discount_amount) - item.incl_tax
                        : new_price
            }
        })
    }
    if (localStorage.getItem("SINGLE_PRODUCT")) {
        localStorage.removeItem("SINGLE_PRODUCT")
    }
    localStorage.setItem("CARD_PRODUCT_LIST", JSON.stringify(cartproductlist));

    // //-----app command for wrapper 3.0
    // if ((calledAfterDelete === false || calledAfterDelete === null) && typeof oldCartlist != "undefined" && oldCartlist != null && oldCartlist.length > 0 && _product) {
    //     var status = "remove";
    //     var isProduct = "";
    //     if (pro != null) {
    //         isProduct = cartproductlist.find(a => a.product_id == pro.WPID);
    //     }
    //     //(JSON.stringify(oldCartlist) !== JSON.stringify(cartproductlist))
    //     // if (oldCartlist.every((element_1) =>
    //     //     cartproductlist.some(
    //     //         (element_2) =>
    //     //             element_1.cart_after_discount !== element_2.cart_after_discount ||
    //     //             element_1.cart_discount_amount !== element_2.cart_discount_amount ||
    //     //             element_1.after_discount !== element_2.after_discount ||
    //     //             element_1.discount_amount !== element_2.discount_amount ||
    //     //             element_1.product_after_discount !== element_2.product_after_discount ||
    //     //             element_1.product_discount_amount !== element_2.product_discount_amount ||
    //     //             element_1.quantity !== element_2.quantity)

    //     // ))
    //     if (typeof isProduct != "undefined" && isProduct != null && typeof oldCartlist !== "undefined" && oldCartlist != null && oldCartlist.length > 1)
    //     // (oldCartlist.cart_after_discount !== cartproductlist.cart_after_discount 
    //     // || oldCartlist.cart_discount_amount !== cartproductlist.cart_discount_amount
    //     // || oldCartlist.after_discount !== cartproductlist.after_discount
    //     // || oldCartlist.discount_amount !== cartproductlist.discount_amount
    //     // || oldCartlist.product_after_discount !== cartproductlist.product_after_discount
    //     // || oldCartlist.product_discount_amount !== cartproductlist.product_discount_amount
    //     // || oldCartlist.quantity !== cartproductlist.quantity
    //     // )) 
    //     {
    //         status = "modify";
    //     }
    //     else if ((oldCartlist.length <= cartproductlist.length) /*||  isFresh === true || (oldCartlist.length === cartproductlist.length && isFresh === true)*/) {
    //         status = "add"
    //     }
    //     else if ((oldCartlist.length === cartproductlist.length) && (JSON.stringify(oldCartlist) !== JSON.stringify(cartproductlist))) {
    //         status = "modify";
    //     }
    //     _product = cartproductlist.find(a => (a.product_id == _product.product_id || a.variation_id == _product.product_id) || (a.product_id == _product.WPID || a.variation_id == _product.WPID));
    //     if (_product && !_product.hasOwnProperty("pid"))
    //         productDataForApp(_product, status, cartproductlist);
    // }
    // else
    //     if (localStorage.getItem("CARD_PRODUCT_LIST")) {
    //         var _cartlist = JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST"));
    //         if (_cartlist && _cartlist.length == 1 && !_cartlist[0].hasOwnProperty("pid")) {
    //             productDataForApp(_cartlist[0], "add", cartproductlist);
    //         }
    //     }

    if ((calledAfterDelete === false || calledAfterDelete === null) && typeof cartproductlist != "undefined" && cartproductlist != null && cartproductlist.length > 0 && _product) {
        var status = "";
        var isProduct = "";
        if (pro != null && localStorage.getItem("CARD_PRODUCT_LIST")) {
            var _cartproductlist = JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST"));
            isProduct = _cartproductlist.find(a => a.product_id == (_product.WPID || _product.product_id));
        }

        if ((typeof isProduct != "undefined" && isProduct != null) && (oldCartlist.length < cartproductlist.length) /*||  isFresh === true || (oldCartlist.length === cartproductlist.length && isFresh === true)*/) {
            status = "add"
        }
        else if ((oldCartlist.length === cartproductlist.length) && (JSON.stringify(oldCartlist) !== JSON.stringify(cartproductlist))) {
            status = "modify";
        }
        else if ((oldCartlist.length > cartproductlist.length)) {
            status = "remove";
        }
        else if (typeof isProduct != "undefined" && isProduct != null && typeof oldCartlist !== "undefined" && oldCartlist != null && oldCartlist.length > 1) {
            status = "modify";
        }
        // _product = cartproductlist.find(a => (a.product_id == _product.product_id || a.variation_id == _product.product_id) || (a.product_id == _product.WPID || a.variation_id == _product.WPID));
        // if (_product && !_product.hasOwnProperty("pid") && status != "")
        //     productDataForApp(_product, status, cartproductlist);
    }
    else if (localStorage.getItem("CARD_PRODUCT_LIST") && _product != null) {
        var _cartlist = JSON.parse(localStorage.getItem("CARD_PRODUCT_LIST"));
        if (_cartlist && _cartlist.length == 1 && !_cartlist[0].hasOwnProperty("pid")) {
            productDataForApp(_cartlist[0], "add", cartproductlist);
        }
    }

    if (_product != null) {
        _product = cartproductlist && cartproductlist.find(a => (a.product_id == _product.product_id || a.variation_id == _product.product_id) || (a.product_id == _product.WPID || a.variation_id == _product.WPID));
        if (_product && _product.hasOwnProperty("pid") == true && status != "") {
            _product = cartproductlist && cartproductlist.find(a => a.product_id == pro.WPID);
            if(_product){
                 productDataForApp(_product, status, cartproductlist);
            }           
        }
        else if (_product && _product.hasOwnProperty("pid") == false && status != "") {
            if(_product){
            productDataForApp(_product, status, cartproductlist);
            }
        }
    }
    else if ((_product == null || typeof _product == "undefined") && (oldCartlist.length === cartproductlist.length) && (JSON.stringify(oldCartlist) !== JSON.stringify(cartproductlist)) && localStorage.getItem("CART") && cartproductlist.length > 0) {
        var data = {
            "version": "3.0", "action": "modify", "item": cartproductlist,
            "cart": calculateCart(cartproductlist)
        }
        sendDataToWrapper("cart_activity_detial", "", data)
    }

    return cartproductlist;

}
const productDataForApp = (_product, action, cartproductlist = null) => {
    if( !_product){
        return;
    }
    var data = {};
    var addons = { "name": "", "price": "" };
    var discount = { "name": _product.discount_type, "percent": _product.new_product_discount_amount, "amount": _product.product_discount_amount };
    var total = (_product.discount_type === "Number" ? _product.Price - (_product.product_discount_amount) : _product.Price - (_product.product_discount_amount * _product.quantity));
    var item = { "sku": _product.Sku, "name": _product.Title, "price": _product.Price, "descr": "", "addons": addons, "discount": discount, "total": total, "quantity": _product.quantity };
    //var cart = { "subtotal": _SubTotal, "taxes": _Tax, "discount": _Discount, "total": _Total }
    data["version"] = "3.0";
    data["action"] = action;
    data["item"] = item;
    data["cart"] = calculateCart(cartproductlist);
    //console.log("--cart_activity_detial-" + JSON.stringify(data))
    // data["addons"] = addons;
    // data["discount"] = discount;
    //data["total"] = total;
    // console.log("------" + action + " data----" + JSON.stringify(data));
    // console.log("------status--" + action );

    if (action == "remove") {
        data = {
            "version": "3.0", "action": "remove", "item": {
                "sku": _product.Sku,
            },
            "cart": data["cart"]
        }
    }

    sendDataToWrapper("cart_activity_detial", "", data)
    // const Android = window.Android;
    // if (typeof Android !== "undefined" && Android !== null && (Android.getDatafromDevice("isWrapper") == true)) {
    //     wrapperReceiptData(data);
    // }
}
const calculateCart = (listItem) => {
    var _subtotal = 0.0;
    var _total = 0.0;
    var _taxAmount = 0.0;
    var _totalDiscountedAmount = 0.0;
    var _customFee = 0.0;
    var _exclTax = 0;
    var _inclTax = 0;
    var _taxId = [];
    var _taxRate = [];
    var TaxIs = [];
    var _subtotalPrice = 0.00;
    var _subtotalDiscount = 0.00;
    var _cartDiscountAmount = 0.00;
    var _productDiscountAmount = 0.00;
    var _seprateDiscountAmount = 0.00;
    var taxratelist = [];
    if ((typeof localStorage.getItem('TAXT_RATE_LIST') !== 'undefined') && localStorage.getItem('TAXT_RATE_LIST') !== null) {
        taxratelist = localStorage.getItem('TAXT_RATE_LIST') && JSON.parse(localStorage.getItem('TAXT_RATE_LIST'));
    }
    if (taxratelist && taxratelist !== null && taxratelist !== "undefined") {
        taxratelist && taxratelist.length > 0 && taxratelist.map(tax => {
            _taxId.push(tax.TaxId);
            _taxRate.push(tax.TaxRate);
            if (tax.check_is === true) {
                TaxIs.push({ [tax.TaxId]: parseFloat(tax.TaxRate) })
            }
        })
    }
    listItem && listItem.map((item, index) => {
        if (item.Price) {
            _subtotalPrice += item.Price
            _subtotalDiscount += parseFloat(item.discount_amount === null || isNaN(item.discount_amount) === true ? 0 : item.discount_amount)
            //ADDED CODE TO MAKE CONDITION TRUE FOR MODIFIER
            if (item.product_id || (item.hasOwnProperty("pid") && item.hasOwnProperty("modifiers_key") && item.hasOwnProperty("Price"))) {//donothing  
                var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
                _exclTax += item.excl_tax ? item.excl_tax : 0;
                _inclTax += item.incl_tax ? item.incl_tax : 0;
                _cartDiscountAmount += item.cart_discount_amount;
                // _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount:item.product_discount_amount; // quantity commment for addons
                _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType === true ? 1 : item.quantity);
            }
            else {
                _customFee += item.Price;
                _exclTax += item.excl_tax ? item.excl_tax : 0;
                _inclTax += item.incl_tax ? item.incl_tax : 0;
            }
        }
    })

    //total count of the prodcuts in the cart
    if (listItem && listItem.length > 0) {
        var qty = 0;
        listItem.map(item => {
            if (item && item.Price && item.Price !== "" && typeof item.product_id !== "undefined") {
                qty += item.quantity;
            }
        })
    }

    _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
    _subtotal = _subtotalPrice - _productDiscountAmount;
    _totalDiscountedAmount = _subtotalDiscount;
    if (_taxRate) {
        _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
    }
    _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);
    var cart = { "subtotal": _subtotal, "taxes": _taxAmount, "discount": _cartDiscountAmount, "total": _total }

    var taxtype = (typeOfTax() === 'incl' ? LocalizedLanguage.inclTax : LocalizedLanguage.exclTax);
    return cart;
}
export const singleProductDiscount = (isProductX = false, productxQty = null, _qty) => {
    //var qty = $('#qualityUpdater').val() || 1;
    var qty = _qty;
    if (isProductX && isProductX == true && productxQty) {
        qty = productxQty;
    }
    var product = localStorage.getItem("PRODUCT") ? JSON.parse(localStorage.getItem("PRODUCT")) : null
    var product_list = localStorage.getItem("SINGLE_PRODUCT") ? JSON.parse(localStorage.getItem("SINGLE_PRODUCT")) : null
    var product_after_discount = 0;
    var single_product = null;
    if (product !== null && product_list !== null) {
        var product_id = 0;
        var cart_after_discount = 0;

        var cart_discount_amount = product_list.cart_discount_amount ? parseFloat(product_list.cart_discount_amount) : 0;
        var price = 0;
        var discount = parseFloat(product.discount_amount)
        var Tax = parseFloat(product.Tax_rate);
        var product_after_discount = 0;
        var product_discount_amount = 0;
        var discount_amount_is = 0;
        var afterDiscount = 0;
        var new_price = 0.00;
        if (product_list.Type !== "variable"/* && product_list.Type !== "variation"*/) {
            product_id = product_list.WPID ? product_list.WPID : product_list.product_id;;
            single_product = product_list;
        } else {
            if (isProductX == false) {
                var new_single_product = product_list.Variations && product_list.Variations.filter(item => item.WPID === product.Id);
                single_product = new_single_product && new_single_product[0];
                if (typeof single_product === "undefined") {
                    single_product = product_list && product_list;
                }
                product_id = product_list.WPID ? product_list.WPID : product_list.product_id;
            } else {
                single_product = product_list && product_list;
                product_id = product_list.WPID ? product_list.WPID : product_list.product_id;
            }

        }
        localStorage.removeItem("SINGLE_PRODUCT");
        localStorage.removeItem("PRODUCT")
        price = single_product && typeof (single_product) !== undefined && single_product.Price ? single_product.Price : 0;
        var TAX_CASE = typeOfTax();
        if (product_id) {
            price = parseFloat(price);
            var isProdAddonsType = '';//CommonJs.checkForProductXAddons(product_id);// check for productX is Addons type products
            if (price == 0) {
                product_after_discount = 0;
                product_discount_amount = 0;
                new_price = 0.00;
            } else {
                if (product.discountType == 'Percentage') {
                    if (discount > 100) {
                        localStorage.removeItem("PRODUCT")
                        localStorage.removeItem("SINGLE_PRODUCT")
                        // alert('more than 100% discount not applicable');
                        //console.log(LocalizedLanguage.discountMoreThenMsg)
                        var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                        store.dispatch(popupMessage(data));
                        //$('#no_discount').modal('show')
                    } else {

                        // var TAX_CASE = typeOfTax();
                        var incl_tax = 0;
                        var productDiscount = 0;
                        productDiscount = percentWiseDiscount(price, discount);
                        if (single_product.TaxStatus !== 'none' && single_product.isTaxable == true) { //single_product.discount_amount !== 0 &&
                            if (TAX_CASE == 'incl') {
                                incl_tax = getInclusiveTax(single_product.Price, single_product.TaxClass)
                                //item["incl_tax"] = incl_tax
                                // productDiscount = percentWiseDiscount(price - incl_tax, discount);
                                productDiscount = percentWiseDiscount(price, discount);

                            } else {
                                productDiscount = percentWiseDiscount(price, discount);
                            }
                        }

                        // afterDiscount = (price - incl_tax - productDiscount) * (isProductX && isProductX==true ? qty:1);
                        //afterDiscount = (price - productDiscount) * (isProductX && isProductX == true ? qty : 1);
                        afterDiscount = (price - productDiscount) * (isProductX && isProductX == true ? qty : qty);
                        discount_amount_is = productDiscount;
                        //var _disc = percentWiseDiscount(price, discount);
                        // product_after_discount = price - _disc;
                        // product_discount_amount = _disc;
                        product_after_discount = afterDiscount;
                        product_discount_amount = discount_amount_is;
                    }
                } else {

                    TAX_CASE = typeOfTax();
                    var incl_tax_num = 0;
                    var productDiscount = 0;
                    if (single_product.discount_amount !== 0 && single_product.TaxStatus !== 'none' && (single_product.isTaxable == true/*||single_product.Taxable==true*/)) {
                        if (TAX_CASE == 'incl') {
                            incl_tax_num = getInclusiveTax(single_product.Price - discount, single_product.TaxClass)
                            // productDiscount = percentWiseDiscount(price - incl_tax_num, discount);
                        }
                    }

                    var discount_percent = NumberWiseDiscount(price * qty, discount)
                    if (/*product.discountType == 'Percentage' &&*/ discount_percent > 100) {
                        localStorage.removeItem("PRODUCT")
                        localStorage.removeItem("SINGLE_PRODUCT")
                        //$('#no_discount').modal('show')
                        //console.log(LocalizedLanguage.discountMoreThenMsg)
                        var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                        store.dispatch(popupMessage(data));

                    } else {
                        //NEED TO ADD MODIFIER AMOUNT IF DISCOUNT IN NUMBER
                        var modi_amount = getModifierTotalAmount(product_list);

                        discount_amount_is = percentWiseDiscount((price * qty) + modi_amount, discount_percent)
                        afterDiscount = price * qty - discount_amount_is;
                        if (isProdAddonsType && isProdAddonsType == true) {
                            afterDiscount = price - incl_tax_num - discount_amount_is; // minus incl_tax_num for addons in case of number discount
                        }
                        product_after_discount = afterDiscount;
                        product_discount_amount = parseFloat(discount_amount_is);
                        //var total_tax = afterDiscount + (afterDiscount * Tax / 100.00);
                    }
                }
            }
            if (single_product && single_product !== null) {
                single_product["product_after_discount"] = parseFloat(product_after_discount)
                single_product["product_discount_amount"] = parseFloat(product_discount_amount)
                single_product["after_discount"] = afterDiscount
                single_product["discount_amount"] = parseFloat(cart_discount_amount) + parseFloat(product_discount_amount);
                single_product["discount_type"] = product.discountType ? product.discountType : 'Number'
                single_product["new_product_discount_amount"] = discount

                localStorage.setItem("SINGLE_PRODUCT", JSON.stringify(single_product))
            }

        }
    }

    // return dispatch => {
    //     dispatch(request());
    //     dispatch(success(single_product));
    // };
    // function request() { return { type: cartProductConstants.PRODUCT_SINGLE_ADD_REQUEST } }
    // function success(single_product) { return { type: cartProductConstants.PRODUCT_SINGLE_ADD_SUCCESS, single_product } }
}


export const checkProdcutStockStatus = (_product, Quantity) => {
    var _stockStatus = _product ?
        (_product.ManagingStock == true ?
            ((typeof _product.StockQuantity != 'undefined') && Quantity != '' && (Quantity > 0 || _product.StockStatus == "onbackorder") ? 'instock' : "outofstock")
            :
            (_product.ManagingStock == false && (_product.StockStatus == null || _product.StockStatus == 'instock' || _product.StockStatus == "onbackorder") ? 'unlimited'
                : (typeof _product.StockQuantity != 'undefined') ? _product.StockStatus : "outofstock")
        )
        : null;
    return _stockStatus;
}

export const singleProductModifierDiscount = (product, product_list, _qty) => {
    var qty = 1;//_qty;

    var product_after_discount = 0;
    var single_product = null;

    //product_list -> selected product to apply discount
    if (product !== null && product_list !== null) {
        single_product = product;//product_list;
        var product_id = product_list.product_id;
        var cart_after_discount = 0;

        var cart_discount_amount = product.cart_discount_amount ? parseFloat(product.cart_discount_amount) : 0;
        var price = 0;
        var discount = product_list.new_product_discount_amount? parseFloat(product_list.new_product_discount_amount):0;
        var Tax = parseFloat(product.Tax_rate);
        var product_after_discount = 0;
        var product_discount_amount = 0;
        var discount_amount_is = 0;
        var afterDiscount = 0;
        var new_price = 0.00;

        price = product && typeof (product) !== undefined && product.Price ? product.Price : 0;
        price = price / _qty;
        var TAX_CASE = typeOfTax();
        if (product_id) {
            price = parseFloat(price);
            var isProdAddonsType = '';//CommonJs.checkForProductXAddons(product_id);// check for productX is Addons type products
            if (price == 0) {
                product_after_discount = 0;
                product_discount_amount = 0;
                new_price = 0.00;
            } else {
                if (product.discountType == 'Percentage') {
                    if (discount > 100) {
                        //var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                        //store.dispatch(popupMessage(data));
                    } else {

                        var incl_tax = 0;
                        var productDiscount = 0;
                        productDiscount = percentWiseDiscount(price, discount);
                        if (product.TaxStatus !== 'none' && product.isTaxable == true) { //single_product.discount_amount !== 0 &&
                            if (TAX_CASE == 'incl') {
                                incl_tax = getInclusiveTax(product.Price, product.TaxClass)
                                productDiscount = percentWiseDiscount(price, discount);

                            } else {
                                productDiscount = percentWiseDiscount(price, discount);
                            }
                        }
                        afterDiscount = (price - productDiscount) * qty;
                        discount_amount_is = productDiscount;
                        product_after_discount = afterDiscount;
                        product_discount_amount = discount_amount_is;
                    }
                } else {

                    TAX_CASE = typeOfTax();
                    var incl_tax_num = 0;
                    var productDiscount = 0;
                    if (product.discount_amount && product.discount_amount !== 0 && product.TaxStatus !== 'none' && (product.isTaxable == true/*||single_product.Taxable==true*/)) {
                        if (TAX_CASE == 'incl') {
                            incl_tax_num = getInclusiveTax(product.Price - discount, product.TaxClass)
                        }
                    }
                    var pprice = product_list.old_price;
                    var discount_percent = NumberWiseDiscount(pprice * qty, discount)
                    // if (discount_percent > 100) {
                    //     //var data = { title: "", msg: LocalizedLanguage.discountMoreThenMsg, is_success: true }
                    //     //store.dispatch(popupMessage(data));

                    // } else 
                    {

                        discount_amount_is = percentWiseDiscount(price * qty, discount_percent)
                       if(discount_amount_is && discount_amount_is>0){
                        afterDiscount = price * qty - discount_amount_is;
                        if (isProdAddonsType && isProdAddonsType == true) {
                            afterDiscount = price - incl_tax_num - discount_amount_is; // minus incl_tax_num for addons in case of number discount
                        }
                       }
                        product_after_discount = afterDiscount;
                        product_discount_amount = parseFloat(discount_amount_is);
                        //var total_tax = afterDiscount + (afterDiscount * Tax / 100.00);
                    }
                }
            }
            if (single_product && single_product !== null) {
                single_product["product_after_discount"] = parseFloat(product_after_discount)
                single_product["product_discount_amount"] = parseFloat(product_discount_amount)
                single_product["after_discount"] = afterDiscount
                single_product["discount_amount"] = parseFloat(cart_discount_amount) + parseFloat(product_discount_amount);
                single_product["discount_type"] = product.discountType ? product.discountType : ''
                // single_product["new_product_discount_amount"] = discount;
                return single_product;
            }
        }
    }
    else {
        return null;
    }
}