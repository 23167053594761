import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import imglogo from '../../assets/images/svg/Oliver-Horizontal.svg'
import imgGoogle from '../../assets/images/svg/google-logo.svg'
import imgFaceBook from '../../assets/images/svg/facebook-logo.svg'
import imgApple from '../../assets/images/svg/apple-logo.svg'
import Checkmark from '../../assets/images/svg/Checkmark.svg'
import NoAccountBanner from "../../assets/images/svg/NoAccountBanner.svg"
import OliverIconeColor from "../../assets/images/svg/Oliver-Icon-Color.svg"
import OliverHorozontal from "../../assets/images/svg/Oliver-Horizontal.svg"
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { userLogin, userExternalLogin } from '../login/loginSlice';
import { GetUserProfileLogin } from '../login/googleLoginSlice';
import STATUSES from "../../constants/apiStatus";
import Config from "../../Config";
import { LoadingModal } from "../common/commonComponents/LoadingModal";
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import { initDB, useIndexedDB } from 'react-indexed-db';
import { GetOpenRegister, closeRegister } from '../cashmanagement/CashmanagementSlice'
import { tile } from "../dashboard/tiles/tileSlice";
import { checkForEnvirnmentAndDemoUser } from "../../settings/CommonJS";
import { sendFireBaseTokenToAdmin } from "../firebase/FirebaseNotifications";
import { NavigateComponent, RememberData } from "../../settings/CommonModuleJS";
import { validatePin } from "../pinPage/pinSlice";
import { GTM_ClientDetail } from "../common/CommonfunctionGTM";
import { DBConfig } from "../../DBConfig";
import { sendDataToWrapper } from "../../settings/AndroidIOSConnect";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { checkNotifyPermission } from "../../settings/CommonWork";


function Login() {
    var auth2 = ''
    const googleLoginBtn = useRef(null);
    const navigate = useNavigate();
    const [userEmail, setName] = useState("")

    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState();
    //const [rememberme, setRememberme] = useState(localStorage.getItem("rememberme") ? localStorage.getItem("rememberme") === "true" ? true : false : false);

    const [rememberme, setRememberme] = useState(true);
    const [noAccount, setNoAccount] = useState(false);

    const [googleUserData, setGoogleUserData] = useState(null)
    //Clear index db-----------------------------
    var { clear: customers_clear } = useIndexedDB('customers');
    var { clear: modifiers_clear } = useIndexedDB('modifiers');
    var { clear: products_clear } = useIndexedDB('products');
    var _error="";
    if (!sessionStorage.getItem("lastUrl")) {
        customers_clear().then(() => {
            //console.log('All Clear customers!');
        });
        modifiers_clear().then(() => {
            // console.log('All Clear modifiers!');
        });
        products_clear().then(() => {
            //console.log('All Clear products!');
        });
        //Trigger this listener when the view has changed
        sendDataToWrapper("current_view", "login", "");
    }
    //-------------------------------------------

    //TIZEN WRAPPER -> USING MESSAGE EVENT SETTING LOCAL STORAGE
    window.addEventListener('message', function (e) {
        var data = e && e.data;
        if (typeof data == 'string' && data !== "" && e.data === "isTizenWrapper") {
            localStorage.setItem("isTizenWrapper", 'true')
        }
        if (typeof data == 'string' && data !== "" && e.data === "disableScanner") {
            localStorage.setItem("disableScanner", 'true');
        }
    });
    const doRemeber = () => {
        setRememberme(!rememberme);
        if (rememberme == false) {
            localStorage.setItem("rememberme", "true")
        }
        else {
            localStorage.setItem("rememberme", "false")
            localStorage.removeItem("remember_data");
        }
    }
    useEffect(() => {
        //localStorage.setItem("rememberme", "true");
        if (localStorage.getItem("rememberme") && localStorage.getItem("rememberme") == "true") {
            if (localStorage.getItem("remember_data")) {
                RememberData("get");
                NavigateComponent(navigate,'pin');                
                return;
            }
        }
        // check the user logged in with mobile send sending data in queary string------------
        var urlParam = window.location.search;
        var splParam = urlParam.replace("?", "").split("&");
        var finalParam = ""
        splParam.forEach(element => {
            finalParam += finalParam == "" ? "" : "&";
            if (element.substring(0, element.indexOf('=')) === "muserdata")
                finalParam += decodeURI(element.substring(element.indexOf('=') + 1));
        });
        if (finalParam !== null && finalParam !== "") {
            var jsondata = JSON.parse(finalParam);
            var sitelist = jsondata ? jsondata.content : null;

            var activeSubscription= sitelist.subscriptions.find(sub=>(sub.subscription_detail.url !==null && sub.subscription_detail.url !==""))
            console.log("activeSubscription",activeSubscription)
            var userSubscription = activeSubscription ? activeSubscription : null;
            if (userSubscription) {
                // sessionStorage.setItem("AUTH_KEY", userSubscription.subscription_detail.client_guid + ":" + userSubscription.subscription_detail.server_token);
                localStorage.setItem("AUTH_KEY", userSubscription.subscription_detail.client_guid + ":" + userSubscription.subscription_detail.server_token);
            }
            // var lang = userSubscription && userSubscription.subscription_permission.language ? userSubscription.subscription_permission.language : 'en';
            var lang = userSubscription && userSubscription.language ? userSubscription.language : 'en';
            localStorage.setItem("LANG", lang);

            if (sitelist) {
                localStorage.setItem('sitelist', JSON.stringify(sitelist));
                var userID = '';
                localStorage.setItem('userId', sitelist.UserId)
                localStorage.setItem("clientDetail", JSON.stringify(userSubscription));
                localStorage.setItem("hasPin", sitelist.HasPin && sitelist.HasPin);
            }
            // Call API to send fairebase token to Admin----------------------
            var isValidENV = checkForEnvirnmentAndDemoUser()
            if (isValidENV == true && isIOS == false && isSafari == false) { // call notification functionality only on dev1 and qa1 (development)
                sendFireBaseTokenToAdmin(dispatch);
            }
            //----------------------------------------------------------------   

            var client = userSubscription;
            //localStorage.getItem("clientDetail") ? JSON.parse(localStorage.getItem("clientDetail")) : null;
          

            setTimeout(() => {
                //history.push('/site_link');
                //this.doRemember();
                //isShowWrapperSetting("LoginView.js->DidMount",'site_link','push');
                navigate('/site');
            }, 200);
        }
    })

    //It will clear all local storage items
    const clearLocalStorages = () => {
        //localStorage.clear();
        localStorage.removeItem("cloudPrinters");
        localStorage.removeItem("DemoGuid");
        localStorage.removeItem("VisiterUserID");
        localStorage.removeItem("VisiterUserEmail");
        localStorage.removeItem("shopstatus");
        localStorage.removeItem("UserLocations");
        localStorage.removeItem("userId");
        localStorage.removeItem("LANG");
        localStorage.removeItem("AdCusDetail")
        localStorage.removeItem("clientDetail");
        localStorage.removeItem("selectedRegister");
        localStorage.removeItem("RegisterPermissions");
        localStorage.removeItem("user");
        localStorage.removeItem("demoUser");
        localStorage.removeItem("productcount");
        localStorage.removeItem("PRODUCTX_DATA");
        localStorage.removeItem("GET_EXTENTION_FIELD");
        localStorage.removeItem("GroupSaleRecord")


        localStorage.removeItem("shopstatus");
        localStorage.removeItem("UserLocations");
        localStorage.removeItem("userId");
        localStorage.removeItem("LANG");
        localStorage.removeItem("AdCusDetail")
        localStorage.removeItem("clientDetail");
        localStorage.removeItem("selectedRegister");
        localStorage.removeItem("RegisterPermissions");
        localStorage.removeItem("user");
        localStorage.removeItem("demoUser");
        localStorage.removeItem("PRODUCTX_DATA");
        localStorage.removeItem("CART");
        localStorage.removeItem("firebaseStaffName");
        localStorage.removeItem("firebaseSelectedRegisters");
        localStorage.removeItem("pdf_format");
        localStorage.removeItem("CustomerList");
        localStorage.removeItem("FAV_LIST_ARRAY");
        localStorage.removeItem("FAVROUTE_LIST_ARRAY");
        localStorage.removeItem("categorieslist");
        localStorage.removeItem("WarehouseId");
        localStorage.removeItem("DEFAULT_TAX_STATUS");
        localStorage.removeItem("APPLY_DEFAULT_TAX");
        localStorage.removeItem("oliver_refund_order_payments");


        localStorage.removeItem("ELECTED_TAX");
        localStorage.removeItem("CASH_ROUNDING");
        localStorage.removeItem("Location");
        localStorage.removeItem("statelist");
        localStorage.removeItem("pos-posdeviceid");
        localStorage.removeItem("UDID");
        localStorage.removeItem("registerName");
        localStorage.removeItem("user_full_name");
        localStorage.removeItem("CUSTOMER_TO_ACTVITY");
        localStorage.removeItem("Cash_Management_ID");
        localStorage.removeItem("CARD_PRODUCT_LIST");
        localStorage.removeItem("hasPin");
        localStorage.removeItem("countrylist");
        localStorage.removeItem("orderreciept");
        localStorage.removeItem("check_subscription_status_datetime");
        localStorage.removeItem("PRODUCT_REFRESH_DATE");
        localStorage.removeItem("notifyList");
        localStorage.removeItem("sitelist");
        localStorage.removeItem("recent_apps");
        localStorage.removeItem("LocationName");
        localStorage.removeItem("CART_QTY");
        localStorage.removeItem("TAX_SETTING");
        localStorage.removeItem("SHOP_TAXRATE_LIST");
        localStorage.removeItem("TAXT_RATE_LIST");
        localStorage.removeItem("PAYMENT_TYPE_NAME");
        localStorage.removeItem("discountlst");
        localStorage.removeItem("user_List");
        localStorage.removeItem("SELECTED_TAX");
        localStorage.removeItem("SELECTED_MODIFIERS")

        sessionStorage.removeItem("CUSTOMER_ID")
        sessionStorage.removeItem("backUser")
        sessionStorage.removeItem("lastUrl")
        sessionStorage.removeItem("user_shoplogo_base64")
        localStorage.removeItem("companylogo_base64");
        localStorage.removeItem("advertise_image_base64");
         localStorage.removeItem("hasNotifyPermission");
         localStorage.removeItem('customer_advertise_images');
    }
    const dispatch = useDispatch();
    const { status, data, error, is_success } = useSelector((state) => state.login)
    //console.log("status", status, "data", data, "error", error, "is_success", is_success)
    dispatch(validatePin(null))
    const { g_status, g_data, g_error, g_is_success } = useSelector((state) => state.googleLogin)
    //console.log("GoogleData", g_status, g_data, g_error, g_is_success);
    // if (status == STATUSES.IDLE && is_success) {
    //     // var isValidENV = checkForEnvirnmentAndDemoUser()
    //     // if (isValidENV == true) { // call notification functionality only on dev1 and qa1 (development)
    //     //     sendFireBaseTokenToAdmin(dispatch)
    //     // }
    //     dispatch(GetOpenRegister(0));
    //     dispatch(tile("clear"));
    //     navigate('/site')
    // }

    // call server on the bassis of facbook and google response 
    const CallService = (FGdata) => {
        console.log("FGdata", FGdata)
        clearLocalStorages()
        dispatch(userExternalLogin(FGdata))
    }
    //Set google profile Data ----------------------------
    var gdata = ''
    if (g_status == STATUSES.IDLE && g_is_success == true && g_data) {

        var dob = g_data.birthdays && g_data.birthdays[0].date
        gdata = {
            FirstName: g_data.names && g_data.names[0] ? g_data.names[0].givenName : '',
            LastName: g_data.names && g_data.names[0] ? g_data.names[0].familyName : '',
            Id: g_data.metadata && g_data.metadata.sources ? g_data.metadata.sources[0].id : '',
            picture: g_data.photos ? g_data.photos[0].url : '',
            Gender: g_data.genders ? g_data.genders[0].formattedValue : '',
            Dob: dob && dob.year ? dob.year + "-" + dob.month + "-" + dob.day : '',
            AgeRange: g_data.ageRanges ? g_data.ageRanges[0].ageRange : '',
            Address: g_data.locations && g_data.locations[0] && g_data.locations[0].value ? g_data.locations[0].value : '',
            AccessToken: googleUserData ? googleUserData.access_token : '',
            userLoginInfo: googleUserData ? googleUserData.userLoginInfo : '',
            DefaultUserName: googleUserData ? googleUserData.DefaultUserName : '',
            PhoneNumber: googleUserData && googleUserData.PhoneNumber ? googleUserData.PhoneNumber : '',
            Email: googleUserData ? googleUserData.Email : '',
            ClientGuid: googleUserData ? googleUserData.ClientGuid : '',
            SendAuthToken: googleUserData ? googleUserData.SendAuthToken : '',
            ModelName: googleUserData ? googleUserData.ModelName : '',
            DeviceId: googleUserData ? googleUserData.DeviceId : '',
            Version: googleUserData ? googleUserData.Version : '',
            CountryName: googleUserData && googleUserData.country ? googleUserData.country : '',
            CityName: googleUserData && googleUserData.city ? googleUserData.city : '',

        }

        // this.setState({ userProfileData: g_data })
        // if(this.state.isCallService == true){           

        // }
    }

    var gExternalLogin = false;
    useEffect(() => {// call external login for google use once
        if (gExternalLogin === false && (g_status == STATUSES.IDLE && g_is_success && gdata !== '')) {
            CallService(gdata);
            gExternalLogin = true;
        }
    }, [g_is_success])

    const handleKey = (e) => {
        var key = e.which || e.keyCode;
        if (key === 13) {
            handleSubmit(e);
        }
    }

    //  demo user login. cafe@oliver.com
    var isCalled = false;
   // console.log("window.location.hostname",window.location.hostname)
    useEffect(() => {
        if (window.location.hostname == Config.key.DEMO_USER_URL && isCalled === false) {
            dispatch(userLogin({ "email": Config.key.DEMO_USER_EMAIL, "password": Config.key.DEMO_USER_PASSWORD }))
            isCalled = true;
        }
    }, [])


    const handleSubmit = (e) => {
        clearLocalStorages();
        if (userEmail && password) {
            setLoginError('');
            // if (this.state.check == false) {  //$('#remember').attr('checked')              

            //     cookies.set('user', '');
            //     cookies.set('pwd', '');
            // }
            dispatch(userLogin({ "email": userEmail, "password": password }))

            // this.state.username = "";
            // this.state.password = "";

        } else {
            if (!userEmail && !password) {
                setLoginError('Email and Password is required');
                // $('#username').focus();
            } else if (!userEmail) {
                setLoginError('Email is required');
                // $('#username').focus();
            } else {
                setLoginError('Password is required');
                // $('#password').focus();
            }
        }
        e.preventDefault();
    }

    const handleUserLogin = () => {
        dispatch(userLogin({ "email": userEmail, "password": password }))

    }
    // const SetErrro = () => useMemo(() => {
    //     //if (status == STATUSES.ERROR) {
    //     setLoginError(error)
    //     // }
    // })
    // if (status == STATUSES.ERROR) {
    //     SetErrro()
    // }
    if (status == STATUSES.IDLE && is_success) {
        var loginRes = data && data.content;
        if (window.location.hostname !== Config.key.DEMO_USER_URL) {
            checkNotifyPermission()
        }
        if (loginRes && loginRes.subscriptions !== undefined && loginRes.subscriptions.length > 0) {
            var activeSubscription= loginRes.subscriptions.find(sub=>(sub.subscription_detail.url !==null && sub.subscription_detail.url !==""))
            if(activeSubscription){
            var userSubscription = activeSubscription;
            // var userSubscriptionList = loginRes.subscriptions.filter(m => m.subscription_detail.host_name != null);
            // if (userSubscriptionList != null && typeof userSubscriptionList != "undefined" && userSubscriptionList.length > 0) {
            //     userSubscription = userSubscriptionList[0];
            // }
            // userSubscription && sessionStorage.setItem("AUTH_KEY", userSubscription.subscription_detail.client_guid + ":" + userSubscription.subscription_detail.server_token);

            // if (userSubscription && userSubscription.subscription_detail && userSubscription.subscription_detail.bridge_status === "Not Connected") {
            //     alert(LocalizedLanguage.BridgeNotConnected);
            // }
            userSubscription && localStorage.setItem("AUTH_KEY", userSubscription.subscription_detail.client_guid + ":" + userSubscription.subscription_detail.server_token);
            var lang = userSubscription && userSubscription.language ? userSubscription.language : 'en';
            // var lang = userSubscription && userSubscription.subscription_permission.language ? userSubscription.subscription_permission.language : 'en';
            localStorage.setItem("LANG", lang);
            localStorage.setItem('sitelist', JSON.stringify(loginRes))
            localStorage.setItem('userId', loginRes.UserId)
            localStorage.setItem("clientDetail", JSON.stringify(userSubscription));
            localStorage.setItem("hasPin", loginRes.HasPin && loginRes.HasPin);
            setTimeout(() => {
                GTM_ClientDetail();
            }, 1000);
            dispatch(userLogin("clear"));
            var isValidENV = checkForEnvirnmentAndDemoUser()
            if (isValidENV == true && isIOS == false && isSafari == false) { // call notification functionality only on dev1 and qa1 (development)
                sendFireBaseTokenToAdmin(dispatch)
            }
            navigate('/site')
            

        }else{
            _error=LocalizedLanguage.BridgeNotConnected;
        }
    }

}
    var checkOnce = false;
    useEffect(() => {//Checking the FB Google login Responce. If No subscription then show the error popup
        if (checkOnce === false && (status == STATUSES.IDLE && is_success)) {
            if (loginRes && loginRes.subscriptions !== undefined && loginRes.subscriptions.length <= 0) {
                setNoAccount(true);
                checkOnce = true;
            }
        }
    }, [is_success])

    const handleNameChange = (e) => {
        // console.log("event",e.target.value);
        setName(e.target.value);
    }

    const handlePasswordChange = (e) => {
        // console.log("event",e.target.value);
        setPassword(e.target.value);
    }


    const responseFacebook = (response) => {
        localStorage.removeItem('FGLoginData');
        console.log("responseFacebook", response);
        if (response && response.status !== "unknown") {
            //  this.setState({ isLoginSuccess: "true" })
            localStorage.setItem('FGLoginData', JSON.stringify(response))
            var dob = response && response.birthday ? response.birthday.split('/') : ''
            var formatedDob = dob && dob.length == 3 ? '' + dob[2] + '-' + dob[1] + '-' + dob[0] : ''
            var sendRes = {
                userLoginInfo: {
                    LoginProvider: response && response.graphDomain ? response.graphDomain : "",
                    ProviderKey: response && response.id ? response.id : ""
                },
                DefaultUserName: response && response.name ? response.name : "",
                Email: response && response.email ? response.email : '',
                ClientGuid: "",
                SendAuthToken: response && response.accessToken ? response.accessToken : "",
                ModelName: "",
                DeviceId: "",
                Version: "",
                FirstName: response && response.first_name ? response.first_name : "",
                LastName: response && response.last_name ? response.last_name : "",
                AccessToken: response && response.accessToken ? response.accessToken : "",
                Picture: response && response.picture ? response.picture.data && response.picture.data.url : "",
                Gender: response && response.gender ? response.gender : "",
                PhoneNumber: response && response.phoneNumber ? response.PhoneNumber : '',
                Dob: formatedDob,
                AgeRange: response && response.age_range && response.age_range.min ? response.age_range.min : "",
                // Address: response && response.address ? response.address : "",
                Address: response && response.location && response.location.name ? response.location.name : "",
                CountryName: response && response.country ? response.country : "",
                CityName: response && response.city ? response.city : "",

            }

            CallService(sendRes);
        } else {
            // this.setState({ isLoginSuccess: "false" })
        }
    }



    // get user profile information
    const getProfileDetails = (param) => {
        var data = {
            access_token: param && param.access_token,
            userId: param && param.userLoginInfo.ProviderKey
        }
        dispatch(GetUserProfileLogin(data))


    }



    const componentClicked = () => {
        // this.setState({ isLoginSuccess: "" })
        console.log("componentClicked");
    }

    const responseGoogle = (response) => {
        console.log(response);
    }

    // Life Cycle Method-----------
    useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
            // Handle successful response.
            singInApple();
            console.log("---AppleIDSignInOnSuccess----" + JSON.stringify(event));
        });
        // Listen for authorization failures.
        document.addEventListener('AppleIDSignInOnFailure', (event) => {
            // Handle error.
            console.log("---AppleIDSignInOnFailure---" + event.detail.error);
        });
        //Apple login Listner END

        googleSDK();
        appleLogin();
        localStorage.removeItem("user");
        localStorage.removeItem("IsCashDrawerOpen");

    })

    //Apple login methods Start
    const appleLogin = () => {
        let appleConnectLoaded = (AppleID) => {
            AppleID.auth.init({
                clientId: "sellbeta.oliverpos.com",
                scope: 'name email',
                state: 'origin:web',
                redirectURI: Config.key.APPLE_LOGIN_RETURN_URL,
                usePopup: true
            });
            setTimeout(() => {//To Remove the default apple logo
                // $("svg text").text('Sign in with Apple')
                // $("svg text").text($("svg text").text().substring(1));
                //  $("svg text").removeAttr("textLength");
                // $("svg text").css("fontFamily", "Poppins, Helvetica, sans-serif");
                //   $("svg text").css("font-size", "0.8rem");           
            }, 100);
        };

        (function (d, s, cb) {
            var js, fjs = d.getElementsByTagName(s)[0];
            js = d.createElement(s);
            js.src = "//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
            fjs.parentNode.insertBefore(js, fjs);
            js.addEventListener("load", () => cb(window.AppleID));
        }(document, 'script', appleConnectLoaded));
    }
    const singInApple = async () => {
        try {
            const response = await window.AppleID.auth.signIn();
            if (response != null && typeof response != "undefined") {
                localStorage.removeItem('FGLoginData');
                localStorage.setItem('FGLoginData', JSON.stringify(response));
                console.log("successful login with apple--" + JSON.stringify(response))
                var code = '';
                var id_token = '';
                var state = '';
                var fname = '';
                var lname = '';
                var email = '';
                if (response.authorization && typeof response.authorization != "undefined") {
                    code = response.authorization.code;
                    id_token = response.authorization.id_token;
                    state = response.authorization.state;
                }
                if (response.user && typeof response.user != "undefined" && response.user.name && response.user.email) {
                    fname = response.user.name.firstName;
                    lname = response.user.name.lastName;
                    email = response.user.email;
                }
                if (id_token != "") {
                    var _data = parseJwt(id_token);
                    if (_data != null && _data.email) {
                        email = _data.email;
                    }
                }
                var data = {
                    FirstName: fname,
                    LastName: lname,
                    Email: email,
                    Id: '',
                    picture: '',
                    Gender: '',
                    Dob: '',
                    AgeRange: '',
                    Address: '',
                    AccessToken: id_token,
                    userLoginInfo: {
                        LoginProvider: "apple",
                        ProviderKey: ""
                    },
                    DefaultUserName: '',
                    PhoneNumber: '',
                    ClientGuid: '',
                    SendAuthToken: id_token != '' ? true : false,
                    ModelName: '',
                    DeviceId: '',
                    Version: '',
                    CountryName: '',
                    CityName: ''
                }
                CallService(data);

            }
        }
        catch (error) {
            console.log("---singInApple---catch--");
            // Handle error.
        }
    };





    const googleSDK = () => {
        // this.setState({ isLoginSuccess: "" })
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                auth2 = window['gapi'].auth2.init({
                    client_id: Config.key.GOOGLE_CLIENT_ID,
                    cookiepolicy: 'single_host_origin',
                    scope: 'openid profile email https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/profile.agerange.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read',
                    include_granted_scopes: true
                    // access_type:"offline",
                    // response_type:"code"
                });
                prepareGoogleLoginButton();
            });
        }

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));

    }

    const prepareGoogleLoginButton = () => {
        localStorage.removeItem('FGLoginData');
        // console.log('------ref-----------', googleLoginBtn.current);
        auth2.attachClickHandler(googleLoginBtn.current, {},
            (googleUser) => {
                localStorage.setItem('FGLoginData', JSON.stringify(googleUser));
                var profile = googleUser.getBasicProfile();
                console.log("profile", profile);
                console.log('Token || ' + googleUser.getAuthResponse().id_token);
                console.log('ID: ' + profile.getId());
                console.log('Name: ' + profile.getName());
                console.log('Image URL: ' + profile.getImageUrl());
                console.log('Email: ' + profile.getEmail());
                // console.log("Birthday: " + profile.getBirthday());
                //YOUR CODE HERE           
                if (googleUser) {
                    var sendRes = {
                        userLoginInfo: {
                            LoginProvider: "google",
                            ProviderKey: profile && profile.getId() ? profile.getId() : ""
                        },
                        DefaultUserName: profile && profile.getName() ? profile.getName() : "",
                        Email: profile && profile.getEmail() ? profile.getEmail() : '',
                        ClientGuid: "",
                        SendAuthToken: googleUser && googleUser.getAuthResponse() && googleUser.getAuthResponse().id_token ? googleUser.getAuthResponse().id_token : "",
                        access_token: googleUser && googleUser.getAuthResponse() && googleUser.getAuthResponse().access_token ? googleUser.getAuthResponse().access_token : "",
                        ModelName: "",
                        DeviceId: "",
                        Version: "",
                        // FirstName: profile.getGivenName(),
                        // LastName: profile.getFamilyName(),
                        FirstName: '',
                        LastName: "",
                        Id: "",
                        picture: '',
                        Gender: '',
                        PhoneNumber: '',
                        Dob: '',
                        AgeRange: ''
                    }
                    setGoogleUserData(sendRes);
                    getProfileDetails(sendRes)

                }

            }, (error) => {
                // this.setState({ isLoginSuccess: "false" })
                // alert(JSON.stringify(error, undefined, 2));
            });

    }



    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };


    const handleSignInClick = () => {      
        window.location = "https://onboarding.oliverpos.com/"
    }

    // if (status == STATUSES.LOADING) {
    //     return <div> Loading... </div>
    // }
     _error = loginError != "" ? loginError : error !== "" ? error : "";

    var _location = localStorage.getItem("Location")
    var _clientDetail = localStorage.getItem("clientDetail")
    var isCallable = true;
    //    useEffect(() =>{    
    //            if(isCallable==true){
    //                 const request = indexedDB.open("POSDB")
    //                 let db;      
    //                 request.onsuccess = () =>{
    //                     db = request.result;
    //                     // console.log(db.objectStoreNames.contains('products'),"storename")
    //                     if(!db.objectStoreNames.contains('products') //|| !db.objectStoreNames.contains('customers') || !db.objectStoreNames.contains('modifiers')
    //                     ){
    //                         // alert('available')
    //                         window.location.reload();
    //                     }
    //                 } 
    //                 isCallable=false;
    //             }      
    //       },[_clientDetail,_location])

    // ! delete set Interval code
    //   setInterval(() => {
    //     console.log('i am working')
    //     const request = indexedDB.open("POSDB")
    //     let db;
    //     request.onsuccess = () =>{
    //         db = request.result;
    //         console.log(db.objectStoreNames.contains('products'),"storename")
    //         if(!db.objectStoreNames.contains('products') || !db.objectStoreNames.contains('customers') || !db.objectStoreNames.contains('modifiers')){
    //             alert('available')
    //             window.location.reload();
    //         }
    //     }
    //   }, 2000);
    return (
        <React.Fragment>{status == STATUSES.LOADING ? <LoadingModal></LoadingModal> : null}
            <div className="login-wrapper">
                <div className="auto-margin-top"></div>
                {/* counter: {counter} */}
                <img src={imglogo} />
                <p >{LocalizedLanguage.SignintoyourOliverPOSAccount}</p>
                {/* {error !== "" && <div className="danger">{error} </div>} */}
                {(_error && _error !== "") &&
                    <div className="custom-error-message user-info">
                        {_error}
                    </div>}
                <form className="login-form">
                    <label htmlFor="email">{LocalizedLanguage.email}</label>
                    <input type="text" id="email" placeholder={LocalizedLanguage.EnterEmail} onKeyUp={(e) => handleKey(e)} onChange={(e) => handleNameChange(e)} />
                    <label htmlFor="password">{LocalizedLanguage.password}</label>
                    <input type="password" id="password" placeholder={LocalizedLanguage.EnterPassword} onKeyUp={(e) => handleKey(e)} onChange={(e) => handlePasswordChange(e)} />
                    <div className="row">
                        <a href="https://hub.oliverpos.com/forgotpassword" >{LocalizedLanguage.forgetPass}</a>
                        <label className="custom-checkbox-wrapper">
                            <input type="checkbox" checked onChange={() => null} />
                            <div className="custom-checkbox" onClick={() => doRemeber()}>
                                <img src={Checkmark} alt="" style={{ "display": rememberme === true ? "block" : "none" }} onClick={rememberme == true ? localStorage.setItem("rememberme", "true") : localStorage.setItem("rememberme", "false")} />
                            </div>
                            {LocalizedLanguage.RememberMe}
                        </label>
                    </div>
                    <button type="button" onClick={handleSubmit} onKeyUp={(e) => handleKey(e)}>{LocalizedLanguage.signin}</button>
                </form>
                <div className="or-row">
                    <div className="divider"></div>
                    <p>{LocalizedLanguage.or}</p>
                    <div className="divider"></div>
                </div>
                <button id="googleButton" ref={googleLoginBtn} type="submit"   >
                    <div className="img-container">
                        <img src={imgGoogle} alt="" />

                    </div>
                    Sign in with Google

                </button>


                {/* <GoogleLogin
            clientId={Config.key.FACEBOOK_CLIENT_ID}
            buttonText=" Sign in with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
        /> */}


                <button id="facebookButton">
                    <div className="img-container">
                        <img src={imgFaceBook} alt="" />
                    </div>
                    <FacebookLogin cssClass="btn user_login_fb_on"
                        appId={Config.key.FACEBOOK_CLIENT_ID}
                        autoLoad={false}
                        fields="first_name, last_name,name,email"
                        scope="public_profile, email"
                        onClick={componentClicked}
                        callback={responseFacebook}
                        textButton="Sign in with Facebook"
                        isMobile={!isMobile}
                    />


                </button>


                <button type="submit" id="appleid-signin" title="Log in using your Apple account"
                    data-color="black" data-mode="center-align" data-height="40" data-border="true" data-type="sign-in" data-border-radius="4"
                    className="appleButton">
                    <div className="img-container" >
                        <img src={imgApple} alt="" />
                    </div>
                    {LocalizedLanguage.SigninwithApple}
                </button>

                <div className="row">
                    <p>{LocalizedLanguage.Donthaveanaccount}</p>
                    <a href="#" onClick={() => handleSignInClick()} >{LocalizedLanguage.SignupNow}</a>
                </div>
                <div className="auto-margin-bottom"></div>

            </div>

            <div className={noAccount == true ? "subwindow-wrapper" : "subwindow-wrapper hidden"}>
                <div className={noAccount == true ? "subwindow no-account current" : "subwindow no-account"} >
                    <div className="subwindow-body">
                        <img src={NoAccountBanner} alt="" />
                        <div className="content">
                            <img src={OliverIconeColor} alt="" className="landscape-only" />
                            <img src={OliverHorozontal} alt="" className="mobile-only" />
                            <p className="mobile-only">{LocalizedLanguage.SignintoyourOliverPOSAccount}</p>
                            <p className="style1">
                                {LocalizedLanguage.noaccount} <br />
                                {LocalizedLanguage.registeredforthisemail}
                            </p>
                            <p className="style2">
                                {LocalizedLanguage.emailprovideddoesnotappear} <br />
                                {LocalizedLanguage.registeredaccountwithOliver} <br />
                                {LocalizedLanguage.followinglinktryagain}
                            </p>
                            <button id="createAccount" onClick={() => handleSignInClick()}> {LocalizedLanguage.CreateAccount}</button>
                            <button id="loginTryAgain" onClick={() => { setNoAccount(false) }}>{LocalizedLanguage.tryAgain}</button>
                            <div className="row">
                                <p>{LocalizedLanguage.QuestionsorIssues}</p>
                                <a href="https://oliverpos.com/resources/support/">{LocalizedLanguage.Contactus}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
};

export default Login;