import React, { useState, useEffect, useRef } from "react";
import X_Icon_DarkBlue from '../../assets/images/svg/X-Icon-DarkBlue.svg';
import Stripe_Icon from '../../assets/images/svg/Stripe Icon.svg'
import CashButtonImage from '../../assets/images/svg/CashButtonImage.svg';
import CardButtonImage from '../../assets/images/svg/CardButtonImage.svg';
import { chunkArray } from "../common/localSettings";
import LocalizedLanguage from "../../settings/LocalizedLanguage";
import { v4 as uniqueKey } from 'uuid';
import { currecyFunction } from "../common/commonFunctions/currencyFunction";
import { isAutoFocus } from "../../settings/AndroidIOSConnect";
const PartialPayment = (props) => {
    const inputElement = useRef(null);
    const [txtValue, setTxtValue] = useState(0)
    const [pbpCount, setpbpCount] = useState(0)
    const [partialAmount, setPartialAmount] = useState("");
    const [enterAmountErr, setEnterAmountErr] = useState(false)

    const outerClick = (e) => {
        if (e && e.target && e.target.className && e.target.className === "subwindow-wrapper") {
            props.toggleShowPartialPayment();
            localStorage.removeItem("paybyproduct_unpaid");
        }
    }
    const popupClose = () => {
        props.toggleShowPartialPayment();
        localStorage.removeItem("paybyproduct_unpaid");
    }
    useEffect(() => {
        if (props.getRemainingPrice && props.partialType == "") {
            var _amount = props.getRemainingPrice();
            setTxtValue(parseFloat(_amount).toFixed(2));
            if (isAutoFocus == true)
                inputElement.autoFocus = true;
        }
        else if (props.getRemainingPrice && props.partialType != "") {
            if (props.partialType === "pay_by_product") {
                var paybyproduct = localStorage.getItem("paybyproduct_unpaid") ? JSON.parse(localStorage.getItem("paybyproduct_unpaid")) : null;
                var _amount = 0;
                if (paybyproduct != null && paybyproduct.length > 0) {
                    paybyproduct.map(p => {
                        _amount += p.total;
                    });
                    _amount = Math.fround(_amount).toFixed(2)
                    setTxtValue(_amount);
                    var count = paybyproduct.filter(a => a.hasOwnProperty("unpaid_qty") && a.unpaid_qty > 0 && (!a.hasOwnProperty("is_pid") || (a.hasOwnProperty("is_pid") && a.is_pid==false)));
                    setpbpCount(count != null && typeof count != "undefined" ? count.length : 0);
                }
            }
            else {
                var _amount = props.getRemainingPrice();
                var partialby = parseInt(props.partialType);
                var getPayments = (typeof localStorage.getItem("oliver_order_payments") !== "undefined") ? JSON.parse(localStorage.getItem("oliver_order_payments")) : [];
                if (getPayments && getPayments.length > 0) {
                    var count = getPayments.length;
                    if (props.partialType == 2) {
                        partialby = partialby - count;
                    }
                    else if (props.partialType == 3) {
                        partialby = partialby - count;
                    }
                    else if (props.partialType == 4) {
                        partialby = partialby - count;
                    }
                    //localStorage.setItem("payType", "lastToComplete");
                }
                else if (props.partialType == 2 && getPayments == null) {
                    partialby = partialby - 0;
                }

                setTxtValue(parseFloat(_amount / partialby).toFixed(2));
            }

        }
        // if(props && props.amount)
        // setTxtValue(props.amount);
    }, [props.amount]);
    const setValue = (type) => {
        setEnterAmountErr(true)
        if (partialAmount.length <= 0 && props.partialType == "") {
            return;
        }
        props.pay_partial && props.pay_partial(txtValue, type, props.partialType);
    }
    const onChange = (e) => {
        const re = /^[0-9\.]+$/;
        var val = e.target.value
        setPartialAmount(val);
        if (val.length) {
            setEnterAmountErr(false);
        }
        if (val === '' || re.test(val)) {
            if (val.split('.').length > 2)
                val = val.replace(/\.+$/, "");
            setTxtValue(val)
        }
    }
    var paymentTypeName = localStorage.getItem("PAYMENT_TYPE_NAME") ? JSON.parse(localStorage.getItem("PAYMENT_TYPE_NAME")) : [];
    //Arranging array to put cash and card type in the first and the second 
    if (paymentTypeName && paymentTypeName.length > 0) {
        var card = paymentTypeName.find(a => a.Name.toLowerCase() === "card");
        var cash = paymentTypeName.find(a => a.Name.toLowerCase() === "cash");
        paymentTypeName = paymentTypeName.filter(a => a.Name.toLowerCase() != "cash" && a.Name.toLowerCase() != "card");
        if (card && typeof card != "undefined")
            paymentTypeName.unshift(card);
        if (cash && typeof cash != "undefined")
            paymentTypeName.unshift(cash);
    }

    return (
        <div className={props.isShow === true ? "subwindow-wrapper" : "subwindow-wrapper hidden"} onClick={(e) => outerClick(e)}>
            <div className={props.isShow === true ? "subwindow partial-payment current" : "subwindow partial-payment"}>
                <div className="subwindow-header">
                    <p> {props.partialType == "" ? `${LocalizedLanguage.partialpayments}` : `${LocalizedLanguage.splitpayments} `}</p>
                    <button className="close-subwindow" onClick={() => popupClose()}>
                        <img src={X_Icon_DarkBlue} alt="" />
                    </button>
                </div>
                <div className="subwindow-body">
                    <div className="auto-margin-top"></div>
                    {props.partialType != "" ?
                        <React.Fragment><br />
                            <div className="partial-text-row">
                                <p className="style1">{LocalizedLanguage.totalbalancedue}:</p>
                                {/* <p className="style2">${parseFloat(props.getRemainingPrice()).toFixed(2)}</p> */}
                                <p className="style2">{currecyFunction(parseFloat(props.getRemainingPrice()).toFixed(2))}</p>
                            </div>
                            <div className="partial-text-row">
                                <p className="style1">{LocalizedLanguage.splitpayments}:</p>
                                <p className="style2">{props.partialType === "pay_by_product" ? LocalizedLanguage.byproduct + "(" + pbpCount + ") " : LocalizedLanguage.person + "(" + props.partialType + ")"}</p>
                            </div>
                            <div className="partial-text-row">
                                <p className="style1">{LocalizedLanguage.amountperpayment}:</p>
                                {/* <p className="style2">${parseFloat(txtValue).toFixed(2)}</p> */}
                                <p className="style2">{currecyFunction(parseFloat(txtValue).toFixed(2))}</p>
                            </div><br />
                        </React.Fragment>
                        // <div className="partial-text-row">
                        //     <label htmlFor="partialPaymentAmount">Total balance due: ${parseFloat(props.getRemainingPrice()).toFixed(2)}</label><br />
                        //     <label htmlFor="partialPaymentAmount">Split Type: {props.partialType === "pay_by_product"?"By Product":"Person("+props.partialType+")"}</label><br />
                        //     <label htmlFor="partialPaymentAmount">Amount per payment: ${txtValue}</label><br /><br />
                        // </div>
                        :
                        <React.Fragment>
                            {enterAmountErr ? <p className="partial_amount_err" >{LocalizedLanguage.amountRequire}</p> : ""}
                            <label htmlFor="partialPaymentAmount">{LocalizedLanguage.enterpartialpayamount}:</label>
                            <input ref={inputElement} autoFocus={isAutoFocus == true ? true : false} type="number" id="partialPaymentAmount" placeholder={txtValue} onChange={e => onChange(e)} disabled={props.partialType == "" ? false : true} />

                        </React.Fragment>
                    }
                    <p>{LocalizedLanguage.paymenttypes}</p>
                    <div className="payment-types">

                        {paymentTypeName && paymentTypeName.length > 0 && paymentTypeName.map(payment => {
                            return payment.image || payment.Code === "stripe_terminal" ?
                                // <img src={payment.image}  alt=""></img>
                                <button onClick={() => setValue(payment)} key={uniqueKey()}>
                                    <img src={Stripe_Icon} alt=""></img></button>
                                :
                                payment.Code === "cash" ? <button onClick={() => setValue(payment)} key={uniqueKey()}>
                                    <img src={CashButtonImage} alt=""></img></button>
                                    :
                                    payment.Code === "card" ? <button onClick={() => setValue(payment)} key={uniqueKey()}>
                                        <img src={CardButtonImage} alt=""></img></button>
                                        :
                                        <button style={{ backgroundColor: payment.ColorCode, borderColor: payment.ColorCode }} onClick={() => setValue(payment)} key={uniqueKey()}>
                                            {payment.Name}
                                        </button>
                        })
                        }

                        {
                            props.extensionPayments && props.extensionPayments.length > 0 && props.extensionPayments.map(exPayment => {
                                return <button style={{ backgroundColor: "var(--oliver-blue)" }} onClick={() => props.ToggleiFrameWindow(exPayment)} key={exPayment.Id} title={exPayment.Name}>
                                    {exPayment.logo != null ? <img src={exPayment.logo} alt=""></img> : exPayment.Name}</button>
                                // {exPayment.logo != null ? "" : exPayment.Name}</button>

                            })
                        }

                        {/* {
                            paymentTypeName && paymentTypeName.length > 0 && paymentTypeName.map(payment => {
                                return <button style={{ backgroundColor: payment.ColorCode, borderColor: payment.ColorCode }} key={payment.Id} onClick={() => setValue(payment)}>
                                    {payment.Name}
                                </button>
                            })
                        } */}
                        {/* <button>Cash</button>
                <button className="background-coral">Card</button>
                <button>
                    <img src="../Assets/Images/SVG/spongebob-squarepants-2.svg" alt="" />
                </button>
                <button className="background-red">Placeholder</button>
                <button className="background-teal">Placeholder</button>
                <button className="background-cyan">Placeholder</button>
                <button className="background-violet">Placeholder</button>
                <button className="background-yellow">Placeholder</button> */}
                    </div>
                    <div className="auto-margin-bottom"></div>
                </div>
            </div></div>)
}
export default PartialPayment 